import {
    MediaInsertionStep,
    MediaInsertionStepType,
    WorkflowLaneModel,
} from 'platform/mediaplan/mediaplan.types';
import '../MediaplanWorkflow.scss';

interface Props {
    stepType: MediaInsertionStepType;
    affectedLanes: WorkflowLaneModel[];
    onBatchChange: (steps: MediaInsertionStep[]) => void;
}

const WorkflowStepColumnCheckbox = ({ stepType, affectedLanes, onBatchChange }: Props) => {
    const isCompleted = ({ steps }: WorkflowLaneModel) => steps[stepType]?.completed;
    const someCompleted = affectedLanes.some(isCompleted);
    const allCompleted = affectedLanes.every(isCompleted);

    return (
        <input
            type="checkbox"
            checked={allCompleted}
            ref={(ref) => {
                if (ref) {
                    // eslint-disable-next-line no-param-reassign
                    ref.indeterminate = someCompleted && !allCompleted;
                }
            }}
            onChange={({ target: { checked } }) =>
                onBatchChange(
                    affectedLanes
                        .filter((lane) => isCompleted(lane) !== checked)
                        .map((lane) => ({
                            mediaInsertionId: lane.mediaInsertion.id!,
                            vendorSystem: lane.vendor.externalSystem,
                            type: stepType,
                            completed: checked,
                        }))
                )
            }
        />
    );
};

export default WorkflowStepColumnCheckbox;
