import IconBadge from 'platform/common/components/IconBadge/IconBadge';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';

const TaskBlockedBadge = () => (
    <Tooltip renderTooltip={() => 'This task cannot be completed until all upstream tasks are finished'}>
        <IconBadge
            className="badge-outline-secondary me-2"
            icon={<i className="fa fa-ban" />}
            text="Blocked"
        />
    </Tooltip>
);

export default TaskBlockedBadge;
