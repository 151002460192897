import { useLayoutEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { debounce } from 'lodash-es';
import { SEARCH_TERM_STRATEGY_LABELS } from 'platform/campaign/campaign/campaign.types';
import ChannelLabel from 'platform/channels/components/ChannelLabel';
import { DATA_TYPES } from 'platform/common/dataTypes';
import useToggle from 'platform/common/hooks/useToggle';
import {
    CustomerJourneyReport,
    JourneyEventType,
    JourneyEventTypeCM360,
} from 'platform/dashboard/dashboard.types';
import FunnelIcons from './FunnelIcons';
import './JourneyRow.scss';

export const getJourneyIconClass = (
    event_type: JourneyEventType | JourneyEventTypeCM360,
    action_status?: string
) => {
    switch (event_type) {
        case 'Site Visit':
            return 'dm-browser text-primary';
        case 'click':
        case 'Media Click':
            return 'far fa-hand-pointer text-dark';
        case 'view':
        case 'Media View':
            return 'far fa-eye text-dark';
        case 'action':
        case 'Action': {
            if (action_status === 'missed') {
                return 'fal fa-shopping-cart text-success';
            }

            return 'fal fa-cart-arrow-down text-success';
        }
        case 'pageview':
            return 'fa-regular fa-window-restore text-primary';
        default:
            return '';
    }
};

const getIconBackgroundColor = (type: JourneyEventType) => {
    switch (type) {
        case 'Site Visit':
            return '#E4F1FF';
        case 'Media Click':
            return '#F9EDBB';
        case 'Media View':
            return '#F9EDBB';
        case 'Action':
            return '#DCF5E7';
        default:
            return '';
    }
};

export const TypeIcon = ({ row }: { row: CustomerJourneyReport }) => (
    <i
        className={classNames(
            'd-flex align-items-center justify-content-center font-xl',
            getJourneyIconClass(row.event_type, row.action_status)
        )}
        style={{
            borderRadius: '50%',
            width: '45px',
            height: '45px',
            backgroundColor: getIconBackgroundColor(row.event_type),
        }}
    />
);

const SiteVisitJourneyItem = ({ row }: { row: CustomerJourneyReport }) => {
    const [isSubpanelOpen, toggleSubpanel] = useToggle(true);
    const [isSegmentsSectionExpanded, toggleSegmentsSection] = useToggle(false);
    const [isToggleSegmentsSectionVisible, setToggleSegmentsVisible] = useState(false);
    const segmentsSectionRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        const measureElement = () => {
            if (segmentsSectionRef.current) {
                setToggleSegmentsVisible(
                    segmentsSectionRef.current.offsetHeight > 23 ||
                        segmentsSectionRef.current.offsetWidth !== segmentsSectionRef.current.scrollWidth
                );
            }
        };

        const debouncedOnResize = debounce(measureElement, 250);

        window.addEventListener('resize', debouncedOnResize);
        setTimeout(measureElement, 0);

        return () => window.removeEventListener('resize', debouncedOnResize);
    }, []);

    const subpanelExists = !!row.journey_visited_segments;

    return (
        <div>
            <div className="d-flex align-items-center">
                <div className="me-4">
                    <TypeIcon row={row} />
                </div>

                <div className="flex-grow-1">
                    <h5>
                        {row.event_type}
                        {subpanelExists && (
                            <Button className="JourneyRow--subpanelButton" onClick={() => toggleSubpanel()}>
                                <i
                                    className={`fas ${isSubpanelOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                                />
                            </Button>
                        )}
                    </h5>
                    <div className="text-muted">{row.journey_datetime}</div>
                </div>

                <div className="text-center" style={{ maxWidth: '300px', flex: '1 1 300px' }}>
                    <h5>{row.journey_pageviews}</h5>
                    <div className="text-muted">Pageviews</div>
                </div>

                <div className="text-center border-left" style={{ maxWidth: '300px', flex: '1 1 300px' }}>
                    <h5>{DATA_TYPES.DURATION_SECONDS.format(row.journey_visit_time)}</h5>
                    <div className="text-muted">Visit time</div>
                </div>

                <div className="text-center border-left" style={{ maxWidth: '300px', flex: '1 1 300px' }}>
                    <h5>
                        <FunnelIcons
                            funnelLetters={
                                row.journey_funnel_levels ? row.journey_funnel_levels.split(',') : []
                            }
                        />
                    </h5>
                    <div className="text-muted">Funnel</div>
                </div>
            </div>
            {isSubpanelOpen && subpanelExists && (
                <div className="JourneyRow--subpanel">
                    {row.journey_visited_segments && (
                        <div className="d-flex align-items-baseline">
                            <div className="d-flex align-items-center">
                                <i className="fal fa-chart-pie" />
                                <div className="JourneyRow--subpanelRowLabel">Segments</div>
                            </div>
                            <div
                                className="d-flex"
                                ref={segmentsSectionRef}
                                style={
                                    !isToggleSegmentsSectionVisible || isSegmentsSectionExpanded
                                        ? { flexWrap: 'wrap' }
                                        : { overflow: 'hidden', whiteSpace: 'nowrap' }
                                }
                            >
                                {row.journey_visited_segments.map((name) => (
                                    <span key={name} className="JourneyRow--badge me-2">
                                        {name}
                                    </span>
                                ))}
                                {isToggleSegmentsSectionVisible && isSegmentsSectionExpanded && (
                                    <div
                                        className="text-primary cursor-pointer"
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => toggleSegmentsSection()}
                                    >
                                        show less
                                    </div>
                                )}
                            </div>
                            {isToggleSegmentsSectionVisible && !isSegmentsSectionExpanded && (
                                <div
                                    className="text-primary cursor-pointer JourneyRow--showAllButton"
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => toggleSegmentsSection()}
                                >
                                    show all
                                </div>
                            )}
                        </div>
                    )}
                    {subpanelExists && <div className="JourneyRow--subpanelSeparator" />}
                </div>
            )}
        </div>
    );
};

const MediaViewJourneyItem = ({ row }: { row: CustomerJourneyReport }) => (
    <div className="d-flex align-items-center">
        <div className="me-4">
            <TypeIcon row={row} />
        </div>

        <div className="flex-grow-1">
            <h5>{row.event_type}</h5>
            <div className="text-muted">{row.journey_datetime}</div>
        </div>

        <div className="text-center" style={{ maxWidth: '300px', flex: '1 1 300px' }}>
            <h5 className="d-flex align-items-center justify-content-center">
                <ChannelLabel withIcon channel={row.channel} />
                {row.vendor_name && row.vendor_name !== row.channel && ` / ${row.vendor_name}`}
                {row.channel === 'SEARCH' &&
                    row.search_term_strategy &&
                    ` / ${SEARCH_TERM_STRATEGY_LABELS[row.search_term_strategy]}`}
            </h5>
            <div className="text-muted">Channel</div>
        </div>

        <div className="text-center border-left" style={{ maxWidth: '300px', flex: '1 1 300px' }}>
            <h5>{row.channel === 'SEARCH' ? row.google_keyword || '-' : row.domain || '-'}</h5>
            <div className="text-muted">{row.channel === 'SEARCH' ? 'Keyword' : 'Domain'}</div>
        </div>
    </div>
);

const MediaClickJourneyItem = ({ row }: { row: CustomerJourneyReport }) => (
    <div className="d-flex align-items-center">
        <div className="me-4">
            <TypeIcon row={row} />
        </div>

        <div className="flex-grow-1">
            <h5>{row.event_type}</h5>
            <div className="text-muted">{row.journey_datetime}</div>
        </div>

        <div className="text-center" style={{ maxWidth: '300px', flex: '1 1 300px' }}>
            <h5 className="d-flex align-items-center justify-content-center">
                <ChannelLabel withIcon channel={row.channel} />
                {row.vendor_name && row.vendor_name !== row.channel && ` / ${row.vendor_name}`}
                {row.channel === 'SEARCH' &&
                    row.search_term_strategy &&
                    ` / ${SEARCH_TERM_STRATEGY_LABELS[row.search_term_strategy]}`}
            </h5>
            <div className="text-muted">Channel</div>
        </div>

        <div className="text-center border-left" style={{ maxWidth: '300px', flex: '1 1 300px' }}>
            <h5>{row.channel === 'SEARCH' ? row.google_keyword || '-' : row.domain || '-'}</h5>
            <div className="text-muted">{row.channel === 'SEARCH' ? 'Keyword' : 'Domain'}</div>
        </div>
    </div>
);

const ActionJourneyItem = ({ row }: { row: CustomerJourneyReport }) => (
    <div className="d-flex align-items-center">
        <div className="me-4">
            <TypeIcon row={row} />
        </div>

        <div className="flex-grow-1">
            <h5>{row.action_status === 'missed' ? 'Contributed action' : 'Action'}</h5>
            <div className="text-muted">{row.journey_datetime}</div>
        </div>

        {row.action_status === 'missed' ? (
            <div className="text-center" style={{ maxWidth: '300px', flex: '1 1 300px' }}>
                <h5 className="d-flex align-items-center justify-content-center">
                    {row.google_source} / {row.google_medium}
                </h5>
                <div className="text-muted">Source / Medium</div>
            </div>
        ) : (
            <div className="text-center" style={{ maxWidth: '300px', flex: '1 1 300px' }}>
                <h5 className="d-flex align-items-center justify-content-center">
                    <ChannelLabel withIcon channel={row.channel} />
                </h5>
                <div className="text-muted">Channel</div>
            </div>
        )}

        <div className="text-center border-left" style={{ maxWidth: '300px', flex: '1 1 300px' }}>
            {row.journey_product_category_ids ? (
                <div className="text-start p-2">
                    {row.journey_product_category_ids.map((name) => (
                        <span className="JourneyRow--badge me-1 mb-1" key={name}>
                            {name}
                        </span>
                    ))}
                </div>
            ) : (
                <h5>-</h5>
            )}
            <div className="text-muted">Category</div>
        </div>
    </div>
);

const JourneyRow = ({ row }: { row: CustomerJourneyReport }) => {
    switch (row.event_type) {
        case 'Site Visit':
            return <SiteVisitJourneyItem row={row} />;
        case 'Media View':
            return <MediaViewJourneyItem row={row} />;
        case 'Media Click':
            return <MediaClickJourneyItem row={row} />;
        case 'Action':
            return <ActionJourneyItem row={row} />;
        default:
            return null;
    }
};

export default JourneyRow;
