import { useMemo } from 'react';
import { Tooltip } from 'reactstrap';
import ButtonArray, { ButtonType } from 'platform/common/components/ButtonArray/ButtonArray';
import { KanbanFilterValues } from '../Kanban/KanbanFilters';
import { LabelNames, Task } from '../task.types';
import TimelineBarTooltip from './TimelineBarTooltip';
import useTimelineChart from './useTimelineChart';
import './Timeline.scss';

export type DateType = 'WEEK' | 'MONTH';

const DATE_BUTTONS: ButtonType<DateType>[] = [
    { label: 'Week', value: 'WEEK' },
    { label: 'Month', value: 'MONTH' },
];

export type TimelineTask = Task & { laneName: string };

interface Props {
    tasks: TimelineTask[];
    labelNames: LabelNames;
    filters: KanbanFilterValues;
    onTaskOpen: (taskId: number) => void;
}

const Timeline = ({ tasks, filters, labelNames, onTaskOpen }: Props) => {
    const filteredTasks = useMemo(
        () =>
            tasks.sort((a, b) => {
                if (!a.startDate && !a.dueDate) {
                    return -1;
                }
                if (!b.startDate && !b.dueDate) {
                    return 1;
                }
                if (!a.startDate) {
                    return -1;
                }
                if (!b.startDate) {
                    return 1;
                }
                return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
            }) as TimelineTask[],
        [tasks, filters]
    );

    const {
        topAxisRef,
        chartRef,
        scrollRef,
        tooltipTargetRef,
        hoveredTaskId,
        selectedDateType,
        setSelectedDateType,
        onScroll,
    } = useTimelineChart(filteredTasks, onTaskOpen);

    const hoveredTask = filteredTasks.find((t) => t.id === hoveredTaskId);

    return (
        <>
            <div className="d-flex w-100">
                <ButtonArray
                    className="mb-3 ms-auto"
                    selected={selectedDateType}
                    onClick={setSelectedDateType}
                    buttons={DATE_BUTTONS}
                />
            </div>
            <div className="position-fixed" ref={tooltipTargetRef} />
            {hoveredTask && (
                <Tooltip
                    isOpen
                    delay={50}
                    fade={false}
                    target={tooltipTargetRef}
                    className="pl-tooltip"
                    hideArrow
                >
                    <TimelineBarTooltip labelNames={labelNames} task={hoveredTask} />
                </Tooltip>
            )}

            <div
                ref={scrollRef}
                onScroll={onScroll}
                style={{ height: '89vh' }}
                className="w-100 overflow-auto Timeline"
            >
                <svg className="TopAxis" ref={topAxisRef} />
                <svg ref={chartRef} />
            </div>
        </>
    );
};

export default Timeline;
