import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { Task, TASK_STATUS_LABELS, TaskLane } from '../task.types';

const getIconClass = (task: Task) => {
    switch (task.status) {
        case 'DONE':
            return 'fa-circle-check';
        case 'ARCHIVED':
            return 'fa-box-archive';
        default:
            return '';
    }
};

const TaskStatusIcon = ({ task, lane }: { task: Task; lane?: TaskLane }) => {
    if (task.status === 'DONE' || task.status === 'ARCHIVED') {
        return (
            <Tooltip renderTooltip={() => TASK_STATUS_LABELS[task.status]}>
                <i className={`far fa-lg ${getIconClass(task)} me-2`} />
            </Tooltip>
        );
    }
    if (lane?.type === 'WORK_FINISHED' || lane?.type === 'DONE') {
        return (
            <Tooltip renderTooltip={() => 'Work finished'}>
                <i className="text-success fa fa-lg fa-flag me-2" />
            </Tooltip>
        );
    }
    return null;
};

export default TaskStatusIcon;
