import { startCase } from 'lodash-es';
import moment from 'moment/moment';
import { formatObjectTree } from 'platform/audit/audit.mapper';
import { getCampaigns } from 'platform/campaign/campaign/services/campaign.service';
import { getUnitSummaries } from 'platform/campaign/unit/services/units.service';
import { SelectItem } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import DateRangePicker from 'platform/common/components/DateRangePicker/DateRangePicker';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { makeOptions } from 'platform/common/utils/option.util';
import { fetchUsers } from 'platform/userManagement/services/userManagement.service';
import { getAuditedChanges } from './audit.service';
import { AUDIT_OBJECT_TYPE_LABELS, AuditedChange, AuditObjectType } from './audit.types';

const loadStaticFilters = async (advertiserId: number) => {
    const [users, campaigns] = await Promise.all([
        fetchUsers(),
        getCampaigns({ advertiserIds: [advertiserId] }),
    ]);
    const userOptions = users.map((u) => ({ value: u.name, label: `${u.name} (${u.login})` }));

    return {
        userOptions,
        campaignOptions: campaigns.map((c) => ({ value: c.id, label: c.name })),
    };
};

interface Options {
    userOptions: SelectItem[];
    campaignOptions: SelectItem[];
}

type QueryParams = {
    objectTypes: AuditObjectType[];
    campaignId?: number;
    unitId?: number;
    author?: string;
    dateFrom?: string;
    dateTo?: string;
};

const AuditLog = () => {
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const {
        queryParams: { objectTypes, campaignId, unitId, author, dateFrom, dateTo },
        setQueryParams,
    } = useUrlSync<QueryParams>({
        objectTypes: [],
        campaignId: undefined,
        unitId: undefined,
        author: undefined,
        dateFrom: moment().subtract(30, 'days').format(ISO_DATE_FORMAT),
        dateTo: moment().format(ISO_DATE_FORMAT),
    });

    const [
        {
            data: { userOptions, campaignOptions },
        },
    ] = usePromise<Options>(
        { userOptions: [], campaignOptions: [] },
        () => loadStaticFilters(advertiserId),
        []
    );

    const [{ data: unitOptions, loading: unitOptionsLoading }] = usePromise(
        [],
        async () => {
            if (!campaignId) {
                return [];
            }
            const units = await getUnitSummaries({
                advertiserIds: [advertiserId],
                campaignIds: [campaignId],
            });
            return units.map((unit) => ({
                value: unit.id,
                label: `${unit.id} (Strategy: ${unit.strategyName})`,
            }));
        },
        [campaignId]
    );

    const [{ data, loading }] = usePromise<AuditedChange[]>(
        [],
        () => {
            const filter = { objectTypes, advertiserId, campaignId, unitId, author, dateFrom, dateTo };
            return getAuditedChanges(filter);
        },
        [...objectTypes, advertiserId, campaignId, unitId, author, dateFrom, dateTo]
    );

    const columns: TableColumn<AuditedChange>[] = [
        {
            Header: 'Campaign',
            accessor: 'campaignName',
            autoWidth: true,
            width: 350,
            Cell: ({ value }) => <CellWithEllipsis value={value} />,
        },
        {
            Header: 'Updated Object',
            accessor: 'objectId',
            width: 160,
            Cell: ({ original }) => `${original.objectType}: ${original.objectId}`,
        },
        {
            Header: 'Updated Setting',
            accessor: 'propertyName',
            width: 200,
            Cell: ({ value }) => <CellWithEllipsis value={startCase(value)} />,
        },
        {
            Header: 'New Value',
            Cell: ({ original }) => (
                <div style={{ whiteSpace: 'pre-wrap' }}>{formatObjectTree(original.newValue)}</div>
            ),
        },
        {
            Header: 'Date and user',
            accessor: 'timestamp',
            width: 150,
            Cell: ({ original }) => <UpdatedOn date={original.timestamp} updatedBy={original.author} />,
        },
    ];

    const datepickerRender = (params?: any) => (
        <DateRangePicker
            from={dateFrom}
            to={dateTo}
            className="ms-auto"
            onChange={(from, to) => setQueryParams({ dateFrom: from, dateTo: to })}
            {...params}
        />
    );

    return (
        <>
            <HeaderContainer>
                <div className="d-flex flex-wrap align-items-center">
                    <PageHeader
                        title="Change Log"
                        collapsedContent={datepickerRender({ className: 'borderless' })}
                    />
                    {datepickerRender()}
                </div>
            </HeaderContainer>
            <BodyContainer helpKey="audit_log">
                <ListFilters className="mb-3">
                    <SelectWithAddon
                        name="Object types"
                        value={objectTypes}
                        options={makeOptions(AUDIT_OBJECT_TYPE_LABELS)}
                        isMulti
                        onChange={(value) => setQueryParams({ objectTypes: value })}
                    />
                    <SelectWithAddon
                        name="Campaign"
                        value={campaignId}
                        options={campaignOptions}
                        onChange={(value) => setQueryParams({ campaignId: value })}
                    />
                    {!!campaignId && (
                        <SelectWithAddon
                            name="Unit"
                            value={unitId}
                            options={unitOptions}
                            isLoading={unitOptionsLoading}
                            onChange={(value) => setQueryParams({ unitId: value })}
                        />
                    )}
                    <SelectWithAddon
                        name="User"
                        value={author}
                        options={userOptions}
                        onChange={(value) => setQueryParams({ author: value })}
                    />
                </ListFilters>
                <FormattedTable
                    data={data}
                    loading={loading}
                    exportData={{ filename: 'changeLog' }}
                    columns={columns}
                />
            </BodyContainer>
        </>
    );
};

export default AuditLog;
