import { useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ApolloError } from '@apollo/client';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import {
    generateInsightReport,
    generateOlapReport,
    generatePivot,
    PivotSettings,
    ReportSettings,
} from 'platform/analytics/analytics.service';
import {
    CustomFilter,
    FilterDefinition,
    FilterOperator,
    InteractionComponentState,
    Report,
    ReportComponentState,
    ReportFilter,
    ReportInteraction,
    ReportType,
    Template,
} from 'platform/analytics/analytics.types';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { hasFilters } from 'platform/analytics/modes/InsightAnalytics';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { dateFilterSelectors, Periods } from 'platform/common/ducks/dateFilter.duck';
import { useCancelableEffect } from 'platform/common/hooks/useCancelableEffect';
import { resolveCustomDateRange } from 'platform/common/utils/date.util';
import { getTemplate } from '../analytics.util';
import {
    areFilterValuesResolved,
    fetchFilterOptionsMap,
    resolveFilters,
} from './ReportFilters/useReportFilterOptions';
import { formatDimensions } from './formatReport.util';

interface Props {
    settings: ReportSettings | PivotSettings;
    type: ReportType;
    shouldFormatDimensions?: boolean;
}

const adjustDateFrom = (date?: string, dimenions: string[] = []) => {
    if (dimenions.includes('week') && moment(date).month() === 0) {
        const LAST_WEEK_IN_YEAR = [52, 53];
        const week = Number(moment(date).format('W'));
        // where are cases when last week in previous year includes days from next year
        return LAST_WEEK_IN_YEAR.includes(week)
            ? // to avoid such cases we need to set date to first day of next week
              moment(date).add(1, 'week').startOf('isoWeek').format(ISO_DATE_FORMAT)
            : date;
    }

    return date;
};

interface ReportStateType {
    loading: boolean;
    error?: ApolloError;
    report?: Report;
    compareReport?: Report;
}

const resolveInsightFilters = async (
    customFilters: CustomFilter[] = [],
    filterDefinitions: FilterDefinition[],
    advertiserIds: number[]
): Promise<CustomFilter[]> => {
    const filtersToResolve = customFilters.filter(
        (filter) => !!filterDefinitions.find((f) => f.target === filter.column && f.type === 'LIST')
    );

    const filters = filtersToResolve.map((filter) => ({
        key: filter.column,
        operator: filter.operator,
        values: filter.value,
    }));

    const optionsMap = await fetchFilterOptionsMap(filters, filterDefinitions, advertiserIds);
    const resolvedFilters = resolveFilters(filters, filterDefinitions, optionsMap).map(
        ({ key, resolvedValues = [] }) => ({
            column: key,
            operator: 'IS' as FilterOperator,
            value: resolvedValues,
        })
    );

    return [
        ...resolvedFilters,
        ...customFilters.filter((filter) => !resolvedFilters.some((f) => f.column === filter.column)),
    ];
};

export const resolveCustomFilters = async (
    customFilters: CustomFilter[] = [],
    filterDefinitions: FilterDefinition[],
    advertiserIds: number[]
): Promise<ReportFilter[]> => {
    const filtersToResolve = customFilters.filter((filter) => !filter.resolvedValues);
    if (!filtersToResolve.length) {
        return customFilters.map((filter) => ({
            key: filter.column,
            values: filter.resolvedValues ?? [],
        }));
    }

    const filters = filtersToResolve.map((filter) => ({
        key: filter.column,
        operator: filter.operator,
        values: filter.value,
    }));

    const optionsMap = await fetchFilterOptionsMap(filters, filterDefinitions, advertiserIds);
    return resolveFilters(filters, filterDefinitions, optionsMap).map(({ key, resolvedValues = [] }) => ({
        key,
        values: resolvedValues,
    }));
};

const generateReport = async ({
    type,
    customReportId,
    settings,
    filters,
    periods,
    month,
    usePeriods,
    useCompare,
    advertiserIds,
    filterDefinitions,
    interactions,
}: {
    type: ReportType;
    customReportId?: number;
    settings: ReportSettings | PivotSettings;
    filters: ReportFilter[];
    periods: Periods;
    month?: number;
    usePeriods: boolean;
    useCompare: boolean;
    advertiserIds: number[];
    filterDefinitions: FilterDefinition[];
    interactions: ReportInteraction[];
}): Promise<{ report: Report; compareReport?: Report }> => {
    const { primaryFrom, primaryTo, secondaryFrom, secondaryTo } = resolveCustomDateRange(
        periods,
        settings.customDateRange
    );

    const fetchCompareReport = useCompare && !!secondaryFrom && !!secondaryTo;

    const fetchInsightReport = async (period: { from?: string; to?: string }) => {
        const { templateId, dimensions, metrics, sort, customFilters, paging, includeVatRate, modelOptIn } =
            settings as ReportSettings;

        const resolvedCustomFilters = await resolveInsightFilters(
            customFilters,
            filterDefinitions,
            advertiserIds
        );

        return generateInsightReport({
            templateId: templateId!,
            columns: [...(dimensions ?? []), ...(metrics ?? [])],
            filters,
            customFilters: resolvedCustomFilters,
            period,
            month,
            sort,
            paging,
            customReportId,
            includeVatRate,
            modelOptIn,
            interactions,
        });
    };

    switch (type) {
        case 'PIVOT': {
            const report = await generatePivot({
                ...(settings as PivotSettings),
                filter: {
                    dimensionFilters: filters,
                    period: {
                        from: primaryFrom,
                        to: primaryTo,
                    },
                },
            });
            return { report: { ...report, type: 'PIVOT' } };
        }
        case 'INSIGHT': {
            const [report, compareReport] = await Promise.all([
                fetchInsightReport({
                    from: usePeriods ? primaryFrom : undefined,
                    to: usePeriods ? primaryTo : undefined,
                }),
                usePeriods && fetchCompareReport
                    ? fetchInsightReport({
                          from: secondaryFrom,
                          to: secondaryTo,
                      })
                    : Promise.resolve(undefined),
            ]);
            return {
                report: { ...report, type: 'INSIGHT' },
                compareReport,
            };
        }

        default: {
            const reportSettings = settings as ReportSettings;
            const customFilters = await resolveCustomFilters(
                reportSettings.customFilters,
                filterDefinitions,
                advertiserIds
            );
            const dimensionFilters = [...filters, ...customFilters];
            const [report, compareReport] = await Promise.all([
                generateOlapReport({
                    ...(reportSettings as ReportSettings),
                    from: adjustDateFrom(primaryFrom, reportSettings.dimensions),
                    to: primaryTo,
                    dimensionFilters,
                    interactions,
                }),
                fetchCompareReport
                    ? generateOlapReport({
                          ...(reportSettings as ReportSettings),
                          from: adjustDateFrom(secondaryFrom, reportSettings.dimensions),
                          to: secondaryTo,
                          dimensionFilters,
                          interactions,
                      })
                    : Promise.resolve(undefined),
            ]);
            return {
                report: { ...report, type: 'DEFAULT' },
                compareReport,
            };
        }
    }
};

export const getComponentInteractions = (
    components: ReportComponentState[],
    template?: Template
): (ReportInteraction & { required: boolean })[] =>
    components
        ?.filter((c) => c.type === 'INTERACTION')
        .filter((i: InteractionComponentState) =>
            template
                ? template.filters.some((f) => i.key === f.target || i.interactionType === 'DATE_PICKER')
                : true
        )
        .filter((i: InteractionComponentState) => (Array.isArray(i.value) ? !!i.value.length : !!i.value))
        .reduce((acc: (ReportInteraction & { required: boolean })[], c: InteractionComponentState) => {
            if (c.interactionType === 'DATE_PICKER') {
                return [
                    ...acc,
                    { key: c.fromKey, value: c.value?.from ?? '', required: c.required },
                    { key: c.toKey, value: c.value?.to ?? '', required: c.required },
                ];
            }

            return [
                ...acc,
                {
                    key: c.key,
                    value: c.value ?? c.defaultValue ?? 1,
                    required: c.required,
                },
            ];
        }, []);

export const useReport = ({ settings, type, shouldFormatDimensions = true }: Props) => {
    const { templates, definitions } = useAnalyticsMetadata();
    const { mode, customReportId, components } = useSelector(analyticsSelectors.settings);
    const templateId =
        'templateId' in settings ? settings.templateId : (settings as PivotSettings).definition.templateId;
    const template = getTemplate(templates, templateId!);

    const periods = useSelector(dateFilterSelectors.periods, shallowEqual);
    const month = useSelector(dateFilterSelectors.month);
    const filters = useSelector(analyticsSelectors.nonEmptyFilters);
    const filtersWithOperator = useSelector(analyticsSelectors.filtersWithOperator);
    const compareType = useSelector(analyticsSelectors.compareType);
    const advertiserIds = useSelector(activeAdvertiserSelectors.ids);
    const commonClassifiers = useSelector(classifierSelectors.commonClassifiers);

    const [state, setState] = useState<ReportStateType>({ loading: false });
    const [previousInteractions, setPreviousInteractions] = useState<ReportInteraction[]>(
        getComponentInteractions(components, template)
    );

    const interactions = useMemo(() => getComponentInteractions(components, template), [components]);

    const hasRequiredInteractionsWithNoValues = interactions.some((i) => i.required && !i.value);

    const tryFetch = async (isCanceledFn?: () => boolean) => {
        try {
            if (hasRequiredInteractionsWithNoValues) {
                setState({ ...state, loading: false, report: undefined });
                return;
            }
            if (isCanceledFn && isCanceledFn()) return;

            const { report, compareReport } = await generateReport({
                settings,
                type,
                customReportId,
                filters,
                periods,
                month,
                usePeriods:
                    mode !== 'INSIGHT' ||
                    hasFilters(['date_from', 'date_to'])((settings as ReportSettings).templateId!, templates),
                useCompare: mode !== 'INTERACTIVE',
                advertiserIds,
                filterDefinitions: definitions.filters,
                interactions: interactions.map(({ required, ...interaction }) => interaction),
            });
            if (isCanceledFn && isCanceledFn()) return;

            setState({
                ...state,
                report: shouldFormatDimensions
                    ? formatDimensions(report, template, commonClassifiers)
                    : report,
                compareReport:
                    shouldFormatDimensions && compareReport
                        ? formatDimensions(compareReport, template, commonClassifiers)
                        : compareReport,
                loading: false,
                error: undefined,
            });
        } catch (error) {
            if (isCanceledFn && isCanceledFn()) return;
            setState({ ...state, error, loading: false, report: undefined });
        }
    };

    const updateInteractionReport = async (isCanceledFn?: () => boolean) => {
        if (!isEqual(interactions, previousInteractions)) {
            setState({ ...state, loading: true });
            setPreviousInteractions(interactions);
            tryFetch(isCanceledFn);
        }
    };

    const fetchReport = async (isCanceledFn?: () => boolean) => {
        setState({ ...state, loading: true });
        if (!areFilterValuesResolved(filtersWithOperator)) {
            return;
        }
        tryFetch(isCanceledFn);
    };

    useCancelableEffect(fetchReport, [
        settings,
        filters,
        periods.primaryFrom,
        periods.primaryTo,
        periods.secondaryFrom,
        periods.secondaryTo,
        month,
        compareType,
        filtersWithOperator,
    ]);

    useCancelableEffect(updateInteractionReport, [interactions]);

    return { ...state, refetchReport: fetchReport };
};
