import { AdvertiserExternalIntegration } from 'platform/advertisers/advertiser.types';
import { advertiserIntegrationsToFormModel } from 'platform/advertisers/mappers/advertiser.mapper';
import {
    fetchAdvertiser,
    updateAdvertiserIntegrations,
} from 'platform/advertisers/services/advertiser.service';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { useLoading } from 'platform/common/hooks/useLoading';
import { toastSuccess } from 'platform/common/utils/toast.util';
import AdvertiserIntegrationsForm, { AdvertiserIntegrationsFormModel } from './AdvertiserIntegrationsForm';

export const PATH = '/advertiser-settings/integrations';
export const ADVERTISER_INTEGRATIONS_HELP_KEY = 'advertiser_integrations_form';

const toApi = (integrations: AdvertiserExternalIntegration[]) =>
    integrations.map((integration) =>
        integration.externalSystem === 'GCM'
            ? {
                  ...integration,
                  accounts: integration.accounts.map((account) => ({
                      ...account,
                      floodlightConfigurationId:
                          account.useCustomFloodlightConfigurationId && account.floodlightConfigurationId
                              ? account.floodlightConfigurationId
                              : Number(account.externalId) || undefined,
                  })),
              }
            : integration
    );

const AdvertiserIntegrationsFormContainer = () => {
    const { id } = useActiveAdvertiserComponent();
    const [loading, withLoading] = useLoading();
    const canEdit = useFeature('ADVANCED_CAMPAIGN_SETTINGS_EDIT');

    const handleOpen = async (): Promise<AdvertiserIntegrationsFormModel> => {
        const { name, activeAdserver, externalSystemIntegrations: integrations } = await fetchAdvertiser(id);
        const externalSystemIntegrations = advertiserIntegrationsToFormModel(integrations);
        return { id, name, activeAdserver, externalSystemIntegrations };
    };

    const handleSubmit = async (data: AdvertiserIntegrationsFormModel) => {
        await withLoading(() => updateAdvertiserIntegrations(id, toApi(data.externalSystemIntegrations)));
        toastSuccess('Integrations updated');
    };

    return (
        <BodyContainer helpKey="advertiser_integrations">
            <FormContainer
                helpKey={ADVERTISER_INTEGRATIONS_HELP_KEY}
                canEdit={canEdit}
                onOpen={handleOpen}
                onSubmit={handleSubmit}
                isEdit
            >
                {(props) => <AdvertiserIntegrationsForm loading={loading} {...props} />}
            </FormContainer>
        </BodyContainer>
    );
};

export default AdvertiserIntegrationsFormContainer;
