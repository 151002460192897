import { FormGroup } from 'reactstrap';
import { FormikProps } from 'formik';
import { sortBy, uniq } from 'lodash-es';
import { DataSharingTemplate } from 'platform/adminPanel/adminPanel.types';
import {
    DataSharingRuleSnippetModel,
    ThirdPartyCodesFormValues,
} from 'platform/advertiserTag/advertiserTag.types';
import { SCRIPT_TYPE_OPTIONS } from 'platform/advertiserTag/constants/advertiserTag.constant';
import { getGlobalVariableValue } from 'platform/advertiserTag/mappers/thirdPartyCodes.mapper';
import { Advertiser } from 'platform/advertisers/advertiser.types';
import { assertIsDefined } from 'platform/common/common.assert';
import CodeMirrorSrc from 'platform/common/components/CodeMirror/CodeMirrorSource';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import Select from 'platform/common/components/Select/Select';
import Separator from 'platform/common/components/Separator/Separator';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import FormToggle from 'platform/formik/FormToggle/FormToggle';
import { Vendor } from 'platform/vendors/vendors.types';

interface Props {
    onCodeSnippetRemove: (i: number) => void;
    advertiser: Advertiser;
    vendors: Vendor[];
    templates: DataSharingTemplate[];
    ruleIsActive: boolean;
    codeSnippets: DataSharingRuleSnippetModel[];
    fieldName: string;
    setFieldValue: FormikProps<ThirdPartyCodesFormValues>['setFieldValue'];
}

const formatTemplateOption = ({ systemName, notes }: DataSharingTemplate) => (
    <Tooltip tooltipVisible={!!notes} renderTooltip={() => notes}>
        {systemName}
        {!!notes && <i className="fa fa-info-circle ms-2 text-info" />}
    </Tooltip>
);

const DataSharingRuleCodeSnippets = ({
    codeSnippets,
    vendors,
    advertiser,
    templates,
    ruleIsActive,
    fieldName,
    onCodeSnippetRemove,
    setFieldValue,
}: Props) => {
    const vendorOptions = vendors.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    return (
        <>
            {codeSnippets.map((snippet, index) => {
                const selectedTemplate = templates.find((t) => t.id === snippet.dataSharingTemplateId);
                const vendorTemplates = templates.filter((t) => t.vendorId === snippet.vendorId);

                const snippetFieldName = `${fieldName}.${index}`;

                return (
                    <ControlledCard
                        key={snippet.tempId}
                        color="secondary"
                        title={selectedTemplate?.systemName ?? 'New snippet'}
                        toggleable={false}
                        onRemove={codeSnippets.length > 1 ? () => onCodeSnippetRemove(index) : undefined}
                    >
                        <FormRow label="Vendor">
                            <FormSelect
                                name={`${snippetFieldName}.vendorId`}
                                options={vendorOptions}
                                isClearable={false}
                                validate={required}
                                onChange={() => {
                                    setFieldValue(`${snippetFieldName}.dataSharingTemplateId`, null);
                                }}
                            />
                        </FormRow>
                        <FormRow label="Template" rowHtmlId={`${snippetFieldName}.dataSharingTemplateId`}>
                            <FormSelect
                                name={`${snippetFieldName}.dataSharingTemplateId`}
                                options={sortBy(vendorTemplates, (t) => t.systemName)}
                                valueKey="id"
                                labelKey="systemName"
                                formatOptionLabel={formatTemplateOption}
                                isClearable={false}
                                validate={required}
                                onChange={(templateId: string) => {
                                    const template = templates.find((t) => t.id === Number(templateId));
                                    assertIsDefined(template, `report template with id ${templateId}`);
                                    setFieldValue(
                                        `${snippetFieldName}.userVariables`,
                                        uniq(template.variablesInTemplate || []).map((key) => ({
                                            key,
                                            value: '',
                                        }))
                                    );
                                    setFieldValue(
                                        `${snippetFieldName}.globalVariables`,
                                        uniq(template.globalVariablesInTemplate || []).map((key) => ({
                                            key,
                                            value: getGlobalVariableValue(key, advertiser),
                                            override: false,
                                        }))
                                    );
                                }}
                            />
                        </FormRow>
                        <FormRow label="Active">
                            <FormSwitch name={`${snippetFieldName}.active`} disabled={!ruleIsActive} />
                        </FormRow>
                        <FormRow label="Script type">
                            <Select
                                value={selectedTemplate?.codeType}
                                options={SCRIPT_TYPE_OPTIONS}
                                isDisabled
                            />
                        </FormRow>
                        <FormGroup>
                            <CodeMirrorSrc
                                name={`${snippetFieldName}.codeTemplate`}
                                value={selectedTemplate?.codeTemplate}
                                className="form-control"
                                options={{
                                    mode: 'htmlmixed',
                                    lineWrapping: true,
                                    readOnly: true,
                                }}
                            />
                        </FormGroup>
                        <Separator label="Global macros" />
                        {snippet.globalVariables.map(({ key, override }, i) => (
                            <FormRow
                                label={key}
                                key={key}
                                childrenColClassName="d-flex justify-content-between"
                            >
                                <FormInput
                                    name={`${snippetFieldName}.globalVariables.${i}.value`}
                                    validate={required}
                                    disabled={!override}
                                />
                                <FormToggle
                                    name={`${snippetFieldName}.globalVariables.${i}.override`}
                                    icon={override ? 'fa-redo' : 'fa-pencil-alt'}
                                    onChange={(overrideOn: boolean) => {
                                        if (overrideOn) return;

                                        setFieldValue(
                                            `${snippetFieldName}.globalVariables.${i}.value`,
                                            getGlobalVariableValue(key, advertiser)
                                        );
                                    }}
                                />
                            </FormRow>
                        ))}
                        <Separator label="User-defined macros" />
                        {snippet.userVariables.map(({ key }, i) => (
                            <FormRow label={key} key={key}>
                                <FormInput
                                    name={`${snippetFieldName}.userVariables.${i}.value`}
                                    validate={required}
                                />
                            </FormRow>
                        ))}
                    </ControlledCard>
                );
            })}
        </>
    );
};

export default DataSharingRuleCodeSnippets;
