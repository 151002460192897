import { MouseEvent, ReactNode, forwardRef, useEffect, useRef } from 'react';
import classNames from 'classnames';
import * as Ladda from 'ladda';

type Props = {
    disabled?: boolean;
    className?: string;
    spinnerColor?: string;
    children: string | ReactNode;
    animationDelay?: number;
    onClick: () => Promise<void>;
};

const AnimatedButton = forwardRef<HTMLButtonElement, Props>(
    ({ disabled, className, spinnerColor, children, animationDelay = 200, onClick }, ref) => {
        const innerRef = useRef<HTMLButtonElement | null>(null);
        const laddaInstance = useRef<Ladda.LaddaButton | null>(null);

        useEffect(() => {
            const button = innerRef.current;
            if (button) {
                laddaInstance.current = Ladda.create(button);
            }
            return () => laddaInstance.current?.remove();
        }, []);

        const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            const promise = onClick();
            if (promise?.finally) {
                setTimeout(() => {
                    laddaInstance.current?.start();
                    promise.finally(() => laddaInstance.current?.stop());
                }, animationDelay);
            }
        };

        return (
            <button
                data-spinner-color={spinnerColor}
                type="button"
                onClick={handleClick}
                className={classNames('btn btn-primary ladda-button', className)}
                data-style="zoom-out"
                ref={ref ?? innerRef}
                disabled={disabled}
            >
                {children}
            </button>
        );
    }
);

export default AnimatedButton;
