import { CSSProperties, ReactNode, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { flip } from '@popperjs/core';
import { FlipModifier } from '@popperjs/core/lib/modifiers/flip';
import classNames from 'classnames';
import { PopoverPlacement } from '../Popover/Popover';

interface Props {
    children: ReactNode;
    renderTooltip: () => ReactNode;
    tooltipVisible?: boolean;
    className?: string;
    tooltipClassName?: string;
    style?: CSSProperties;
    placement?: PopoverPlacement;
    autoHide?: boolean;
}

const Tooltip = ({
    children,
    renderTooltip,
    tooltipVisible = true,
    className,
    tooltipClassName,
    style,
    placement = 'top',
    autoHide = true,
}: Props) => {
    // useRef here would result in ref being null until (if) rerendered.
    const [ref, setRef] = useState<HTMLSpanElement | null>(null);

    return (
        <span ref={setRef} style={style} className={className}>
            {children}
            {ref && tooltipVisible && (
                <UncontrolledTooltip
                    autohide={autoHide}
                    className={classNames('pl-tooltip', tooltipClassName)}
                    hideArrow
                    placement={placement}
                    target={ref}
                    delay={50}
                    modifiers={[
                        {
                            ...flip,
                            options: { allowedAutoPlacements: ['top', 'bottom'] },
                        } as FlipModifier,
                    ]}
                >
                    {renderTooltip()}
                </UncontrolledTooltip>
            )}
        </span>
    );
};

export default Tooltip;
