import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import { TableCell } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InfoIconAlert from 'platform/common/components/InfoIconAlert/InfoIconAlert';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import { DATA_TYPES } from 'platform/common/dataTypes';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import ProductFeedFormContainer from './ProductFeedFormContainer';
import { deleteProductFeed, fetchProductFeeds } from './productFeed.service';
import { ProductFeed } from './productFeed.types';

export const PATH = '/advertiser-settings/product-feeds';

const GlobalProductFeedList = () => {
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const dispatch = useDispatch();
    const canEdit = useFeature('ADVANCED_CAMPAIGN_SETTINGS_EDIT');
    const showConfirmationModal = useConfirmationModal();

    const [{ loading, data }, reload] = usePromise<ProductFeed[]>(
        [],
        () => fetchProductFeeds({ advertiserId }),
        [advertiserId]
    );

    const handleDelete = async (id: number) => {
        await deleteProductFeed(id);
        reload();
    };

    const columns: TableColumn<ProductFeed>[] = [
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 80,
        },
        {
            Header: 'Name',
            accessor: 'name',
            maxWidth: 400,
        },
        {
            Header: 'Feed URL',
            accessor: 'url',
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: ({ original: { id, name } }: TableCell<ProductFeed>) => (
                <InlineDropdown
                    items={[
                        {
                            label: canEdit ? 'Edit' : 'View',
                            action: () => dispatch(push(`${PATH}/${id}`)),
                        },
                        ...(canEdit
                            ? [
                                  {
                                      label: 'Delete...',
                                      action: () =>
                                          showConfirmationModal(() => handleDelete(id), {
                                              title: `Delete feed "${name}"?`,
                                          }),
                                  },
                              ]
                            : []),
                    ]}
                />
            ),
        },
    ];

    return (
        <>
            <BodyContainer helpKey="product_feeds_list">
                {!canEdit && <CanNotEditWarning />}
                <FormattedTable
                    topToolbar={
                        <ListToolbar onCreate={canEdit ? () => dispatch(push(`${PATH}/new`)) : undefined} />
                    }
                    columns={columns}
                    loading={loading}
                    data={data}
                    NoDataComponent={() => (
                        <div className="p-4 d-flex align-items-center justify-content-center">
                            No feeds configured yet
                        </div>
                    )}
                />
                <InfoIconAlert className="my-4">
                    We can import only feeds in the format of Google Merchant Center.
                </InfoIconAlert>
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <ProductFeedFormContainer redirectTo={PATH} canEdit={canEdit} afterSubmit={reload} />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <ProductFeedFormContainer redirectTo={PATH} canEdit={canEdit} afterSubmit={reload} />
                    }
                />
            </Routes>
        </>
    );
};

export default GlobalProductFeedList;
