import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { shallowEqual, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { ChartOptions } from 'chart.js';
import 'chartjs-adapter-moment';
import { isNil } from 'lodash-es';
import { CHART_PREVIEW_HEIGHT } from 'platform/analytics/analytics.constants';
import { ReportSettings } from 'platform/analytics/analytics.service';
import { exportChartData, exportedFilename, findColumn } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import ReportChartTitle from 'platform/analytics/reportComponents/ReportChartContainer/ReportChartTitle';
import ReportTracingTips from 'platform/analytics/reportComponents/ReportTracingTips/ReportTracingTips';
import { useReport } from 'platform/analytics/reportComponents/useReport';
import ChartEmptyView from 'platform/common/components/ChartEmptyView/ChartEmptyView';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import InformationModal from 'platform/common/components/InformationModal/InformationModal';
import { useModal } from 'platform/common/components/Modal/Modal';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { DATA_TYPES, typeOf } from 'platform/common/dataTypes';
import { dateFilterSelectors } from 'platform/common/ducks/dateFilter.duck';
import { layoutSelectors } from 'platform/common/ducks/layout.duck';
import { CHART_COLORS } from 'platform/common/utils/color.util';
import { ReportBarChartProps } from '../ReportBarChart/ReportBarChart';
import '../ReportChart.scss';

const TOP_ROWS_COUNT = 10;

type StackBarChart = {
    labels: string[];
    datasets: {
        data: any[];
        backgroundColor: any;
        label: any;
    }[];
};

const getSortedData = (data: StackBarChart, showOtherValues?: boolean) => {
    const sorted = {
        ...data,
        datasets: data.datasets
            .sort((a, b) => b.data[0] - a.data[0])
            .map((dataset, index) => ({
                ...dataset,
                backgroundColor: CHART_COLORS[index],
            })),
    };

    if (showOtherValues && data.labels.length > TOP_ROWS_COUNT) {
        const topLabels = data.labels.slice(0, TOP_ROWS_COUNT);
        if (topLabels.length === TOP_ROWS_COUNT) topLabels.push('Other');

        const result = {
            labels: topLabels,
            datasets: sorted.datasets.map((dataset) => {
                const topRows: number[] = Array(TOP_ROWS_COUNT + 1).fill(0);
                dataset.data.forEach((value, index) => {
                    if (index < TOP_ROWS_COUNT) {
                        topRows[index] = value;
                    } else {
                        topRows[TOP_ROWS_COUNT] += value;
                    }
                });
                return { ...dataset, data: topRows };
            }),
        };
        return result;
    }

    return sorted;
};

const ReportStackBarChart = ({
    template,
    reportName,
    componentState,
    MAX_LABEL_LENGTH = 40,
}: ReportBarChartProps) => {
    const {
        title,
        showOtherValues = true,
        sortBy,
        customFilters,
        customDateRange,
        metrics,
        dimensions,
    } = componentState;

    const xDimension = dimensions[0] ?? '';
    const byDimension = dimensions[1] ?? '';
    const { includeVatRate, modelOptIn } = useSelector(analyticsSelectors.settings);
    const isMobile = useSelector(layoutSelectors.isMobile);
    const { showModal } = useModal();
    const periods = useSelector(dateFilterSelectors.periods, shallowEqual);
    const settings = useMemo<ReportSettings>(
        () => ({
            dimensions,
            metrics,
            customFilters,
            customDateRange,
            templateId: template.id,
            sort:
                sortBy === 'METRIC'
                    ? [{ orderBy: metrics[0], direction: 'DESC' }]
                    : [{ orderBy: xDimension, direction: 'ASC' }],
            includeVatRate,
            modelOptIn,
        }),
        [sortBy, dimensions, metrics, customFilters, customDateRange, template.id, includeVatRate, modelOptIn]
    );

    const { loading, report, error, refetchReport } = useReport({
        settings,
        type: template.type,
    });

    if (loading) return <Placeholder minHeight="290px" />;
    if (error) return <ErrorMessage error={error} onRetry={refetchReport} />;

    const metricCells = report?.header.filter((item) =>
        dimensions.length > 1 ? byDimension.includes(item.key) : metrics.includes(item.key)
    );
    const dimensionCell = findColumn(report, xDimension);

    if (!report || !metricCells?.length || !dimensionCell) {
        return <ChartEmptyView />;
    }

    const { format: formatDimension } = typeOf(findColumn(report, xDimension));
    const rows = report.rows.filter((row) => row[xDimension]);

    const xAxesLabels =
        [...new Set([...rows.map((row) => row[xDimension]).map(formatDimension)].filter(Boolean))] ?? [];

    const labels =
        dimensions.length > 1
            ? [...new Set(rows.map((item) => item[byDimension]))]
            : metrics
                  .map((metric) => metricCells.find((m) => m.key === metric)?.name || '')
                  .map(formatDimension)
                  .filter(Boolean)
                  .reverse();

    const data: StackBarChart = {
        labels: xAxesLabels,
        datasets:
            dimensions.length > 1
                ? labels.map((label, index) => ({
                      data: xAxesLabels.map((xlabel) => {
                          const res = rows.find(
                              (row) => row[xDimension] === xlabel && row[byDimension] === label
                          );
                          if (res && res[metrics[0]]) return res[metrics[0]];
                          return undefined;
                      }),
                      backgroundColor: CHART_COLORS[index],
                      label,
                  }))
                : metrics.map((metric, index) => ({
                      data: [...rows.map((row) => row[metric])],
                      backgroundColor: CHART_COLORS[index],
                      label: metricCells.find((m) => m.key === metric)?.name || '',
                  })),
    };

    const sortedData = getSortedData(data, showOtherValues);
    const options: ChartOptions<'bar'> = {
        layout: labels.some((x) => x.length > MAX_LABEL_LENGTH) ? { padding: { bottom: 16 } } : undefined,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    callback: (tick: number) => {
                        let value = '';

                        if (xAxesLabels && xAxesLabels[tick]) {
                            value = xAxesLabels[tick];
                        }

                        if (typeof value !== 'string' || value.length <= MAX_LABEL_LENGTH) {
                            return value;
                        }

                        let middle = Math.floor(value.length / 2);
                        const before = value.lastIndexOf(' ', middle);
                        const after = value.indexOf(' ', middle + 1);

                        if (middle - before < after - middle) {
                            middle = before;
                        } else {
                            middle = after;
                        }

                        const s1 = value.substring(0, middle);
                        const s2 = value.substring(middle + 1);

                        return [s1, s2];
                    },
                },
            },
            y: {
                stacked: true,
                beginAtZero: true,
            },
        },
        animation: undefined,
        maintainAspectRatio: false,
        plugins: {
            legend:
                dimensions.length > 1 || metrics.length > 1
                    ? {
                          display: true,
                          position: 'bottom' as const,
                      }
                    : undefined,
            tooltip: {
                mode: 'nearest',
                intersect: false,
                callbacks: {
                    label(tooltipItem): string | string[] {
                        if (isNil(tooltipItem.dataIndex) || isNil(tooltipItem.datasetIndex)) return '-';

                        const metric = metrics[0];
                        const { format } = typeOf(findColumn(report, metric), DATA_TYPES.FLOAT);
                        const value = tooltipItem.dataset.data?.[tooltipItem.dataIndex];
                        const total = sortedData.datasets
                            .map((op) => op.data[tooltipItem.dataIndex])
                            .filter((val) => val !== null && val !== undefined && !isNaN(val))
                            .reduce((acc, val) => acc + val, 0);

                        return ` ${tooltipItem.dataset.label}: ${format(value)} (${DATA_TYPES.P100.format(
                            typeof total === 'number' && typeof value === 'number' && total > 0
                                ? (value / total) * 100
                                : 0
                        )})`;
                    },
                },
            },
        },
    };

    const titleText = title || `${metricCells.map((m) => m.name).join(', ')} by ${dimensionCell.name}`;

    return (
        <>
            <ReportChartTitle title={titleText} template={template} componentState={componentState}>
                <div className="ReportChart-buttonContainer ms-1">
                    <Button
                        className="ReportChart-button"
                        title="View in modal"
                        onClick={() =>
                            showModal((toggle) => (
                                <InformationModal
                                    isOpen
                                    toggle={toggle}
                                    title={titleText}
                                    style={{ maxWidth: '80%' }}
                                >
                                    <Bar height={CHART_PREVIEW_HEIGHT} data={data} options={options} />
                                </InformationModal>
                            ))
                        }
                    >
                        <i className="fal fa-expand" />
                    </Button>
                    {!isMobile && (
                        <Button
                            className="ReportChart-button"
                            title="Download chart data"
                            onClick={exportChartData(
                                metrics,
                                xDimension,
                                report,
                                exportedFilename(reportName, titleText, periods)
                            )}
                        >
                            <i className="fa fa-download" />
                        </Button>
                    )}
                </div>
            </ReportChartTitle>
            <div className="d-flex align-items-center h-100 p-3">
                <div className="w-100">
                    <Bar data={sortedData} options={options} height={285} />
                </div>
            </div>
            <ReportTracingTips report={report} />
        </>
    );
};

export default ReportStackBarChart;
