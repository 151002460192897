import { useEffect } from 'react';
import { Label } from 'reactstrap';
import { FormikProps } from 'formik';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { required, requiredNumber } from 'platform/common/utils/validators.util';
import FormButtonArray from 'platform/formik/FormButtonArray/FormButtonArray';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import { ObserverFormModel, ObserverSchedulingFormModel } from '../observer.types';

const CRON_BUTTONS = [
    {
        value: 'MONTHLY',
        label: 'Monthly',
    },
    {
        value: 'WEEKLY',
        label: 'Weekly',
    },
    {
        value: 'DAILY',
        label: 'Daily',
    },
    {
        value: 'HOURLY',
        label: 'Hourly',
    },
    {
        value: 'MINUTES',
        label: 'Minutes',
    },
];

const WEEK_DAYS = [
    {
        value: 'MON',
        label: 'Monday',
    },
    {
        value: 'TUE',
        label: 'Tuesday',
    },
    {
        value: 'WED',
        label: 'Wednesday',
    },
    {
        value: 'THU',
        label: 'Thursday',
    },
    {
        value: 'FRI',
        label: 'Friday',
    },
    {
        value: 'SAT',
        label: 'Saturday',
    },
    {
        value: 'SUN',
        label: 'Sunday',
    },
];

interface Props {
    canEdit: boolean;
    schedulingValue: ObserverSchedulingFormModel;
    setFieldValue: FormikProps<ObserverFormModel>['setFieldValue'];
}

const ObserverCronBuilder = ({
    canEdit,
    schedulingValue: { expression, day, weekDays, hours, minutes, dayOfMonth, selectedTab },
    setFieldValue,
}: Props) => {
    useEffect(() => {
        generateCron();
    }, [day, weekDays, hours, minutes, dayOfMonth, selectedTab]);

    const generateCron = () => {
        let newExpression = '';

        switch (selectedTab) {
            case 'MONTHLY': {
                newExpression = `0 ${minutes || 0} ${hours || 0} ${dayOfMonth || 0} 1/1 ?`;
                break;
            }
            case 'WEEKLY': {
                const days =
                    WEEK_DAYS.map((w) => w.value)
                        .filter((weekDay) => (weekDays || []).includes(weekDay))
                        .join(',') || '*';

                newExpression = `0 ${minutes || 0} ${hours || 0} ? * ${days}`;
                break;
            }
            case 'DAILY': {
                newExpression = `0 ${minutes || 0} ${hours || 0} 1/${day || 1} * ?`;
                break;
            }
            case 'HOURLY': {
                newExpression = `0 ${minutes || 0} 0/${hours || 0} 1/1 * ?`;
                break;
            }
            case 'MINUTES': {
                newExpression = `0 0/${minutes || 0} * 1/1 * ?`;
                break;
            }
            default: {
                break;
            }
        }

        setFieldValue('scheduling.expression', newExpression);
    };

    const hoursMinutesField = (
        <FormRow label="At">
            <div className="d-flex">
                <FormNumberInput
                    containerClassName="me-2"
                    name="scheduling.hours"
                    min="0"
                    max="23"
                    disabled={!canEdit}
                />
                <FormNumberInput name="scheduling.minutes" min="0" max="59" disabled={!canEdit} />
            </div>
        </FormRow>
    );

    return (
        <>
            <FormRow label="Tabs">
                <FormButtonArray name="scheduling.selectedTab" buttons={CRON_BUTTONS} disabled={!canEdit} />
            </FormRow>

            {selectedTab === 'MONTHLY' && (
                <>
                    <FormRow label="Day of month">
                        <FormNumberInput
                            validate={requiredNumber}
                            name="scheduling.dayOfMonth"
                            min="0"
                            max="31"
                            disabled={!canEdit}
                        />
                    </FormRow>
                    {hoursMinutesField}
                </>
            )}

            {selectedTab === 'WEEKLY' && (
                <>
                    <FormRow label="Day of week">
                        <FormSelect
                            validate={required}
                            name="scheduling.weekDays"
                            options={WEEK_DAYS}
                            isMulti
                        />
                    </FormRow>
                    {hoursMinutesField}
                </>
            )}

            {selectedTab === 'DAILY' && (
                <FormRow label="Every">
                    <div className="d-flex">
                        <FormNumberInput
                            validate={requiredNumber}
                            name="scheduling.day"
                            min="0"
                            max="23"
                            disabled={!canEdit}
                        />
                        <Label className="col-form-label mx-2">day(s) at</Label>
                        <FormNumberInput
                            containerClassName="me-2"
                            name="scheduling.hours"
                            min="0"
                            max="23"
                            disabled={!canEdit}
                        />
                        <FormNumberInput name="scheduling.minutes" min="0" max="59" disabled={!canEdit} />
                    </div>
                </FormRow>
            )}

            {selectedTab === 'HOURLY' && (
                <FormRow label="Every">
                    <div className="d-flex">
                        <FormNumberInput name="scheduling.hours" min="0" max="23" disabled={!canEdit} />
                        <Label className="col-form-label mx-2">hour(s) on minute</Label>
                        <FormNumberInput name="scheduling.minutes" min="0" max="59" disabled={!canEdit} />
                    </div>
                </FormRow>
            )}

            {selectedTab === 'MINUTES' && (
                <FormRow label="Every">
                    <div className="d-flex">
                        <FormNumberInput name="scheduling.minutes" min="0" max="59" disabled={!canEdit} />
                        <Label className="col-form-label ms-2">minute(s)</Label>
                    </div>
                </FormRow>
            )}

            <FormRow label="Cron expression">
                <div className="text-muted">
                    <span>{expression}</span>
                    <span> (Central Europe Time)</span>
                </div>
            </FormRow>
        </>
    );
};

export default ObserverCronBuilder;
