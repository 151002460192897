import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { Formik } from 'formik';
import { useImageUpload } from 'platform/adminPanel/hooks/useImageUpload';
import { Profile } from 'platform/app/app.types';
import { authActions, authSelectors } from 'platform/app/ducks/auth.duck';
import { updateCurrentUserProfile } from 'platform/app/services/auth.service';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { useModal } from 'platform/common/components/Modal/Modal';
import SimpleCard from 'platform/common/components/SimpleCard/SimpleCard';
import SimpleCardTitle from 'platform/common/components/SimpleCard/SimpleCardTitle';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { toastSuccess } from 'platform/common/utils/toast.util';
import { required, urlHttps } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormInputWithUrlPreview from 'platform/formik/FormInputWithUrlPreview/FormInputWithUrlPreview';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import ImgCropModal from './ImgCropModal';

const ProfileGeneralInfoForm = () => {
    const { showModal } = useModal();
    const profile = useSelector(authSelectors.ready.profile);
    const dispatch = useDispatch();
    const { uploadingImage, handleImageUpload } = useImageUpload();

    const handleSubmit = (data: Profile) => {
        updateCurrentUserProfile(data).then(() => {
            dispatch(authActions.profileWasUpdated());
            toastSuccess('Profile updated successfully');
        });
    };

    return (
        <Formik initialValues={profile} onSubmit={handleSubmit}>
            {({ submitForm, values, setFieldValue }) => (
                <SimpleCard headerContent={<SimpleCardTitle text="General" />}>
                    <FormRow label="Name">
                        <FormInput name="name" type="text" validate={required} />
                    </FormRow>
                    <FormRow label="Email">
                        <FormInput name="login" type="text" disabled />
                    </FormRow>
                    <FormRow label="Mobile phone No.">
                        <FormInput name="phone" type="text" validate={required} />
                    </FormRow>
                    <FormRow label="Image url">
                        <FormInputWithUrlPreview
                            name="imageUrl"
                            value={values.imageUrl}
                            validate={[urlHttps]}
                        />
                    </FormRow>
                    <FormRow>
                        <UploadInput
                            acceptableMimeTypes="image/jpeg, image/png, image/svg+xml"
                            onFileUpload={(file, uploadProgressProps) =>
                                showModal((toggle) => (
                                    <ImgCropModal
                                        file={file}
                                        toggle={toggle}
                                        onSave={(savedFile) => {
                                            handleImageUpload({
                                                file: savedFile,
                                                rescaleToSize: {
                                                    width: 150,
                                                    height: 150,
                                                },
                                                uploadProgressProps,
                                                onUpload: (url) => setFieldValue('imageUrl', url),
                                            });
                                        }}
                                    />
                                ))
                            }
                            loading={uploadingImage}
                            subtitle="Upload jpeg/png/svg. Recommended format 100x100 px"
                        />
                    </FormRow>
                    {profile.adminUser && (
                        <>
                            <FormRow label="Demo mode">
                                <FormSwitch name="demoModeEnabled" />
                            </FormRow>
                            {values.demoModeEnabled && (
                                <FormRow label="Metrics multiplier">
                                    <FormNumberInput name="demoModeMultiplier" />
                                </FormRow>
                            )}
                        </>
                    )}
                    <div className="d-flex justify-content-end">
                        <Button color="primary" onClick={submitForm}>
                            Save
                        </Button>
                    </div>
                </SimpleCard>
            )}
        </Formik>
    );
};

export default ProfileGeneralInfoForm;
