import { Card, CardBody, Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { times, uniq } from 'lodash-es';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { getExternalSystemByLabel } from 'platform/common/constants/externalIntegration.constant';
import { usePromise } from 'platform/common/hooks/usePromise';
import { sortByName } from 'platform/common/utils/array.util';
import {
    AdvertiserTag,
    ExternalSystemIntegration,
    fetchCdpIntegrationsData,
} from 'platform/dashboard/dashboard.service';
import { isDefined } from '../../../common/common.types';

const getActiveVendorIds = (activeAdvertiserTag?: AdvertiserTag) => {
    if (!activeAdvertiserTag) {
        return [];
    }

    const codeSnippets = activeAdvertiserTag.thirdPartyTrackingRules.flatMap((r) => r.codeSnippets);

    return uniq(codeSnippets.map((s) => s.vendorId).filter(isDefined));
};

const isIntegrationActive = (vendor: string, integrations: ExternalSystemIntegration[]) => {
    const system = getExternalSystemByLabel(vendor);
    return integrations.some((i) => i.externalSystem === system && i.enabled);
};

interface Props {
    advertiserId: number;
}

const IntegrationsCard = ({ advertiserId }: Props) => {
    const [{ data, loading }] = usePromise(undefined, () => fetchCdpIntegrationsData(advertiserId), [
        advertiserId,
    ]);

    const activeVendorIds = getActiveVendorIds(data?.advertiser.activeAdvertiserTag);

    return (
        <Card>
            <CardBody className="p-3">
                <h5 className="mb-3">Integrations</h5>
                {loading && (
                    <Row>
                        {times(3).map((index) => (
                            <Col key={index} md={6} lg={4}>
                                <Placeholder height="80px" />
                            </Col>
                        ))}
                    </Row>
                )}
                {!loading && data && (
                    <Row>
                        {sortByName(data.vendors)
                            .filter((vendor) => vendor.useInCDP)
                            .map((vendor) => {
                                const active =
                                    isIntegrationActive(
                                        vendor.name,
                                        data.advertiser.externalSystemIntegrations
                                    ) || activeVendorIds.includes(vendor.id);
                                return (
                                    <Col key={vendor.name} md={6} lg={4}>
                                        <div
                                            className={classNames(
                                                'border p-3 mb-3 d-flex align-items-center',
                                                {
                                                    'bg-secondary': !active,
                                                }
                                            )}
                                        >
                                            {vendor.iconUrl && (
                                                <img
                                                    className="me-2"
                                                    alt={vendor.name}
                                                    src={vendor.iconUrl}
                                                    height={20}
                                                />
                                            )}
                                            <strong>{vendor.name}</strong>
                                            <div className="ms-auto">
                                                {active ? (
                                                    <div className="d-flex align-items-center text-success">
                                                        <div>Active</div>
                                                        <i className="fal fa-lg fa-check-circle ms-2" />
                                                    </div>
                                                ) : (
                                                    <div className="d-flex align-items-center text-muted">
                                                        <div>Inactive</div>
                                                        <i className="fal fa-lg fa-ban ms-2" />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                    </Row>
                )}
            </CardBody>
        </Card>
    );
};

export default IntegrationsCard;
