import { SyncRequestType } from 'platform/adminPanel/components/SyncJournal/syncJournal.types';
import {
    CampaignClassification,
    CampaignClassificationModel,
    LabelClassification,
} from 'platform/campaign/campaign/campaignClassification.types';
import { CampaignState } from 'platform/campaign/campaign/constants/state.constant';
import { IntegrationType, MediaType } from 'platform/campaign/strategy/constants/integrationTypes.constant';
import { BudgetOptimization } from 'platform/common/constants/optimization.constant';
import { makeOptions } from 'platform/common/utils/option.util';
import { MediaInsertionLink } from 'platform/mediaplan/mediaplan.types';
import {
    Adserver,
    ExternalSystem,
    IntegrationStatus,
} from '../../common/constants/externalIntegration.constant';

export type SynchronizationState = {
    message?: string;
    warnings?: string[];
    status?: IntegrationStatus;
    lastSucceeded?: Date;
    lastFailed?: Date;
    failedRequestTypes?: SyncRequestType[];
};

export type CampaignIntegration = {
    externalId?: string;
    externalAdvertiserId?: string;
    externalSystem: ExternalSystem;
    synchronizationState: SynchronizationState;
    budget?: number;
    dailyBudget?: number;
    attributes?: IntegrationAttributes;
};

export type IntegrationAttributes = {
    budgetOptimization?: BudgetOptimization;
    objective?: string;
};

export type Monetization = {
    costPerSpendPercentage?: number;
    productFeeSpendX?: number;
    productFeeTargetCPM?: number;
    productFeeFloor?: number;
};

export const CAMPAIGN_IMPORT_CHANNELS: { [key in ExternalSystem]?: string[] } = {
    GOOGLE: ['SEARCH', 'DISPLAY', 'VIDEO', 'PRICE_COMPARISON', 'APP_STORE', 'NATIVE', 'CROSS'],
    BING: ['SEARCH', 'DISPLAY', 'PRICE_COMPARISON', 'CROSS'],
    FACEBOOK: ['SOCIAL'],
};

export type TargetAudience = 'RETARGETING' | 'PROSPECTING';

export const TARGET_AUDIENCE_LABELS: Record<TargetAudience, string> = {
    RETARGETING: 'Retargeting',
    PROSPECTING: 'Prospecting',
};

export const TARGET_AUDIENCE_OPTIONS = makeOptions(TARGET_AUDIENCE_LABELS);

export type MarketingGoal = 'AWARENESS' | 'PERFORMANCE' | 'CONSIDERATION';

export const MARKETING_GOAL_LABELS: Record<MarketingGoal, string> = {
    AWARENESS: 'Awareness',
    PERFORMANCE: 'Performance',
    CONSIDERATION: 'Consideration',
};

export const MARKETING_GOAL_OPTIONS = makeOptions(MARKETING_GOAL_LABELS);

export type SearchTermStrategy = 'BRAND' | 'BRAND_COMBI' | 'GENERIC' | 'ALL';

export const SEARCH_TERM_STRATEGY_LABELS: Record<SearchTermStrategy, string> = {
    BRAND: 'Brand',
    BRAND_COMBI: 'Brand Combi',
    GENERIC: 'Generic',
    ALL: 'All',
};

export const SEARCH_TERM_STRATEGY_OPTIONS = makeOptions(SEARCH_TERM_STRATEGY_LABELS).filter(
    (o) => o.value !== 'ALL'
);

export type Campaign = {
    id: number;
    name: string;
    advertiserId: number;
    adserver: Adserver;
    type: IntegrationType;
    dateFrom: string;
    dateTo?: string;
    isFlightDateValid?: boolean;
    integration?: CampaignIntegration;
    redirectVendorId?: number;
    redirectExternalId?: string;
    redirectExternalSystem?: ExternalSystem;
    clickThroughUrlSuffix?: string;
    monetization: Monetization;
    clientBudget?: number;
    createdOn?: string;
    updatedOn?: string;
    importedOn?: string;
    creativeConceptId?: number;
    state?: CampaignState;
    notes?: string;
    vendorSystem?: string;
    mediaInsertionIds?: number[];
} & CampaignClassification;

export type CampaignModel = {
    id?: number;
    name?: string;
    clientBudget?: number;
    advertiserId: number;
    adserver: Adserver;
    notes?: string;
    vendorSystem?: string;
    mediaType: MediaType;
    type: IntegrationType;
    classification: CampaignClassificationModel;
    mediaInsertionLinks: MediaInsertionLink[];
    flightDates: {
        from: string;
        to?: string;
    };
    importedOn?: string;
    creativeConceptId?: number;
    monetization?: Monetization;
    integration?: CampaignIntegration;
    redirectVendorId?: number;
    redirectExternalSystem?: ExternalSystem;
    redirectExternalId?: string;
    clickThroughUrlSuffix?: string;
};

export type AdvertiserKeywordModel = {
    advertiserId: number;
} & AdvertiserKeywordBidModel;

type AdvertiserKeywordBidModel = {
    keyword: string;
    bidFactor: number;
};

export type CampaignPatch = {
    id: number;
    dateTo?: string;
    state?: CampaignState;
    budget?: number;
    dailyBudget?: number;
    monetizationId?: number;
} & LabelClassification;
