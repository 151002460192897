import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'redux-first-history';
import { Category } from 'platform/category/category.types';
import { getCategoryNames } from 'platform/category/category.util';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import LabelWithSubtext from 'platform/common/components/LabelWithSubtext/LabelWithSubtext';
import { useModal } from 'platform/common/components/Modal/Modal';
import Popover from 'platform/common/components/Popover/Popover';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { formatDateTime } from 'platform/common/utils/date.util';
import { filterBySearchQuery } from 'platform/common/utils/search.util';
import KpisTable from 'platform/segments/components/KpisTable/KpisTable';
import SegmentAvailabilityIndicator from 'platform/segments/components/SegmentAvailabilityIndicator/SegmentAvailabilityIndicator';
import SegmentHealthIndicator from 'platform/segments/components/SegmentHealthIndicator/SegmentHealthIndicator';
import DataSharingRulesModal from 'platform/segments/components/SegmentPage/DataSharingRulesModal';
import SegmentAlertingIndicator from 'platform/segments/components/SegmentPage/SegmentAlertingIndicator';
import SegmentToolbar from 'platform/segments/components/SegmentPage/SegmentToolbar';
import SegmentTypeBadge from 'platform/segments/components/SegmentTypeBadge/SegmentTypeBadge';
import { SEGMENT_TYPE_LABELS } from 'platform/segments/constants/segmentTypes.constant';
import { Advertiser, DataSharingTemplate, SegmentListItem } from 'platform/segments/segments.types';
import { changeSegmentState } from 'platform/segments/services/segments.service';
import { shapeVendors } from 'platform/segments/utils/segmentSyncPanel.util';

interface Props {
    loading: boolean;
    canEdit: boolean;
    segmentList: SegmentListItem[];
    advertiser?: Advertiser;
    vendors: { id: number; iconUrl?: string; name: string; useInCDP: boolean }[];
    categories: Category[];
    dataSharingTemplates: DataSharingTemplate[];
    searchQuery: string;
    fetchSegments: () => void;
    onSearchQueryChange: (query: string) => void;
}

const SegmentList = ({
    loading,
    canEdit,
    segmentList,
    searchQuery,
    advertiser,
    vendors,
    categories,
    dataSharingTemplates,
    fetchSegments,
    onSearchQueryChange,
}: Props) => {
    const dispatch = useDispatch();
    const { showModal } = useModal();

    const getRules = (segmentId: number) =>
        advertiser?.activeAdvertiserTag?.thirdPartyTrackingRules.filter((rule) =>
            rule.includedSegments.includes(segmentId)
        ) || [];

    const columns: TableColumn<SegmentListItem>[] = [
        {
            Header: 'Status',
            accessor: 'state',
            maxWidth: 75,
            Cell: (cell) => <StatusBadge status={cell.value} />,
        },
        {
            screenOnly: true,
            Header: 'Type',
            accessor: (row: SegmentListItem) => SEGMENT_TYPE_LABELS[row.type],
            maxWidth: 80,
            Cell: (cell) => <SegmentTypeBadge type={cell.original.type} />,
        },
        {
            exportOnly: true,
            Header: 'Type',
            exportCell: (row: SegmentListItem) => SEGMENT_TYPE_LABELS[row.type],
        },
        {
            screenOnly: true,
            Header: '',
            accessor: 'health',
            sortable: false,
            maxWidth: 40,
            Cell: (cell) => (
                <SegmentHealthIndicator
                    health={cell.value}
                    idForTooltip={`SegmentHealthIndicator-${cell.original.id}`}
                />
            ),
        },
        {
            exportOnly: true,
            Header: 'Health',
            accessor: 'health',
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            maxWidth: 70,
        },
        {
            screenOnly: true,
            Header: '',
            accessor: 'availability',
            sortable: false,
            maxWidth: 25,
            className: 'd-flex justify-content-center p-0',
            Cell: (cell) => <SegmentAvailabilityIndicator availability={cell.value} />,
        },
        {
            Header: '',
            sortable: false,
            maxWidth: 30,
            className: 'd-flex justify-content-center p-0',
            Cell: ({ original }) => <SegmentAlertingIndicator rules={original} />,
        },
        {
            exportOnly: true,
            Header: 'Availability',
            accessor: 'availability',
        },
        {
            exportOnly: true,
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Name/Advertiser',
            accessor: 'name',
            minWidth: 200,
            Cell: (cell) => (
                <Link
                    className="no-text-decoration text-dark"
                    to={`/segment-details/${cell.original.id}/overview`}
                >
                    <LabelWithSubtext
                        label={cell.value}
                        nativeTooltipText={cell.original.description}
                        subtext={advertiser?.name}
                        className="cursor-pointer"
                    />
                </Link>
            ),
        },
        {
            Header: 'Section',
            accessor: 'classificationCodes',
            minWidth: 150,
            Cell: ({ original: { classificationCodes } }) => (
                <LabelWithSubtext
                    label={getCategoryNames(classificationCodes, categories, 'SS')}
                    subtext={getCategoryNames(classificationCodes, categories, 'SSO')}
                />
            ),
            exportCell: ({ classificationCodes }) => {
                const sections = getCategoryNames(classificationCodes, categories, 'SS');
                const sources = getCategoryNames(classificationCodes, categories, 'SSO');
                return `${sections} / ${sources}`;
            },
        },
        {
            Header: 'Users, 30d',
            accessor: 'stats.adh_segment_unique_users_30d.value',
            type: DATA_TYPES.INT,
            minWidth: 100,
            maxWidth: 150,
            Cell: (cell) => (
                <Tooltip
                    renderTooltip={() => (
                        <KpisTable
                            segmentId={cell.original.id}
                            kpis={['adh_segment_unique_users_30d', 'adh_segment_total_30d']}
                        />
                    )}
                >
                    {cell.formattedValue}
                </Tooltip>
            ),
        },
        {
            Header: 'Users, ystd',
            accessor: 'stats.adh_segment_unique_users_yesterday.value',
            type: DATA_TYPES.INT,
            minWidth: 100,
            maxWidth: 150,
            Cell: (cell) => (
                <Tooltip
                    renderTooltip={() => (
                        <KpisTable
                            segmentId={cell.original.id}
                            kpis={['adh_segment_unique_users_yesterday', 'adh_segment_total_yesterday']}
                        />
                    )}
                >
                    {cell.formattedValue}
                </Tooltip>
            ),
        },
        {
            Header: 'Actions per segment',
            accessor: 'stats.adh_segment_unique_purchases_30d.value',
            type: DATA_TYPES.INT,
            minWidth: 130,
            maxWidth: 150,
        },
        {
            Header: 'Action prob.',
            accessor: 'stats.adh_segment_purchase_probability_30d.value',
            type: DATA_TYPES.P100,
            className: 'pull-right cell-align-right',
            minWidth: 100,
            maxWidth: 150,
        },
        {
            screenOnly: true,
            Header: 'Integrations',
            minWidth: 100,
            maxWidth: 150,
            className: 'cell-align-center',
            Cell: (cell) => (
                <Popover
                    renderPopover={() => {
                        const { activeAdvertiserTag } = advertiser || {};
                        const vendorList = shapeVendors(vendors, cell.original, activeAdvertiserTag);
                        const columnCount =
                            Math.ceil(vendorList.length / 6) > 3 ? 3 : Math.ceil(vendorList.length / 6) || 1;
                        const rowCount = Math.ceil(vendorList.length / columnCount) || 1;

                        return (
                            <div
                                className="SegmentSyncTable mx-3 mt-3"
                                style={{ height: 40 * rowCount, width: 150 * columnCount }}
                            >
                                {vendorList.map(({ id, name, iconUrl, indicatorColorClass }) => (
                                    <div key={id} className="SegmentSyncTable-item">
                                        <i className={`fa fa-circle me-1 ${indicatorColorClass}`} />
                                        {iconUrl && (
                                            <img
                                                className="SegmentSyncTable-vendorImage"
                                                alt={name}
                                                src={iconUrl}
                                                width={20}
                                                height={20}
                                            />
                                        )}
                                        {name}
                                    </div>
                                ))}
                            </div>
                        );
                    }}
                >
                    <i className="fa fa-link p-2" />
                </Popover>
            ),
        },
        {
            Header: 'Track as action',
            accessor: 'convertible',
            sortable: false,
            width: 150,
            className: 'cell-align-center',
            headerClassName: 'cell-align-center',
            Cell: ({ original }) => (
                <span
                    className={
                        original.convertible
                            ? 'fa fa-check-circle text-success'
                            : 'fa fa-minus-circle text-muted'
                    }
                />
            ),
        },
        {
            Header: 'URLs',
            className: 'cell-align-center',
            headerClassName: 'cell-align-center',
            width: 100,
            exportCell: (row: SegmentListItem) => row.urls?.join('\n'),
            Cell: ({ original }) => {
                const { urls } = original;

                if (!urls || !urls.length) {
                    return null;
                }

                return (
                    <Tooltip
                        renderTooltip={() => (
                            <div className="tooltip-inner-body p-2">
                                {urls.map((url, urlIndex) => (
                                    <div key={urlIndex}>
                                        <div className="py-1">{url}</div>
                                        {urlIndex !== urls.length - 1 && <hr className="my-0" />}
                                    </div>
                                ))}
                            </div>
                        )}
                    >
                        <span>{urls.length}</span>
                        <i className="far fa-question-circle ms-2" />
                    </Tooltip>
                );
            },
        },
        {
            Header: 'Data sharing rules',
            width: 150,
            className: 'cell-align-center',
            headerClassName: 'cell-align-center',
            exportCell: (row: SegmentListItem) => {
                const rules = getRules(row.id);
                return rules
                    .flatMap((rule) => rule.codeSnippets)
                    .map((snippet) => snippet.dataSharingTemplate || snippet.codeIntegralPart)
                    .join('\n');
            },
            Cell: ({ original }) => {
                const rules = getRules(original.id);

                if (!rules.length) {
                    return null;
                }

                return (
                    <div
                        className="d-flex align-items-center cursor-pointer outline-0 hover:text-primary"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            showModal((toggle) => (
                                <DataSharingRulesModal
                                    toggle={toggle}
                                    title={`${original.name} - Data sharing rules`}
                                    rules={rules}
                                    dataSharingTemplates={dataSharingTemplates}
                                />
                            ));
                        }}
                    >
                        <span>{rules.length}</span>
                        <i className="far fa-question-circle ms-2" />
                    </div>
                );
            },
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 150,
            Cell: (cell) => (
                <UpdatedOn date={cell.original.updatedOn} updatedBy={cell.original.updatedUserName} />
            ),
            exportCell: (row: SegmentListItem) => `${formatDateTime(row.updatedOn)}/${row.updatedUserName}`,
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: (cell) => {
                const items = [
                    {
                        label: canEdit ? 'Edit' : 'View',
                        action: () => {
                            dispatch(push(`/segments/${cell.original.id}`));
                        },
                    },
                ];

                if (canEdit) {
                    items.push({
                        label: cell.original.state === 'ARCHIVED' ? 'Activate' : 'Archive',
                        action: async () => {
                            await changeSegmentState(cell.original.id, !(cell.original.state === 'ARCHIVED'));
                            fetchSegments();
                        },
                    });
                }

                return <InlineDropdown items={items} />;
            },
        },
    ];

    const filteredList = filterBySearchQuery(
        segmentList,
        [...columns.map((column) => column.accessor), 'updatedUserName'],
        searchQuery
    );

    return (
        <FormattedTable
            containerClass="border-0 m-0 shadow-none"
            topToolbar={
                <SegmentToolbar
                    canEdit={canEdit}
                    advertiser={advertiser}
                    searchQuery={searchQuery}
                    onSearchQueryChange={onSearchQueryChange}
                />
            }
            exportData={{ filename: 'segments' }}
            columns={columns}
            data={filteredList}
            loading={loading}
            defaultSorted={[
                {
                    orderBy: 'stats.adh_segment_unique_users_30d.value',
                    direction: 'DESC',
                },
            ]}
        />
    );
};

export default SegmentList;
