import { ChangeEvent, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { gql, useQuery } from '@apollo/client';
import AdvertiserTagUpdateConfirmationModal from 'platform/adminPanel/components/AdvertiserTagUpdateList/AdvertiserTagUpdateConfirmationModal';
import { AdvertiserTag } from 'platform/advertiserTag/advertiserTag.types';
import { reuploadAdvertiserTags } from 'platform/advertiserTag/services/advertiserTag.service';
import { Advertiser } from 'platform/advertisers/advertiser.types';
import { TableCell } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import { useModal } from 'platform/common/components/Modal/Modal';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { useFeature } from 'platform/common/hooks/useFeature';
import useToggle from 'platform/common/hooks/useToggle';
import { formatDateTime } from 'platform/common/utils/date.util';
import { filterBySearchQuery } from 'platform/common/utils/search.util';

export type TagWithAdvertiser = Pick<
    AdvertiserTag,
    'id' | 'domain' | 'updatedOn' | 'updatedUserName' | 'lastUploadOn'
> & {
    advertiser: Pick<Advertiser, 'id' | 'name' | 'state'>;
};

type RowType = TableCell<TagWithAdvertiser>;

const AdvertiserTagUpdateList = () => {
    const [search, setSearch] = useState('');

    const [uploading, toggleUpldoaing] = useToggle(false);
    const {
        data,
        loading,
        refetch: refetchTags,
    } = useQuery<{
        advertiserTags: TagWithAdvertiser[];
    }>(
        gql`
            query AdvertiserTagUpdateList {
                advertiserTags {
                    id
                    domain
                    updatedOn
                    updatedUserName
                    lastUploadOn
                    advertiser {
                        id
                        state
                        name
                    }
                }
            }
        `,
        { fetchPolicy: 'network-only' }
    );

    const tagsWithActiveAdvertiser =
        data?.advertiserTags.filter((tag) => tag.advertiser.state === 'ACTIVE') || [];

    const canEdit = useFeature('ROLE_CDP_EDIT');

    const [selectedTags, setSelectedTags] = useState<number[]>([]);
    const onTagSelect = (id: number) => () =>
        setSelectedTags((ids) => (ids.includes(id) ? ids.filter((tagId) => tagId === id) : [...ids, id]));

    const isTagSelected = (id: number) => selectedTags.includes(id);

    const onAllTagsSelect = (e: ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        setSelectedTags(
            selectedTags.length ? [] : tagsWithActiveAdvertiser.map((advertiserTag) => advertiserTag.id) || []
        );
    };

    const columnDefinitions = [
        {
            Header: () => (
                <Input
                    type="checkbox"
                    className="position-static m-0"
                    checked={!!selectedTags.length}
                    onChange={onAllTagsSelect}
                />
            ),
            width: 70,
            headerClassName: 'cell-align-center',
            className: 'cell-align-center',
            Cell: (props: RowType) => (
                <Input
                    type="checkbox"
                    className="position-static m-0 cursor-pointer"
                    checked={isTagSelected(props.original.id)}
                    onChange={onTagSelect(props.original.id)}
                />
            ),
            sortable: false,
        },
        {
            Header: 'Status',
            accessor: (tag: TagWithAdvertiser) => tag.advertiser.state,
            type: DATA_TYPES.TEXT,
            width: 80,
            Cell: ({ original }: RowType) => <StatusBadge status={original.advertiser.state} />,
        },
        {
            Header: 'Advertiser',
            type: DATA_TYPES.TEXT,
            width: 200,
            accessor: (tag: TagWithAdvertiser) => tag.advertiser.name,
        },
        {
            Header: 'Tag ID',
            accessor: (tag: TagWithAdvertiser) => tag.id,
            type: DATA_TYPES.ID,
            width: 75,
        },
        {
            Header: 'AdvertiserTag domain',
            accessor: (tag: TagWithAdvertiser) => tag.domain,
            minWidth: 200,
            type: DATA_TYPES.TEXT,
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: ({ original }: RowType) => (
                <Button
                    onClick={async () => {
                        toggleUpldoaing();
                        await reuploadAdvertiserTags([original.id]);
                        refetchTags();
                        toggleUpldoaing();
                    }}
                >
                    <i className="fal fa-play" />
                </Button>
            ),
        },
        {
            Header: 'Last uploaded',
            accessor: (tag: TagWithAdvertiser) => tag.lastUploadOn,
            maxWidth: 160,
            Cell: (props: TableCell<AdvertiserTag>) => formatDateTime(props.value) || null,
        },
        {
            Header: 'Edited',
            accessor: (tag: TagWithAdvertiser) => tag.updatedOn,
            width: 160,
            Cell: ({ original }: RowType) => (
                <UpdatedOn date={original.updatedOn} updatedBy={original.updatedUserName} />
            ),
        },
    ];

    const { showModal } = useModal();

    return (
        <div>
            <BodyContainer helpKey="advertiser_tag_update_list">
                {!canEdit && <CanNotEditWarning />}
                <FormattedTable
                    topToolbar={
                        <>
                            <Button
                                size="md"
                                color="primary"
                                className="me-3"
                                onClick={() =>
                                    showModal((toggle) => (
                                        <AdvertiserTagUpdateConfirmationModal
                                            toggle={toggle}
                                            tags={tagsWithActiveAdvertiser.filter((tag) =>
                                                selectedTags.includes(tag.id)
                                            )}
                                            onOk={async () => {
                                                await reuploadAdvertiserTags(selectedTags);
                                                await refetchTags();
                                                setSelectedTags([]);
                                            }}
                                        />
                                    ))
                                }
                                title="Update"
                                disabled={!selectedTags.length}
                            >
                                Update
                            </Button>
                            <ListToolbar value={search} onSearch={setSearch} />
                        </>
                    }
                    columns={columnDefinitions}
                    data={filterBySearchQuery(
                        tagsWithActiveAdvertiser,
                        [...columnDefinitions.map((column) => column.accessor), 'updatedUserName'],
                        search
                    )}
                    loading={loading || uploading}
                    defaultPageSize={50}
                    defaultSorted={[
                        {
                            orderBy: 'Advertiser',
                            direction: 'ASC',
                        },
                    ]}
                />
            </BodyContainer>
        </div>
    );
};

export default AdvertiserTagUpdateList;
