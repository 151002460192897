import { Button } from 'reactstrap';
import './WelcomeScreen.scss';

interface Props {
    onVideoButtonClick: (videoSrc: string) => void;
    onPlatformButtonClick: () => void;
}

const WelcomeScreen = ({ onVideoButtonClick, onPlatformButtonClick }: Props) => (
    <div className="WelcomeScreen px-5">
        <div className="WelcomeScreen-title">
            Liebes Telekom-Team, herzlich Willkommen auf unserer Plattform!
        </div>
        <div className="WelcomeScreen-text">
            Um einen einfachen Einstieg in unsere Plattform zu ermöglichen, haben wir im folgenden ein kurzes
            Einführungsvideo bereitgestellt
        </div>
        <div className="WelcomeScreen-buttons">
            <div>
                <Button
                    className="modal-button modal-button--blue mb-4"
                    onClick={() =>
                        onVideoButtonClick(
                            'https://dap-dev-images.s3.amazonaws.com/admin/videos/DAP_Demo_Intro.mp4'
                        )
                    }
                >
                    Weiter mit der Einführung
                    <i className="fal fa-arrow-right ms-3" />
                </Button>
            </div>
            <div>
                <Button className="modal-button" onClick={onPlatformButtonClick}>
                    Direkt zur Plattform
                    <i className="fal fa-arrow-right ms-3" />
                </Button>
            </div>
            <div className="WelcomeScreen-subText mt-2">
                Das Demo-Login zeigt nur einen Ausschnitt des Funktionsumfangs
            </div>
        </div>
    </div>
);

export default WelcomeScreen;
