import { ChangeEvent, useRef } from 'react';
import { Button } from 'reactstrap';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { CHAT_DEFAULT_FILE_ACCEPT_TYPES } from '../chat.constants';
import useChat from '../useChat';
import { useChatFileUpload } from '../useChatFileUpload';

const ChatFileUploadButton = () => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { selectedAgent } = useChat();
    const { uploadFiles } = useChatFileUpload();

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
        uploadFiles(e.target.files as unknown as File[]);
        e.target.value = ''; // clear the input value to allow uploading the same file again
    };

    return (
        <Tooltip renderTooltip={() => 'File'} className="d-flex align-items-center">
            <Button
                className="ms-2 border-0 bg-transparent p-0 d-flex align-items-center"
                onClick={handleClick}
            >
                <i className="far fa-files fs-5 text-light-slate-gray me-2" />
                <input
                    ref={fileInputRef}
                    accept={[
                        ...(selectedAgent?.supportedFileTypes || []),
                        CHAT_DEFAULT_FILE_ACCEPT_TYPES,
                    ]?.join(', ')}
                    type="file"
                    className="d-none"
                    onChange={handleUpload}
                />
            </Button>
        </Tooltip>
    );
};

export default ChatFileUploadButton;
