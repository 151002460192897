import qs from 'qs';
import { AdvertiserAccountKey } from 'platform/advertisers/advertiser.types';
import { NavigationItem } from 'platform/app/app.types';

export const CAMPAIGNS: NavigationItem = {
    name: 'Campaigns',
    path: '/campaigns',
    requiresFeature: 'ROLE_CAMPAIGNS_VIEW',
};

export const CAMPAIGN_LIST: NavigationItem = {
    name: 'Campaigns',
    systemReportKey: 'campaign_list',
    requiresFeature: 'ROLE_CAMPAIGNS_VIEW',
};

export const CAMPAIGN_IMPORT: NavigationItem = {
    name: 'Campaigns Importer',
    path: '/campaigns-importer',
    requiresFeature: 'IMPORTER',
};

export const getCampaignImportListUrl = (params: AdvertiserAccountKey) =>
    `${CAMPAIGN_IMPORT.path}?${qs.stringify(params)}`;

export const getCampaignImportDetailsUrl = (externalCampaignId: string, params: AdvertiserAccountKey) =>
    `${CAMPAIGN_IMPORT.path}/${externalCampaignId}?${qs.stringify(params)}`;

export const CHANGE_LOG: NavigationItem = {
    name: 'Change Log',
    path: '/change-log',
    requiresFeature: 'ROLE_CHANGELOG_VIEW',
};
