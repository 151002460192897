import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import { gql, useQuery } from '@apollo/client';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { STATUS_OPTIONS } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { useFeature } from 'platform/common/hooks/useFeature';
import { filterBySearchQuery } from 'platform/common/utils/search.util';
import { DataSharingTemplate } from '../../adminPanel.types';
import SegmentSharingTemplateCreate from '../SegmentSharingTemplateForm/SegmentSharingTemplateCreate';
import SegmentSharingTemplateEdit from '../SegmentSharingTemplateForm/SegmentSharingTemplateEdit';

const QUERY = gql`
    query SegmentSharingTemplates($fragment: String, $state: [String]) {
        dataSharingTemplates(fragment: $fragment, state: $state) {
            codeTemplate
            codeType
            globalVariablesInTemplate
            id
            status
            systemName
            name
            updatedOn
            updatedUserName
            variablesInTemplate
            vendorId
        }
    }
`;

const SegmentSharingTemplateList = () => {
    const dispatch = useDispatch();
    const canEdit = useFeature('ROLE_ADMINPANEL_EDIT');
    const [searchQuery, setSearchQuery] = useState('');
    const [status, setStatus] = useState(['ACTIVE']);

    const { data, loading, refetch } = useQuery<{ dataSharingTemplates: DataSharingTemplate[] }>(QUERY, {
        variables: {
            state: status,
        },
    });

    const listData = data?.dataSharingTemplates || [];

    const columns: TableColumn<DataSharingTemplate>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            type: DATA_TYPES.TEXT,
            maxWidth: 80,
            Cell: ({ original }) => <StatusBadge status={original.status} />,
        },
        {
            Header: 'ID',
            accessor: 'id',
            maxWidth: 80,
            type: DATA_TYPES.ID,
        },
        {
            Header: 'Vendor',
            accessor: 'name',
            maxWidth: 160,
            type: DATA_TYPES.TEXT,
        },
        {
            Header: 'Name',
            accessor: 'systemName',
            type: DATA_TYPES.TEXT,
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            type: DATA_TYPES.TEXT,
            maxWidth: 160,
            Cell: ({ original }) => (
                <UpdatedOn date={original.updatedOn} updatedBy={original.updatedUserName} />
            ),
        },
        {
            Header: '',
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: ({ original }) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () => {
                                dispatch(push(`/admin-panel/segment-sharing-templates/${original.id}`));
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <>
            <BodyContainer helpKey="segment_sharing_template_list">
                {!canEdit && <CanNotEditWarning />}
                <SelectWithAddon
                    name="Status"
                    className="mb-3"
                    value={status}
                    options={STATUS_OPTIONS}
                    isMulti
                    onChange={setStatus}
                />
                <FormattedTable
                    topToolbar={
                        <ListToolbar
                            onCreate={
                                canEdit
                                    ? () => dispatch(push('/admin-panel/segment-sharing-templates/new'))
                                    : undefined
                            }
                            onSearch={(newValue) => setSearchQuery(newValue)}
                        />
                    }
                    columns={columns}
                    data={filterBySearchQuery(
                        listData,
                        [...columns.map((column) => column.accessor), 'updatedUserName'],
                        searchQuery
                    )}
                    loading={loading}
                    defaultSorted={[
                        {
                            orderBy: 'systemName',
                            direction: 'ASC',
                        },
                    ]}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <SegmentSharingTemplateCreate
                            redirectTo="/admin-panel/segment-sharing-templates"
                            canEdit={canEdit}
                            afterSubmit={refetch}
                        />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <SegmentSharingTemplateEdit
                            redirectTo="/admin-panel/segment-sharing-templates"
                            canEdit={canEdit}
                            afterSubmit={refetch}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default SegmentSharingTemplateList;
