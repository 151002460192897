import { ReactNode, FocusEvent } from 'react';
import { FormFeedback } from 'reactstrap';
import { useField } from 'formik';
import { isString } from 'lodash-es';
import Select, { SelectProps } from 'platform/common/components/Select/Select';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { OptionType } from 'platform/common/components/Select/select.types';
import { validator, Validator } from 'platform/common/utils/validators.util';

export type FormSelectProps<T extends OptionType = OptionType> = {
    name: string;
    containerClassName?: string;
    inputGroupClassName?: string;
    fetchError?: string;
    firstAddon?: string;
    secondAddon?: ReactNode;
    endAddon?: ReactNode;
    validate?: Validator | Validator[];
    valueKey?: string;
    labelKey?: string;
    isValidationMessageVisible?: boolean;
    disabled?: boolean;
} & SelectProps<T>;

const FormSelect = <T extends OptionType = OptionType>({
    inputGroupClassName,
    containerClassName,
    valueKey = 'value',
    labelKey = 'label',
    isValidationMessageVisible = true,
    firstAddon,
    secondAddon,
    endAddon,
    disabled,
    validate,
    fetchError,
    id,
    className,
    name,
    options = [],
    getOptionValue = (option) => option[valueKey],
    getOptionLabel = (option) => option[labelKey],
    onChange,
    onBlur,
    ...rest
}: FormSelectProps<T>) => {
    const [field, meta, helpers] = useField({ name, validate: validate && validator(validate) });
    const invalid = meta.touched && !!meta.error;

    const handleChange = (value: any) => {
        // Formik resets touched state of undefined values upon form submission, so we coerce to null instead:
        helpers.setValue(value ?? null);
        if (onChange) {
            onChange(value);
        }
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        helpers.setTouched(true);
        onBlur?.(e);
    };

    const hasAddOn = !!firstAddon || !!secondAddon || !!endAddon;

    const messages = (
        <>
            {invalid && isString(meta.error) && isValidationMessageVisible && (
                <FormFeedback className="d-block">{meta.error}</FormFeedback>
            )}
            {fetchError && isValidationMessageVisible && (
                <FormFeedback className="d-block">{fetchError}</FormFeedback>
            )}
        </>
    );

    if (hasAddOn) {
        return (
            <div className={containerClassName}>
                <SelectWithAddon<T>
                    {...rest}
                    name={firstAddon}
                    inputId={id || name}
                    className={inputGroupClassName}
                    selectClassname={className}
                    options={options}
                    value={field.value || options.find((option) => !!option.default)}
                    invalid={invalid}
                    isDisabled={disabled}
                    getOptionValue={getOptionValue}
                    getOptionLabel={getOptionLabel}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                {messages}
            </div>
        );
    }

    return (
        <div className={containerClassName}>
            <Select
                {...rest}
                inputId={id || name}
                className={className}
                options={options}
                value={field.value || options.find((option) => !!option.default)}
                invalid={invalid}
                isDisabled={disabled}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                onBlur={handleBlur}
                onChange={handleChange}
                hideSelectedOptions
            />
            {messages}
        </div>
    );
};

export default FormSelect;
