import { DependencyList, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { PivotSettings, ReportSettings } from 'platform/analytics/analytics.service';
import { ColumnColouringType, ReportType, TableState } from 'platform/analytics/analytics.types';
import { isOlapReport } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { useAnalyticsTemplate } from 'platform/analytics/hooks/useAnalyticsTemplate';
import { useCompatibleFilters } from 'platform/analytics/hooks/useCompatibleFilters';
import {
    getDimensionsWithDependants,
    getPivotRows,
} from 'platform/analytics/reportComponents/ReportTableContainer/columnBuilders';
import { useReport } from 'platform/analytics/reportComponents/useReport';
import { Sort } from 'platform/common/common.types';
import { arrayItemUpdate } from 'platform/common/utils/array.util';
import { stringifyParams } from 'platform/common/utils/url.util';
import { DATA_EXPLORER } from 'platform/dataExplorer/dataExplorer.route';

export const useReportTableContainer = (componentState: TableState) => {
    const dispatch = useDispatch();
    const { includeVatRate, modelOptIn } = useSelector(analyticsSelectors.settings);
    const activePreset = componentState.presets[componentState.activePresetIndex];
    const { dimensions, metrics, customFilters, customDateRange, pivot, templateId } = activePreset;
    const template = useAnalyticsTemplate(templateId);
    const reportType: ReportType = !!pivot && isOlapReport(template) ? 'PIVOT' : template.type;

    const deps: DependencyList = useMemo(() => {
        const dynamicDeps: any[] = [
            template.dimensions,
            template.metrics,
            dimensions,
            metrics,
            customFilters,
            customDateRange,
            activePreset.pageSize,
            activePreset.sort,
            templateId,
            pivot,
            includeVatRate,
            modelOptIn,
        ];

        // should refetch OLAP report if page number, page size or sort changed
        if (reportType === 'DEFAULT') {
            dynamicDeps.push(componentState.pageNumber, componentState.pageSize, componentState.sort);
        }

        return dynamicDeps;
    }, [
        reportType,
        template.dimensions,
        template.metrics,
        dimensions,
        metrics,
        customFilters,
        customDateRange,
        activePreset.pageSize,
        activePreset.sort,
        componentState.pageNumber,
        componentState.pageSize,
        componentState.sort,
        templateId,
        pivot,
        includeVatRate,
        modelOptIn,
    ]);

    const settings = useMemo(() => {
        if (pivot && reportType === 'PIVOT') {
            return {
                definition: {
                    templateId,
                    column: pivot.column,
                    rows: getPivotRows(metrics, dimensions, pivot),
                    totals: true,
                },
                sorting: componentState.sort,
                includeVatRate,
                modelOptIn,
            } as PivotSettings;
        }

        const dimensionsWithPivot = pivot ? [...dimensions, pivot.column] : dimensions;

        const defaultPaging = isOlapReport(template)
            ? {
                  pageNumber: componentState.pageNumber,
                  pageSize: componentState.pageSize,
              }
            : undefined;

        return {
            dimensions: getDimensionsWithDependants(dimensionsWithPivot, template.dimensions),
            withDrillAndActions: true,
            metrics,
            templateId,
            customFilters,
            customDateRange,
            sort: activePreset.sort?.length
                ? activePreset.sort
                : componentState.sort.filter(({ orderBy }) =>
                      [...template.metrics, ...template.dimensions].map((c) => c.key).includes(orderBy)
                  ),
            paging: activePreset.pageSize
                ? { pageNumber: 0, pageSize: activePreset.pageSize }
                : defaultPaging,
            includeVatRate,
            modelOptIn,
        } as ReportSettings;
    }, deps);

    const { compatibleMetrics, compatibleColumnsLoading } = useCompatibleFilters({
        templateId,
        componentId: componentState.id,
        columns: [...metrics, ...dimensions],
    });

    const { loading, report, error, compareReport, refetchReport } = useReport({
        settings,
        type: reportType,
        shouldFormatDimensions: false,
    });

    const redirectToDataExplorer = () => {
        dispatch(
            push({
                pathname: DATA_EXPLORER.path,
                search: stringifyParams({
                    explorerSettings: encodeURIComponent(
                        JSON.stringify({
                            activeTemplateId: template.id,
                            dimensions,
                            metrics,
                        })
                    ),
                }),
            })
        );
    };

    const changePage = (pageNumber: number) =>
        dispatch(
            analyticsSettingsActions.changeComponentState({
                ...componentState,
                pageNumber,
            })
        );

    const changePageSize = (pageSize: number) =>
        dispatch(
            analyticsSettingsActions.changeComponentState({
                ...componentState,
                pageSize,
            })
        );

    const changeSorting = (sort: Sort[]) =>
        dispatch(analyticsSettingsActions.changeComponentState({ ...componentState, sort }));

    const removeColumn = (columnId?: string) =>
        dispatch(
            analyticsSettingsActions.changeComponentState({
                ...componentState,
                presets: arrayItemUpdate(componentState.presets, componentState.activePresetIndex, {
                    ...activePreset,
                    metrics: activePreset.metrics.filter((m) => m !== columnId),
                    dimensions: activePreset.dimensions.filter((m) => m !== columnId),
                }),
            })
        );

    const changeColumnColouring = (columnId: string, colouring: ColumnColouringType | undefined) => {
        const columnsColouring = { ...componentState.columnsColouring };
        if (!colouring) {
            delete columnsColouring?.[columnId];
        }
        dispatch(
            analyticsSettingsActions.changeComponentState({
                ...componentState,
                columnsColouring: {
                    ...columnsColouring,
                    ...(colouring ? { [columnId]: colouring } : {}),
                },
            })
        );
    };

    return {
        template,
        redirectToDataExplorer,
        changePage,
        changePageSize,
        changeSorting,
        removeColumn,
        changeColumnColouring,
        compatibleMetrics,
        report,
        compareReport,
        error,
        loading: loading || compatibleColumnsLoading,
        refetchReport,
    };
};
