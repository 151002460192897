import { NavigationItem } from 'platform/app/app.types';

export const ADMIN_PANEL: NavigationItem = {
    name: 'Admin panel',
    path: '/admin-panel',
    redirectTo: '/admin-panel/segment-sharing-templates',
    requiresFeature: 'ROLE_ADMINPANEL_VIEW',
};

export const REPORT_MANAGEMENT: NavigationItem = {
    name: 'Report Management',
    path: '/report-management',
    requiresFeature: 'ROLE_ADMINPANEL_VIEW',
};
