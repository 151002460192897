import { useState } from 'react';
import { User } from 'platform/userManagement/types/user.type';
import { Task, TaskBoardChange, TaskCreationData, TaskData } from '../task.types';
import KanbanCardLabel from './KanbanCardLabel';
import KanbanSortableSubCard from './KanbanSortableSubCard';
import KanbanSubCardAdder from './KanbanSubCardAdder';
import KanbanToggleButton from './KanbanToggleButton';

interface Props {
    boardId: number;
    subtasks: Task[];
    userOptions: User[];
    onOpen: (taskId: number) => void;
    onAdd: (position: number, data: TaskCreationData) => Promise<void>;
    onMove: (taskId: number, position: number) => Promise<void>;
    onUpdate: (taskId: number, data: Partial<TaskData>) => Promise<void>;
    onDelete: (taskId: number) => Promise<void>;
    onTaskWatchChange: (taskId: number, watch: boolean) => Promise<TaskBoardChange[]>;
}

const KanbanSubCardList = ({
    boardId,
    subtasks,
    userOptions,
    onOpen,
    onAdd,
    onMove,
    onUpdate,
    onDelete,
    onTaskWatchChange,
}: Props) => {
    const [addingTask, setAddingTask] = useState(false);

    const handleAdd = async (data: TaskCreationData) => {
        await onAdd(subtasks.length, data);
        setAddingTask(false);
    };

    const handleDrop = (moveFrom: Task, moveTo: Task) => {
        const position = subtasks.findIndex((s) => s.id === moveTo.id);
        onMove(moveFrom.id, position);
    };

    return (
        <>
            <div className="d-flex">
                <KanbanCardLabel icon="fa fa-list-check">Subtasks</KanbanCardLabel>
                <KanbanToggleButton onClick={() => setAddingTask(true)} />
            </div>
            {addingTask && <KanbanSubCardAdder onSubmit={handleAdd} onCancel={() => setAddingTask(false)} />}
            <div className={subtasks.length ? 'mt-2' : ''}>
                {subtasks.map((subtask, index) => (
                    <KanbanSortableSubCard
                        key={subtask.id}
                        boardId={boardId}
                        subtask={subtask}
                        position={index}
                        totalSubtasks={subtasks.length}
                        userOptions={userOptions}
                        onClick={() => onOpen(subtask.id)}
                        onMove={(position) => onMove(subtask.id, position)}
                        onUpdate={(data) => onUpdate(subtask.id, data)}
                        onDelete={() => onDelete(subtask.id)}
                        onDrop={handleDrop}
                        onTaskWatchChange={onTaskWatchChange}
                    />
                ))}
            </div>
        </>
    );
};

export default KanbanSubCardList;
