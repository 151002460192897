import JSZip from 'jszip';
import { getFileExtension } from 'platform/common/utils/file.util';
import { dataUrlToBase64 } from 'platform/common/utils/url.util';
import { CreativeSize } from 'platform/creatives/constants/creativeDimensions';

const META_AD_SIZE_TAG = /<meta\s+name=["']ad.size["']\s+content=["']width=(\d+)\s*,\s*height=(\d+)/i;
const GWD_METADATA_TAG = /<script type=["']text\/gwd-admetadata["']>(.+?)<\/script>/i;

export type AssetMetadata = CreativeSize;

const DEFAULT_BANNER_SIZE = { width: 1000, height: 1000 };

// See https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
const IMAGE_EXTENSIONS = [
    'apng',
    'avif',
    'gif',
    'png',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp',
    'bmp',
    'ico',
    'cur',
    'tif',
    'tiff',
];

export const isImage = (file?: { type?: string }) => file?.type?.startsWith('image/');

export const isImageFilename = (filename: string) =>
    IMAGE_EXTENSIONS.includes(getFileExtension(filename) ?? '');

export const getHtmlAssetMetadata = async (zipBase64: string): Promise<AssetMetadata> => {
    const { files } = await JSZip.loadAsync(zipBase64, { base64: true });
    const htmlFile = Object.values(files).find((f) => f.name.endsWith('.html'));
    if (!htmlFile) {
        return DEFAULT_BANNER_SIZE;
    }
    const html = await htmlFile.async('text');
    const [, metaWidth, metaHeight] = html.match(META_AD_SIZE_TAG) ?? [];
    if (Number(metaWidth) && Number(metaHeight)) {
        return { width: Number(metaWidth), height: Number(metaHeight) };
    }
    const [, gwdMetadata] = html.match(GWD_METADATA_TAG) ?? [];
    if (gwdMetadata) {
        const { minWidth, minHeight, maxWidth, maxHeight } = JSON.parse(gwdMetadata).creativeProperties ?? {};
        const width = Number(minWidth) || Number(maxWidth);
        const height = Number(minHeight) || Number(maxHeight);
        if (width && height) {
            return { width, height };
        }
    }
    return DEFAULT_BANNER_SIZE;
};

export const getAssetMetadata = async (
    file: { type?: string; content?: string },
    imgTagId: string
): Promise<AssetMetadata> => {
    if (isImage(file)) {
        const img = document.getElementById(imgTagId) as HTMLImageElement;
        if (img) {
            return { width: img.naturalWidth || 1000, height: img.naturalHeight || 1000 };
        }
    }
    if (file.content) {
        try {
            return getHtmlAssetMetadata(dataUrlToBase64(file.content));
        } catch {
            return DEFAULT_BANNER_SIZE;
        }
    }
    return DEFAULT_BANNER_SIZE;
};
