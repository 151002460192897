import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash-es';
import LabelClassificationCard from 'platform/campaign/logBook/components/LogBookForm/LabelClassificationCard';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import { LogBookTable } from 'platform/mediaplan/components/LogBookTable';
import MediaInsertionPopoverButton from 'platform/mediaplan/components/MediaplanTreeForm/MediaInsertionPopoverButton';
import {
    CHANGE_REASON_OPTIONS,
    ChangeTrackingModel,
    MediaplanChange,
    MediaplanFormModel,
} from 'platform/mediaplan/mediaplan.types';
import { requiresComment } from 'platform/mediaplan/mediaplan.utils';
import './Mediaplan.scss';

interface Props {
    title: string;
    field: string;
    onChange: (key: string, value: any) => void;
    mandatory: boolean;
    newChange?: ChangeTrackingModel;
    previousChanges?: MediaplanChange[];
}

const LogBookPopover = ({ title, newChange, previousChanges, mandatory, field, onChange }: Props) => {
    const isDataMissing = !newChange?.reason || (requiresComment(newChange) && !newChange.comment);
    const { values } = useFormikContext<MediaplanFormModel>();
    const currentReason = get(values, `${field}.reason`);

    return (
        <MediaInsertionPopoverButton
            style={{ width: 900 }}
            iconClassName={classNames('fa-book ms-3', {
                'text-success': !isEmpty(previousChanges) || !isDataMissing,
            })}
        >
            {() => (
                <>
                    <div className="MediaNode-popoverTitle">Log &quot;{title}&quot; changes</div>
                    <ControlledCard
                        title="General Info"
                        subtitle="required"
                        bodyClassName="d-flex align-items-start"
                        toggleable={false}
                    >
                        <FormRow label="Change reason" rowClassName="me-3" labelClassName="" labelWidth={12}>
                            <FormSelect
                                name={`${field}.reason`}
                                options={CHANGE_REASON_OPTIONS}
                                validate={mandatory ? required : undefined}
                            />
                        </FormRow>
                        <FormRow label="Comment" labelClassName="" rowClassName="flex-grow-1" labelWidth={12}>
                            <FormInput
                                name={`${field}.comment`}
                                type="textarea"
                                disabled={!currentReason}
                                placeholder={requiresComment(newChange) ? '(required)' : '(optional)'}
                                validate={requiresComment(newChange) ? required : undefined}
                                style={{ height: 34 }}
                                rows={2}
                            />
                        </FormRow>
                    </ControlledCard>
                    <LabelClassificationCard
                        labelWidth={12}
                        labelClassName="text-start"
                        bodyClassName="d-flex gap-4"
                        field={`${field}.classification`}
                        values={newChange?.classification}
                        onChange={onChange}
                        disabled={!currentReason}
                    />
                    {previousChanges && previousChanges.length > 0 && (
                        <ControlledCard
                            className="Mediaplan-logBookPopover-changeHistory dark mt-3 mb-0"
                            bodyClassName="p-0"
                            title={`History (${previousChanges.length} changes)`}
                        >
                            <LogBookTable changes={previousChanges} />
                        </ControlledCard>
                    )}
                </>
            )}
        </MediaInsertionPopoverButton>
    );
};

export default LogBookPopover;
