import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { Section } from 'platform/app/app.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { usePromise } from 'platform/common/hooks/usePromise';
import CustomReportFormRoutes from 'platform/customReports/ReportManagement/CustomReportFormRoutes';
import { useRefetchCustomReports } from 'platform/customReports/ReportManagement/useRefetchCustomReports';
import { useReportHandlers } from 'platform/customReports/ReportManagement/useReportHandlers';
import SectionsCard from 'platform/customReports/SectionsCard';
import { assignReportsToUser, fetchCustomReports } from 'platform/customReports/customReport.service';
import { CustomReport } from 'platform/customReports/customReport.types';
import { ADMIN_SEAT_ID } from 'platform/userManagement/mappers/user.mapper';

interface Props {
    templates: CustomReport[];
    status: ActiveOrArchived[];
    canEdit: boolean;
    onReportMove: (
        oldReport: CustomReport,
        newReport: Partial<CustomReport>,
        reports: CustomReport[],
        seatId?: number
    ) => Promise<void>;
    onReportChange: (report: CustomReport) => Promise<void>;
    onReportsCreate: (reports: CustomReport[]) => Promise<number[]>;
    onStatusChange: (status: ActiveOrArchived[]) => void;
}

const AdvertiserReports = ({
    templates,
    status,
    canEdit,
    onReportMove,
    onReportChange,
    onReportsCreate,
    onStatusChange,
}: Props) => {
    const { seatId, id: advertiserId } = useActiveAdvertiserComponent();

    const [
        {
            data: [seatReports, adminSeatCentralReports],
            loading,
        },
        refetchReports,
    ] = usePromise<CustomReport[][]>(
        [[], []],
        () =>
            Promise.all([
                fetchCustomReports({ seatId }).then((reports) => reports.filter((r) => r.createdByAdmin)),
                fetchCustomReports({
                    status,
                    pinned: true,
                    seatId: ADMIN_SEAT_ID,
                    section: ['CENTRAL_ANALYTICS'],
                    usedAsTemplate: false,
                }),
            ]),
        [status, seatId]
    );

    const { refetch } = useRefetchCustomReports(refetchReports, seatId);

    const { handleReportChange, handleReportsCreate, handleReportMove } = useReportHandlers({
        seatId,
        refetchReports,
        onReportChange,
        onReportMove,
        onReportsCreate,
    });

    const filterReports = (section: Section) =>
        seatReports.filter(
            (report) => (status.length ? status.includes(report.status) : true) && report.section === section
        );

    const isReportAssigned = (report: CustomReport) => {
        const seatSectionReportIds = filterReports(report.section!).map((r) => r.id);
        return seatSectionReportIds.includes(report.id);
    };

    const handleCentralReportAssign = async (report: CustomReport) => {
        if (!seatId) {
            return;
        }
        const reportIds = seatReports.map((r) => r.id!);
        const newReportIds = reportIds.includes(report.id!)
            ? reportIds.filter((r) => r !== report.id)
            : [...reportIds, report.id!];

        await assignReportsToUser({ seatId }, newReportIds);
        refetch();
    };

    const ADVERTISER_CARD_SECTIONS: {
        sections: {
            name: Section;
            reports: CustomReport[];
            onReportToggle?: (report: CustomReport) => void;
            canEdit?: boolean;
            canDrag?: boolean;
        }[];
    }[] = [
        {
            sections: [
                {
                    name: 'CUSTOM_DASHBOARD',
                    reports: filterReports('CUSTOM_DASHBOARD').filter((r) => r.createdByAdmin),
                },
                {
                    name: 'BUDGET',
                    reports: filterReports('BUDGET'),
                },
                {
                    name: 'OPTIMIZATION',
                    reports: filterReports('OPTIMIZATION'),
                },
                {
                    name: 'CUSTOM_ANALYTICS',
                    reports: filterReports('CUSTOM_ANALYTICS').filter((r) => r.createdByAdmin),
                },
            ],
        },
        {
            sections: [
                {
                    name: 'CENTRAL_ANALYTICS',
                    reports: adminSeatCentralReports,
                    onReportToggle: handleCentralReportAssign,
                    canEdit: false,
                    canDrag: false,
                },
            ],
        },
        {
            sections: [
                {
                    name: 'COLLABORATION',
                    reports: filterReports('COLLABORATION'),
                },
            ],
        },
    ];

    return (
        <BodyContainer helpKey="advertiser_reports">
            <ListFilters className="mb-3">
                <SelectWithAddon
                    name="Status"
                    value={status}
                    options={[
                        { value: 'ACTIVE', label: 'Active ' },
                        { value: 'ARCHIVED', label: 'Archived ' },
                    ]}
                    onChange={onStatusChange}
                    isMulti
                />
            </ListFilters>
            {loading && <OverlayLoader />}
            {seatId === ADMIN_SEAT_ID && (
                <Alert color="warning">
                    <div className="mb-2">To continue you need to assign a seat to this advertiser. </div>
                    <div>
                        Therefore, please navigate to{' '}
                        <Link to={`advertisers/advertiser/${advertiserId}`}>advertiser</Link> and select the
                        corresponding seat under &quot;Default seat&quot;.
                    </div>
                </Alert>
            )}
            {seatId !== ADMIN_SEAT_ID &&
                ADVERTISER_CARD_SECTIONS.map((card, index) => (
                    <InsetBox className="mb-3" key={index}>
                        {card.sections.map((section) => (
                            <SectionsCard
                                key={section.name}
                                seatId={seatId!}
                                section={section.name}
                                reports={section.reports}
                                templates={templates.filter((t) => t.section === section.name)}
                                loading={loading}
                                canEdit={section.canEdit ?? canEdit}
                                canDrag={section.canDrag}
                                isReportAssigned={isReportAssigned}
                                refetchReports={refetchReports}
                                onReportMove={(oldReport, newReport) =>
                                    handleReportMove(oldReport, newReport, seatReports)
                                }
                                onReportToggle={section.onReportToggle}
                                onReportChange={handleReportChange}
                                onReportsCreate={handleReportsCreate}
                            />
                        ))}
                    </InsetBox>
                ))}
            <CustomReportFormRoutes canEdit={canEdit} afterSubmit={refetch} />
        </BodyContainer>
    );
};

export default AdvertiserReports;
