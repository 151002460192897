import { useEffect, useRef, useState, FocusEvent, CSSProperties } from 'react';
import {
    ActionMeta,
    components as reactSelectComponents,
    Props as ReactSelectProps,
    InputActionMeta,
    MultiValueProps,
} from 'react-select';
import { isObject, sortBy } from 'lodash-es';
import { matchSorter } from 'match-sorter';
import { isDefined } from 'platform/common/common.types';
import {
    COLLAPSED_MORE_VALUE,
    getSelectStyles,
    isGroupSelect,
} from 'platform/common/components/Select/select.utils';
import MenuWithSelectAll from '../SelectTree/MenuWithSelectAll';
import { SelectTreeInnerComponentProps } from '../SelectTree/SelectTree';
import ReactSelectWrapper from './ReactSelectWrapper';
import { OptionType } from './select.types';
import './Select.scss';

export interface SelectPropsBase<T = any> extends ReactSelectProps<T> {
    value?: any;
    options: T[];
    getOptionLabel?: (value: T) => any;
    getOptionValue?: (value: T) => any;
    selectStyle?: CSSProperties;
    menuStyle?: CSSProperties;
    invalid?: boolean;
    canSelectAll?: boolean;
    onChange?: (value: any, action?: ActionMeta<any>) => void;
}

export type SelectProps<T = OptionType> = {
    collapseMoreValues?: boolean;
    keepSearchInputAfterSelect?: boolean;
} & Omit<SelectPropsBase<T>, keyof SelectTreeInnerComponentProps<T>>;

const Select = <T extends OptionType>({
    value,
    options,
    components,
    selectStyle,
    menuStyle,
    isMulti,
    menuIsOpen,
    collapseMoreValues,
    invalid = false,
    keepSearchInputAfterSelect = false,
    canSelectAll = true,
    returnOnlyValues = true,
    isSearchable = true,
    closeMenuOnSelect = true,
    isClearable = true,
    getOptionLabel = (option: T) => option.label,
    getOptionValue = (option: T) => option.value,
    onFocus,
    onBlur,
    onChange,
    onInputChange,
    styles,
    ...rest
}: SelectProps<T>) => {
    const [search, setSearch] = useState('');
    const [focused, setFocused] = useState(false);
    const ref = useRef<any>(null);
    const collapseThreshold = collapseMoreValues ? 4 : undefined;

    useEffect(() => {
        if (menuIsOpen) {
            ref.current?.focus();
        }
    }, [menuIsOpen]);

    const getLabel = (option: any) => {
        if (option.value === COLLAPSED_MORE_VALUE) return option.label;
        return getOptionLabel(option);
    };

    const getValue = (option: any) => {
        if (option.value === COLLAPSED_MORE_VALUE) return option.value;
        return getOptionValue(option);
    };

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        setFocused(true);
        onFocus?.(e);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        setFocused(false);
        onBlur?.(e);
    };

    const handleChange = (valueOptions: any, action: ActionMeta<T>) => {
        if (!onChange) {
            return;
        }
        const values = (() => {
            if (isMulti && !valueOptions) return [];
            if (!returnOnlyValues) return valueOptions;
            if (Array.isArray(valueOptions)) {
                if (action?.action === 'remove-value') {
                    if (collapseThreshold && action?.removedValue?.value === COLLAPSED_MORE_VALUE) {
                        return value?.slice(0, collapseThreshold - 1);
                    }
                    return value?.filter((val: any) => getValue(action?.removedValue) !== val);
                }
                return valueOptions.map(getValue);
            }
            return getValue(valueOptions || {});
        })();

        onChange(values, action);
    };

    const handleInputChange = (input: string, actionMeta: InputActionMeta) => {
        if (!(keepSearchInputAfterSelect && !input && actionMeta.action === 'set-value')) {
            setSearch(input);
        }
        onInputChange?.(input, actionMeta);
    };

    const processValues = (values: any[]) =>
        values.map((v: any) => options.find((option) => getValue(option) === v)).filter(isDefined);

    const resolveValue: any = (() => {
        if (Array.isArray(value) && value.some((v) => !isObject(v))) {
            return isMulti && !focused && collapseThreshold && value.length > collapseThreshold
                ? [
                      ...processValues(value.slice(0, collapseThreshold - 1)),
                      {
                          value: COLLAPSED_MORE_VALUE,
                          label: `+${value.length - collapseThreshold + 1}`,
                      },
                  ]
                : processValues(value);
        }
        if (!isObject(value)) {
            if (isGroupSelect(options)) {
                return options
                    .reduce<T[]>((arr, item) => {
                        arr.push(item.options ? item.options : item);
                        return arr;
                    }, [])
                    .flat()
                    .find((el) => el.value === value);
            }
            return options.find((option) => value === getValue(option));
        }

        return value;
    })();

    const resolvedOptions = search
        ? matchSorter(options, search, {
              keys: [getLabel, getValue],
              threshold: matchSorter.rankings.NO_MATCH,
              sorter: (items) => sortBy(items, [(o) => o.item.status, (o) => getLabel(o.item)]),
          })
        : options;

    const MultiValueRemove = (multiValueProps: MultiValueProps<any>) =>
        components?.MultiValueRemove ? (
            <components.MultiValueRemove {...multiValueProps} />
        ) : (
            <reactSelectComponents.MultiValueRemove {...multiValueProps} />
        );

    return (
        <ReactSelectWrapper
            {...rest}
            classNamePrefix="react-select"
            value={resolveValue}
            options={resolvedOptions}
            components={{
                MultiValueRemove,
                Menu: MenuWithSelectAll,
                ...components,
            }}
            styles={{
                ...getSelectStyles({
                    invalid,
                    selectStyle,
                    menuStyle,
                }),
                ...styles,
            }}
            inputValue={keepSearchInputAfterSelect ? search : undefined}
            isOptionDisabled={(option) => option.disabled}
            selectAllEnabled={isMulti && canSelectAll}
            isMulti={isMulti}
            menuIsOpen={menuIsOpen}
            isClearable={isClearable}
            closeMenuOnSelect={closeMenuOnSelect}
            returnOnlyValues={returnOnlyValues}
            isSearchable={isSearchable}
            getOptionLabel={getLabel}
            getOptionValue={getValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            onInputChange={handleInputChange}
        />
    );
};

export default Select;
