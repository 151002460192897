import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { ReportFilter } from 'platform/analytics/analytics.types';
import BulkCampaignCostSourceModal from 'platform/analytics/modes/CampaignAnalytics/BulkCampaignCostSourceModal';
import BulkCampaignLabelModal from 'platform/analytics/modes/CampaignAnalytics/BulkCampaignLabelModal';
import BulkCampaignRuntimeModal from 'platform/analytics/modes/CampaignAnalytics/BulkCampaignRuntimeModal';
import BulkCampaignStateModal from 'platform/analytics/modes/CampaignAnalytics/BulkCampaignStateModal';
import CampaignActions from 'platform/analytics/modes/CampaignAnalytics/CampaignActions';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import ButtonDropdown from 'platform/common/components/ButtonDropdown/ButtonDropdown';
import { useModal } from 'platform/common/components/Modal/Modal';
import { dateFilterSelectors } from 'platform/common/ducks/dateFilter.duck';
import { layoutSelectors } from 'platform/common/ducks/layout.duck';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import BulkCampaignProductSuggestModal from './BulkCampaignProductSuggestModal';

type Props = {
    filters: ReportFilter[];
    afterUpdate: () => void;
};

const CampaignToolBar = ({ filters, afterUpdate }: Props) => {
    const dispatch = useDispatch();
    const { showModal } = useModal();
    const isMobile = useSelector(layoutSelectors.isMobile);
    const hasFeature = useSelector(authSelectors.hasFeatureFn);
    const date = useSelector(dateFilterSelectors.date);
    const canEdit = hasFeature('ROLE_CAMPAIGNS_EDIT');
    const { id: advertiserId } = useActiveAdvertiserComponent();

    return (
        <>
            {hasFeature('ANALYTICS_INLINE_ACTIONS') && (
                <CampaignActions dispatch={dispatch} hasFeature={hasFeature} filters={filters} />
            )}
            {!isMobile && canEdit && (
                <ButtonDropdown
                    color="secondary"
                    className="ms-2 me-1"
                    items={[
                        {
                            label: 'Campaign state',
                            action: () =>
                                showModal((toggle) => (
                                    <BulkCampaignStateModal
                                        toggle={toggle}
                                        afterUpdate={afterUpdate}
                                        filters={filters}
                                        date={date}
                                    />
                                )),
                        },
                        {
                            label: 'Runtime',
                            action: () =>
                                showModal((toggle) => (
                                    <BulkCampaignRuntimeModal
                                        toggle={toggle}
                                        afterUpdate={afterUpdate}
                                        filters={filters}
                                        date={date}
                                    />
                                )),
                        },
                        {
                            label: 'Labels',
                            action: () =>
                                showModal((toggle) => (
                                    <BulkCampaignLabelModal
                                        toggle={toggle}
                                        afterUpdate={afterUpdate}
                                        filters={filters}
                                        date={date}
                                    />
                                )),
                        },
                        {
                            label: 'Cost sources',
                            action: () =>
                                showModal((toggle) => (
                                    <BulkCampaignCostSourceModal
                                        toggle={toggle}
                                        afterUpdate={afterUpdate}
                                        filters={filters}
                                        date={date}
                                    />
                                )),
                        },
                    ]}
                >
                    <i className="fa fa-pencil" /> Bulk Edit
                </ButtonDropdown>
            )}
            {!isMobile && canEdit && (
                <Button
                    color="secondary"
                    className="ms-1 me-3"
                    onClick={() =>
                        showModal((toggle) => (
                            <BulkCampaignProductSuggestModal
                                advertiserId={advertiserId}
                                toggle={toggle}
                                afterUpdate={afterUpdate}
                                filters={filters}
                                date={date}
                            />
                        ))
                    }
                >
                    <i className="fa-solid fa-wand-magic-sparkles me-1" /> Suggest classification
                </Button>
            )}
        </>
    );
};

export default CampaignToolBar;
