import { authSelectors } from 'platform/app/ducks/auth.duck';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InfoIconAlert from 'platform/common/components/InfoIconAlert/InfoIconAlert';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import useMasterAdvertiser from 'platform/common/hooks/useMasterAdvertiser';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import SegmentPageBodyContainer from './SegmentPageBodyContainer';

const SegmentPage = () => {
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const canEdit = useFeature('ROLE_CDP_EDIT');
    const isAdmin = useTypedSelector(authSelectors.isAdmin);
    const { masterAdvertiser, resolvedAdvertiserId } = useMasterAdvertiser(advertiserId);

    return (
        <div className="SegmentPage">
            <HeaderContainer>
                <PageHeader title="Segments" />
            </HeaderContainer>
            {masterAdvertiser && isAdmin && (
                <InfoIconAlert className="mt-2">
                    This data is retrieved from {masterAdvertiser?.name}
                </InfoIconAlert>
            )}
            {!canEdit && <CanNotEditWarning className="mt-3" />}
            <SegmentPageBodyContainer advertiserId={resolvedAdvertiserId} />
        </div>
    );
};

export default SegmentPage;
