import { Card, CardBody, Row } from 'reactstrap';
import { uniq } from 'lodash-es';
import { Report } from 'platform/analytics/analytics.types';
import ChannelLabel from 'platform/channels/components/ChannelLabel';
import { isNotEmpty } from 'platform/common/common.types';
import { ExternalSystem, getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import { getDuration } from 'platform/common/utils/date.util';
import { CustomerJourneyReportCM360 } from 'platform/dashboard/dashboard.types';
import { ItemGroupCM360 } from '../CustomerJourneyCM360';
import JourneyRowBlockDivider from '../JourneyRowBlockDivider';
import JourneyHeaderRowBlock from './JourneyHeaderRowBlock';
import JourneyTimeline from './JourneyTimeline/JourneyTimeline';

interface Props {
    report: Report<CustomerJourneyReportCM360>;
    groups: ItemGroupCM360[];
}

const JourneyEventListHeader = ({ report, groups }: Props) => {
    const lastActionRow = report.rows.filter((row) => row.journey_event_type === 'action').at(-1);

    if (!lastActionRow) {
        return null;
    }

    return (
        <Card>
            <CardBody className="p-0">
                <Row className="p-3">
                    <JourneyHeaderRowBlock
                        label="Winning Channel"
                        value={
                            <ChannelLabel
                                channel={lastActionRow.channel}
                                searchTermStrategy={lastActionRow.search_term_strategy}
                            />
                        }
                    />
                    <JourneyRowBlockDivider />
                    <JourneyHeaderRowBlock
                        label="Winning Vendor"
                        value={
                            lastActionRow.vendor_name ? (
                                <>
                                    {lastActionRow.vendor_icon_url && (
                                        <img
                                            alt="vendor icon"
                                            src={lastActionRow.vendor_icon_url}
                                            className="me-1"
                                            height={20}
                                            width={24}
                                            style={{ marginBottom: 2, objectFit: 'contain' }}
                                        />
                                    )}
                                    {getIntegrationLabel(lastActionRow.vendor_name as ExternalSystem)}
                                </>
                            ) : undefined
                        }
                    />
                    <JourneyRowBlockDivider />
                    <JourneyHeaderRowBlock
                        label="Total Channels"
                        value={uniq(report.rows.map((row) => row.channel).filter(isNotEmpty)).length}
                    />
                    <JourneyRowBlockDivider />
                    <JourneyHeaderRowBlock
                        label="Total Interactions"
                        value={report.rows.filter((row) => row.journey_event_type !== 'action').length}
                    />
                    <JourneyRowBlockDivider />
                    <JourneyHeaderRowBlock
                        label="Journey Duration"
                        value={getDuration(
                            report.rows[0].journey_event_date_time,
                            lastActionRow.journey_event_date_time
                        ).humanize()}
                    />
                </Row>
                {groups.length > 1 && <JourneyTimeline groups={groups} />}
            </CardBody>
        </Card>
    );
};

export default JourneyEventListHeader;
