import { useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { DataIngestionJobParams } from 'platform/adminPanel/components/DataIngestion/dataIngestion.service';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import DateRangePicker from 'platform/common/components/DateRangePicker/DateRangePicker';
import FormRow from 'platform/common/components/FormRow/FormRow';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import Switch from 'platform/formik/Switch/Switch';

type Props = {
    jobName: string;
    toggle: () => any;
    onSubmit: (params?: DataIngestionJobParams) => Promise<void>;
};

const DataIngestionJobModal = ({ jobName, toggle, onSubmit }: Props) => {
    const today = moment().format(ISO_DATE_FORMAT);
    const monthAgo = moment().subtract(1, 'month').format(ISO_DATE_FORMAT);
    const [custom, setCustom] = useState(false);
    const [params, setParams] = useState<{ periodFrom: string | undefined; periodTo: string | undefined }>({
        periodFrom: monthAgo,
        periodTo: today,
    });

    return (
        <Modal isOpen toggle={toggle}>
            <ModalHeader onClose={toggle}>
                Run <b>{jobName}</b> job
            </ModalHeader>
            <ModalBody>
                <FormRow label="Use custom data range" childrenColClassName="d-flex align-items-center">
                    <Switch value={custom} onChange={({ target }) => setCustom(target.checked)} />
                    {custom && (
                        <DateRangePicker
                            className="ms-3"
                            from={params.periodFrom}
                            to={params.periodTo}
                            maxDate={today}
                            onChange={(periodFrom, periodTo) => setParams({ periodFrom, periodTo })}
                        />
                    )}
                </FormRow>
            </ModalBody>
            <ModalFooter>
                <AnimatedButton disabled={false} onClick={() => onSubmit(custom ? params : undefined)}>
                    Run
                </AnimatedButton>
            </ModalFooter>
        </Modal>
    );
};

export default DataIngestionJobModal;
