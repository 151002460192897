import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';

const KanbanColumnTimeoutSettings = () => (
    <>
        <div className="d-flex align-items-center">
            <FormInput
                name="timeout.days"
                type="number"
                className="me-2"
                inputStyle={{ maxWidth: 40, height: 24 }}
                autoFocus
                validate={required}
            />
            or more days
        </div>
        <div className="d-flex align-items-center mt-2">
            <FormSwitch className="me-2" name="timeout.notifyLaneOwners" />
            Notify column owners
        </div>
        <div className="d-flex align-items-center mt-2">
            <FormSwitch className="me-2" name="timeout.notifyTaskUsers" />
            Notify assignees &amp; watchers
        </div>
    </>
);

export default KanbanColumnTimeoutSettings;
