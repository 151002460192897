import { CSSProperties, KeyboardEvent, ReactNode, useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import classNames from 'classnames';
import InlineEditContainer from 'platform/common/components/InlineEditContainer/InlineEditContainer';
import { DATA_TYPES } from 'platform/common/dataTypes';

export type DataTypes =
    | typeof DATA_TYPES.EUR
    | typeof DATA_TYPES.INT
    | typeof DATA_TYPES.P100
    | typeof DATA_TYPES.ARRAY_OF_STRINGS
    | typeof DATA_TYPES.FLOAT
    | typeof DATA_TYPES.TEXT;

type Props = {
    placeholder?: string;
    className?: string;
    iconClassName?: string;
    value?: string | number | string[];
    dataType?: DataTypes;
    typeOptions?: any;
    disabled?: boolean;
    inputType: 'text' | 'number';
    onChange: (a: any) => void;
    renderValue?: () => ReactNode;
    style?: CSSProperties;
};

const InlineEditInput = ({
    placeholder,
    className,
    iconClassName,
    inputType,
    value,
    onChange,
    typeOptions,
    dataType = DATA_TYPES.TEXT,
    disabled,
    style,
    renderValue,
}: Props) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const formattedValue =
        value === undefined || value === null || value === '' ? value : dataType.format(value, typeOptions);

    const parsedValue = inputType === 'number' ? Number(dataType.parse(inputValue, typeOptions)) : inputValue;

    return (
        <InlineEditContainer
            value={formattedValue}
            placeholder={placeholder}
            disabled={disabled}
            iconClassName={iconClassName}
            style={style}
            renderValue={renderValue}
        >
            {(props) => (
                <Input
                    className={classNames(className, 'py-1 px-2')}
                    onWheel={(e) => inputType === 'number' && e.currentTarget.blur()}
                    type={inputType}
                    onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                        if (event.key === 'Enter') {
                            onChange(parsedValue);
                            props.toggleEditMode();
                        }
                    }}
                    onBlur={() => {
                        onChange(parsedValue);
                        props.toggleEditMode();
                    }}
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={(event) => setInputValue(event.target.value)}
                    autoFocus
                />
            )}
        </InlineEditContainer>
    );
};

export default InlineEditInput;
