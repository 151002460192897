import axiosBff from 'platform/axios/axiosBff';
import { Status } from 'platform/common/constants/status.constant';
import {
    InsightColumn,
    InsightQueryMetadata,
    InsightQuerySummary,
    InsightQueryVersion,
    InsightQueryVersionSummary,
    InsightReport,
    InsightSource,
} from 'platform/insight/insight.types';

export const fetchInsightReport = (id: number) =>
    axiosBff.get<InsightReport>(`/insight/api/reports/${id}`).then((r) => r.data);

export const fetchInsightReports = (params: { status?: Status[]; name?: string; advertiserId?: number[] }) =>
    axiosBff.get<InsightReport[]>('/insight/api/reports', { params }).then((r) => r.data);

export const changeInsightReportStatus = (id: number, status: Status) =>
    axiosBff.put(`/insight/api/reports/${id}/status`, { status });

export const createInsightReport = (report: InsightReport) => axiosBff.post('/insight/api/reports', report);

export const updateInsightReport = (id: number, report: InsightReport) =>
    axiosBff.put(`/insight/api/reports/${id}`, report);

export const fetchInsightQuerySummary = (id: number) =>
    axiosBff.get<InsightQuerySummary>(`/insight/api/queries/${id}`).then((r) => r.data);

export const fetchInsightQueryVersions = (queryId: number) =>
    axiosBff
        .get<InsightQueryVersionSummary[]>(`/insight/api/queries/${queryId}/versions`)
        .then((r) => r.data);

export const fetchInsightQueryVersion = (queryId: number, timestamp: string) =>
    axiosBff
        .get<InsightQueryVersion>(`/insight/api/queries/${queryId}/versions/${timestamp}`)
        .then((r) => r.data);

export const analyseInsightQuery = (payload: {
    source: InsightSource;
    code: string;
    columns?: InsightColumn[];
}) => axiosBff.post<InsightQueryMetadata>(`/insight/api/queries/analyse`, payload).then((r) => r.data);

export const normalizeColumnMetadata = (columns: InsightColumn[]) =>
    columns.map((c) => ({
        ...c,
        key: c.key.toLowerCase(),
        aggregateFunction: c.aggregateFunction?.toLowerCase(),
    }));
