import classNames from 'classnames';
import { Period } from 'platform/common/common.types';
import DateRangePicker from 'platform/common/components/DateRangePicker/DateRangePicker';
import { formatDate } from 'platform/common/utils/date.util';
import ReportInteractionContainer from './ReportInteractionContainer';

interface Props {
    value?: Period;
    onlyFuture?: boolean;
    onlyPast?: boolean;
    required?: boolean;
    onChange: (value: Period) => void;
}

const today = formatDate(new Date());

const ReportInteractionDatePicker = ({ value, onlyFuture, onlyPast, onChange, required }: Props) => (
    <ReportInteractionContainer required={required} isValueEmpty={!value?.from && !value?.to}>
        {(setTouched, invalid) => (
            <DateRangePicker
                className={classNames({ 'form-control is-invalid p-0': invalid })}
                onChange={(from, to) => onChange({ from, to })}
                from={value?.from}
                to={value?.to}
                ranges={{}}
                allowApplyEmptyValues
                minDate={onlyFuture ? today : undefined}
                maxDate={onlyPast ? today : undefined}
                onBlur={() => setTouched(true)}
            />
        )}
    </ReportInteractionContainer>
);

export default ReportInteractionDatePicker;
