import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { arrayItemMove } from 'platform/common/utils/array.util';
import { useRefetchCustomReports } from 'platform/customReports/ReportManagement/useRefetchCustomReports';
import InsetBox from '../../common/components/InsetBox/InsetBox';
import { ActiveOrArchived } from '../../common/constants/status.constant';
import { usePromise } from '../../common/hooks/usePromise';
import { ADMIN_SEAT_ID } from '../../userManagement/mappers/user.mapper';
import SectionsCard from '../SectionsCard';
import { assignReportsToUser, fetchCustomReports } from '../customReport.service';
import { CustomReport } from '../customReport.types';

interface Props {
    status: ActiveOrArchived[];
    canEdit: boolean;
    onReportChange: (report: CustomReport) => Promise<void>;
    onStatusChange: (status: ActiveOrArchived[]) => void;
}

const CentralReports = ({ status, canEdit, onReportChange, onStatusChange }: Props) => {
    const [{ data: adminSeatReports, loading }, refetchReports] = usePromise<CustomReport[]>(
        [],
        () =>
            fetchCustomReports({
                status,
                pinned: true,
                seatId: ADMIN_SEAT_ID,
            }),
        [status]
    );

    const { refetch } = useRefetchCustomReports(refetchReports, ADMIN_SEAT_ID);

    const handleReportChange = async (report: CustomReport) => {
        await onReportChange(report);
        refetch();
    };

    const handleReportMove = async (oldReport: CustomReport, newReport: CustomReport) => {
        const oldIndex = adminSeatReports.findIndex((r) => r.id === oldReport.id);
        const newIndex = adminSeatReports.findIndex((r) => r.id === newReport.id);

        if (oldIndex === -1 || oldIndex === newIndex) {
            return;
        }

        await assignReportsToUser(
            { seatId: ADMIN_SEAT_ID },
            arrayItemMove(adminSeatReports, oldIndex, newIndex).map((r) => r.id!)
        );
        refetch();
    };

    return (
        <BodyContainer helpKey="central_reports">
            <ListFilters className="mb-3">
                <SelectWithAddon
                    name="Status"
                    value={status}
                    options={[
                        { value: 'ACTIVE', label: 'Active ' },
                        { value: 'ARCHIVED', label: 'Archived ' },
                    ]}
                    onChange={onStatusChange}
                    isMulti
                />
            </ListFilters>
            {loading && <OverlayLoader />}
            <InsetBox className="mb-3">
                <SectionsCard
                    seatId={ADMIN_SEAT_ID}
                    section="CENTRAL_ANALYTICS"
                    reports={adminSeatReports.filter((r) => r.section === 'CENTRAL_ANALYTICS')}
                    loading={loading}
                    canEdit={canEdit}
                    refetchReports={refetchReports}
                    onReportMove={handleReportMove}
                    onReportChange={handleReportChange}
                />
            </InsetBox>
        </BodyContainer>
    );
};

export default CentralReports;
