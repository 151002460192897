import { useRef } from 'react';
import { Editor as EditorComponent } from '@tinymce/tinymce-react';
import classNames from 'classnames';
import { getPredefinedPrompts } from 'platform/chat/chat.service';
import { ChatMessagePayload, PredefinedPrompt } from 'platform/chat/chat.types';
import { chatMessagePollAsyncResult } from 'platform/chat/chat.utis';
import { useFeature } from 'platform/common/hooks/useFeature';
import { useLoading } from 'platform/common/hooks/useLoading';
import { usePromise } from 'platform/common/hooks/usePromise';
import { sortByOrder } from 'platform/common/utils/chart.utils';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import styles from './TextEditor.scss';

const AI_SUGGESTIONS_SELECT_NAME = 'ai-suggestions-select';
const EDITOR_AGENT = 16;

// There's no easy way of adding a custom icon to the TinyMCE toolbar
// Only way is by passing icon svg string
// https://www.tiny.cloud/docs/api/tinymce.editor.ui/tinymce.editor.ui.registry/#addicon
const SVG_FA_WAND_ICON =
    '<svg class="Note-fa-wand" xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 576 512"><path fill="inherit" d="M234.7 42.7L197 56.8c-3 1.1-5 4-5 7.2s2 6.1 5 7.2l37.7 14.1L248.8 123c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7L315 71.2c3-1.1 5-4 5-7.2s-2-6.1-5-7.2L277.3 42.7 263.2 5c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5L234.7 42.7zM46.1 395.4c-18.7 18.7-18.7 49.1 0 67.9l34.6 34.6c18.7 18.7 49.1 18.7 67.9 0L529.9 116.5c18.7-18.7 18.7-49.1 0-67.9L495.3 14.1c-18.7-18.7-49.1-18.7-67.9 0L46.1 395.4zM484.6 82.6l-105 105-23.3-23.3 105-105 23.3 23.3zM7.5 117.2C3 118.9 0 123.2 0 128s3 9.1 7.5 10.8L64 160l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L128 160l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L128 96 106.8 39.5C105.1 35 100.8 32 96 32s-9.1 3-10.8 7.5L64 96 7.5 117.2zm352 256c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L416 416l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L480 416l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L480 352l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L416 352l-56.5 21.2z"/></svg>';

interface Props {
    value?: string;
    height?: number | string;
    minHeight?: number;
    className?: string;
    autoResize?: boolean;
    backgroundColor?: string;
    autoFocus?: true | undefined;
    onChange: (value: string) => void;
    onBlur?: () => void;
    onKeyDown?: (event: React.KeyboardEvent) => void;
}

const TextEditor = ({
    value,
    height = 150,
    minHeight,
    className,
    autoResize = false,
    autoFocus,
    backgroundColor = styles.editorBackgroundColor,
    onBlur,
    onChange,
    onKeyDown,
}: Props) => {
    const editorRef = useRef<EditorComponent | null>(null);
    const canUseDeeplyPrompts = useFeature('CHATBOT_PROMPTS_VIEW');
    const [aiSuggestionLoading, withAiSuggestionLoading] = useLoading();
    const [{ data: prompts, loading: promptsLoading }] = usePromise<PredefinedPrompt[]>(
        [],
        () => {
            if (!canUseDeeplyPrompts) return Promise.resolve([]);

            return Promise.resolve(
                getPredefinedPrompts({ status: ['ACTIVE'] })
                    .then((allPrompts) => allPrompts.filter((prompt) => prompt.agentId === EDITOR_AGENT))
                    .then(sortByOrder)
            );
        },
        []
    );

    const handlePromptAction = async (promptId: number | undefined) => {
        const currentVal = editorRef.current?.props.value;
        const selectedPrompt = prompts.find((prompt) => prompt.id === Number(promptId));

        if (aiSuggestionLoading || !currentVal || !selectedPrompt) return;

        const payload: ChatMessagePayload = {
            agentId: EDITOR_AGENT,
            messages: [
                {
                    role: 'user',
                    contentParts: [{ type: 'TEXT', content: selectedPrompt.prompt }],
                    prompt: { name: selectedPrompt.name, id: selectedPrompt.id, type: 'PREDEFINED' },
                },
                {
                    role: 'user',
                    contentParts: [{ type: 'TEXT', content: currentVal }],
                },
            ],
            placeholders: {},
        };
        const res = await withAiSuggestionLoading(() => chatMessagePollAsyncResult(payload, true));

        const newVal = `${res.message}\n\n-----original input-----\n\n${currentVal}`;
        onChange(newVal);
    };

    // tinymce does not provide good typescript models resulting in a lot of errors
    // thus using any here
    const editorSetup = (editor: any) => {
        if (canUseDeeplyPrompts) {
            editor.ui.registry.addIcon('fa-wand', SVG_FA_WAND_ICON);

            editor.ui.registry.addMenuButton(AI_SUGGESTIONS_SELECT_NAME, {
                icon: 'fa-wand',
                fetch: (callback: (arg0: { type: string; text: string; value: number }[]) => void) => {
                    const items = prompts.map((prompt) => ({
                        type: 'menuitem',
                        text: prompt.name,
                        value: prompt.id,
                        onAction: () => handlePromptAction(prompt.id),
                    }));

                    callback(items);
                },
            });
        }

        editor.on('keydown', onKeyDown);
    };

    // Not starting editor rendering until we have all prompts (setup function gets called only once)
    if (promptsLoading) {
        return <OverlayLoader />;
    }

    return (
        <div className={classNames('Note', className)}>
            {aiSuggestionLoading && <OverlayLoader />}
            <EditorComponent
                ref={editorRef}
                value={value}
                tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.5.1/tinymce.min.js"
                init={{
                    height: autoResize ? undefined : height,
                    toolbar_mode: 'wrap',
                    autoresize_bottom_margin: 0,
                    min_height: minHeight,
                    menubar: false,
                    branding: false,
                    statusbar: false,
                    fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
                    plugins: `advlist autolink lists link table ${autoResize ? 'autoresize' : ''}`,
                    toolbar: `undo redo | bold italic backcolor | link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | table | fontsizeselect | ${AI_SUGGESTIONS_SELECT_NAME}`,
                    content_style: `
                      body {
                        background: ${backgroundColor};
                        color: ${styles.editorTextColor};
                        font-size: ${styles.editorFontSize};
                        line-height: ${styles.editorLineHeight};
                      }
                    `,
                    auto_focus: autoFocus,
                    default_link_target: '_blank',
                    target_list: [{ title: 'New tab', value: '_blank' }],
                    setup: editorSetup,
                }}
                onEditorChange={onChange}
                onBlur={onBlur}
            />
        </div>
    );
};

export default TextEditor;
