import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { useCustomReports } from 'platform/analytics/hooks/useCustomReports';
import { activeAdvertiserActions, AdvertiserOption } from 'platform/common/ducks/activeAdvertiser.duck';
import { useLoading } from 'platform/common/hooks/useLoading';
import { useActiveTaskBoards } from 'platform/task/hooks/useActiveTaskBoards';
import { authSelectors } from '../ducks/auth.duck';

export const useAdvertiserContext = (activeAdvertiser: AdvertiserOption) => {
    const { reloadAnalyticsMetadata } = useAnalyticsMetadata();
    const { reloadCustomReports } = useCustomReports();
    const { reloadActiveTaskBoards } = useActiveTaskBoards();
    const [advertiserContextLoading, withLoading] = useLoading(true);
    const dispatch = useDispatch();
    const hasAnalyticsAuthority = useSelector(authSelectors.hasAnalyticsAuthority);

    const changeAdvertiserContext = (advertiser: AdvertiserOption) =>
        withLoading(() => {
            if (!hasAnalyticsAuthority()) {
                return Promise.resolve(undefined);
            }

            return Promise.all([
                reloadAnalyticsMetadata(advertiser.id),
                reloadCustomReports(advertiser.seatId),
                reloadActiveTaskBoards(advertiser.id),
            ]);
        });

    useEffect(() => {
        changeAdvertiserContext(activeAdvertiser);
    }, []);

    const changeAdvertiser = (selectedAdvertisers: AdvertiserOption[]) => {
        dispatch(activeAdvertiserActions.changeAdvertisers(selectedAdvertisers.map((a) => a.id)));
        changeAdvertiserContext(selectedAdvertisers[0]);
    };

    return {
        advertiserContextLoading,
        changeAdvertiser,
    };
};
