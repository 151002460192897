import { ComponentType, FC } from 'react';
import { LinkDisplay, NavigationParams } from 'platform/analytics/analytics.types';
import { TwoFactorAuthentication } from 'platform/common/constants/twoFactorAuthentication.constant';
import { makeOptions } from 'platform/common/utils/option.util';
import { AccessType } from 'platform/userManagement/constants/access.constant';
import { Authority } from './constants/authority.constant';

export type Feature =
    | 'CUSTOM_DASHBOARDS'
    | 'ROLE_ADVERTISERS_VIEW'
    | 'ROLE_ADVERTISERS_EDIT'
    | 'ROLE_CAMPAIGNS_VIEW'
    | 'ROLE_CAMPAIGNS_EDIT'
    | 'ROLE_CHANGELOG_VIEW'
    | 'ROLE_CREATIVES_VIEW'
    | 'ROLE_CREATIVES_EDIT'
    | 'ROLE_ADVERTISER_TAG_VIEW'
    | 'ROLE_ADVERTISER_TAG_EDIT'
    | 'ADVANCED_ADMIN_SETTINGS'
    | 'ADVANCED_CAMPAIGN_SETTINGS'
    | 'ADVANCED_CAMPAIGN_SETTINGS_EDIT'
    | 'DYNAMIC_FEEDS'
    | 'DYNAMIC_FEEDS_EDIT'
    | 'IMPORTER'
    | 'MEDIAPLAN_VIEW'
    | 'MEDIAPLAN_EDIT'
    | 'LOGBOOK_ADMIN'
    | 'LOGBOOK_VIEW'
    | 'LOGBOOK_BUDGET_SHIFTING_VIEW'
    | 'LOGBOOK_OPTIMIZATION_VIEW'
    | 'ALGORITHM_VIEW'
    | 'ALGORITHM_EDIT'
    | 'ALGORITHM_HISTORY'
    | 'ALGORITHM_PREVIEW'
    | 'OPTIMIZATION'
    | 'BUDGET'
    | 'ROLE_ANALYTICS_VIEW'
    | 'ROLE_CUSTOMER_JOURNEY_VIEW'
    | 'CUSTOM_ANALYTICS'
    | 'FINANCE_ANALYTICS'
    | 'MANAGEMENT_ANALYTICS'
    | 'ANALYTICS_INLINE_ACTIONS'
    | 'ANALYTICS_FILTERS_EDIT'
    | 'ANALYTICS_SETTINGS_EDIT'
    | 'ROLE_CDP_VIEW'
    | 'ROLE_CDP_EDIT'
    | 'ROLE_ADMINPANEL_VIEW'
    | 'ROLE_ADMINPANEL_EDIT'
    | 'ROLE_ADMIN_DATA_VIEW'
    | 'SPEND_DATA'
    | 'USER_MANAGEMENT'
    | 'TESTING_ANALYTICS'
    | 'MONITORING_ANALYTICS'
    | 'TASK_EDIT'
    | 'TASK_BOARD_EDIT'
    | 'CHATBOT_DEFAULT_VIEW'
    | 'CHATBOT_AGENTS_VIEW'
    | 'CHATBOT_BETA_TESTING'
    | 'CHATBOT_PROMPTS_VIEW'
    | 'CHATBOT_MANAGEMENT_VIEW'
    | 'CHATBOT_MANAGEMENT_EDIT'
    | 'ANALYTICS_PERSONAL_SECTION_EDIT'
    | 'PRODUCT_ACTION_PROBABILITY'
    | 'MESSAGE_CENTER_EDIT'
    | 'CUSTOM_FOLDER_EDIT'
    | 'HELP_VIEW'
    | 'HELP_EDIT';

type NavigationItemType = 'SEPARATOR' | 'REPORT_PIN' | 'USER_REPORT_PARENT' | 'PLACEHOLDER';

export type Section =
    | 'CUSTOM_DASHBOARD'
    | 'CUSTOM_ANALYTICS'
    | 'COLLABORATION'
    | 'CENTRAL_ANALYTICS'
    | 'MANAGEMENT'
    | 'MONITORING'
    | 'FINANCE'
    | 'BUDGET'
    | 'OPTIMIZATION'
    | 'CAMPAIGNS'
    | 'TESTING'
    | 'PERSONAL';

export const SECTION_LABELS: { [key in Section]: string } = Object.freeze({
    CUSTOM_DASHBOARD: 'Custom dashboard',
    CUSTOM_ANALYTICS: 'Custom analytics',
    COLLABORATION: 'Collaboration',
    CENTRAL_ANALYTICS: 'Central Analytics',
    MANAGEMENT: 'Management',
    MONITORING: 'Monitoring',
    FINANCE: 'Finance',
    BUDGET: 'Budget',
    OPTIMIZATION: 'Optimization',
    CAMPAIGNS: 'Campaigns',
    TESTING: 'Testing',
    PERSONAL: 'Personal',
});

export const SECTION_OPTIONS = makeOptions(SECTION_LABELS);

export interface Profile {
    id: number;
    name: string;
    login: string;
    phone: string;
    company: string;
    roleId: number;
    effectiveAdvertiserAccessLevel: AccessType;
    effectiveAdvertiserIds: number[];
    accessibleChannels?: string[];
    imageUrl?: string;
    demoModeEnabled: boolean;
    demoModeMultiplier?: number;
    adminUser: boolean; // user from Admin seat
    superuser: boolean; // user with Superuser role
    seatId: number;
}

export interface Account {
    id: number;
    seatId: number;
    phone: string;
    name: string;
    impersonate: boolean;
    canImpersonate: boolean;
    authorities: Authority[];
    features: Feature[];
    login: string;
    twoFactorAuthentication: TwoFactorAuthentication;
    needed2FA: boolean;
    platformLogoUrl?: string;
    platformAccessUrl?: string;
    companyLogoUrl?: string;
}

export interface NavigationItem {
    name: string;
    path?: string;
    systemReportKey?: string;
    redirectTo?: string;
    icon?: FC | string;
    redirectRequiresFeature?: Feature;
    requiresFeature?: Feature;
    children?: NavigationItem[];
    isActivePath?: (pathname: string, params: NavigationParams) => boolean;
    active?: boolean;
    pin?: string;
    type?: NavigationItemType;
    isExternal?: boolean;
    section?: Section;
    display?: LinkDisplay;
    lazy?: boolean;
    admin?: boolean;
    disabled?: boolean;
    lastAccessedOn?: string;
    isAdvertiserOwned?: boolean;
    isLast?: boolean;
    showRecentChildren?: boolean;
}

export type RoutingItem = NavigationItem & {
    component: ComponentType<any>;
};
