import { AxiosResponse } from 'axios';
import contentDisposition from 'content-disposition';
import FileSaver from 'file-saver';
import { precisionRound } from 'platform/common/utils/formatters.util';
import { toastError } from './toast.util';

export type FileInfo = {
    name: string;
    size: string;
    type: string;
    content: string | Blob;
};

export type ReadFileAs = 'binaryString' | 'text' | 'dataURL' | 'blob';

const readFile = (file: File, readFileAs: ReadFileAs): Promise<string | Blob> => {
    if (readFileAs === 'blob') {
        return Promise.resolve(file); // coz File extends Blob
    }
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            resolve(fileReader.result as string);
        };
        fileReader.onerror = (e: ProgressEvent<FileReader>) => {
            fileReader.abort();
            reject(e);
        };

        switch (readFileAs) {
            case 'binaryString':
                fileReader.readAsBinaryString(file);
                break;
            case 'text':
                fileReader.readAsText(file);
                break;
            case 'dataURL':
                fileReader.readAsDataURL(file);
                break;
            default:
                throw new Error(`Unknow readFileAs param '${readFileAs}'`);
        }
    });
};

const mapFileResultToInfo = ({ file, result }: { file: File; result: string | Blob }) => ({
    content: result,
    name: file.name,
    type: file.type,
    size: String(file.size),
});

export const readFileData = ({
    acceptedFiles,
    rejectedFiles,
    readFileAs = 'dataURL',
    callback,
}: {
    acceptedFiles: File[];
    rejectedFiles: File[];
    readFileAs: ReadFileAs;
    callback: (file: FileInfo) => void;
}) => {
    if (rejectedFiles && rejectedFiles.length) {
        toastError({
            message: `Could not upload file(s): ${rejectedFiles
                .map((f) => f.name)
                .join(', ')}. Files with type (${rejectedFiles
                .map((f) => f.type)
                .join(', ')}) cannot be uploaded`,
        });
        return;
    }

    const file = acceptedFiles[0];
    if (!file) return;

    readFile(file, readFileAs).then((result) => callback(mapFileResultToInfo({ file, result })));
};

export const readFileListData = ({
    acceptedFiles,
    rejectedFiles,
    readFileAs = 'dataURL',
    callback,
}: {
    acceptedFiles: File[];
    rejectedFiles: File[];
    readFileAs: ReadFileAs;
    callback: (files: FileInfo[]) => void;
}) => {
    if (rejectedFiles && rejectedFiles.length) {
        toastError({
            message: `Could not upload file(s): ${rejectedFiles
                .map((f) => f.name)
                .join(', ')}. Files with type (${rejectedFiles
                .map((f) => f.type)
                .join(', ')}) cannot be uploaded`,
        });
        return;
    }

    Promise.all(
        acceptedFiles.map((file) => readFile(file, readFileAs).then((result) => ({ file, result })))
    ).then((results) => callback(results.map(mapFileResultToInfo)));
};

export const getFileExtension = (filename: string) => {
    const dot = filename.lastIndexOf('.');
    return dot > 0 ? filename.substring(dot + 1).toLowerCase() : undefined;
};

export const stripFileExtension = (filename?: string) => {
    if (!filename) {
        return '';
    }
    const dot = filename.lastIndexOf('.');
    return dot > 0 ? filename.substring(0, dot) : filename;
};

export const formatFileSize = (size: number): string => {
    if (!size) return '';
    if (size < 1024) return `${size} B`;
    if (size < 1024 * 1024) return `${precisionRound(size / 1024, 0)} KB`;
    return `${precisionRound(size / 1024 / 1024, 1)} MB`;
};

export const FILE_UPLOAD_HEADERS = Object.freeze({
    headers: { 'Content-Type': 'multipart/form-data' },
});

export const fileUploadData = (content: Blob, name?: string): FormData => {
    const formData = new FormData();
    formData.append('file', content, name ? encodeURIComponent(name) : undefined);
    return formData;
};

export const saveDownloadedFile = ({ data, headers }: AxiosResponse<Blob>) => {
    const blob = new Blob([data], { type: headers['content-type'] });
    const name = contentDisposition.parse(headers['content-disposition']).parameters?.filename;
    FileSaver.saveAs(blob, decodeURIComponent(name));
};

export const blobToImage = (data: Blob): HTMLImageElement => {
    const img = document.createElement('img');
    img.src = URL.createObjectURL(data);
    img.onload = () => URL.revokeObjectURL(img.src);
    return img;
};
