import { useEffect } from 'react';
import { useParams } from 'react-router';
import { ActiveAdvertiserProps } from 'platform/app/AppContext';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useAdvertiserUsers } from 'platform/common/hooks/useAdvertiserUsers';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import KanbanPage from './Kanban/KanbanPage';
import {
    createTask,
    createTaskLane,
    deleteTask,
    deleteTaskAttachment,
    deleteTaskLane,
    downloadTaskAttachment,
    getTaskBoardChanges,
    getTaskBoardState,
    moveTask,
    moveTaskLane,
    updateTask,
    updateTaskBoardLabel,
    updateTaskLane,
    updateTaskNotificationPreferences,
    uploadTaskAttachment,
} from './task.service';

const TaskBoardContainer = ({ onAdvertisersChange }: ActiveAdvertiserProps) => {
    const params = useParams<{ id: string }>();
    const boardId = Number(params.id);
    const canEditBoard = useFeature('TASK_BOARD_EDIT');
    const [{ data: board }] = usePromise(undefined, () => getTaskBoardState(boardId), [boardId]);
    const userOptions = useAdvertiserUsers(board?.advertiserId);
    const { id: activeAdvertiserId } = useActiveAdvertiserComponent();
    const accessibleAdvertisers = useTypedSelector(activeAdvertiserSelectors.options);

    useEffect(() => {
        if (board && board.advertiserId !== activeAdvertiserId) {
            onAdvertisersChange(accessibleAdvertisers.filter((a) => a.id === board.advertiserId));
        }
    }, [board?.advertiserId]);

    if (!board || !userOptions?.length) {
        return <OverlayLoader />;
    }

    return (
        <KanbanPage
            initialState={board}
            userOptions={userOptions}
            canEditBoard={canEditBoard}
            onLaneAdd={(position, lane) => createTaskLane(boardId, { position }, lane)}
            onLaneUpdate={(id, lane) => updateTaskLane(boardId, id, lane)}
            onLaneMove={(id, position) => moveTaskLane(boardId, id, { position })}
            onLaneDelete={(id) => deleteTaskLane(boardId, id)}
            onTaskAdd={(location, task) => createTask(boardId, location, task)}
            onTaskUpdate={(id, taskData) => updateTask(boardId, id, taskData)}
            onTaskWatchChange={(id, watch) => updateTaskNotificationPreferences(boardId, id, { watch })}
            onTaskMove={(id, location) => moveTask(boardId, id, location)}
            onTaskDelete={(id) => deleteTask(boardId, id)}
            onLabelUpdate={(key, name) => updateTaskBoardLabel(boardId, key, { name })}
            onAttachmentUpload={(taskId, name, content) =>
                uploadTaskAttachment(boardId, taskId, name, content)
            }
            onAttachmentDelete={(taskId, attachmentId) => deleteTaskAttachment(boardId, taskId, attachmentId)}
            onAttachmentDownload={(taskId, attachmentId) =>
                downloadTaskAttachment(boardId, taskId, attachmentId)
            }
            onLoadNewChanges={(sinceVersion) => getTaskBoardChanges(boardId, { sinceVersion })}
        />
    );
};

export default TaskBoardContainer;
