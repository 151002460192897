import { useSelector } from 'react-redux';
import { Col, Label } from 'reactstrap';
import { map, uniq } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormInputButton from 'platform/common/components/FormInputButton/FormInputButton';
import FormRow from 'platform/common/components/FormRow/FormRow';
import InfoIconAlert from 'platform/common/components/InfoIconAlert/InfoIconAlert';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { makeOptions } from 'platform/common/utils/option.util';
import { email, required, requiredDatepickerFromValue } from 'platform/common/utils/validators.util';
import FormButtonArray from 'platform/formik/FormButtonArray/FormButtonArray';
import FormCodeEditor from 'platform/formik/FormCodeEditor/FormCodeEditor';
import FormDateRangePicker from 'platform/formik/FormDateRangePicker/FormDateRangePicker';
import FormDynamicOptionList from 'platform/formik/FormDynamicOptionList/FormDynamicOptionList';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { copyToClipboard } from '../ObserverList';
import {
    OBSERVER_REPORT_DESTINATION_NAMES,
    OBSERVER_REPORT_MESSAGE_CATEGORIES,
    OBSERVER_REPORT_MESSAGE_PRIORITIES,
    OBSERVER_REPORT_MESSAGE_TYPES,
    OBSERVER_REPORT_QUERY_SOURCE_NAMES,
    OBSERVER_REPORT_TYPE_NAMES,
} from '../observer.constants';
import { ObserverFormModel } from '../observer.types';
import ObserverAiFormFields from './ObserverAiFormFields';
import ObserverAlgorithmTemplateSelect from './ObserverAlgorithmTemplateSelect';
import ObserverCronBuilder from './ObserverCronBuilder';
import ObserverFormEmailFields from './ObserverFormEmailFields';
import ObserverFormFtpFields from './ObserverFormFtpFields';

export const getPlaceholders = (reportQuery?: string) =>
    (reportQuery && uniq(map(reportQuery.match(/\$(.+?)\$/g), (match) => match.replace(/\$/g, '')))) || [];

const getReportUrl = (report: ObserverFormModel, placeholders: string[] = []) => {
    const key = report.systemKey || report.id || 0;
    const params = placeholders
        .map((p) => (p === 'vendor_id' ? 'vendorToken' : p))
        .map((p) => `&${p}=`)
        .join('');
    return `${location.origin}/transfer/${key}?accessToken=${report.accessToken}${params}`;
};

const ObserverForm = ({
    onCancel,
    labels,
    canEdit,
    formikProps: { values, initialValues, submitForm, setFieldValue },
}: FormProps<ObserverFormModel>) => {
    const advertiserOptions = useSelector(activeAdvertiserSelectors.options);
    const queryPlaceholders = getPlaceholders(values.query);
    const reportUrl = getReportUrl(values, queryPlaceholders);

    return (
        <CardForm
            title={`${labels.prefix} ${OBSERVER_REPORT_TYPE_NAMES[initialValues.reportType]}`}
            subtitle={values.id ? `ID: ${values.id}` : null}
            submitLabel={labels.submit}
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info" subtitle="required">
                <FormRow label="Type">
                    <FormButtonArray
                        name="reportType"
                        buttons={makeOptions(OBSERVER_REPORT_TYPE_NAMES)}
                        validate={required}
                        onChange={(reportType) => {
                            setFieldValue('dailyAlertsThreshold', null);
                            setFieldValue('rowsThreshold', null);
                            setFieldValue(
                                'attachFile',
                                reportType !== 'ON_DEMAND' && values.destinations.includes('EMAIL')
                                    ? true
                                    : null
                            );
                        }}
                    />
                </FormRow>
                <FormRow label="Name">
                    <FormInput name="name" type="text" validate={required} />
                </FormRow>
                {values.reportType !== 'ON_DEMAND' && (
                    <>
                        <FormRow label="Delivery">
                            <FormSelect
                                name="destinations"
                                isMulti
                                options={makeOptions(OBSERVER_REPORT_DESTINATION_NAMES)}
                                onChange={(destinations) => {
                                    setFieldValue('attachFile', destinations.includes('EMAIL'));
                                    setFieldValue('groupMessages', destinations.includes('MESSAGE_CENTER'));
                                }}
                            />
                        </FormRow>
                    </>
                )}
                {values.reportType === 'ON_DEMAND' && (
                    <>
                        <FormRow label="System key">
                            <FormInput name="systemKey" type="text" />
                        </FormRow>
                        <FormRow label="Access token" childrenColClassName="d-flex align-items-center">
                            <Col md="11">
                                <FormInputButton
                                    name="accessToken"
                                    buttonTitle="Generate"
                                    onClick={() => setFieldValue('accessToken', uuidv4())}
                                    isButtonDisabled={!!values.accessToken}
                                    inputProps={{ disabled: true }}
                                    validate={required}
                                />
                            </Col>

                            {!!values.accessToken && (
                                <Col md="1">
                                    <Tooltip
                                        placement="bottom-start"
                                        className="d-flex justify-content-center"
                                        renderTooltip={() => ' Copy token to clipboard'}
                                    >
                                        <i
                                            tabIndex={0}
                                            role="button"
                                            onClick={() => copyToClipboard(values.accessToken!)}
                                            className="fa fa-copy"
                                        />
                                    </Tooltip>
                                </Col>
                            )}
                        </FormRow>
                        {values.id && values.accessToken && (
                            <FormRow label="Report URL" childrenColClassName="d-flex">
                                <Col md="11">{reportUrl}</Col>
                                <Col md="1">
                                    <Tooltip
                                        renderTooltip={() => 'Copy report URL to clipboard'}
                                        placement="bottom-start"
                                        className="d-flex justify-content-center"
                                    >
                                        <i
                                            tabIndex={0}
                                            role="button"
                                            onClick={() => copyToClipboard(reportUrl)}
                                            className="fa fa-copy"
                                        />
                                    </Tooltip>
                                </Col>
                            </FormRow>
                        )}
                        {!!queryPlaceholders.length && (
                            <FormRow label="Placeholders">
                                {queryPlaceholders.map((item) => `$${item}$ `)}
                            </FormRow>
                        )}
                    </>
                )}
            </ControlledCard>

            <ControlledCard title="Additional info" subtitle="optional">
                <FormRow label="Advertiser">
                    <FormSelect
                        name="advertiserId"
                        labelKey="name"
                        valueKey="id"
                        options={advertiserOptions}
                        isClearable
                        onChange={(advertiserId) =>
                            !advertiserId ? setFieldValue('showInAlgorithmManager', false) : null
                        }
                    />
                </FormRow>
                <ObserverAlgorithmTemplateSelect />
                <FormRow label="Description">
                    <FormInput name="description" type="textarea" validate={required} />
                </FormRow>
                <FormRow label="Show in algorithm manager">
                    <FormSwitch
                        name="showInAlgorithmManager"
                        disabled={!values.advertiserId || !!values.algorithmTemplateId}
                    />
                </FormRow>
            </ControlledCard>

            {values.destinations.some((d) => d === 'EMAIL' || d === 'MESSAGE_CENTER') && (
                <ControlledCard title="Message">
                    <ObserverFormEmailFields />
                </ControlledCard>
            )}

            {values.destinations.includes('EMAIL') && values.deeplyEnabled && (
                <ControlledCard title="DeeplyAI">
                    <ObserverAiFormFields />
                    <InfoIconAlert>
                        You can use the DeeplyAI result in your Message type by using the placeholder
                        “$deeply$
                    </InfoIconAlert>
                </ControlledCard>
            )}

            {values.destinations.includes('EMAIL') && (
                <ControlledCard title="Delivery: Email">
                    <FormRow label="Recipients">
                        <FormDynamicOptionList
                            name="staticRecipients"
                            buttonTitle="Add recipient"
                            validateInput={email}
                        />
                    </FormRow>
                    <FormRow label="Dynamic recipients">
                        <FormSwitch name="dynamicRecipients" />
                    </FormRow>
                </ControlledCard>
            )}

            {values.destinations.includes('MESSAGE_CENTER') && (
                <ControlledCard title="Delivery: AI Cockpit">
                    <FormRow label="Message category">
                        <FormSelect
                            name="messageCategory"
                            options={makeOptions(OBSERVER_REPORT_MESSAGE_CATEGORIES)}
                            validate={required}
                        />
                    </FormRow>
                    <FormRow label="Message type">
                        <FormSelect
                            name="messageType"
                            options={makeOptions(OBSERVER_REPORT_MESSAGE_TYPES)}
                            validate={required}
                        />
                    </FormRow>
                    <FormRow label="Message priority">
                        <FormSelect
                            name="messagePriority"
                            options={makeOptions(OBSERVER_REPORT_MESSAGE_PRIORITIES)}
                            validate={required}
                        />
                    </FormRow>
                    <FormRow label="Message expires in days">
                        <FormNumberInput name="messageExpiresIn" type="number" validate={required} />
                    </FormRow>
                    <FormRow label="Group messages">
                        <FormSwitch name="groupMessages" />
                    </FormRow>
                    <FormRow label="Remind about tasks">
                        <FormSwitch name="remindAboutMessages" />
                    </FormRow>
                    {values.remindAboutMessages && (
                        <FormRow label="Remind after hours">
                            <FormNumberInput name="remindAfterHours" type="number" validate={required} />
                        </FormRow>
                    )}
                </ControlledCard>
            )}

            {values.destinations.includes('FTP') && (
                <ControlledCard title="Delivery: FTP">
                    <ObserverFormFtpFields />
                </ControlledCard>
            )}

            {values.reportType === 'ALERT' && (
                <ControlledCard title="Conditions">
                    <FormRow label="Maximum alerts per day (inclusive)">
                        <FormNumberInput name="dailyAlertsThreshold" />
                    </FormRow>
                    <FormRow label="If output contains more lines than">
                        <FormNumberInput name="rowsThreshold" />
                    </FormRow>
                </ControlledCard>
            )}
            {values.reportType !== 'ON_DEMAND' && values.scheduling && (
                <ControlledCard title="Scheduling">
                    <FormRow label="Dates">
                        <FormDateRangePicker
                            optionalEndDate
                            name="dateRange"
                            validate={requiredDatepickerFromValue}
                        />
                    </FormRow>
                    <ObserverCronBuilder
                        canEdit={canEdit}
                        setFieldValue={setFieldValue}
                        schedulingValue={values.scheduling}
                    />
                </ControlledCard>
            )}
            <ControlledCard title="Report settings">
                <FormRow label="Source">
                    <FormSelect
                        name="source"
                        options={makeOptions(OBSERVER_REPORT_QUERY_SOURCE_NAMES)}
                        validate={required}
                        isClearable={false}
                    />
                </FormRow>
                <Label>Alert query</Label>
                <FormCodeEditor
                    name="query"
                    options={{
                        mode: 'text/x-sql',
                        lineWrapping: true,
                        lineNumbers: true,
                    }}
                    validate={required}
                />
                {values.reportType === 'ON_DEMAND' && (
                    <div className="text-muted">
                        Use $parameter$ to define dynamic parameters, e.g. $advertiser_id$.
                    </div>
                )}
                {!!queryPlaceholders.length && (
                    <FormRow label="Placeholders">{queryPlaceholders.map((item) => `$${item}$ `)}</FormRow>
                )}
            </ControlledCard>
        </CardForm>
    );
};

export default ObserverForm;
