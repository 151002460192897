import { SECTION_OPTIONS } from 'platform/app/app.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { STATUS_OPTIONS } from 'platform/common/constants/status.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { usePromise } from 'platform/common/hooks/usePromise';
import { required, urlHttps } from 'platform/common/utils/validators.util';
import { fetchCustomReports } from 'platform/customReports/customReport.service';
import FormDynamicOptionList from 'platform/formik/FormDynamicOptionList/FormDynamicOptionList';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import AssignedUsers from 'platform/userManagement/components/SeatForm/AssignedUsers';
import { SeatFormModel } from 'platform/userManagement/types/seat.type';
import { getSectionReportSortItems } from 'platform/userManagement/utils/users.util';
import CustomAccess, { validateCustomAccessLevel } from '../CustomAccess/CustomAccess';
import FormSortableSelect from '../SortableSelect/FormSortableSelect';

const SeatForm = ({
    onCancel,
    labels,
    canEdit,
    formikProps: {
        initialValues,
        submitForm,
        values: { seatReportIds },
    },
}: FormProps<SeatFormModel>) => {
    const [{ data: reportList, loading: reportOptionsLoading }] = usePromise(
        [],
        () => fetchCustomReports({ pinned: true }),
        []
    );

    return (
        <CardForm
            title={`${labels.prefix} Seat`}
            subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
            submitLabel={labels.submit}
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info">
                <FormRow label="Name">
                    <FormInput name="name" type="text" validate={required} />
                </FormRow>
                <FormRow label="Allowed domains">
                    <FormDynamicOptionList name="allowedDomains" buttonTitle="Add domain" />
                </FormRow>
                <FormRow label="Status">
                    <FormSelect
                        name="status"
                        options={STATUS_OPTIONS}
                        className="w-50"
                        isSearchable={false}
                        isClearable={false}
                        validate={required}
                    />
                </FormRow>
            </ControlledCard>
            <ControlledCard title="User access" subtitle="required">
                <FormRow label="Advertiser access">
                    <CustomAccess
                        name="advertiserAccess"
                        buttonAllText="User has access to all advertisers."
                        validate={validateCustomAccessLevel}
                    />
                </FormRow>
            </ControlledCard>
            <ControlledCard title="Reports" isExpanded={false}>
                {SECTION_OPTIONS.map((section) => (
                    <FormRow label={section.label}>
                        <FormSortableSelect
                            name="seatReportIds"
                            options={reportList
                                .filter((r) => r.section === section.value)
                                .map((r) => ({
                                    value: r.id!,
                                    label:
                                        r.status === 'ARCHIVED'
                                            ? `(Archived) ${r.name} (${r.id})`
                                            : `${r.name} (${r.id})`,
                                    hidden: r?.status === 'ARCHIVED',
                                }))}
                            isLoading={reportOptionsLoading}
                            items={getSectionReportSortItems(section.value, seatReportIds, reportList)}
                            dragKey={section.value}
                        />
                    </FormRow>
                ))}
            </ControlledCard>
            <ControlledCard title="Advanced platform settings" isExpanded={false}>
                <FormRow label="Platform name">
                    <FormInput name="platformName" type="text" validate={required} />
                </FormRow>
                <FormRow label="Company logo URL">
                    <FormInput name="companyLogoUrl" type="text" validate={urlHttps} />
                </FormRow>
                <FormRow label="Platform access URL">
                    <FormInput name="platformAccessUrl" type="text" validate={[required, urlHttps]} />
                </FormRow>
                <FormRow label="Platform logo URL" additionalText="Upload svg or png to deepmedia.de/logos">
                    <FormInput name="platformLogoUrl" type="text" validate={urlHttps} />
                </FormRow>
            </ControlledCard>
            <AssignedUsers users={initialValues.assignedUsers} />
        </CardForm>
    );
};

export default SeatForm;
