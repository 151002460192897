import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { get } from 'lodash-es';
import { SelectItem } from 'platform/common/common.types';
import DateRangePickerPopover from 'platform/common/components/DateRangePicker/DateRangePickerPopover';
import SelectOptionWithStatus from 'platform/common/components/Select/SelectOptionWithStatus';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { useLoading } from 'platform/common/hooks/useLoading';
import { usePromise } from 'platform/common/hooks/usePromise';
import useToggle from 'platform/common/hooks/useToggle';
import FormCreatableSelect from 'platform/formik/FormCreatableSelect/FormCreatableSelect';
import { createTimedOffer, fetchTimedOffers } from '../advertiserManagement/TimedOffers/timedOffer.service';
import { TimedOffer } from '../advertiserManagement/TimedOffers/timedOffer.types';
import { CampaignModel } from '../campaign/campaign.types';

const toOptions = (items: TimedOffer[] | undefined): SelectItem<number>[] =>
    (items ?? []).map(({ id, name, status }) => ({
        label: name,
        value: id!,
        status,
    }));

interface Props {
    name: string;
    className?: string;
    inputClassName?: string;
    firstAddon?: string;
}

const FormTimeOffersSelect = ({ name, inputClassName, className, firstAddon }: Props) => {
    const { setFieldValue, values } = useFormikContext<CampaignModel>();
    const ref = useRef<HTMLDivElement>(null);
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const [newTimeOfferName, setNewTimeOfferName] = useState<string>('');
    const [popoverOpen, togglePopover] = useToggle(false);
    const [{ data: fetchedOptions, loading: fetchLabelsLoading }, refetchTimeOffers] = usePromise(
        [],
        async () => fetchTimedOffers({ advertiserId }).then(toOptions),
        [advertiserId]
    );
    const [createLabelLoading, withLoading] = useLoading();

    const handleDatePickerChange = async (dateFrom: string, dateTo: string): Promise<void> => {
        togglePopover();
        const { id } = await withLoading(() =>
            createTimedOffer({
                advertiserId,
                name: newTimeOfferName,
                dateFrom,
                dateTo,
                status: 'ACTIVE',
            })
        );
        await refetchTimeOffers();
        setFieldValue(name, [...get(values, name), id]);
    };

    return (
        <div className="flex-grow-1" ref={ref}>
            <FormCreatableSelect
                firstAddon={firstAddon}
                isMulti
                name={name}
                menuStyle={{ minWidth: 400 }}
                options={fetchedOptions}
                isLoading={fetchLabelsLoading || createLabelLoading}
                onNewOption={(options) => {
                    setNewTimeOfferName(options.pop()); // mutating the options array as we do not need the new option name in the form values
                    setFieldValue(name, options);
                    togglePopover();
                }}
                formatOptionLabel={SelectOptionWithStatus}
                formatCreateLabel={(value) => (
                    <span>
                        <b>Create label</b>
                        {` "${value}"`}
                    </span>
                )}
                placeholder="Select or enter..."
                className={className}
                inputClassName={inputClassName}
            />
            {ref.current && popoverOpen && (
                <DateRangePickerPopover
                    target={ref.current}
                    placement={undefined}
                    toggle={togglePopover}
                    onChange={handleDatePickerChange}
                    onCancel={() => {
                        togglePopover();
                        setNewTimeOfferName('');
                    }}
                />
            )}
        </div>
    );
};

export default FormTimeOffersSelect;
