import { AxiosResponse } from 'axios';
import FileLink from 'platform/common/components/FileLink/FileLink';
import RoundIconButton from 'platform/common/components/RoundIconButton/RoundIconButton';
import { TaskAttachment } from '../task.types';

interface Props {
    attachment: TaskAttachment;
    onDownload: (attachmentId: number) => Promise<AxiosResponse<Blob>>;
    onDelete: (attachmentId: number) => Promise<void>;
}

const KanbanAttachment = ({ attachment, onDelete, onDownload }: Props) => (
    <div className="Kanban-attachment">
        <RoundIconButton
            className="Kanban-attachment-remove"
            icon="fa fa-xmark"
            onClick={() => onDelete(attachment.id)}
        />
        <FileLink
            filename={attachment.name}
            className="flex-column p-2 w-100 flex-grow-1"
            iconClassName="Kanban-attachment-image-container"
            labelClassName="fw-bold mt-1"
            onDownload={() => onDownload(attachment.id)}
        />
    </div>
);

export default KanbanAttachment;
