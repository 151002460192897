import { useState } from 'react';
import { Input } from 'reactstrap';

interface Props {
    value?: number;
    minValue?: number;
    maxValue?: number;
    step?: number;
    onChange: (value?: number) => void;
}

const ReportInteractionSlider = ({ value, minValue, maxValue, step, onChange }: Props) => {
    const [innerValue, setInnerValue] = useState(value);

    return (
        <div className="d-flex align-items-center">
            <div className="me-1 text-primary">{minValue}</div>
            <div className="d-flex flex-column flex-fill align-items-center">
                <Input
                    style={{ marginTop: '10px' }}
                    type="range"
                    value={innerValue}
                    step={step}
                    min={minValue}
                    max={maxValue}
                    onChange={(e) => setInnerValue(Number(e.target.value))}
                    onMouseUp={() => onChange(innerValue)}
                />
                <div className="text-primary fw-bold font-sm" style={{ marginTop: '-10px' }}>
                    {innerValue}
                </div>
            </div>
            <div className="ms-1 text-primary">{maxValue}</div>
        </div>
    );
};

export default ReportInteractionSlider;
