import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Formik } from 'formik';
import FormRow from 'platform/common/components/FormRow/FormRow';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import SelectOptionWithIcon from 'platform/common/components/Select/SelectOptionWithIcon';
import Spinner from 'platform/common/components/Spinner/Spinner';
import BorderlessUserSelect from 'platform/common/components/UserSelect/BorderlessUserSelect';
import { useLoading } from 'platform/common/hooks/useLoading';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import Switch from 'platform/formik/Switch/Switch';
import { User } from 'platform/userManagement/types/user.type';
import { TASK_LANE_TYPE_OPTIONS, TaskLaneData } from '../task.types';
import KanbanColumnTimeoutSettings from './KanbanColumnTimeoutSettings';
import './KanbanColumnModal.scss';

type Props = {
    lane: TaskLaneData;
    userOptions: User[];
    onSubmit: (data: TaskLaneData) => Promise<void>;
    onClose: () => void;
};

const KanbanColumnModal = ({ lane, userOptions, onSubmit, onClose }: Props) => {
    const [saving, withSaving] = useLoading();

    const handleSave = async (data: TaskLaneData) => {
        await withSaving(() => onSubmit(data));
        onClose();
    };

    return (
        <Modal isOpen toggle={onClose} className="Kanban-column-modal">
            <Formik initialValues={lane} onSubmit={handleSave}>
                {({ values, setFieldValue, submitForm }) => (
                    <>
                        <ModalHeader onClose={onClose}>
                            <div className="d-flex align-items-center gap-2">
                                <FormInput name="name" placeholder="Column name" validate={required} />
                                {saving && <Spinner size="sm" />}
                            </div>
                        </ModalHeader>
                        <ModalBody className="p-4">
                            <FormRow label="Treat tasks as">
                                <FormSelect
                                    containerClassName="w-100"
                                    name="type"
                                    options={TASK_LANE_TYPE_OPTIONS}
                                    formatOptionLabel={SelectOptionWithIcon}
                                    isSearchable={false}
                                    isClearable={false}
                                />
                            </FormRow>
                            <FormRow label="Column owners">
                                <BorderlessUserSelect
                                    className="w-100"
                                    userIds={values.ownerIds ?? []}
                                    options={userOptions}
                                    onChange={(userIds) => setFieldValue('ownerIds', userIds)}
                                />
                            </FormRow>
                            {!!values.ownerIds?.length && (
                                <div className="d-flex align-items-center mt-3">
                                    <FormSwitch name="ownerMustBeAssignee" />
                                    <span className="ms-2">Owner must be assigned to tasks</span>
                                </div>
                            )}
                            <div className="d-flex align-items-center mt-3">
                                <Switch
                                    value={!!values.timeout}
                                    onChange={(e) =>
                                        setFieldValue('timeout', e.target.checked ? ({} as any) : undefined)
                                    }
                                />
                                <span className="ms-2 me-1">Alert if tasks get stuck here</span>
                            </div>
                            {!!values.timeout && (
                                <div className="ms-5 mt-2">
                                    <KanbanColumnTimeoutSettings />
                                </div>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={submitForm}>
                                Save
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default KanbanColumnModal;
