import { ComponentType, useEffect, useState } from 'react';
import { OptionProps } from 'react-select';
import classNames from 'classnames';
import { sortBy } from 'lodash-es';
import Select from 'platform/common/components/Select/Select';
import SelectSortableMultiValue from 'platform/common/components/Select/SelectSortableMultiValue';
import { LabelNames, TaskLabelOption } from '../task.types';
import KanbanLabelRemove from './KanbanLabelRemove';

type Props = {
    placeholder?: string;
    labelKeys: string[];
    labelNames: LabelNames;
    className?: string;
    Option?: ComponentType<OptionProps<TaskLabelOption, boolean>>;
    menuIsOpen?: boolean;
    onChange: (labelKeys: string[]) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    onRename?: (key: string, name: string) => void;
    autoFocus?: boolean;
    onKeyDown?: (e: React.KeyboardEvent) => void;
};

type LabelOption = { value: string; label: string };

const LETTERS = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];

const DEFAULT_OPTIONS: LabelOption[] = [...LETTERS, ...LETTERS.map((l) => `${l}1`)].map((key) => ({
    value: key,
    label: `Label ${key}`,
}));

export const buildLabelOptions = (labelNames: LabelNames): LabelOption[] => {
    const boardLabelKeys = Object.keys(labelNames);
    const boardLabelOptions = boardLabelKeys.map((key) => ({ value: key, label: labelNames[key] }));
    return [
        ...sortBy(boardLabelOptions, (o) => o.label),
        ...DEFAULT_OPTIONS.filter((o) => !boardLabelKeys.includes(o.value)),
    ];
};

const KanbanLabelSelect = ({
    placeholder,
    labelKeys,
    labelNames,
    className,
    Option,
    menuIsOpen,
    onChange,
    onBlur,
    onFocus,
    onRename,
    autoFocus,
    onKeyDown,
}: Props) => {
    const [options, setOptions] = useState<LabelOption[]>([]);
    useEffect(() => setOptions(buildLabelOptions(labelNames)), [labelNames]);

    return (
        <Select<TaskLabelOption>
            className={classNames('Kanban-label-select', className)}
            value={labelKeys}
            options={options}
            formatOptionLabel={({ value, label }: TaskLabelOption) => (
                <div className={`Task-label Task-label-color-${value}`}>{label}</div>
            )}
            components={{
                MultiValue: SelectSortableMultiValue,
                MultiValueRemove: KanbanLabelRemove,
                IndicatorSeparator: null,
                DropdownIndicator: null,
                ...(Option && { Option }),
            }}
            placeholder={placeholder}
            isMulti
            hideSelectedOptions={!onRename}
            closeMenuOnSelect={false}
            menuIsOpen={menuIsOpen}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            autoFocus={autoFocus}
            onKeyDown={onKeyDown}
        />
    );
};

export default KanbanLabelSelect;
