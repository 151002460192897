import { useQuery } from '@apollo/client';
import { assertIsDefined } from 'platform/common/common.assert';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { IntegrationStatus } from 'platform/common/constants/externalIntegration.constant';
import VendorIconList from 'platform/segments/components/SegmentSyncPanel/VendorIconList';
import segmentSyncPanelQuery from 'platform/segments/queries/segmentSyncPanelQuery.graphql';
import { shapeVendors } from 'platform/segments/utils/segmentSyncPanel.util';
import './SegmentSyncPanel.scss';

type Props = {
    segmentId: number;
};

type GraphqlResponse = {
    segment: {
        id: number;
        integrations: {
            [key: string]: {
                status: IntegrationStatus;
            };
        };
        advertiser: {
            activeAdvertiserTag?: {
                thirdPartyTrackingRules: {
                    codeSnippets: {
                        vendorId?: number;
                        dataSharingTemplateId?: number;
                    }[];
                }[];
            };
        };
    };
    vendors: { id: number; iconUrl?: string; name: string; useInCDP: boolean }[];
};

const SegmentSyncPanel = ({ segmentId }: Props) => {
    const { data, loading } = useQuery<GraphqlResponse>(segmentSyncPanelQuery, {
        variables: {
            id: segmentId,
        },
        fetchPolicy: 'network-only',
    });

    return (
        <div className="SegmentSyncPanel p-3">
            <div className="mb-3 fw-bold">Segment sharing</div>
            {(() => {
                if (loading) {
                    return <Placeholder height="80px" />;
                }

                assertIsDefined(data, `segment sharing data for segment ${segmentId}`);

                const { segment, vendors } = data;
                const { activeAdvertiserTag } = segment.advertiser;
                const vendorList = shapeVendors(vendors, segment, activeAdvertiserTag);
                const rowCount = Math.ceil(vendorList.length / 4) || 1;

                return (
                    <div className="SegmentSyncTable" style={{ height: 40 * rowCount }}>
                        <VendorIconList vendorList={vendorList} />
                    </div>
                );
            })()}
        </div>
    );
};

export default SegmentSyncPanel;
