import Select from 'platform/common/components/Select/Select';
import styles from 'platform/common/constants/styles.constant.scss';
import { useChatLanguageContext } from '../ChatProviders/ChatLanguageProvider';
import { CHAT_LANGUAGE_OPTIONS } from '../chat.constants';

const ChatLanguageSelect = () => {
    const { isLanguageMenuOpen, language, handleLanguageSelect } = useChatLanguageContext();

    return (
        <div>
            <Select
                menuIsOpen={isLanguageMenuOpen}
                isSearchable={false}
                menuPosition="fixed"
                components={{ DropdownIndicator: null, IndicatorSeparator: null }}
                isClearable={false}
                value={language}
                onChange={handleLanguageSelect}
                options={CHAT_LANGUAGE_OPTIONS}
                selectStyle={{
                    minWidth: '40px',
                    width: '43px',
                    border: 0,
                    fontSize: '12px',
                }}
                menuStyle={{
                    width: '50px',
                    fontSize: '12px',
                }}
                styles={{
                    singleValue: (provided) => ({
                        ...provided,
                        color: styles.lightSlateGray,
                    }),
                    menuPortal: ({ left, top, ...provided }) => ({
                        ...provided,
                    }),
                }}
            />
        </div>
    );
};

export default ChatLanguageSelect;
