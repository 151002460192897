import { Alert } from 'reactstrap';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormCategorySelectTree from 'platform/common/components/FormCategorySelectTree/FormCategorySelectTree';
import FormRow from 'platform/common/components/FormRow/FormRow';
import Separator from 'platform/common/components/Separator/Separator';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { usePromise } from 'platform/common/hooks/usePromise';
import { nonNegativeNumber, positiveWholeNumber, required } from 'platform/common/utils/validators.util';
import FormButtonArray from 'platform/formik/FormButtonArray/FormButtonArray';
import FormDynamicOptionList from 'platform/formik/FormDynamicOptionList/FormDynamicOptionList';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import Convertible from 'platform/segments/components/SegmentForm/Convertible';
import Integrations from 'platform/segments/components/SegmentForm/Integrations';
import { SegmentFormModel } from 'platform/segments/components/SegmentForm/SegmentForm.types';
import TriggerSegment from 'platform/segments/components/SegmentForm/TriggerSegment';
import { ACCESS_LABELS, AccessType } from 'platform/segments/constants/segmentAccess.constant';
import {
    SEGMENT_TYPE_OPTIONS,
    SEGMENT_TYPE_OPTIONS_FOR_NEW_SEGMENT,
    SegmentType,
} from 'platform/segments/constants/segmentTypes.constant';
import {
    getClassificationCodesBySegmentType,
    SEGMENT_ALERTING_RULE_LABELS,
} from 'platform/segments/mappers/segment.mapper';
import { StandardEvent } from 'platform/segments/segments.types';
import { getStandardEvents } from 'platform/segments/services/segments.service';

const availabilityButtons = Object.keys(ACCESS_LABELS).map((key: AccessType) => ({
    value: key,
    label: ACCESS_LABELS[key],
    disabled: key === 'CUSTOM',
}));

const loadStandardEvents = async (
    { name, advertiserTagVarName, advertiser }: SegmentFormModel,
    isEdit: boolean
): Promise<StandardEvent[]> => {
    if (isEdit) {
        return [{ advertiserTagVarName, name }];
    }
    return getStandardEvents(advertiser.advertiserTagId);
};

const SegmentForm = ({
    onCancel,
    labels,
    canEdit,
    formikProps: { initialValues, setFieldValue, submitForm, values },
    isEdit,
}: FormProps<SegmentFormModel>) => {
    const { type, convertible, integrations, id, advertiser, eventType, isAlertingOn } = values;

    const [{ data: standardEvents, loading }] = usePromise(
        [],
        () => loadStandardEvents(initialValues, isEdit),
        []
    );

    const changeClassification = (segmentType: SegmentType, advertiserTagVarName: string) =>
        setFieldValue(
            'classificationCodes',
            getClassificationCodesBySegmentType(segmentType, advertiserTagVarName)
        );

    return (
        <CardForm
            title={`${labels.prefix} Segment`}
            subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
            submitLabel={labels.submit}
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info" subtitle="required">
                <FormRow label="Segment type">
                    <FormSelect
                        name="type"
                        options={isEdit ? SEGMENT_TYPE_OPTIONS : SEGMENT_TYPE_OPTIONS_FOR_NEW_SEGMENT}
                        onChange={(value: SegmentType) => changeClassification(value, eventType)}
                        validate={required}
                        disabled={isEdit}
                        isSearchable={false}
                        isClearable={false}
                    />
                    {type === 'STANDARD_EVENT' && !loading && !standardEvents.length && (
                        <Alert color="danger" className="mt-2 mb-0">
                            All Standard events are already created. Please select different{' '}
                            {`"Segment type"`} to continue.
                        </Alert>
                    )}
                </FormRow>
                {type === 'STANDARD_EVENT' && (
                    <FormRow label="Event type">
                        <FormSelect
                            name="eventType"
                            valueKey="advertiserTagVarName"
                            labelKey="name"
                            options={standardEvents}
                            onChange={(value: string) => changeClassification(type, value)}
                            validate={required}
                            disabled={isEdit}
                            isSearchable={false}
                            isClearable={false}
                        />
                    </FormRow>
                )}
                {type !== 'STANDARD_EVENT' && (
                    <FormRow label="Name">
                        <FormInput
                            name="name"
                            type="text"
                            validate={required}
                            disabled={isEdit && !['CUSTOM_EVENT', 'URL_VISIT', 'TRIGGER'].includes(type)}
                        />
                    </FormRow>
                )}
                {type === 'TRIGGER' && <TriggerSegment />}
                {type === 'CUSTOM_EVENT' && (
                    <FormRow label="Variable">
                        <FormInput name="advertiserTagVarName" type="text" validate={required} />
                    </FormRow>
                )}
                {['URL_VISIT', 'STANDARD_EVENT', 'CUSTOM_EVENT'].includes(type) && (
                    <FormRow label="Urls">
                        <FormDynamicOptionList
                            name="urls"
                            buttonTitle="Add URL"
                            validate={type === 'URL_VISIT' ? required : undefined}
                        />
                    </FormRow>
                )}
                <FormRow label="Description">
                    <FormInput name="description" type="textarea" />
                </FormRow>
                {isEdit && (
                    <FormRow label="AdvertiserTag ID">
                        <div className="p-2">{initialValues.advertiserTagId}</div>
                    </FormRow>
                )}
                <FormRow label="Availability">
                    <FormButtonArray name="availability" buttons={availabilityButtons} />
                </FormRow>
                <Separator label="Optional" />
                <FormRow label="Categories">
                    <FormCategorySelectTree
                        name="classificationCodes"
                        roots={['SS', 'SSO', 'TS']}
                        validate={required}
                    />
                </FormRow>
                <FormRow label="Track as micro action/action">
                    <FormSwitch name="convertible" />
                </FormRow>
                {convertible && <Convertible advertiser={advertiser} />}
                <FormRow label="Alerting" rowClassName="mb-1">
                    <FormSwitch name="isAlertingOn" />
                </FormRow>
                {isAlertingOn && (
                    <>
                        <FormRow>
                            <div>{SEGMENT_ALERTING_RULE_LABELS.dailyVisitsAlertingThreshold}</div>
                            <FormNumberInput
                                name="dailyVisitsAlertingThreshold"
                                label="%"
                                containerClassName="w-100"
                                validate={positiveWholeNumber}
                            />
                        </FormRow>
                        <FormRow>
                            <div>{SEGMENT_ALERTING_RULE_LABELS.missingOrderIdsAlertingThreshold}</div>
                            <FormNumberInput
                                name="missingOrderIdsAlertingThreshold"
                                label="%"
                                containerClassName="w-100"
                                validate={nonNegativeNumber}
                            />
                        </FormRow>
                    </>
                )}
            </ControlledCard>
            {id && (
                <Integrations
                    fieldName="integrations"
                    segmentId={id}
                    segmentType={type}
                    integrations={integrations}
                    onStatusResync={setFieldValue}
                />
            )}
        </CardForm>
    );
};

export default SegmentForm;
