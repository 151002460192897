import { Fragment, useLayoutEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { debounce, first, last } from 'lodash-es';
import tripleChevrons from 'platform/assets/icons/journey/chevrons3.svg';
import { fmtDate } from 'platform/common/utils/date.util';
import {
    DurationInterval,
    getDurationInterval,
    getDurationText,
} from '../CustomerJourneyCM360/customerJourney.utils';
import { ItemGroup } from './CustomerJourney';
import { TypeIcon } from './JourneyRow';
import './JourneySummaryBar.scss';

const JOURNEY_EVENT_WIDTH = 90;
const SPACE_BETWEEN_EVENTS_MIN_WIDTH = 50;
const TOGGLEABLE_COLUMN_WIDTH = 80;
const DATE_FORMAT = 'MM.DD';

const DurationInfo = ({
    durationInterval,
    durationText,
}: {
    durationText: string;
    durationInterval: DurationInterval;
}) => {
    if (durationInterval === 'DAYS') {
        return <div>{durationText}</div>;
    }

    if (durationInterval === 'MINUTES' || durationInterval === 'HOURS') {
        return (
            <div>
                <i className="far fa-clock me-1" />
                <span>{durationText}</span>
            </div>
        );
    }

    return (
        <div
            style={{
                transform: 'rotate(270deg)',
                height: '19px',
                marginLeft: '-20px',
            }}
        >
            <img src={tripleChevrons} alt={durationText} />
        </div>
    );
};

const ToggleableColumn = ({
    onClick,
    buttonText,
    durationText,
    durationInterval,
}: {
    onClick: () => void;
    buttonText: string;
    durationText: string;
    durationInterval: DurationInterval;
}) => (
    <div className="d-flex flex-column align-items-center" style={{ width: `${TOGGLEABLE_COLUMN_WIDTH}px` }}>
        <div className="mt-2" style={{ height: '45px' }} />
        <div className="my-2" style={{ height: '19px' }} />
        <div style={{ height: '11px' }} />
        <DurationInfo durationText={durationText} durationInterval={durationInterval} />
        <div style={{ height: '10px' }} />
        <div className="my-2 d-flex align-items-center" style={{ width: '100%', height: '10px' }}>
            <div
                style={{
                    width: `${(TOGGLEABLE_COLUMN_WIDTH - 50 - 2 * 5) / 2}px`,
                    height: '2px',
                    backgroundColor: '#1B90D7',
                }}
            />
            <Button
                color="primary"
                className="d-flex align-items-center justify-content-center font-xs p-0"
                style={{
                    width: '50px',
                    height: '20px',
                    margin: '0 5px',
                    borderRadius: '8px',
                }}
                onClick={onClick}
            >
                {buttonText}
            </Button>
            <div
                style={{
                    width: `${(TOGGLEABLE_COLUMN_WIDTH - 50 - 2 * 5) / 2}px`,
                    height: '2px',
                    backgroundColor: '#1B90D7',
                }}
            />
        </div>
        <div style={{ height: '19px' }} />
    </div>
);

const ExpandableGroup = ({
    groups,
    startGroups,
    endGroups,
}: {
    groups: ItemGroup[];
    startGroups: ItemGroup[];
    endGroups: ItemGroup[];
}) => {
    const [expanded, setExpanded] = useState(false);

    const lastStartGroup = last(startGroups);
    const firstEndGroup = first(endGroups);
    if (!lastStartGroup || !firstEndGroup) {
        throw new Error('Invalid Situation. lastStartGroup or firstEndGroup missing');
    }

    if (expanded) {
        const firstGroup = first(groups);
        const lastGroup = last(groups);

        if (!firstGroup || !lastGroup) {
            throw new Error('Invalid Situation. Groups should never be empty');
        }

        return (
            <div className="d-flex">
                <ToggleableColumn
                    durationText={getDurationText(lastStartGroup.endTime, firstGroup.startTime)}
                    durationInterval={getDurationInterval(lastStartGroup.endTime, firstGroup.startTime)}
                    buttonText="> <"
                    onClick={() => {
                        setExpanded(false);
                    }}
                />
                {groups.map((group, index) => {
                    const nextGroup = groups[index + 1];

                    return (
                        <Fragment key={group.startTime}>
                            <JourneyEvent
                                group={group}
                                groupIndex={startGroups.length + index}
                                leftDashVisible
                                rightDashVisible
                                circleEmpty
                            />
                            {nextGroup && <JourneyEventSeparator group={group} nextGroup={nextGroup} />}
                        </Fragment>
                    );
                })}
                <ToggleableColumn
                    durationText={getDurationText(lastGroup.endTime, firstEndGroup.startTime)}
                    durationInterval={getDurationInterval(lastGroup.endTime, firstEndGroup.startTime)}
                    buttonText="> <"
                    onClick={() => {
                        setExpanded(false);
                    }}
                />
            </div>
        );
    }

    return (
        <ToggleableColumn
            durationText={getDurationText(lastStartGroup.endTime, firstEndGroup.startTime)}
            durationInterval={getDurationInterval(lastStartGroup.endTime, firstEndGroup.startTime)}
            buttonText={`< ${groups.length} >`}
            onClick={() => {
                setExpanded(true);
            }}
        />
    );
};

const scrollToJourneyListGroup = (groupIndex: number) => {
    const elem = document.getElementById(`JourneyListGroup-${groupIndex}`);
    if (elem) {
        elem.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
};

const JourneyEventSeparator = ({ group, nextGroup }: { group: ItemGroup; nextGroup: ItemGroup }) => {
    const durationInterval = getDurationInterval(group.endTime, nextGroup.startTime);
    const durationText = getDurationText(group.endTime, nextGroup.startTime);

    return (
        <div
            className="d-flex flex-column align-items-center flex-grow-1"
            style={{ minWidth: `${SPACE_BETWEEN_EVENTS_MIN_WIDTH}px` }}
        >
            <div className="mt-2" style={{ height: '45px' }} />
            <div className="my-2" style={{ height: '19px' }} />
            <div style={{ height: '11px' }} />
            <DurationInfo durationText={durationText} durationInterval={durationInterval} />
            <div style={{ height: '10px' }} />
            <div className="my-2 d-flex align-items-center" style={{ width: '100%', height: '10px' }}>
                <div
                    style={{
                        width: '100%',
                        height: '2px',
                        backgroundImage: `linear-gradient(to right, #1B90D7 ${
                            durationInterval === 'DAYS' ? '50%' : '100%'
                        }, #FFF 0%)`,
                        backgroundPosition: 'bottom',
                        backgroundSize: '10px 2px',
                        backgroundRepeat: 'repeat-x',
                    }}
                />
            </div>
            <div style={{ height: '19px' }} />
        </div>
    );
};

const JourneyEvent = ({
    group,
    groupIndex,
    leftDashVisible,
    rightDashVisible,
    circleEmpty,
}: {
    group: ItemGroup;
    groupIndex: number;
    leftDashVisible: boolean;
    rightDashVisible: boolean;
    circleEmpty: boolean;
}) => (
    <div
        className="d-flex flex-column align-items-center JourneySummaryBar-JourneyEvent"
        style={{ width: `${JOURNEY_EVENT_WIDTH}px` }}
        role="button"
        tabIndex={0}
        onClick={() => {
            scrollToJourneyListGroup(groupIndex);
        }}
    >
        <div className="mt-2" style={{ height: '45px' }}>
            <TypeIcon row={group.rows[0]} />
        </div>

        <div className="my-2" style={{ height: '19px' }}>
            {group.eventType} {group.rows.length > 1 ? `x${group.rows.length}` : ''}
        </div>
        <div className="border-left" style={{ height: '40px' }} />
        <div className="my-2 d-flex align-items-center" style={{ height: '10px' }}>
            <div
                style={{
                    width: `${(JOURNEY_EVENT_WIDTH - 10 - 2 * 5) / 2}px`,
                    height: '2px',
                    backgroundColor: leftDashVisible ? '#1B90D7' : 'transparent',
                }}
            />
            <div
                style={{
                    width: '10px',
                    height: '10px',
                    margin: '0 5px',
                    backgroundColor: circleEmpty ? '#FFF' : '#1B90D7',
                    border: '2px solid #1B90D7',
                    borderRadius: '50%',
                }}
            />
            <div
                style={{
                    width: `${(JOURNEY_EVENT_WIDTH - 10 - 2 * 5) / 2}px`,
                    height: '2px',
                    backgroundColor: rightDashVisible ? '#1B90D7' : 'transparent',
                }}
            />
        </div>
        <div>
            {group.startTime === group.endTime
                ? fmtDate(DATE_FORMAT)(group.startTime)
                : `${fmtDate(DATE_FORMAT)(group.startTime)} - ${fmtDate(DATE_FORMAT)(group.endTime)}`}
        </div>
    </div>
);

const getFittingItemsCount = (componentWidth: number, totalCount: number) => {
    const totalWidth = totalCount * JOURNEY_EVENT_WIDTH + (totalCount - 1) * SPACE_BETWEEN_EVENTS_MIN_WIDTH;

    if (totalWidth <= componentWidth) {
        return totalCount;
    }

    return Math.floor(
        (componentWidth - TOGGLEABLE_COLUMN_WIDTH + 2 * SPACE_BETWEEN_EVENTS_MIN_WIDTH) /
            (JOURNEY_EVENT_WIDTH + SPACE_BETWEEN_EVENTS_MIN_WIDTH)
    );
};

const JourneySummaryBar = ({ groups }: { groups: ItemGroup[] }) => {
    const [componentWidth, setComponentWidth] = useState(0);
    const ref = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        const measureElement = () => {
            if (ref.current) {
                setComponentWidth(ref.current.offsetWidth);
            }
        };

        const debouncedOnResize = debounce(measureElement, 250);

        window.addEventListener('resize', debouncedOnResize);
        setTimeout(measureElement, 0);

        return () => window.removeEventListener('resize', debouncedOnResize);
    }, []);

    return (
        <div className="d-flex align-items-center py-3 px-2 mx-1 overflow-auto" ref={ref}>
            {(() => {
                if (!componentWidth) {
                    return null;
                }

                const totalCount = groups.length;
                const fittingItemsCount = getFittingItemsCount(componentWidth, totalCount);

                if (totalCount === fittingItemsCount) {
                    return (
                        <>
                            {groups.map((group, index) => {
                                const nextGroup = groups[index + 1];

                                return (
                                    <Fragment key={group.startTime}>
                                        <JourneyEvent
                                            group={group}
                                            groupIndex={index}
                                            leftDashVisible={index !== 0}
                                            rightDashVisible={!!nextGroup}
                                            circleEmpty={index !== 0 && !!nextGroup}
                                        />
                                        {nextGroup && (
                                            <JourneyEventSeparator group={group} nextGroup={nextGroup} />
                                        )}
                                    </Fragment>
                                );
                            })}
                        </>
                    );
                }

                const visibleItemsCountOnOneSide = Math.floor(fittingItemsCount / 2);
                const startGroups = groups.slice(0, visibleItemsCountOnOneSide);
                const collapsedGroups = groups.slice(
                    visibleItemsCountOnOneSide,
                    totalCount - visibleItemsCountOnOneSide
                );
                const endGroups = groups.slice(totalCount - visibleItemsCountOnOneSide);

                return (
                    <>
                        {startGroups.map((group, index) => {
                            const nextGroup = startGroups[index + 1];

                            return (
                                <Fragment key={group.startTime}>
                                    <JourneyEvent
                                        group={group}
                                        groupIndex={index}
                                        leftDashVisible={index !== 0}
                                        rightDashVisible
                                        circleEmpty={index !== 0}
                                    />
                                    {nextGroup && (
                                        <JourneyEventSeparator group={group} nextGroup={nextGroup} />
                                    )}
                                </Fragment>
                            );
                        })}
                        <ExpandableGroup
                            groups={collapsedGroups}
                            startGroups={startGroups}
                            endGroups={endGroups}
                        />
                        {endGroups.map((group, index) => {
                            const nextGroup = endGroups[index + 1];

                            return (
                                <Fragment key={group.startTime}>
                                    <JourneyEvent
                                        group={group}
                                        groupIndex={startGroups.length + collapsedGroups.length + index}
                                        leftDashVisible
                                        rightDashVisible={!!nextGroup}
                                        circleEmpty={!!nextGroup}
                                    />
                                    {nextGroup && (
                                        <JourneyEventSeparator group={group} nextGroup={nextGroup} />
                                    )}
                                </Fragment>
                            );
                        })}
                    </>
                );
            })()}
        </div>
    );
};

export default JourneySummaryBar;
