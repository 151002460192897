import { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import { listMediaplans, searchMediaInsertions } from 'platform/campaign/campaign/services/mediaplan.service';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { dateFilterSelectors, dateFilterActions, Periods } from 'platform/common/ducks/dateFilter.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import { formatDate } from 'platform/common/utils/date.util';
import { Mediaplan, MediaInsertionSearchResult } from 'platform/mediaplan/mediaplan.types';
import { ReportFilter } from '../analytics.types';
import { findFilter } from '../analytics.util';
import { analyticsSelectors } from '../ducks/analytics.duck';
import { analyticsMetadataActions } from '../ducks/analyticsMeta.duck';
import { useAnalyticsMetadata } from './useAnalyticsMetadata';

export const MEDIA_PLAN_ID = 'media_plan_id';
const MEDIA_INSERTION_ID = 'media_insertion_id';

const resolveMediaPlan = (mediaPlans: Mediaplan[], mediaFilterValues?: ReportFilter): Mediaplan[] => {
    const filter: Mediaplan[] =
        mediaFilterValues && mediaFilterValues.values.length > 1
            ? mediaPlans.filter(
                  (mp: Mediaplan) => !!mediaFilterValues.values.map(Number).find((value) => value === mp.id)
              )
            : mediaPlans.filter((mp) => mediaFilterValues?.values[0] === mp.id);

    if (filter.length) {
        return filter;
    }

    return mediaPlans.filter((mp) => moment().isBetween(mp.dateFrom, mp.dateTo, undefined, '[]'));
};

export const addMediaPlanFilterValues = (
    mediaPlans: Mediaplan[],
    mediaPlanFilter?: ReportFilter
): ReportFilter => {
    const mediaPlan = resolveMediaPlan(mediaPlans, mediaPlanFilter);

    return { key: MEDIA_PLAN_ID, values: mediaPlan.map((mp: Mediaplan) => mp?.id) };
};

export const getBudgetReportingPeriod = ({
    mediaInsertions,
    mediaPlans,
    periods,
    filters,
}: {
    mediaInsertions: MediaInsertionSearchResult[];
    mediaPlans: Mediaplan[];
    periods: Periods;
    filters: ReportFilter[];
}) => {
    const mediaInsertionFilter = findFilter(filters, MEDIA_INSERTION_ID);
    const mediaPlanFilter = findFilter(filters, MEDIA_PLAN_ID);
    const mediaPlan = resolveMediaPlan(mediaPlans, mediaPlanFilter);

    const mediaInsertion =
        mediaInsertionFilter &&
        mediaInsertions.find((insertion) => insertion.id === mediaInsertionFilter.values[0]);

    let budgetPeriod;

    if (mediaPlan.length && !mediaInsertion) {
        budgetPeriod = {
            from: mediaPlan.map((mp) => mp?.dateFrom).sort()[0] || '',
            to:
                mediaPlan
                    .map((mp) => mp?.dateTo)
                    .sort()
                    .reverse()[0] || '',
        };
    }

    if (mediaInsertion) {
        const today = moment();
        budgetPeriod = {
            from: mediaInsertion.dateFrom,
            to: today.isAfter(moment(mediaInsertion.dateTo))
                ? mediaInsertion.dateTo
                : formatDate(today.subtract(1, 'days')),
        };
    }
    if (budgetPeriod && budgetPeriod.from === periods.primaryFrom && budgetPeriod.to === periods.primaryTo) {
        return undefined;
    }

    return budgetPeriod;
};

const useMediaplanFilter = () => {
    const dispatch = useDispatch();
    const showConfirmModal = useConfirmationModal();
    const filters = useSelector(analyticsSelectors.ownFilters, (left, right) =>
        left.every((f, i) => isEqual(f, right[i]))
    );
    const mediaplanFilter = findFilter(filters, MEDIA_PLAN_ID);
    const periods = useSelector(dateFilterSelectors.periods, shallowEqual);
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const metadata = useAnalyticsMetadata();

    usePromise(
        undefined,
        async () => {
            if (mediaplanFilter && !metadata.mediaPlans.length && !metadata.mediaInsertions.length) {
                const [activeMediaPlans, inactiveMediaplans, mediaInsertions] = await Promise.all([
                    listMediaplans({ advertiserId, status: ['ACTIVE'] }),
                    listMediaplans({ advertiserId, status: ['INACTIVE'] }),
                    searchMediaInsertions({ advertiserId }),
                ]);

                dispatch(
                    analyticsMetadataActions.setMeta({
                        ...metadata,
                        mediaInsertions,
                        mediaPlans: [...activeMediaPlans, ...inactiveMediaplans],
                    })
                );
            }
        },
        [!!mediaplanFilter]
    );

    useEffect(() => {
        if (!mediaplanFilter) {
            return;
        }

        const budgetReportingPeriod = getBudgetReportingPeriod({
            filters,
            mediaInsertions: metadata.mediaInsertions,
            mediaPlans: metadata.mediaPlans,
            periods,
        });

        changeBudgetReportSettings({
            period: budgetReportingPeriod,
            filter: mediaplanFilter.values.length
                ? mediaplanFilter
                : addMediaPlanFilterValues(metadata.mediaPlans, mediaplanFilter),
        });
    }, [filters]);

    const changeBudgetReportSettings = ({
        filter,
        period,
    }: {
        filter?: ReportFilter;
        period?: { from?: string; to?: string };
    }) => {
        if (period && !!filter?.values.length && !!filter?.values[0]) {
            showConfirmModal(
                () =>
                    dispatch(
                        dateFilterActions.changePeriods({
                            primaryFrom: period.from,
                            primaryTo: period.to,
                            preset: undefined,
                        })
                    ),
                {
                    title: 'Change date picker',
                    text: 'Would you like the date picker to update to the date range of the selected media plan?',
                }
            );
        }
    };
};

export default useMediaplanFilter;
