import { orderBy } from 'lodash-es';
import { useChatLanguageContext } from 'platform/chat/ChatProviders/ChatLanguageProvider';
import ConditionalWrapper from 'platform/common/components/ConditionalWrapper/ConditionalWrapper';
import IconButton from 'platform/common/components/IconButton/IconButton';
import RawHtml from 'platform/common/components/RawHtml/RawHtml';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import ChatPromptBadge from '../../ChatInput/ChatPromptsButton/ChatPromptBadge';
import { useChatAgentContext } from '../../ChatProviders/ChatAgentProvider';
import { useChatMessageContext } from '../../ChatProviders/ChatMessageProvider';
import { useChatPromptContext } from '../../ChatProviders/ChatPromptProvider';
import ChatMessage from '../ChatMessage';

interface Props {
    containerClassName?: string;
    className?: string;
}

const ChatWelcomeMessage = ({ containerClassName, className }: Props) => {
    const { handlePredefinedPromptClick } = useChatLanguageContext();
    const { selectedAgent } = useChatAgentContext();
    const { prompts, personalPrompts } = useChatPromptContext();
    const { submit } = useChatMessageContext();
    if (!selectedAgent?.message) return null;

    return (
        <div className="mt-auto">
            <ChatMessage
                className={className}
                containerClassName={containerClassName}
                iconUrl={selectedAgent.icon}
                messageData={{
                    contentParts: [{ content: selectedAgent.message, type: 'TEXT' }],
                    role: 'assistant',
                }}
                contextPrompts={[]}
            >
                {selectedAgent.showPromptsInWelcomeMessage && (
                    <ul className="d-flex flex-row flex-wrap p-0" style={{ listStyleType: 'none' }}>
                        {orderBy(prompts, 'order', 'asc').map((prompt) => (
                            <li key={prompt.id} className="me-1">
                                <ConditionalWrapper
                                    Wrapper={Tooltip}
                                    shouldRenderWrapper={!!prompt.description}
                                    renderTooltip={() => <RawHtml>{prompt.description}</RawHtml>}
                                >
                                    <IconButton onClick={() => handlePredefinedPromptClick(prompt)}>
                                        <ChatPromptBadge text={prompt.name} />
                                    </IconButton>
                                </ConditionalWrapper>
                            </li>
                        ))}
                        {personalPrompts.map((prompt) => (
                            <li key={prompt.id} className="me-1">
                                <IconButton
                                    onClick={() =>
                                        submit([
                                            {
                                                text: prompt.prompt,
                                                prompt: {
                                                    name: prompt.name,
                                                    id: prompt.id!,
                                                    type: 'PERSONAL',
                                                },
                                            },
                                        ])
                                    }
                                >
                                    <ChatPromptBadge text={prompt.name} />
                                </IconButton>
                            </li>
                        ))}
                    </ul>
                )}
            </ChatMessage>
        </div>
    );
};

export default ChatWelcomeMessage;
