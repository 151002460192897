import { useEffect, useState } from 'react';
import { groupBy, keyBy } from 'lodash-es';
import {
    getMediaInsertions,
    getMediaplanWorkflow,
    storeMediaInsertionStep,
    storeMediaplanStep,
    updateWorkflowLaneAssignee,
} from 'platform/campaign/campaign/services/mediaplan.service';
import {
    MediaInsertion,
    MediaInsertionStep,
    MediaInsertionStepType,
    WorkflowEvent,
    WorkflowLaneModel,
    WorkflowStep,
} from 'platform/mediaplan/mediaplan.types';
import { fetchVendors } from 'platform/vendors/vendors.service';
import { Vendor } from 'platform/vendors/vendors.types';

interface WorkflowState {
    mediaplanSteps: { [key: string]: WorkflowStep };
    lanes: WorkflowLaneModel[];
    workflowEvents: WorkflowEvent[];
}

const getLaneKey = ({
    mediaInsertion,
    mediaInsertionId,
    vendor,
    vendorSystem,
}: {
    mediaInsertion?: MediaInsertion;
    mediaInsertionId?: number;
    vendor?: Vendor;
    vendorSystem?: string;
}) => `${mediaInsertionId ?? mediaInsertion?.id}/${vendorSystem ?? vendor?.externalSystem}`;

const fetchData = async (mediaplanId: number): Promise<WorkflowState> => {
    const [workflow, { insertions }, vendors] = await Promise.all([
        getMediaplanWorkflow(mediaplanId),
        getMediaInsertions(mediaplanId),
        fetchVendors(),
    ]);

    const vendorsBySystem = keyBy(vendors, (v) => v.externalSystem as string);
    const stepsByLane = groupBy(workflow.mediaInsertionSteps, (step) => getLaneKey(step));
    const mediaInsertionLanes = insertions.flatMap<WorkflowLaneModel>((mediaInsertion) =>
        (mediaInsertion.vendorSystems ?? []).map((vendorSystem) => ({
            mediaInsertion,
            vendor: vendorsBySystem[vendorSystem],
            assignee: workflow.mediaInsertionLanes.find(
                (x) => x.mediaInsertionId === mediaInsertion.id && x.vendorSystem === vendorSystem
            )?.assignee,
            steps: keyBy(stepsByLane[getLaneKey({ mediaInsertion, vendorSystem })] ?? [], (s) => s.type),
        }))
    );

    return {
        mediaplanSteps: keyBy(workflow.mediaplanSteps, (s) => s.type),
        lanes: mediaInsertionLanes,
        workflowEvents: workflow.events,
    };
};

export const useMediaplanWorkflow = (mediaplanId: number) => {
    const [{ mediaplanSteps, lanes, workflowEvents }, setWorkflowState] = useState<WorkflowState>({
        mediaplanSteps: {},
        lanes: [],
        workflowEvents: [],
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        withLoading(() => fetchData(mediaplanId));
    }, [mediaplanId]);

    const changeMediaplanStep = async (
        step: WorkflowStep,
        event?: WorkflowEvent
    ): Promise<Partial<WorkflowState>> => {
        const storedStep = await storeMediaplanStep(mediaplanId, step);
        return {
            mediaplanSteps: { ...mediaplanSteps, [step.type]: storedStep },
            workflowEvents: event ? [...workflowEvents, event] : workflowEvents,
        };
    };

    const withPrerequisiteSteps = (step: MediaInsertionStep): MediaInsertionStep[] => {
        if (step.type === 'IO_BOOKED' && step.completed) {
            const laneKey = getLaneKey(step);
            const lane = lanes.find((l) => getLaneKey(l) === laneKey);
            return [
                ...(['IO_REQUESTED', 'IO_CHECKED', 'IO_SIGNED'] as MediaInsertionStepType[])
                    .map(
                        (type): MediaInsertionStep =>
                            lane?.steps[type] ?? {
                                type,
                                mediaInsertionId: step.mediaInsertionId,
                                vendorSystem: step.vendorSystem,
                                completed: false,
                            }
                    )
                    .filter((s) => !s.completed)
                    .map((s): MediaInsertionStep => ({ ...s, completed: true })),
                step,
            ];
        }
        return [step];
    };

    const changeLaneStep = async (
        step: MediaInsertionStep,
        event?: WorkflowEvent
    ): Promise<Partial<WorkflowState>> => {
        const laneKey = getLaneKey(step);
        const storedSteps = await Promise.all(
            withPrerequisiteSteps(step).map((s) => storeMediaInsertionStep(mediaplanId, s))
        );
        return {
            lanes: lanes.map((lane) =>
                getLaneKey(lane) === laneKey
                    ? { ...lane, steps: { ...lane.steps, ...keyBy(storedSteps, (s) => s.type) } }
                    : lane
            ),
            workflowEvents: event ? [...workflowEvents, event] : workflowEvents,
        };
    };

    const changeMultiLaneSteps = async (steps: MediaInsertionStep[]): Promise<Partial<WorkflowState>> => {
        const storedSteps = await Promise.all(steps.map((s) => storeMediaInsertionStep(mediaplanId, s)));
        const storedStepsByLaneKey = groupBy(storedSteps, (s) => getLaneKey(s));
        return {
            lanes: lanes.map((lane) => {
                const laneSteps = storedStepsByLaneKey[getLaneKey(lane)];
                return laneSteps?.length > 0
                    ? { ...lane, steps: { ...lane.steps, ...keyBy(laneSteps, (s) => s.type) } }
                    : lane;
            }),
        };
    };

    const changeLaneAssignee = async (
        mediaInsertionId: number,
        vendorSystem: string,
        assignee?: string
    ): Promise<Partial<WorkflowState>> => {
        await updateWorkflowLaneAssignee(mediaplanId, mediaInsertionId, vendorSystem, assignee);

        return {
            lanes: lanes.map((x) =>
                x.mediaInsertion.id === mediaInsertionId && x.vendor.externalSystem === vendorSystem
                    ? { ...x, assignee }
                    : x
            ),
        };
    };

    const withLoading = async (applyChanges: () => Promise<Partial<WorkflowState>>) => {
        setLoading(true);
        try {
            const changes = await applyChanges();
            setWorkflowState((state) => ({ ...state, ...changes }));
        } finally {
            setLoading(false);
        }
    };

    return {
        lanes,
        workflowEvents,
        mediaplanSteps,
        loading,
        changeMediaplanStep: (...args: Parameters<typeof changeMediaplanStep>) =>
            withLoading(() => changeMediaplanStep(...args)),
        changeLaneStep: (...args: Parameters<typeof changeLaneStep>) =>
            withLoading(() => changeLaneStep(...args)),
        changeMultiLaneSteps: (...args: Parameters<typeof changeMultiLaneSteps>) =>
            withLoading(() => changeMultiLaneSteps(...args)),
        changeLaneAssignee: (...args: Parameters<typeof changeLaneAssignee>) =>
            withLoading(() => changeLaneAssignee(...args)),
    };
};
