import { Fragment } from 'react';
import './FunnelIcons.scss';

const FunnelIcons = ({ funnelLetters }: { funnelLetters: string[] }) => {
    if (!funnelLetters.length) return <div>-</div>;

    const funnelNumbers = funnelLetters.filter((l) => l.match(/^\d+$/));
    const dropped = funnelLetters.some((l) => l === 'X');
    return (
        <div className="FunnelIcons">
            {funnelNumbers.map((letter, index) => (
                <Fragment key={letter}>
                    {index !== 0 && <div className="FunnelIcons-separator" />}
                    {index === funnelNumbers.length - 1 && dropped ? (
                        <>
                            <div className={`FunnelIcons-itemDrop`}>{letter}</div>
                            <i className="fal fa-arrow-trend-down FunnelIcons-itemDropIcon" />
                        </>
                    ) : (
                        <div className={`FunnelIcons-itemOk`}>{letter}</div>
                    )}
                </Fragment>
            ))}
        </div>
    );
};

export default FunnelIcons;
