import { CSSProperties, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { flow } from 'lodash-es';
import {
    AnalyticsCompareType,
    AnalyticsMetadataDefinitions,
    ColumnColouringType,
    ColumnsColouring,
    MetricDefinition,
    PivotOptions,
    Report,
    ReportHeaderColumn,
} from 'platform/analytics/analytics.types';
import {
    addColumnIcons,
    addHeaderTooltips,
    handleCompare,
    handleSpecialColumns,
    makeDimensionColsSticky,
} from 'platform/analytics/reportComponents/ReportTableContainer/columnBuilders';
import ReportTracingTips from 'platform/analytics/reportComponents/ReportTracingTips/ReportTracingTips';
import { Sort } from 'platform/common/common.types';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import { typeOf } from 'platform/common/dataTypes';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { ANALYTICS_TABLE_PAGE_SIZE_OPTIONS } from '../analytics.constants';
import { useInsightPivotTable } from './useInsightPivotTable';

interface Props {
    report: Report;
    compareReport?: Report;
    compareWith?: AnalyticsCompareType;
    TopToolbar: ReactNode;
    dimensions: string[];
    metrics: string[];
    pivot: PivotOptions | undefined;
    pageSize: number;
    pageNumber: number;
    summaryTable?: boolean;
    sort: Sort[];
    containerStyle: CSSProperties;
    columnsColouring?: ColumnsColouring;
    definitions?: AnalyticsMetadataDefinitions;
    exportName: string;
    onPageChange: (pageNumber: number) => void;
    onPageSizeChange: (pageSize: number) => void;
    onSortChange: (sort: Sort[]) => void;
    onColumnRemove: (column: TableColumn) => void;
    onColumnColouringChange: (columnId: string, columnColouring: ColumnColouringType) => void;
}

const getHeaderColumns = (headerColumns: ReportHeaderColumn[]) => (columnKeys: string[]) => {
    if (!columnKeys.length) {
        return headerColumns;
    }

    return columnKeys.reduce<ReportHeaderColumn[]>((arr, key) => {
        const header = headerColumns.find((x) => x.key === key);
        if (header) {
            arr.push(header);
        }

        return arr;
    }, []);
};

const getColumnDefinitions = (dimensions: string[]) => (columns: ReportHeaderColumn[]) =>
    columns.map<TableColumn>((column) => ({
        Header: column.name,
        HeaderText: column.name,
        accessor: column.key,
        id: column.key,
        className: dimensions.includes(column.key) ? 'dimensionColumn' : undefined,
        autoWidth: true,
        type: typeOf(column),
        Cell: ({ formattedValue }) => (
            <div className="w-100 text-truncate" title={formattedValue}>
                {formattedValue}
            </div>
        ),
    }));

const toMetricDefinition = ({ key, name, vat, optInEffect }: ReportHeaderColumn): MetricDefinition => ({
    key,
    name,
    vat,
    optInEffect,
});

const InsightReportTable = ({
    report,
    compareReport,
    compareWith,
    TopToolbar,
    dimensions,
    metrics,
    pivot,
    pageSize,
    pageNumber,
    summaryTable = false,
    sort,
    containerStyle,
    exportName,
    columnsColouring,
    definitions,
    onPageSizeChange,
    onPageChange,
    onSortChange,
    onColumnRemove,
    onColumnColouringChange,
}: Props) => {
    const commonClassifiers = useSelector(classifierSelectors.commonClassifiers);

    const pivotTable = useInsightPivotTable({
        pivotColumn: pivot?.column,
        dimensions,
        metrics,
        report,
        compareReport,
        compareWith,
    });

    const transformColumns = flow(
        getHeaderColumns(report.header),
        getColumnDefinitions(dimensions),
        addHeaderTooltips(report),
        addColumnIcons(
            report.header.filter((c) => metrics.includes(c.key)).map(toMetricDefinition),
            columnsColouring,
            onColumnRemove,
            onColumnColouringChange
        ),
        handleSpecialColumns(commonClassifiers, definitions),
        handleCompare(compareWith, report, compareReport, metrics, dimensions),
        makeDimensionColsSticky(dimensions)
    );

    const columnKeys = [...dimensions, ...metrics];

    if (summaryTable) {
        const transformSummaryTableColumns = flow(
            getHeaderColumns(report.header),
            getColumnDefinitions(dimensions),
            addColumnIcons(
                report.header.filter((c) => metrics.includes(c.key)).map(toMetricDefinition),
                columnsColouring,
                onColumnRemove,
                onColumnColouringChange
            ),
            handleSpecialColumns(commonClassifiers, definitions),
            handleCompare(compareWith, report, compareReport, metrics, dimensions),
            makeDimensionColsSticky(dimensions)
        );

        return (
            <>
                <FormattedTable
                    pageSizeOptions={ANALYTICS_TABLE_PAGE_SIZE_OPTIONS}
                    className="SummaryTable"
                    columns={pivotTable?.columns ?? transformSummaryTableColumns(columnKeys)}
                    data={pivotTable?.rows ?? report.rows}
                    sorted={sort}
                    containerClass="shadow-none"
                    containerStyle={containerStyle}
                    showPagination={false}
                    columnsColouring={columnsColouring}
                    onSortedChange={onSortChange}
                />
                <ReportTracingTips report={report} compareReport={compareReport} />
            </>
        );
    }

    return (
        <>
            <FormattedTable
                pageSizeOptions={ANALYTICS_TABLE_PAGE_SIZE_OPTIONS}
                columns={pivotTable?.columns ?? transformColumns(columnKeys)}
                data={pivotTable?.rows ?? report.rows}
                exportData={{
                    headers: pivotTable?.headers ?? undefined,
                    filename: exportName,
                }}
                topToolbar={TopToolbar}
                sorted={sort}
                resizable
                pageSize={pageSize}
                page={pageNumber}
                containerClass="shadow-none"
                columnsColouring={columnsColouring}
                containerStyle={containerStyle}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
                onSortedChange={onSortChange}
            />
            <ReportTracingTips report={report} compareReport={compareReport} />
        </>
    );
};

export default InsightReportTable;
