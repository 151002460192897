import { ReactNode } from 'react';
import { Button } from 'reactstrap';
import './DateRangePickerActions.scss';

interface Props {
    children?: ReactNode;
    disabled?: boolean;
    onCancel: () => void;
    onReset: () => void;
    onApply: () => void;
}

const DateRangerPickerActions = ({ children, disabled, onReset, onCancel, onApply }: Props) => (
    <div className="DateRangePickerActions">
        {children}
        <div className="ms-auto">
            <Button className="me-2 DateRangePickerActions-button" onClick={onCancel}>
                Cancel
            </Button>
            <Button className="me-2 DateRangePickerActions-button" onClick={onReset}>
                Reset
            </Button>
            <Button color="primary" onClick={onApply} disabled={disabled}>
                Apply
            </Button>
        </div>
    </div>
);

export default DateRangerPickerActions;
