import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { groupBy } from 'lodash-es';
import { fetchAdvancedSettings } from 'platform/adminPanel/components/AdvancedSettings/advanced.setting.service';
import {
    ADVANCED_SETTING_GROUP_LABELS,
    AdvancedSetting,
    defaultAdvancedSetting,
} from 'platform/adminPanel/components/AdvancedSettings/advanced.setting.types';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import IconButton from 'platform/common/components/IconButton/IconButton';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { usePromise } from 'platform/common/hooks/usePromise';
import { sortByName } from 'platform/common/utils/array.util';
import AdditionalAttributeInput from './AdditionalAttributeInput';

interface Props {
    field: string;
    onChange: (field: string, value: any) => void;
    additionalAttributes: Record<string, string>;
}

const VISIBLE_GROUP_LABELS = Object.entries(ADVANCED_SETTING_GROUP_LABELS).filter(
    ([group]) => group !== 'INTEGRATION_SPECIFIC'
);

const AdditionalAttributes = ({ field, onChange, additionalAttributes }: Props) => {
    const usedKeys = Object.keys(additionalAttributes);
    const [{ data: allSettings }] = usePromise([], fetchAdvancedSettings, []);

    const usedSettings = groupBy(
        usedKeys.map((key) => allSettings.find((s) => s.key === key) ?? defaultAdvancedSetting(key)),
        (s: AdvancedSetting) => s.group
    );

    const availableSettings = groupBy(
        allSettings.filter(({ key, status }) => status === 'ACTIVE' && !usedKeys.includes(key)),
        (s: AdvancedSetting) => s.group
    );

    const fieldOf = (key: string) => `${field}.['${key}']`;

    const addAttribute = ({ key, valueType }: AdvancedSetting) =>
        onChange(fieldOf(key), valueType === 'SWITCH' ? true : null);

    const removeAttribute = (key: string) => onChange(fieldOf(key), undefined);

    return (
        <ControlledCard title="Advanced Settings">
            <UncontrolledButtonDropdown className="mb-3">
                <DropdownToggle caret color="secondary">
                    New setting
                </DropdownToggle>
                <DropdownMenu className="pb-0">
                    <div className="d-flex flex-column mx-2 mt-2">
                        {VISIBLE_GROUP_LABELS.map(([group, groupLabel]) => (
                            <UncontrolledButtonDropdown key={group} className="mb-2">
                                <DropdownToggle caret color="secondary">
                                    {groupLabel}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {sortByName(availableSettings[group]).map((setting) => (
                                        <DropdownItem key={setting.key} onClick={() => addAttribute(setting)}>
                                            {setting.name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        ))}
                    </div>
                </DropdownMenu>
            </UncontrolledButtonDropdown>

            {VISIBLE_GROUP_LABELS.map(([group, groupLabel]) => {
                const settings = usedSettings[group];
                if (!settings) {
                    return null;
                }
                return (
                    <ControlledCard key={group} title={groupLabel}>
                        {sortByName(settings).map(({ key, name, valueType, description }) => (
                            <FormRow label={name} childrenColClassName="d-flex">
                                <AdditionalAttributeInput field={fieldOf(key)} valueType={valueType} />
                                <Tooltip
                                    className="h-auto ms-3 d-flex align-items-center"
                                    placement="top-end"
                                    renderTooltip={() => (
                                        <div className="m-2">
                                            <div className="font-md">{description}</div>
                                            <div className="mt-2 text-muted">[{key}]</div>
                                        </div>
                                    )}
                                >
                                    <i className="fa fa-info-circle p-1 text-info" />
                                </Tooltip>
                                <IconButton
                                    title="Remove"
                                    className="ms-2"
                                    onClick={() => removeAttribute(key)}
                                >
                                    <i className="far fa-trash-can p-1" />
                                </IconButton>
                            </FormRow>
                        ))}
                    </ControlledCard>
                );
            })}
        </ControlledCard>
    );
};

export default AdditionalAttributes;
