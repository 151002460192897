import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { push } from 'redux-first-history';
import {
    deleteMediaplanFile,
    downloadMediaplanFile,
    listMediaplanFiles,
    storeMediaplanFile,
} from 'platform/campaign/campaign/services/mediaplan.service';
import CardForm from 'platform/common/components/CardForm/CardForm';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { getLabels } from 'platform/common/containers/FormContainer/FormContainer';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { FileUploadProgressProps } from 'platform/common/hooks/useProgress';
import { usePromise } from 'platform/common/hooks/usePromise';
import { FileInfo } from 'platform/common/utils/file.util';
import { dataUrlToBase64 } from 'platform/common/utils/url.util';
import { ACCEPTABLE_IMAGE_TYPES } from 'platform/creatives/constants/asset.constant';
import { MediaplanFileMetadata } from 'platform/mediaplan/mediaplan.types';
import './MediaplanFileList.scss';

interface Props {
    canEdit: boolean;
    redirectTo: string;
}

const MediaplanFileList = ({ canEdit, redirectTo }: Props) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);

    const [uploadLoading, setUploadLoading] = useState(false);
    const [{ data: fileList, loading }, refetch] = usePromise([], () => listMediaplanFiles(id), [id]);
    const showConfirmationModal = useConfirmationModal();
    const dispatch = useDispatch();

    const columnDefinitions: TableColumn<MediaplanFileMetadata>[] = [
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 80,
        },
        {
            Header: 'Name',
            accessor: 'name',
            minWidth: 200,
            type: DATA_TYPES.TEXT,
        },
        {
            Header: 'Edited',
            width: 200,
            Cell: ({ original: file }) => <UpdatedOn date={file.uploadedOn} updatedBy={file.uploadedBy} />,
        },
        {
            Header: 'Download',
            width: 160,
            sortable: false,
            Cell: ({ original: file }) => (
                <Button color="link" onClick={() => downloadMediaplanFile(id, file.id)}>
                    <i className="fa fa-download" />
                </Button>
            ),
        },
        {
            className: 'pull-right cell-align-right',
            maxWidth: 50,
            sortable: false,
            Cell: ({ original: file }) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Delete',
                            action: () =>
                                showConfirmationModal(() => deleteMediaplanFile(id, file.id).then(refetch), {
                                    title: 'File deletion',
                                    text: `Are you sure you want to delete "${file.name}"?`,
                                    okLabel: 'Delete',
                                }),
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <CardForm
            title={`${getLabels(true, canEdit).prefix} mediaplan uploads`}
            subtitle={`ID: ${id}`}
            cardBodyClassname="h-100"
            cancelLabel="Close"
            disabled={!canEdit}
            onCancel={() => dispatch(push(redirectTo))}
        >
            <div className="MediaplanUploads">
                <div className="MediaplanUploads-Header pb-md-3">
                    <UploadInput
                        acceptableMimeTypes={`application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
                        text/csv, application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                         ${ACCEPTABLE_IMAGE_TYPES}`}
                        onFileUpload={(file: FileInfo, uploadProgressProps: FileUploadProgressProps) => {
                            setUploadLoading(true);
                            storeMediaplanFile(id, file.name, dataUrlToBase64(file.content as string), {
                                onUploadProgress: uploadProgressProps?.onUploadProgress,
                            })
                                .then(refetch)
                                .finally(() => {
                                    uploadProgressProps.resetProgress();
                                    setUploadLoading(false);
                                });
                        }}
                        subtitle="Upload xls, xlsx, csv, pdf, doc, docx & image files"
                        loading={uploadLoading}
                    />
                </div>
                <FormattedTable
                    columns={columnDefinitions}
                    data={fileList}
                    loading={loading || uploadLoading}
                />
            </div>
        </CardForm>
    );
};

export default MediaplanFileList;
