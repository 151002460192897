import { INTEGRATION_TYPE_LABELS } from 'platform/campaign/strategy/constants/integrationTypes.constant';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';
import { getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import { CampaignModel } from '../../campaign.types';
import { CampaignIntegrationIcon } from '../CampaignIntegrationIcon';
import CampaignProgrammaticIntegrationSettings from './Integrations/CampaignProgrammaticIntegrationSettings';

type Props = {
    campaign: CampaignModel;
};
export const CampaignFormType = ({ campaign }: Props) => {
    const integration = campaign.integration;
    return (
        <>
            <InputWithAddOn
                name={'type'}
                disabled
                leftAddOn={{ title: 'Type' }}
                className="mb-3"
                value={INTEGRATION_TYPE_LABELS[campaign.type]}
            />
            {integration && campaign.id && campaign.advertiserId && campaign.classification.channel && (
                <InputWithAddOn
                    disabled
                    className="mb-3"
                    leftAddOn={{ title: campaign.type === 'REDIRECT' ? 'Adserver' : 'Integration' }}
                    hideInput
                    rightAddOn={{
                        className: 'd-none',
                        containerClassName: 'flex-fill text-start',
                        title: (
                            <>
                                <CampaignIntegrationIcon
                                    integration={{
                                        ...integration,
                                        campaignId: campaign.id,
                                        advertiserId: campaign.advertiserId,
                                        type: campaign.type,
                                        lastImported: campaign.importedOn,
                                    }}
                                />
                                {getIntegrationLabel(integration.externalSystem)}
                            </>
                        ),
                    }}
                />
            )}
            {integration && campaign.type === 'RTB' && campaign.advertiserId && (
                <CampaignProgrammaticIntegrationSettings
                    advertiserId={campaign.advertiserId}
                    integration={integration}
                />
            )}
        </>
    );
};
