import { useState } from 'react';
import { components, OptionProps } from 'react-select';
import IconButton from 'platform/common/components/IconButton/IconButton';
import { LabelNames } from '../task.types';
import KanbanCardLabel from './KanbanCardLabel';
import KanbanLabelRenameForm from './KanbanLabelRenameForm';
import KanbanLabelSelect from './KanbanLabelSelect';
import KanbanToggleButton from './KanbanToggleButton';

type Props = {
    labelKeys: string[];
    labelNames: LabelNames;
    onChange: (labelKeys: string[]) => void;
    onRename?: (key: string, name: string) => void;
};

type LabelOption = { value: string; label: string };

const KanbanLabelEditor = ({ labelKeys, labelNames, onChange, onRename }: Props) => {
    const [editedOption, setEditedOption] = useState<LabelOption>();
    const [isSelectVisible, setIsSelectVisible] = useState(!!labelKeys.length);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [names, setNames] = useState(labelNames);

    const Option = (props: OptionProps<LabelOption, true>) => (
        <div className="d-flex justify-content-between align-items-center">
            <components.Option {...props} />
            {onRename && (
                <IconButton className="Kanban-label-edit-button" onClick={() => setEditedOption(props.data)}>
                    <i className="fa fa-pen" />
                </IconButton>
            )}
        </div>
    );

    const handleRename = (value: string, label: string) => {
        setNames({ ...names, [value]: label });
        onRename?.(value, label);
    };

    if (editedOption) {
        return (
            <KanbanLabelRenameForm
                labelKey={editedOption.value}
                labelName={editedOption.label}
                onSubmit={handleRename}
                onClose={() => setEditedOption(undefined)}
            />
        );
    }

    return (
        <>
            <div className="d-flex mt-5">
                <KanbanCardLabel icon="fa fa-tags">Labels</KanbanCardLabel>
                <KanbanToggleButton
                    onClick={() => {
                        setIsSelectVisible(true);
                        setIsSelectOpen((isOpen) => !isOpen);
                    }}
                />
            </div>
            {isSelectVisible && (
                <KanbanLabelSelect
                    className="mt-3"
                    labelKeys={labelKeys}
                    labelNames={names}
                    Option={Option}
                    menuIsOpen={isSelectOpen}
                    onChange={onChange}
                    onFocus={() => setIsSelectOpen(true)}
                    onBlur={() => setIsSelectOpen(false)}
                />
            )}
        </>
    );
};

export default KanbanLabelEditor;
