import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import { authActions, authSelectors } from 'platform/app/ducks/auth.duck';
import { resendInvitation } from 'platform/app/services/auth.service';
import Avatar from 'platform/common/components/Avatar/Avatar';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import LabelWithSubtext from 'platform/common/components/LabelWithSubtext/LabelWithSubtext';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import AdvertiserSelect from 'platform/common/containers/AdvertiserSelect/AdvertiserSelect';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { formatDateTime } from 'platform/common/utils/date.util';
import { filterBySearchQuery } from 'platform/common/utils/search.util';
import { toastSuccess } from 'platform/common/utils/toast.util';
import UserForm from 'platform/userManagement/components/UserForm/UserFormContainer';
import UserIntegrationIcons from 'platform/userManagement/components/UserIntegrationIcons';
import { deleteUser, fetchUsersWithSeats } from 'platform/userManagement/services/userManagement.service';
import { canBeImpersonated } from 'platform/userManagement/utils/users.util';
import { USER_STATUS_OPTIONS, UserStatus } from '../../constants/users.constant';
import { User } from '../../types/user.type';

type QueryParams = {
    searchQuery: string;
    status: UserStatus[];
    advertiserIds: number[];
};

const UserList = () => {
    const {
        queryParams: { searchQuery, status, advertiserIds },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>({
        status: ['ACTIVE'],
        searchQuery: '',
        advertiserIds: [],
    });

    const dispatch = useDispatch();
    const canEdit = useFeature('ROLE_ADMINPANEL_EDIT');
    const canImpersonate = useSelector(authSelectors.canImpersonate);
    const profile = useSelector(authSelectors.ready.profile);

    const [{ data, loading }, refetch] = usePromise([], () => fetchUsersWithSeats({ states: status }), [
        status,
    ]);

    const showConfirmationModal = useConfirmationModal();

    const getUserActions = (user: User) => {
        const userActions = [
            {
                label: canEdit ? 'Edit' : 'View',
                action: () => {
                    dispatch(push(`/user-management/users/${user.id}`));
                },
            },
        ];

        if (canEdit && (user.status === 'ARCHIVED' || user.status === 'PENDING_INACTIVE')) {
            userActions.push({
                label: 'Delete',
                action: () => {
                    showConfirmationModal(() => deleteUser(user.id).then(refetch), {
                        title: 'Delete user',
                        text: `User '${user.login}' will be deleted. You will not be able to undo this action.`,
                        okLabel: 'Delete',
                    });
                },
            });
        }

        if (canImpersonate && canBeImpersonated(user, profile)) {
            userActions.push({
                label: 'Impersonate',
                action: () => {
                    dispatch(authActions.impersonate(user.login));
                },
            });
        }

        if (canEdit && (user.status === 'PENDING' || user.status === 'PENDING_INACTIVE')) {
            userActions.push({
                label: 'Resend invitation',
                action: async () => {
                    await resendInvitation(user.id);
                    toastSuccess(`Invitation resent to user ${user.login}`);
                },
            });
        }

        return userActions;
    };

    const columns: TableColumn<User & { seatTitle: string }>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            type: DATA_TYPES.TEXT,
            maxWidth: 80,
            Cell: ({ value }) => <StatusBadge status={value} />,
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 80,
        },
        {
            Header: 'Name',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
            Cell: ({ original: user }) => (
                <>
                    <Avatar name={user.name} imageUrl={user.imageUrl} impersonating={false} />
                    <span className="ms-2 text-truncate" title={user.name}>
                        {user.name}
                    </span>
                </>
            ),
        },
        {
            Header: 'Email',
            accessor: 'login',
            type: DATA_TYPES.TEXT,
            minWidth: 250,
            Cell: CellWithEllipsis,
        },
        {
            Header: 'Seat',
            accessor: 'seatTitle',
            type: DATA_TYPES.TEXT,
            minWidth: 150,
            Cell: CellWithEllipsis,
        },
        {
            Header: 'Role',
            accessor: 'roleTitle',
            type: DATA_TYPES.TEXT,
            minWidth: 150,
            Cell: CellWithEllipsis,
        },
        {
            Header: 'Sync',
            sortable: false,
            width: 50,
            Cell: ({ original: user }) => (
                <UserIntegrationIcons userId={user.id} integrations={user.externalSystemIntegrations} />
            ),
        },
        {
            Header: 'Last activity',
            accessor: (user: User) =>
                // This value is used for sorting
                (user.lastSuccessfulLogins || [])[0] || '',
            width: 180,
            Cell: ({ original: user }) => {
                const formatActivityDate = (date?: string) => (date ? formatDateTime(date) : 'Never');

                return (
                    <Tooltip
                        renderTooltip={() => (
                            <>
                                <div className="fw-bold">Last 10 successful logins</div>
                                {user.lastSuccessfulLogins?.length
                                    ? user.lastSuccessfulLogins.map((x) => (
                                          <div key={x}>{DATA_TYPES.DATE_TIME.format(x)}</div>
                                      ))
                                    : 'Never'}
                            </>
                        )}
                    >
                        <LabelWithSubtext
                            label={formatActivityDate(user.lastSuccessfulLogins?.[0])}
                            subtext={`Tried: ${formatActivityDate(user.lastLoginAttempt)}`}
                            nativeTooltipText={''}
                        />
                    </Tooltip>
                );
            },
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 160,
            Cell: (props) => (
                <UpdatedOn date={props.original.updatedOn} updatedBy={props.original.updatedUserName} />
            ),
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: (props) => <InlineDropdown items={getUserActions(props.original)} />,
        },
    ];

    return (
        <div>
            <BodyContainer helpKey="user_list">
                {!canEdit && <CanNotEditWarning />}
                <div className="d-flex gap-3 mb-3">
                    <SelectWithAddon
                        name="Status"
                        value={status}
                        options={USER_STATUS_OPTIONS}
                        isMulti
                        onChange={(value) => setQueryParams({ status: value })}
                        className={'flex-grow-0 w-auto'}
                    />
                    <AdvertiserSelect
                        value={advertiserIds}
                        isClearable
                        isMulti
                        firstAddon="Advertiser"
                        onChange={(value: number[]) => setQueryParams({ advertiserIds: value })}
                        className="w-auto"
                    />
                </div>
                <FormattedTable
                    topToolbar={
                        <ListToolbar
                            value={searchQuery}
                            onCreate={
                                canEdit ? () => dispatch(push('/user-management/users/new')) : undefined
                            }
                            onSearch={(value) => setQueryParams({ searchQuery: value })}
                        />
                    }
                    columns={columns}
                    data={filterBySearchQuery(
                        data.filter((item) => {
                            if (advertiserIds.length) {
                                return (
                                    item.effectiveAdvertiserAccessLevel === 'ALL' ||
                                    item.effectiveAdvertiserIds?.some((id) => advertiserIds.includes(id))
                                );
                            }
                            return true;
                        }),
                        [...columns.map((column) => column.accessor), 'updatedUserName'],
                        searchQuery
                    )}
                    loading={loading}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={<UserForm redirectTo={returnUrl} canEdit={canEdit} afterSubmit={refetch} />}
                />
                <Route
                    path=":id"
                    element={<UserForm redirectTo={returnUrl} canEdit={canEdit} afterSubmit={refetch} />}
                />
            </Routes>
        </div>
    );
};

export default UserList;
