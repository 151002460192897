import classNames from 'classnames';

type Props = {
    vendorList: {
        id: number;
        name: string;
        iconUrl?: string;
        indicatorColorClass: string;
    }[];
    isTable?: boolean;
};

const VendorIconList = ({ vendorList, isTable = true }: Props) => {
    const classes = classNames({
        'SegmentSyncTable-item': true,
        'SegmentSyncTable-item--auto': !isTable,
    });

    return (
        <>
            {vendorList.map(({ id, name, iconUrl, indicatorColorClass }) => (
                <div key={id} className={classes}>
                    <i className={`fa fa-circle me-1 ${indicatorColorClass}`} />
                    {iconUrl && (
                        <img
                            className="SegmentSyncTable-vendorImage"
                            alt={name}
                            src={iconUrl}
                            width={20}
                            height={20}
                        />
                    )}
                    {name}
                </div>
            ))}
        </>
    );
};

export default VendorIconList;
