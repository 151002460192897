import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormProductLabelSelect from 'platform/campaign/common/FormProductLabelSelect';
import {
    GlobalCostSourceApiModel,
    GlobalCostSourcePreset,
} from 'platform/campaign/globalPreset/globalPreset.types';
import { toApiModel, toPreset } from 'platform/campaign/globalPreset/mappers/globalPreset.mapper';
import globalCostSourceService from 'platform/campaign/globalPreset/services/globalCostSourcePreset.service';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import Separator from 'platform/common/components/Separator/Separator';
import SIDE_PANEL_SIZES from 'platform/common/constants/sidePanelSizes.constant';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { limitFloatTo, percentage, required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

const GlobalCostSourceForm = ({ redirectTo, canEdit, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = params.id && Number(params.id);
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const channels = useSelector(classifierSelectors.channels);

    const handleOnOpen = async () => {
        const globalCostSource: GlobalCostSourceApiModel = id
            ? await globalCostSourceService.fetchGlobalCostSource(id)
            : {
                  name: '',
                  advertiserId,
                  monetization: {},
              };
        return toPreset(globalCostSource);
    };

    const handleSubmit = async (data: GlobalCostSourcePreset) =>
        id
            ? globalCostSourceService.updateGlobalCostSource(id, toApiModel(data))
            : globalCostSourceService.createGlobalCostSource(toApiModel(data));

    return (
        <FormContainer
            helpKey="global_cost_source_form"
            size={SIDE_PANEL_SIZES.WIDE}
            redirectTo={redirectTo}
            canEdit={canEdit}
            sidePanel
            isEdit={!!id}
            onOpen={handleOnOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
        >
            {({ labels, formikProps: { initialValues, submitForm }, onCancel }) => (
                <CardForm
                    title={`${labels.prefix} cost source`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    submitLabel={labels.submit}
                    onCancel={onCancel}
                    disabled={!canEdit}
                    onSubmit={submitForm}
                >
                    <ControlledCard title="General info" subtitle="required">
                        <FormRow label="Name">
                            <FormInput name="name" type="text" validate={required} />
                        </FormRow>
                        <FormRow label="Agency fee">
                            <FormNumberInput
                                name="monetization.costPerSpendPercentage"
                                label="%"
                                validate={[required, limitFloatTo(2), percentage]}
                            />
                        </FormRow>
                        <Separator label="Optional" />
                        <FormRow label="Channel">
                            <FormSelect
                                name="channel"
                                options={channels}
                                getOptionValue={(c) => c.code}
                                getOptionLabel={(c) => c.name}
                            />
                        </FormRow>
                        <FormRow label="Product">
                            <FormProductLabelSelect name="productLabel" advertiserId={advertiserId} />
                        </FormRow>
                    </ControlledCard>
                </CardForm>
            )}
        </FormContainer>
    );
};

export default GlobalCostSourceForm;
