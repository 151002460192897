import classNames from 'classnames';
import moment from 'moment';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { Task, TaskLane } from '../task.types';
import TaskBlockedBadge from './TaskBlockedBadge';
import TaskStatusIcon from './TaskStatusIcon';

interface Props {
    task: Task;
    lane?: TaskLane;
    className?: string;
    isBlocked?: boolean;
}

const TaskTitle = ({ task, lane, className, isBlocked }: Props) => {
    const movedDaysAgo =
        lane?.timeout && task.lastMovedToLane
            ? moment().diff(moment(task.lastMovedToLane), 'days')
            : undefined;

    const isStuck = task.status === 'ACTIVE' && lane?.timeout && (movedDaysAgo ?? 0) > lane?.timeout.days;

    return (
        <div className={classNames('Kanban-card-name', className)}>
            <TaskStatusIcon task={task} lane={lane} />
            {isStuck && (
                <Tooltip renderTooltip={() => `Stuck in its column for ${movedDaysAgo} days already`}>
                    <i className="fa fa-alarm-clock Kanban-red me-2" />
                </Tooltip>
            )}
            {isBlocked && <TaskBlockedBadge />}
            <span className={task.status !== 'ACTIVE' ? 'text-muted' : ''}>{task.name}</span>
        </div>
    );
};

export default TaskTitle;
