import { useSelector } from 'react-redux';
import { flow, groupBy, sortBy } from 'lodash-es';
import moment from 'moment';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import { Status } from 'platform/common/constants/status.constant';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { getObserverReports } from 'platform/observer/observer.service';
import { ObserverReportRow } from 'platform/observer/observer.types';
import { ALGORITHM_SECTION_LABELS, AlgorithmSection } from '../algorithmManager.constant';
import {
    fetchAlgorithmListSummary,
    listAdvertiserAlgorithms,
    listAlgorithmTemplates,
} from '../algorithms.service';
import { AlgorithmListItem } from '../algorithms.types';

interface AlgosSummary {
    algorithm_executions: number;
    algorithm_processed_entries: number;
}

const statusMapForTemplate: Record<Status, Status> = {
    ACTIVE: 'INACTIVE', // display active templates as inactive algorithms
    INACTIVE: 'INACTIVE',
    ARCHIVED: 'ARCHIVED',
};

const unpackObjectArrays = <T extends Record<string, any>>(obj: T) =>
    Object.values(obj).reduce((acc, v) => [...acc, ...v], []);

const filterAndSortAlgorithms = (status: Status[], isDemoMode: boolean) =>
    flow(
        (data: AlgorithmListItem[]) => {
            if (!status.length) {
                return data;
            }

            return data.filter(({ algorithm, template }) =>
                status.includes(algorithm ? algorithm.status : statusMapForTemplate[template.status])
            );
        },
        (sortedByStatus) =>
            sortedByStatus.filter(({ template }) => isDemoMode || !template.display.onlyDemoMode),
        (visible) => {
            const groupedBySection = groupBy(visible, ({ template }) => template.display.section);
            return Object.keys(groupedBySection).reduce(
                (acc, section) => ({
                    ...acc,
                    [section]: sortBy(groupedBySection[section], ({ template }) =>
                        Number(template.display.order)
                    ),
                }),
                {} as Record<AlgorithmSection, AlgorithmListItem[]>
            );
        }
    );

const groupObserverReportsBySection = (reports: ObserverReportRow[]) =>
    reports.reduce<Record<AlgorithmSection, ObserverReportRow[]>>((acc, r) => {
        const section: AlgorithmSection = r.reportType === 'ALERT' ? 'QUALITY_ASSURANCE' : 'DATA_PIPELINE';
        return {
            ...acc,
            [section]: [...(acc[section] || []), r],
        };
    }, {} as Record<AlgorithmSection, ObserverReportRow[]>);

const fetchAlgorithmList = async (advertiserId: number): Promise<AlgorithmListItem[]> => {
    const [algorithms, templates] = await Promise.all([
        listAdvertiserAlgorithms(advertiserId),
        listAlgorithmTemplates(),
    ]);

    return templates
        .filter(
            ({ display }) =>
                !display.onlyAdvertiserIds?.length || display.onlyAdvertiserIds.includes(advertiserId)
        )
        .map((template) => ({
            algorithm: algorithms.find((a) => template.id === a.algorithmTemplateId),
            template,
        }));
};

type QueryParams = {
    status: Status[];
    dateFrom?: string;
    dateTo?: string;
};

export const useAlgorithmList = () => {
    const {
        queryParams: { status, dateFrom, dateTo },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>({
        status: ['ACTIVE'],
        dateTo: moment().format(ISO_DATE_FORMAT),
        dateFrom: moment().subtract(30, 'days').format(ISO_DATE_FORMAT),
    });
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const isDemoMode = useSelector(authSelectors.isDemoModeEnabled);

    const [{ data: algorithms, loading }, refetch] = usePromise<AlgorithmListItem[]>(
        [],
        () => fetchAlgorithmList(advertiserId),
        [advertiserId]
    );

    const [{ data: observerReportsBySection }] = usePromise<
        Record<AlgorithmSection, ObserverReportRow[]> | undefined
    >(
        undefined,
        () =>
            getObserverReports({
                advertiserIds: [advertiserId],
                status: ['ACTIVE'],
                showInAlgorithmManager: true,
            }).then(groupObserverReportsBySection),
        [advertiserId]
    );

    const [{ data: algosSummaryReport, loading: algosSummaryLoading }] = usePromise(
        undefined,
        () => fetchAlgorithmListSummary(advertiserId, dateFrom, dateTo),
        [advertiserId, dateFrom, dateTo]
    );

    const filteredAlgorithms = filterAndSortAlgorithms(status, isDemoMode)(algorithms);

    const sortedAlgorithmSections = (Object.keys(filteredAlgorithms) as AlgorithmSection[]).sort((a, b) => {
        const sectionOrder = Object.keys(ALGORITHM_SECTION_LABELS);
        return sectionOrder.indexOf(a) < sectionOrder.indexOf(b) ? -1 : 1;
    });

    const pipelineCount = filteredAlgorithms?.DATA_PIPELINE?.length ?? 0;
    return {
        algosSummary: algosSummaryReport?.rows[0] as AlgosSummary | undefined,
        algorithmsCount: [
            ...unpackObjectArrays(filteredAlgorithms),
            ...unpackObjectArrays(observerReportsBySection ?? {}),
        ].length,
        pipelineCount,
        sortedAlgorithmSections,
        filteredAlgorithms,
        observerReportsBySection,
        advertiserId,
        status,
        setQueryParams,
        returnUrl,
        loading,
        algosSummaryLoading,
        refetch,
        dateFrom,
        dateTo,
    };
};
