import { CampaignIntegration } from 'platform/campaign/campaign/campaign.types';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';
import { OBJECTIVE_LABELS } from 'platform/common/constants/externalIntegration.constant';
import { labelByKey } from 'platform/common/constants/optimization.constant';

const FacebookIntegration = ({ integration }: { integration: CampaignIntegration }) => (
    <>
        {!integration.attributes?.objective && (
            <InputWithAddOn
                disabled
                className="mb-3"
                leftAddOn={{ title: 'Objective' }}
                value={labelByKey(OBJECTIVE_LABELS, integration?.attributes?.objective)}
            />
        )}
    </>
);

export default FacebookIntegration;
