import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import memoizeOne from 'memoize-one';
import { useLoading } from 'platform/common/hooks/useLoading';
import { TableColumn } from '../FormattedTable/FormattedTable';
import Spinner from '../Spinner/Spinner';
import Tooltip from '../Tooltip/Tooltip';
import dataExport from './dataExport';

export interface ExportAction {
    label: string;
    action: () => void;
}

export interface ExportOptions {
    filename: string;
    headers?: string[][];
    additionalActions?: ExportAction[];
    exportHeader?: boolean;
    loadData: () => any;
}

interface Props {
    columns: TableColumn[];
    exportOptions: ExportOptions;
    disabled?: boolean;
}

const DataExportButton = ({ columns, exportOptions, disabled }: Props) => {
    const [loading, withLoading] = useLoading();
    const { loadData, filename, exportHeader, headers } = exportOptions;

    const getItems = memoizeOne(() => [
        {
            label: 'CSV',
            action: async () => {
                const data = await withLoading(loadData);
                dataExport.downloadCsv(columns, data, filename, exportHeader, headers);
            },
        },
        {
            label: 'XLSX',
            action: async () => {
                const data = await withLoading(loadData);
                dataExport.downloadXlsx(columns, data, filename, exportHeader, headers);
            },
        },
        ...(exportOptions.additionalActions || []),
    ]);

    if (loading) {
        return (
            <Tooltip renderTooltip={() => 'Exporting data...'}>
                <Spinner className="ms-1" size="sm" />
            </Tooltip>
        );
    }

    return (
        <UncontrolledButtonDropdown size="sm" disabled={disabled}>
            <DropdownToggle className="d-flex border-0 shadow-none p-1" style={{ height: 21 }}>
                <i className="fa fa-download" title="Export" />
            </DropdownToggle>
            <DropdownMenu end>
                {getItems().map((x) => (
                    <DropdownItem key={x.label} onClick={x.action}>
                        {x.label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
};

export default DataExportButton;
