import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import qs from 'qs';
import { isDefined } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { Status, STATUS_OPTIONS } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { filterTreeByQuery } from 'platform/common/utils/search.util';
import CategoryCreate from './CategoryCreate';
import CategoryEdit from './CategoryEdit';
import { fetchCategoryTrees } from './category.service';
import { Category } from './category.types';

const PATH = '/categorization';

const searchQueryFields: (keyof Category)[] = ['id', 'name', 'code', 'updatedBy'];

type QueryParams = {
    status: Status[];
    searchQuery: string;
};

const CategoryList = () => {
    const {
        queryParams: { status, searchQuery },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>({
        status: ['ACTIVE'],
        searchQuery: '',
    });

    const dispatch = useDispatch();
    const canEdit = useFeature('ROLE_ADMINPANEL_EDIT');

    const [{ data, loading }, refetch] = usePromise([], () => fetchCategoryTrees({ status }), [status]);

    const columns: TableColumn<Category>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            type: DATA_TYPES.TEXT,
            maxWidth: 80,
            Cell: ({ original }) => <StatusBadge status={original.status} />,
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 90,
        },
        {
            Header: 'Name',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Code',
            accessor: 'code',
            minWidth: 150,
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            maxWidth: 160,
            Cell: ({ original }) => <UpdatedOn date={original.updatedOn} updatedBy={original.updatedBy} />,
        },
        {
            className: 'pull-right cell-align-right',
            maxWidth: 50,
            sortable: false,
            Cell: ({ original }) => (
                <InlineDropdown
                    items={[
                        {
                            label: canEdit ? 'Edit' : 'View',
                            action: () => dispatch(push(`${PATH}/${original.id}`)),
                        },
                        canEdit
                            ? {
                                  label: 'Add',
                                  action: () => {
                                      dispatch(
                                          push({
                                              pathname: `${PATH}/new`,
                                              search: qs.stringify({ parentId: original.id }),
                                          })
                                      );
                                  },
                              }
                            : undefined,
                    ].filter(isDefined)}
                />
            ),
        },
    ];

    const toolbar = (
        <ListToolbar
            value={searchQuery}
            onCreate={canEdit ? () => dispatch(push(`${PATH}/new`)) : undefined}
            onSearch={(value) => setQueryParams({ searchQuery: value })}
        />
    );

    return (
        <>
            <HeaderContainer>
                <PageHeader title={'Categorization'} />
            </HeaderContainer>
            <BodyContainer helpKey="category_list">
                {!canEdit && <CanNotEditWarning />}
                <SelectWithAddon
                    name="Status"
                    className="mb-3"
                    value={status}
                    options={STATUS_OPTIONS}
                    isMulti
                    onChange={(value) => setQueryParams({ status: value })}
                />
                <FormattedTable
                    isTree
                    columns={columns}
                    topToolbar={toolbar}
                    loading={loading}
                    data={filterTreeByQuery(data, searchQuery, 'children', searchQueryFields)}
                    childrenLoaders={[{ field: 'children' }]}
                    columnsAlignedFromIndex={5}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <CategoryCreate canEdit={canEdit} redirectTo={returnUrl} afterSubmit={refetch} />
                    }
                />
                <Route
                    path=":id"
                    element={<CategoryEdit canEdit={canEdit} redirectTo={returnUrl} afterSubmit={refetch} />}
                />
            </Routes>
        </>
    );
};

export default CategoryList;
