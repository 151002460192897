import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import ModelEndpointFormContainer from 'platform/chat/admin/ModelEndpointList/ModelEndpointFormContainer';
import { getModelEndpoints } from 'platform/chat/chat.service';
import { MODEL_ENDPOINT_TYPE_LABELS, ModelEndpoint } from 'platform/chat/chat.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { ACTIVE_OR_ARCHIVED, ActiveOrArchived } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';

const PATH = '/deeply-panel/models';

type QueryParams = {
    status: ActiveOrArchived[];
};

const ModelEndpointList = () => {
    const {
        queryParams: { status },
        setQueryParams,
    } = useUrlSync<QueryParams>({
        status: ['ACTIVE'],
    });

    const dispatch = useDispatch();
    const [{ data: models, loading }, refetch] = usePromise([], () => getModelEndpoints({ status }), [
        status,
    ]);
    const canEdit = useFeature('CHATBOT_MANAGEMENT_EDIT');

    const columns: TableColumn<ModelEndpoint>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            width: 80,
            Cell: ({ value }) => <StatusBadge status={value} />,
        },
        { Header: 'ID', accessor: 'id', width: 100, type: DATA_TYPES.ID },
        { Header: 'Name', accessor: 'name', minWidth: 200, type: DATA_TYPES.TEXT },
        {
            Header: 'Type',
            id: 'type',
            accessor: (row) => MODEL_ENDPOINT_TYPE_LABELS[row.modelType] ?? row.modelType,
            width: 200,
            type: DATA_TYPES.TEXT,
        },
        { Header: 'Max Input Tokens', accessor: 'maxInputTokens', type: DATA_TYPES.INT },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 200,
            Cell: ({ original }) => <UpdatedOn date={original.updatedOn} updatedBy={original.updatedBy} />,
        },
        {
            className: 'pull-right cell-align-right',
            sortable: false,
            width: 50,
            Cell: ({ original: { id } }) => (
                <InlineDropdown
                    items={[
                        {
                            label: canEdit ? 'Edit' : 'View',
                            action: () => {
                                dispatch(push(`${PATH}/${id}`));
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <>
            <BodyContainer helpKey="model_endpoint_list">
                <ListFilters className="mb-3">
                    <SelectWithAddon
                        name="Status"
                        value={status}
                        options={ACTIVE_OR_ARCHIVED}
                        onChange={(value) => setQueryParams({ status: value })}
                        isMulti
                    />
                </ListFilters>

                <FormattedTable
                    topToolbar={
                        <ListToolbar
                            onCreate={
                                canEdit
                                    ? () => {
                                          dispatch(push(`${PATH}/new`));
                                      }
                                    : undefined
                            }
                        />
                    }
                    columns={columns}
                    loading={loading}
                    data={models}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <ModelEndpointFormContainer
                            redirectTo={PATH}
                            canEdit={canEdit}
                            afterSubmit={refetch}
                        />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <ModelEndpointFormContainer
                            redirectTo={PATH}
                            canEdit={canEdit}
                            afterSubmit={refetch}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default ModelEndpointList;
