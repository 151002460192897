import { ChangeEvent, ReactNode, RefObject, useState } from 'react';
import { addToRange, DayModifiers, DayPicker, Matcher } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { Popover } from 'reactstrap';
import { preventOverflow } from '@popperjs/core';
import { PreventOverflowModifier } from '@popperjs/core/lib/modifiers/preventOverflow';
import moment, { Moment } from 'moment';
import { DatePickerMode } from 'platform/analytics/analytics.types';
import DateRangePickerSidebar from 'platform/common/components/DateRangePicker/DateRangePickerSidebar';
import DateRangerPickerActions from 'platform/common/components/DateRangePicker/DateRangerPickerActions';
import { PopoverPlacement } from 'platform/common/components/Popover/Popover';
import useToggle from 'platform/common/hooks/useToggle';
import {
    DateRangePreset,
    DateRanges,
    formatDate,
    getDatePeriod,
    replaceRangeBasedOnDay,
} from '../../utils/date.util';
import Switch from '../Switch/Switch';
import DateInput from './DateInput/DateInput';
import MonthPicker from './MonthPicker/MonthPicker';
import MonthRangePicker from './MonthPicker/MonthRangePicker';
import YearPicker from './YearPicker/YearPicker';
import './DateButton/DateButton.scss';
import './DateRangePickerPopover.scss';

interface Props {
    target: string | HTMLElement | RefObject<HTMLElement>;
    placement: PopoverPlacement;
    toggle: () => void;
    from?: Moment;
    to?: Moment;
    preset?: DateRangePreset | undefined;
    fromDate?: Moment;
    toDate?: Moment;
    minDate?: Moment;
    maxDate?: Moment;
    ranges?: Partial<DateRanges>;
    mode?: DatePickerMode;
    optionalEndDate?: boolean;
    forceRangeSelection?: boolean;
    allowApplyEmptyValues?: boolean;
    onChange: (
        from: string | undefined,
        to: string | undefined,
        preset?: DateRangePreset | undefined
    ) => void;
    onClear?: () => void;
    onCancel: () => void;
}

interface State {
    from: Date | undefined;
    to: Date | undefined;
    minDate: Date | undefined;
    maxDate: Date | undefined;
    preset?: DateRangePreset | undefined;
    focusedMonth?: Date | undefined;
}

const momentToDate = (date: moment.Moment | undefined): Date | undefined =>
    date ? date.toDate() : undefined;

const DateRangePickerPopover = ({
    from,
    to,
    preset,
    minDate,
    maxDate,
    fromDate,
    toDate,
    optionalEndDate,
    target,
    placement,
    ranges,
    mode = 'DEFAULT',
    toggle,
    forceRangeSelection,
    allowApplyEmptyValues,
    onCancel,
    onChange,
    onClear,
}: Props) => {
    const [noEndDate, toggleNoEndDate] = useToggle(optionalEndDate ?? false);
    const [state, setState] = useState<State>({
        from: momentToDate(from),
        to: momentToDate(to),
        minDate: momentToDate(minDate),
        maxDate: momentToDate(maxDate),
        preset,
        focusedMonth: momentToDate(to) ? momentToDate(to) : momentToDate(from) || new Date(),
    });

    const dayPickerModifiers: DayModifiers = noEndDate
        ? { selected: state.from ?? false, today: false, outside: false }
        : {
              start: state.from ?? false,
              end: state.to ?? false,
              range: { from: state.from, to: state.to },
              disabled: [
                  minDate && { before: minDate?.toDate() },
                  maxDate && { after: maxDate.toDate() },
              ].filter(Boolean) as Matcher[],
              today: false,
              outside: false,
          };

    const canApplyChanges = (): boolean => {
        if (!state.from && !state.to) {
            return !!allowApplyEmptyValues;
        }
        return !!(state.from && (state.to || noEndDate));
    };

    const applyChanges = () => {
        if (!state.from && !state.to) {
            onClear?.();
        }
        onChange(formatDate(state.from), formatDate(state.to), state.preset);
    };

    const handleCalendarWeekDayClick = (day: Date) => {
        setState({
            ...state,
            preset: undefined,
            ...getDatePeriod(day, 'isoWeek'),
        });
    };

    const handleCalendarWeekDayRangeClick = (day: Date) => {
        const range = getDatePeriod(day, 'isoWeek');
        setState({ ...state, preset: undefined, ...replaceRangeBasedOnDay(state, range, day, 'isoWeek') });
    };

    const handleMonthClick = (date: Date) => {
        const range = getDatePeriod(date, 'month');
        setState({
            ...state,
            preset: undefined,
            ...range,
        });
    };

    const handleMonthRangeClick = (date: Date) => {
        const range = getDatePeriod(date, 'month');
        setState({ ...state, preset: undefined, ...replaceRangeBasedOnDay(state, range, date, 'month') });
    };

    const handleYearClick = (date: Date) => {
        const range = getDatePeriod(date, 'year');
        setState({
            ...state,
            preset: undefined,
            ...range,
        });
    };

    const handlers: Record<DatePickerMode, ((date: Date) => void) | undefined> = {
        CALENDAR_WEEK: handleCalendarWeekDayClick,
        CALENDAR_WEEK_RANGE: handleCalendarWeekDayRangeClick,
        DEFAULT: undefined,
        MONTH: handleMonthClick,
        MONTH_RANGE: handleMonthRangeClick,
        YEAR: handleYearClick,
    };

    const handleDayClick = (day: Date, modifiers: DayModifiers) => {
        if (modifiers.disabled || forceRangeSelection) {
            return;
        }

        if (mode !== 'DEFAULT') {
            handlers[mode]?.(day);
            return;
        }

        if (noEndDate) {
            setState({
                ...state,
                preset: undefined,
                from: day,
            });
        } else {
            const range = addToRange(day, { from: state.from, to: state.to });
            setState({ ...state, preset: undefined, ...range });
        }
    };

    const handleFromBlur = (value: string) => {
        const momentValue = moment(value);
        const dateValue = momentValue.toDate();

        if (momentValue.isAfter(moment(state.to), 'day')) {
            setState({
                ...state,
                preset: undefined,
                from: dateValue,
                to: dateValue,
            });
            return;
        }

        setState({ ...state, preset: undefined, from: dateValue });
    };

    const handleToBlur = (value: string) => {
        const momentValue = moment(value);
        const dateValue = momentValue.toDate();
        if (momentValue.isBefore(moment(state.from), 'day')) {
            setState({ ...state, preset: undefined, from: dateValue, to: dateValue });
            return;
        }

        setState({ ...state, preset: undefined, to: dateValue });
    };

    const handleNoEndChange = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
        if (checked) {
            setState({ ...state, preset: undefined, to: undefined });
        }
        toggleNoEndDate();
    };

    const handlePresetSelect = (rangePreset: DateRangePreset) => {
        const dateRange = ranges?.[rangePreset]?.getRange();
        if (!dateRange) {
            return;
        }
        setState({
            ...state,
            preset: rangePreset,
            from: dateRange.from.toDate(),
            to: dateRange.to.toDate(),
            focusedMonth:
                state?.focusedMonth?.toDateString() === dateRange.to.toDate().toDateString()
                    ? dateRange.from.toDate()
                    : dateRange.to.toDate(),
        });
    };

    const dayPicker = (
        <DayPicker
            selected={{ from: state.from, to: state.to }}
            fromDate={fromDate?.toDate()}
            toDate={toDate?.toDate()}
            numberOfMonths={2}
            weekStartsOn={1}
            modifiers={dayPickerModifiers}
            showOutsideDays
            showWeekNumber
            ISOWeek
            onDayClick={handleDayClick}
            onMonthChange={(newMonth) => setState({ ...state, focusedMonth: newMonth })}
            month={state.focusedMonth}
            mode="range"
            modifiersStyles={{ range_middle: { backgroundColor: '#e6f7ff', color: 'black' } }}
        />
    );
    const pickerElementMap: Record<DatePickerMode, ReactNode> = {
        DEFAULT: dayPicker,
        CALENDAR_WEEK: dayPicker,
        CALENDAR_WEEK_RANGE: dayPicker,
        MONTH: (
            <MonthPicker
                style={{ width: 260 }}
                className="px-3 pt-3"
                from={state.from}
                to={state.to}
                onDateClick={handlers.MONTH!}
                fromDate={fromDate}
                toDate={toDate}
            />
        ),
        MONTH_RANGE: (
            <MonthRangePicker
                from={state.from}
                to={state.to}
                onDateClick={handlers.MONTH_RANGE!}
                fromDate={fromDate}
                toDate={toDate}
            />
        ),
        YEAR: (
            <YearPicker
                from={state.from}
                to={state.to}
                onDateClick={handlers.YEAR!}
                fromDate={fromDate}
                toDate={toDate}
            />
        ),
    };

    return (
        <Popover
            placement={placement}
            target={target}
            isOpen
            toggle={toggle}
            className="DateRangePickerPopover"
            modifiers={[
                {
                    ...preventOverflow,
                    options: {
                        altAxis: true,
                    },
                } as PreventOverflowModifier,
            ]}
            boundariesElement={document.querySelector('.main-container') ?? undefined}
            trigger="legacy"
            flip={false}
            hideArrow
        >
            <div className="d-flex">
                {!!ranges && (
                    <DateRangePickerSidebar
                        ranges={ranges}
                        range={state.preset}
                        onRangeClick={handlePresetSelect}
                    />
                )}
                <div>
                    <div className="d-flex pt-3 px-3 align-items-center">
                        <DateInput
                            style={{ width: 0 }}
                            className="flex-grow-1"
                            value={state.from ? formatDate(moment(state.from)) : ''}
                            onBlur={handleFromBlur}
                            disabled={forceRangeSelection || mode !== 'DEFAULT'}
                        />

                        <div className="mx-3">—</div>
                        <DateInput
                            style={{ width: 0 }}
                            className="flex-grow-1"
                            value={state.to ? formatDate(moment(state.to)) : ''}
                            onBlur={handleToBlur}
                            disabled={noEndDate || forceRangeSelection || mode !== 'DEFAULT'}
                        />
                    </div>
                    {pickerElementMap[mode]}
                    <DateRangerPickerActions
                        onCancel={onCancel}
                        onReset={() =>
                            setState({
                                ...state,
                                preset: undefined,
                                from: undefined,
                                to: undefined,
                            })
                        }
                        onApply={applyChanges}
                        disabled={!canApplyChanges()}
                    >
                        {optionalEndDate && (
                            <div className="d-flex align-items-center DateRangePickerPopover-noDateContainer">
                                <Switch input={{ value: noEndDate, onChange: handleNoEndChange }} />
                                <div className="ms-3">No end date</div>
                            </div>
                        )}
                    </DateRangerPickerActions>
                </div>
            </div>
        </Popover>
    );
};

export default DateRangePickerPopover;
