import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { ComponentsProps } from 'platform/analytics/modes/Analytics';
import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import Overlay from 'platform/common/components/Overlay/Overlay';
import { layoutSelectors } from 'platform/common/ducks/layout.duck';
import { useFeature } from 'platform/common/hooks/useFeature';
import useToggle from 'platform/common/hooks/useToggle';
import { CustomReport } from 'platform/customReports/customReport.types';
import { ReportComponentState } from '../analytics.types';
import ReportComponentActions from './ReportComponentActions/ReportComponentActions';
import ReportComponentView from './ReportComponentView';
import ReportAiSummaryContainer, {
    ReportAiSummaryContainerProps,
} from './ReportTableContainer/ReportAiSummaryContainer/ReportAiSummaryContainer';

const ANALYTICS_OVERLAY_Z_INDEX = 1050;
const ANALYTICS_COMPONENT_IN_EDIT_Z_INDEX = 1051;

interface Props {
    report?: CustomReport;
    component: ReportComponentState;
    editMode: boolean;
    index: number;
    itemCount: number;
    componentsProps?: ComponentsProps;
}

const ReportComponentPlaceholder = ({
    report,
    component,
    editMode,
    index,
    itemCount,
    componentsProps,
}: Props) => {
    const dispatch = useDispatch();
    const isMobile = useSelector(layoutSelectors.isMobile);
    const nextComponentId = useSelector(analyticsSelectors.nextId);
    const canEdit = useFeature('ROLE_ADMINPANEL_EDIT');
    const [isHighlighted, toggleHighlight] = useToggle(false);

    const handleComponentCopy = () => {
        dispatch(
            analyticsSettingsActions.addComponentToIndex(
                {
                    ...component,
                    id: nextComponentId,
                },
                index + 1
            )
        );
        toggleHighlight();
    };

    const reportEditActions = (
        <ReportComponentActions
            index={index}
            isHighlighted={isHighlighted}
            ANALYTICS_OVERLAY_Z_INDEX={ANALYTICS_OVERLAY_Z_INDEX}
            toggleHighlight={toggleHighlight}
            handleComponentCopy={handleComponentCopy}
            component={component}
            componentsProps={componentsProps}
            canEdit={canEdit}
            itemCount={itemCount}
        />
    );

    return (
        <div
            style={{
                gridColumnEnd: isMobile ? undefined : `span ${component.placement.width}`,
            }}
            className={classNames(
                isMobile && 'mb-3',
                isHighlighted && 'border-primary rounded',
                'ReportComponentPlaceholder d-flex flex-column position-relative'
            )}
        >
            <ReportAiSummaryContainer showContainer={component?.type === 'TABLE'}>
                {(summaryProps: ReportAiSummaryContainerProps) => {
                    const reportComponent = (
                        <ReportComponentView
                            report={report}
                            component={component}
                            componentsProps={componentsProps}
                            editMode={editMode}
                            summaryProps={summaryProps}
                        />
                    );

                    if (component.type === 'SPACER') {
                        return (
                            <>
                                {isHighlighted && <Overlay style={{ zIndex: ANALYTICS_OVERLAY_Z_INDEX }} />}
                                <div
                                    className="d-flex justify-content-between w-100 rounded"
                                    style={{
                                        background: isHighlighted ? '#fff' : 'none',
                                        zIndex: isHighlighted
                                            ? ANALYTICS_COMPONENT_IN_EDIT_Z_INDEX
                                            : undefined,
                                    }}
                                >
                                    {reportComponent}

                                    <div>
                                        {editMode && (
                                            <div className="d-flex outline-none justify-content-end pe-2">
                                                {reportEditActions}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <InsetBox>
                                    <></>
                                </InsetBox>
                            </>
                        );
                    }

                    return (
                        <>
                            {isHighlighted && <Overlay style={{ zIndex: ANALYTICS_OVERLAY_Z_INDEX }} />}
                            <InsetBox
                                className="d-flex flex-column h-100"
                                style={{
                                    zIndex: isHighlighted ? ANALYTICS_COMPONENT_IN_EDIT_Z_INDEX : undefined,
                                }}
                            >
                                {editMode && (
                                    <div className="d-flex outline-none justify-content-end border-bottom pe-2">
                                        {reportEditActions}
                                    </div>
                                )}
                                {reportComponent}
                            </InsetBox>
                        </>
                    );
                }}
            </ReportAiSummaryContainer>
        </div>
    );
};

export default ReportComponentPlaceholder;
