import { useState } from 'react';
import { uploadImage } from 'platform/adminPanel/services/imageUpload.service';
import { FileUploadProgressProps } from 'platform/common/hooks/useProgress';
import { FileInfo } from 'platform/common/utils/file.util';
import { dataUrlToBase64 } from 'platform/common/utils/url.util';

interface Props {
    file: FileInfo;
    onUpload: (url: string) => void;
    rescaleToSize?: {
        width: number;
        height: number;
    };
    uploadProgressProps?: FileUploadProgressProps;
}

export const useImageUpload = () => {
    const [uploadingImage, setUploading] = useState(false);

    const handleImageUpload = ({ file, rescaleToSize, onUpload, uploadProgressProps }: Props) => {
        setUploading(true);
        uploadImage(
            {
                content: dataUrlToBase64(file.content as string),
                fileName: file.name,
                contentType: file.type,
                rescaleToSize,
            },
            uploadProgressProps?.onUploadProgress
        )
            .then(onUpload)
            .finally(() => {
                uploadProgressProps?.resetProgress();
                setUploading(false);
            });
    };

    return {
        uploadingImage,
        handleImageUpload,
    };
};
