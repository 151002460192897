import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { offset } from '@popperjs/core';
import { OffsetModifier } from '@popperjs/core/lib/modifiers/offset';
import classNames from 'classnames';
import { orderBy, uniq } from 'lodash-es';
import { AdvertiserSummary } from 'platform/advertisers/advertiser.types';
import { getAdvertiserSummaries } from 'platform/advertisers/services/advertiser.service';
import EnvelopeIcon from 'platform/assets/icons/ENVELOPE.svg';
import { isDefined } from 'platform/common/common.types';
import CounterBadge from 'platform/common/components/CounterBadge/CounterBadge';
import Spinner from 'platform/common/components/Spinner/Spinner';
import { activeAdvertiserActions } from 'platform/common/ducks/activeAdvertiser.duck';
import { usePolling } from 'platform/common/hooks/usePolling';
import { usePromise } from 'platform/common/hooks/usePromise';
import { OBSERVER_MESSAGE_CENTER } from 'platform/observerMessageCenter/observerMessageCenter.navigation';
import {
    countObserverMessages,
    countObserverMessagesByAdvertiser,
} from 'platform/observerMessageCenter/observerMessageCenter.service';
import { ObserverMessageFilter } from 'platform/observerMessageCenter/observerMessageCenter.types';
import './ObserverMessageNotificationPanel.scss';

interface AdvertiserStats {
    advertiser: AdvertiserSummary;
    messageCount: number;
}

const ObserverMessageNotificationPanel = () => {
    const [open, setOpen] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const dispatch = useDispatch();
    const filter: ObserverMessageFilter = { status: ['NEW'] };

    usePolling(() => countObserverMessages(filter).then(setTotalCount), {
        interval: 5 * 60_000, // every 5 minutes
        immediate: true,
    });

    const [{ data: advertiserStats, loading }] = usePromise<AdvertiserStats[]>(
        [],
        async () => {
            if (!open) return [];

            const counts = await countObserverMessagesByAdvertiser(filter);
            const advertisers = await getAdvertiserSummaries({
                ids: uniq(counts.map((c) => c.advertiserId)),
            });
            const stats = counts
                .map(({ advertiserId, count }) => {
                    const advertiser = advertisers.find((a) => a.id === advertiserId);
                    return advertiser ? { advertiser, messageCount: count } : undefined;
                })
                .filter(isDefined);

            return orderBy(stats, [(s) => s.messageCount, (s) => s.advertiser.name], ['desc', 'asc']);
        },
        [open]
    );

    return (
        <Dropdown className="ObserverMessageNotificationPanel" isOpen={open} toggle={() => setOpen(!open)}>
            <DropdownToggle className="nav-link dropdown-toggle">
                <img src={EnvelopeIcon} alt="Envelope icon" />
                {totalCount > 0 && (
                    <CounterBadge count={totalCount} className="ObserverMessageNotificationPanel-counter" />
                )}
            </DropdownToggle>
            <DropdownMenu
                end
                modifiers={[{ ...offset, options: { offset: [0, 18] } } as OffsetModifier]}
                className={classNames('pb-0', open && 'show')}
            >
                <div className="ObserverMessageNotificationPanel-content d-flex flex-column p-4 overflow-auto">
                    <h5 className="d-flex justify-content-between">
                        <b>DeeplyAI Cockpit</b>
                        <span>New messages</span>
                    </h5>
                    <hr />
                    {!advertiserStats.length && (
                        <div className="text-center">
                            <img src={EnvelopeIcon} alt="icon" width="100" className="opacity-25" />
                            <div>{loading ? <Spinner /> : 'All clear!'}</div>
                        </div>
                    )}
                    {advertiserStats.map(({ advertiser, messageCount }) => (
                        <Link
                            key={advertiser.id}
                            className="d-flex align-items-center justify-content-between gap-1 my-2"
                            to={OBSERVER_MESSAGE_CENTER.path ?? ''}
                            onClick={() => {
                                dispatch(activeAdvertiserActions.changeAdvertisers([advertiser.id]));
                                setOpen(false);
                            }}
                        >
                            <div className="d-flex align-items-center gap-2 flex-fit">
                                <div style={{ height: 20, width: 20 }}>
                                    {advertiser.iconUrl && (
                                        <img className="w-100 h-100" src={advertiser.iconUrl} alt="icon" />
                                    )}
                                </div>
                                <span className="ObserverMessageNotificationPanel-advertiserName">
                                    {advertiser.name}
                                </span>
                            </div>
                            <CounterBadge count={messageCount} />
                        </Link>
                    ))}
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

export default ObserverMessageNotificationPanel;
