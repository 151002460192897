import classNames from 'classnames';
import { urlHttps, Validator } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';

interface Props {
    value: string | undefined;
    name: string;
    validate?: Validator | Validator[];
}

const FormInputWithUrlPreview = ({ value, name, validate }: Props) => {
    const isValidUrl = !urlHttps(value);
    return (
        <FormInput
            name={name}
            rightAddOn={{
                className: classNames(
                    'fa fa-external-link-alt',
                    isValidUrl ? 'cursor-pointer' : 'text-muted'
                ),
                title: '',
                onClick: () => {
                    if (isValidUrl) {
                        window.open(value, '_blank');
                    }
                },
            }}
            validate={validate}
        />
    );
};
export default FormInputWithUrlPreview;
