import { ReactNode } from 'react';
import { pick } from 'lodash-es';
import { FilterOperator, ReportFilterWithOperator } from 'platform/analytics/analytics.types';
import { PopoverContainerType } from 'platform/common/components/Popover/Popover';
import PopoverDropdown from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import './FilterOperatorDropdown.scss';

type Props = {
    filter: ReportFilterWithOperator;
    supportedOperators: FilterOperator[];
    onChange: (filter: ReportFilterWithOperator) => void;
    container?: PopoverContainerType;
};

const OPERATOR_LABELS: Record<FilterOperator, ReactNode> = {
    IS: <span className="font-xl">=</span>,
    IS_NOT: <span className="font-xl">≠</span>,
    CONTAINS: <span className="font-xs">contains</span>,
    CONTAINS_NOT: <span className="font-xs">does not contain</span>,
    GT: <span className="font-xl">&gt;</span>,
    GTE: <span className="font-xl">≥</span>,
    LT: <span className="font-xl">&lt;</span>,
    LTE: <span className="font-xl">≤</span>,
};

const FilterOperatorDropdown = ({ filter, supportedOperators, onChange, container }: Props) => (
    <PopoverDropdown
        togglerClassName="FilterOperatorDropdown-toggler"
        popoverClassName="FilterOperatorDropdown-popover"
        placement="bottom"
        container={container}
        toggler={OPERATOR_LABELS[filter.operator ?? 'IS']}
        items={Object.entries(pick(OPERATOR_LABELS, supportedOperators)).map(([operator, label]) => ({
            label,
            action: () => onChange({ ...filter, operator: operator as FilterOperator }),
        }))}
    />
);

export default FilterOperatorDropdown;
