import { sortBy } from 'lodash-es';
import { useChatMessageContext } from 'platform/chat/ChatProviders/ChatMessageProvider';
import { ChatMessage, ContextPrompt, SubmitFunc } from 'platform/chat/chat.types';
import IconButton from 'platform/common/components/IconButton/IconButton';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import { PopoverDropdownItem } from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import { toastSuccess } from 'platform/common/utils/toast.util';
import ChatMessageFeedback from './ChatMessageFeedback';

const contextPromptsToItems = (
    contextPrompts: ContextPrompt[],
    content: string,
    onContentSubmit?: SubmitFunc
): PopoverDropdownItem[] =>
    sortBy(contextPrompts, (p) => p.order).map((p) => ({
        label: p.name,
        action: () =>
            onContentSubmit?.([
                { text: [p.prompt, content].join('\n'), prompt: { name: p.name, id: p.id, type: 'CONTEXT' } },
            ]),
    }));

interface Props {
    messageData: ChatMessage;
    contextPrompts: ContextPrompt[];
    isLatestAssistantMessage: boolean;
}

const ChatMessageActions = ({
    isLatestAssistantMessage,
    messageData: { role, contentParts },
    contextPrompts,
}: Props) => {
    const { submit, messages } = useChatMessageContext();
    const assistant = role === 'assistant';
    const content = contentParts?.[0]?.content;
    const latestPredefinedPrompt = messages
        ? [...messages]?.reverse().find((m) => m.role === 'user' && m.prompt?.type === 'PREDEFINED')?.prompt
        : undefined;

    return (
        <div className="ms-auto d-flex align-self-start align-items-center mt-2">
            <IconButton
                disabled={!content}
                className="me-2"
                onClick={() => {
                    navigator.clipboard.writeText(content);
                    toastSuccess('Chat message copied to clipboard.');
                }}
            >
                <i className="far fa-copy text-light-slate-gray fs-5" />
            </IconButton>
            {isLatestAssistantMessage && !!latestPredefinedPrompt && !!content && (
                <ChatMessageFeedback promptId={latestPredefinedPrompt.id} />
            )}
            {!!contextPrompts.length && assistant && (
                <InlineDropdown
                    togglerClassName="border-0 p-0"
                    items={contextPromptsToItems(contextPrompts, content, submit)}
                />
            )}
        </div>
    );
};

export default ChatMessageActions;
