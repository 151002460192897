import { shallowEqual, useSelector } from 'react-redux';
import { ReportFilter, LogBookState } from 'platform/analytics/analytics.types';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import ReportLogBook from 'platform/analytics/reportComponents/ReportLogBook/ReportLogBook';
import { LogBookEntry } from 'platform/campaign/logBook/logBook.types';
import { LogbookFilter, searchLogBook } from 'platform/campaign/logBook/logbook.service';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import Pagination from 'platform/common/components/Pagination/Pagination';
import { usePagination } from 'platform/common/components/Pagination/usePagination';
import SearchNotification from 'platform/common/components/SearchNotification/SearchNotification';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { dateFilterSelectors } from 'platform/common/ducks/dateFilter.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import { resolveCustomDateRange } from 'platform/common/utils/date.util';
import { insertInteractionFilters } from '../LogBookLink';
import { getComponentInteractions } from '../useReport';

interface Props {
    componentState: LogBookState;
}

const FILTERS_MAP: Partial<Record<keyof LogBookState, string[]>> = {
    productCategory: ['campaign_product_category', 'strategy_product_category', 'product_category'],
    countryLabel: ['country_labels'],
    productLabel: ['campaign_product_label', 'strategy_product_label', 'product_label'],
    productSubLabel: ['campaign_product_sub_label', 'strategy_product_sub_label'],
    flightLabel: ['campaign_flight_label', 'strategy_flight_label'],
};

const resolveFilters = (
    localFilters: Record<string, string[] | undefined>,
    globalFilters: ReportFilter[]
) => {
    if (Object.values(localFilters).some((v) => !!v?.length)) {
        return localFilters;
    }

    return Object.keys(localFilters).reduce(
        (acc, key: keyof LogBookState) => ({
            ...acc,
            [key]:
                globalFilters
                    .filter((f) => FILTERS_MAP[key]?.includes(f.key))
                    .map((f) => f.values)
                    .flat() ?? localFilters[key],
        }),
        {}
    );
};

const ReportLogBookContainer = ({
    componentState: {
        category,
        countryLabel,
        productCategory,
        productLabel,
        productSubLabel,
        flightLabel,
        logNoteExpanded,
        customDateRange,
    },
}: Props) => {
    const advertiserId = useTypedSelector(activeAdvertiserSelectors.id);
    const periods = useTypedSelector(dateFilterSelectors.periods, shallowEqual);
    const globalFilters = useTypedSelector(analyticsSelectors.filters);
    const { components } = useSelector(analyticsSelectors.settings);

    const [{ data: logs, loading: logsLoading, error }, refetchReport] = usePromise<LogBookEntry[]>(
        [],
        () => {
            const filters = resolveFilters(
                { countryLabel, productCategory, productLabel, productSubLabel, flightLabel },
                globalFilters
            );
            const { primaryFrom, primaryTo } = resolveCustomDateRange(periods, customDateRange);
            const interactions = getComponentInteractions(components);
            const filter: LogbookFilter = {
                category,
                dateFrom: primaryFrom,
                dateTo: primaryTo,
                advertiserId,
                ...filters,
            };
            const filterWithInteractions = insertInteractionFilters(filter, interactions);

            return searchLogBook(filterWithInteractions);
        },
        [
            advertiserId,
            category,
            productCategory,
            productLabel,
            productSubLabel,
            flightLabel,
            countryLabel,
            periods.primaryFrom,
            periods.primaryTo,
            globalFilters,
            customDateRange,
            components,
        ]
    );

    const { pageData, ...paginationProps } = usePagination({
        data: logs,
        pageSizeOptions: [1],
    });

    const showPagination = pageData.length !== logs.length;

    return (
        <>
            {logsLoading && <OverlayLoader />}
            {!!error && <ErrorMessage error={error} onRetry={refetchReport} />}
            {!pageData.length && !logsLoading && !error && (
                <SearchNotification header="No results found" text="Nothing matches your filters" />
            )}
            {!!pageData.length &&
                pageData.map((log) => (
                    <ReportLogBook
                        key={log.id}
                        log={log}
                        noteDefaultExpanded={logNoteExpanded}
                        className={showPagination ? 'position-relative' : undefined}
                    />
                ))}
            {showPagination && (
                <Pagination
                    page={paginationProps.pageNumber}
                    pages={paginationProps.pageCount}
                    {...paginationProps}
                />
            )}
        </>
    );
};

export default ReportLogBookContainer;
