import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as CSV from 'csv-string';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import { groupBy, isEmpty, uniq } from 'lodash-es';
import { generateOlapReport } from 'platform/analytics/analytics.service';
import { ReportFilter, Report } from 'platform/analytics/analytics.types';
import { getFilterNumberValue } from 'platform/analytics/analytics.util';
import { IOScriptExportHeader, sortedHeaderArray } from 'platform/analytics/ioExportScript.constant';
import { generateIOScripts } from 'platform/campaign/campaign/services/campaign.service';
import { assertIsDefined } from 'platform/common/common.assert';
import { isDefined, isNotEmpty, TableCell } from 'platform/common/common.types';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import { BOM } from 'platform/common/components/DataExportButton/dataExport';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import LabelWithSubtext from 'platform/common/components/LabelWithSubtext/LabelWithSubtext';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { usePromise } from 'platform/common/hooks/usePromise';
import { DATE_RANGES } from 'platform/common/utils/date.util';
import { removeEmptyKeys } from 'platform/common/utils/filter.util';
import { UnitIOScript } from 'platform/units/units.type';

type Props = {
    toggle: () => void;
    filters: ReportFilter[];
};

type Unit = {
    unitName: string;
    unitId: number;
};

type VendorUnit = {
    vendorName: string;
    vendorIcon: string;
    units: Unit[];
};

type DataRow = {
    campaign_id: number;
    campaign_name: string;
    vendor_name: string;
    vendor_icon_url: string;
    unit_id: number;
    unit_marker_targeting: string;
    unit_creative_targeting_name: string;
    strategy_name: string;
};

enum TrackerType {
    JAVASCRIPT = 'JAVASCRIPT',
    IFRAME = 'IFRAME',
    VIEW = 'VIEW',
    CLICK = 'CLICK',
    VAST = 'VAST',
}

const selectAllUnits = (
    units: Unit[],
    checked: boolean,
    dispatchState: Dispatch<SetStateAction<number[]>>
) => {
    const ids = units.map((u) => u.unitId);
    if (checked) {
        dispatchState((allScripts) => uniq([...allScripts, ...ids]));
    } else {
        dispatchState((allScripts) => allScripts.filter((s) => !ids.includes(s)));
    }
};

const selectUnit = (unitId: number, checked: boolean, dispatch: Dispatch<SetStateAction<number[]>>) => {
    if (checked) {
        dispatch((allScripts) => [...allScripts, unitId]);
    } else {
        dispatch((allScripts) => allScripts.filter((s) => s !== unitId));
    }
};

const isChecked = (units: Unit[], vendorName: string, unitId: number, selectedScripts: number[]) => {
    if (vendorName) {
        const selectedV = selectedScripts.map((s) => units.find((u) => u.unitId === s)).filter(isDefined);
        return !!selectedV.length && selectedV.length === units.length;
    }
    return selectedScripts.includes(unitId);
};

const isIndeterminate = (units: Unit[], vendorName: string, selectedScripts: number[]) => {
    if (vendorName) {
        const selectedV = selectedScripts.map((s) => units.find((u) => u.unitId === s)).filter(isDefined);
        return !!selectedV.length && selectedV.length < units.length;
    }
    return false;
};

const makeUnitName = (campaignName?: string, creativeTargetingName?: string, markerTargetingName?: string) =>
    `${campaignName} | ${creativeTargetingName || ''} | ${markerTargetingName || ''}`;

const RedirectScriptsModal = ({ toggle, filters }: Props) => {
    const [selectedJSScripts, selectJSScripts] = useState<number[]>([]);
    const [selectedIframeScripts, selectIframeScripts] = useState<number[]>([]);
    const [selectedViewScripts, selectViewScripts] = useState<number[]>([]);
    const [selectedClickScripts, selectClickScripts] = useState<number[]>([]);
    const [selectedVastScripts, selectVastScripts] = useState<number[]>([]);

    const { from, to } = DATE_RANGES.THIS_MONTH.getRange();

    const advertiserId = getFilterNumberValue(filters, 'advertiser_id');
    assertIsDefined(advertiserId, 'filter with key advertiser_id');

    const selectScripts = (id: number) => ({
        [TrackerType.CLICK]: selectedClickScripts.includes(id) || undefined,
        [TrackerType.VIEW]: selectedViewScripts.includes(id) || undefined,
        [TrackerType.JAVASCRIPT]: selectedJSScripts.includes(id) || undefined,
        [TrackerType.IFRAME]: selectedIframeScripts.includes(id) || undefined,
        [TrackerType.VAST]: selectedVastScripts.includes(id) || undefined,
    });

    const [{ loading, data, error }] = usePromise<Report<DataRow> | undefined>(
        undefined,
        () =>
            generateOlapReport({
                templateId: 'campaign_list',
                dimensions: [
                    'campaign_id',
                    'campaign_name',
                    'strategy_name',
                    'vendor_name',
                    'unit_id',
                    'vendor_icon_url',
                    'unit_marker_targeting',
                    'unit_creative_targeting_name',
                ],
                metrics: [],
                dimensionFilters: [...filters, { key: 'integration_type', values: ['REDIRECT'] }],
                from: from.toISOString(),
                to: to.toISOString(),
            }),
        [filters]
    );

    const reportByVendor = groupBy(data?.rows, (r) => r.vendor_name); // sort here

    const vendorUnitTree: VendorUnit[] = Object.keys(reportByVendor)
        .filter((v) => v !== 'null')
        .map((vendor) => ({
            vendorName: vendor,
            vendorIcon: reportByVendor[vendor][0]?.vendor_icon_url,
            units: reportByVendor[vendor]
                .filter((v) => v.unit_id)
                .map((v) => ({
                    unitName: makeUnitName(
                        v.campaign_name,
                        v.unit_creative_targeting_name,
                        v.unit_marker_targeting
                    ),
                    unitId: v.unit_id,
                })),
        }))
        .filter((v) => !isEmpty(v.units));

    const additionalOlapData = (scriptEntity: UnitIOScript) => {
        const {
            vendor_name,
            campaign_name,
            strategy_name,
            unit_marker_targeting,
            unit_creative_targeting_name,
        } = data?.rows.find((row) => row.unit_id === scriptEntity.unitId) || {};
        return {
            vendorName: vendor_name,
            campaignName: campaign_name,
            strategyName: strategy_name,
            unitName: makeUnitName(campaign_name, unit_creative_targeting_name, unit_marker_targeting),
        };
    };

    const onApply = async () => {
        const zip = new JSZip();
        await Promise.all([
            generateIOScripts({
                unitIds: uniq(
                    [
                        selectedJSScripts,
                        selectedIframeScripts,
                        selectedViewScripts,
                        selectedClickScripts,
                        selectedVastScripts,
                    ]
                        .flat()
                        .filter(isDefined)
                ),
                advertiserId,
            }),
            generateIOScripts({
                unitIds: uniq(data?.rows.map((r) => r.unit_id)).filter(isNotEmpty) || [],
                advertiserId,
            }),
        ]).then(([scripts, allScripts]) => {
            const selectedVendorEntities = scripts.map((s) => {
                const scriptTypes = selectScripts(s.unitId);

                return {
                    ...additionalOlapData(s),
                    creativeName: s.creativeName,
                    creativeSize: s.creativeSize,
                    javascript: scriptTypes[TrackerType.JAVASCRIPT] && s.javascript,
                    iframe: scriptTypes[TrackerType.IFRAME] && s.iframe,
                    clickTracker: scriptTypes[TrackerType.CLICK] && s.clickTracker,
                    viewTracker: scriptTypes[TrackerType.VIEW] && s.viewTracker,
                    vast2: scriptTypes[TrackerType.VAST] && s.vast2,
                    vast3: scriptTypes[TrackerType.VAST] && s.vast3,
                    vast4: scriptTypes[TrackerType.VAST] && s.vast4,
                };
            });
            const scriptsByVendor = groupBy(selectedVendorEntities, (s) => s.vendorName);

            if (Object.keys(scriptsByVendor).length > 1) {
                const allScriptsWithOlapData = allScripts.map((s) => ({
                    ...additionalOlapData(s),
                    creativeName: s.creativeName,
                    creativeSize: s.creativeSize,
                    javascript: s.javascript,
                    iframe: s.iframe,
                    clickTracker: s.clickTracker,
                    viewTracker: s.viewTracker,
                    vast2: s.vast2,
                    vast3: s.vast3,
                    vast4: s.vast4,
                }));

                const headers = isEmpty(allScriptsWithOlapData)
                    ? []
                    : Object.keys(allScriptsWithOlapData[0]).map(
                          (key: keyof typeof IOScriptExportHeader) => IOScriptExportHeader[key]
                      );

                zip.file(
                    'overview.csv',
                    new Blob(
                        [BOM, CSV.stringify([headers, ...allScriptsWithOlapData.map(Object.values)], ';')],
                        {
                            type: 'application/octet-stream',
                        }
                    )
                );
            }

            Object.keys(scriptsByVendor).forEach((v) => {
                const headers =
                    scriptsByVendor[v] &&
                    Object.keys(
                        scriptsByVendor[v].reduce(
                            (acc, script) => Object.assign(acc, removeEmptyKeys(script)),
                            {}
                        )
                    );

                const body = scriptsByVendor[v].map((s) =>
                    Object.keys(s).reduce((bodyObj, key) => {
                        if (headers.includes(key)) {
                            return { ...bodyObj, [key]: s[key as keyof {}] };
                        }
                        return bodyObj;
                    }, {})
                );

                zip.file(
                    `${v || ''}_scripts.csv`,
                    new Blob(
                        [
                            BOM,
                            CSV.stringify(
                                [
                                    sortedHeaderArray
                                        .filter((header) => headers.includes(header))
                                        .map(
                                            (key: keyof typeof IOScriptExportHeader) =>
                                                IOScriptExportHeader[key]
                                        ),
                                    ...body.map(Object.values),
                                ],
                                ';'
                            ),
                        ],
                        {
                            type: 'application/octet-stream',
                        }
                    )
                );
            });

            return zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
                FileSaver.saveAs(content, 'scripts.zip');
            });
        });
    };

    const checkboxColumn = (
        header: string,
        selectedScripts: number[],
        dispatch: Dispatch<SetStateAction<number[]>>
    ) => ({
        Header: header,
        headerClassName: 'cell-align-center',
        className: 'cell-align-center',
        Cell: ({ original: { unitId, vendorName, units } }: TableCell<VendorUnit & Unit>) => (
            <input
                type="checkbox"
                className="position-static m-0"
                checked={isChecked(units, vendorName, unitId, selectedScripts)}
                onChange={({ target: { checked } }) => {
                    if (vendorName) {
                        selectAllUnits(units, checked, dispatch);
                    } else {
                        selectUnit(unitId, checked, dispatch);
                    }
                }}
                ref={(ref) => {
                    if (ref) {
                        // eslint-disable-next-line no-param-reassign
                        ref.indeterminate = isIndeterminate(units, vendorName, selectedScripts);
                    }
                }}
            />
        ),
    });

    return (
        <Modal isOpen toggle={toggle} style={{ maxWidth: '95vw' }}>
            <ModalHeader toggle={toggle}>
                <div className="d-flex align-items-center">
                    Please choose items to export:
                    <div className="d-flex align-items-center ms-2" />
                </div>
            </ModalHeader>
            <ModalBody style={{ maxHeight: '80vh', overflow: 'scroll', paddingTop: '4vh' }}>
                <FormattedTable
                    isTree
                    NoDataComponent={error ? () => <ErrorMessage error={error} /> : undefined}
                    data={vendorUnitTree || []}
                    loading={loading}
                    childrenOffset={0}
                    childrenLoaders={[{ field: 'units' }]}
                    columns={[
                        {
                            Header: 'Vendor',
                            Cell: ({ original }: TableCell<VendorUnit & Unit>) => {
                                const { vendorName, vendorIcon } = original;

                                return vendorName ? (
                                    <div className="d-flex">
                                        {vendorIcon && (
                                            <img
                                                alt={vendorName}
                                                src={vendorIcon}
                                                className="me-1"
                                                height="16"
                                            />
                                        )}
                                        {vendorName}
                                    </div>
                                ) : null;
                            },
                        },
                        {
                            Header: 'Unit',
                            Cell: ({ original }: TableCell<VendorUnit & Unit>) => {
                                const { unitName, vendorName, unitId } = original;
                                return !vendorName ? (
                                    <LabelWithSubtext
                                        label={unitName}
                                        subtext={DATA_TYPES.ID.format(unitId)}
                                    />
                                ) : null;
                            },
                            id: 'name',
                            minWidth: 400,
                        },
                        checkboxColumn('Javascript', selectedJSScripts, selectJSScripts),
                        checkboxColumn('iFrame', selectedIframeScripts, selectIframeScripts),
                        checkboxColumn('Click Tracker', selectedClickScripts, selectClickScripts),
                        checkboxColumn('View Tracker', selectedViewScripts, selectViewScripts),
                        checkboxColumn('VAST', selectedVastScripts, selectVastScripts),
                    ]}
                />
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between align-items-center">
                <div>
                    {!loading &&
                        `Export ${
                            uniq([
                                selectedJSScripts,
                                selectedIframeScripts,
                                selectedViewScripts,
                                selectedClickScripts,
                                selectedVastScripts,
                            ]).flat().length
                        } script(s) for the selected unit(s)`}
                </div>
                <div>
                    <Button className="ms-4 me-2" color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                    <AnimatedButton disabled={loading} onClick={onApply}>
                        Export
                    </AnimatedButton>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default RedirectScriptsModal;
