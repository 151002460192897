import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { FileInfo, readFileData } from 'platform/common/utils/file.util';
import { toastError } from 'platform/common/utils/toast.util';
import { useChatAgentContext } from './ChatProviders/ChatAgentProvider';
import { useChatMessageContext } from './ChatProviders/ChatMessageProvider';
import {
    CHAT_DEFAULT_FILE_ACCEPT_TYPES,
    CHAT_EXCLUDED_FILE_TYPES,
    FILE_UPLOAD_ERROR_MSG,
    MAX_CSV_FILE_SIZE,
    MAX_IMAGE_FILE_SIZE,
    MAX_FILE_SIZE_ERROR,
} from './chat.constants';

export const useChatFileUpload = () => {
    const { selectedAgent } = useChatAgentContext();
    const { submit } = useChatMessageContext();
    const [, dropRef] = useDrop({
        accept: [NativeTypes.FILE],
        drop: (dragItem: { files: File[] }) => {
            uploadFiles(dragItem.files);
        },
    });

    const uploadFiles = (
        files: File[],
        submitCallback?: (fileInfo: FileInfo) => void,
        bypassFileValidation = false
    ) => {
        readFileData({
            acceptedFiles: files,
            rejectedFiles: [],
            readFileAs: !CHAT_EXCLUDED_FILE_TYPES.includes(files[0].type) ? 'dataURL' : 'text',
            callback: (fileInfo) => {
                if (
                    !fileInfo ||
                    ![
                        ...(selectedAgent?.supportedFileTypes || []),
                        ...CHAT_DEFAULT_FILE_ACCEPT_TYPES,
                    ]?.includes(fileInfo.type)
                ) {
                    toastError({ message: FILE_UPLOAD_ERROR_MSG });
                } else if (
                    !bypassFileValidation &&
                    Number(fileInfo.size) >
                        (fileInfo.type.includes('csv') ? MAX_CSV_FILE_SIZE : MAX_IMAGE_FILE_SIZE)
                ) {
                    toastError({ message: MAX_FILE_SIZE_ERROR });
                } else {
                    const submitFn = submitCallback
                        ? () => submitCallback(fileInfo)
                        : () =>
                              submit([
                                  {
                                      text: fileInfo.content as string,
                                      fileName: fileInfo.name,
                                      fileType: fileInfo.type,
                                  },
                              ]);

                    submitFn();
                }
            },
        });
    };

    return { dropRef, uploadFiles };
};
