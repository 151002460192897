import { useState } from 'react';
import { isEmpty } from 'lodash-es';
import { fetchProduct } from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.service';
import { SelectItem } from 'platform/common/common.types';
import SelectOptionWithStatus from 'platform/common/components/Select/SelectOptionWithStatus';
import { OptionType } from 'platform/common/components/Select/select.types';
import { usePromise } from 'platform/common/hooks/usePromise';
import { namesToOptions } from 'platform/common/utils/option.util';
import FormCreatableSelect, {
    FormCreatableSelectProps,
} from 'platform/formik/FormCreatableSelect/FormCreatableSelect';

type Props = {
    advertiserId: number;
    productLabel?: SelectItem<string>;
    options?: FormCreatableSelectProps['options'];
} & Omit<FormCreatableSelectProps, 'options' | 'onNewOption'>;

const FormProductSubLabelSelect = ({
    advertiserId,
    productLabel,
    isLoading,
    options,
    isClearable = true,
    ...rest
}: Props) => {
    const [newSubLabel, setNewSubLabel] = useState<OptionType>({});
    const [{ data: fetchedOptions, loading }] = usePromise(
        [],
        async () => {
            if (!productLabel || productLabel.__isNew__) return [];
            if (options?.length || isLoading) return options ?? [];

            const product = await fetchProduct(advertiserId, productLabel.value);

            return namesToOptions(product.subLabels ?? []);
        },
        [advertiserId, productLabel?.value, options, isLoading]
    );

    return (
        <FormCreatableSelect
            {...rest}
            isClearable={isClearable}
            returnOnlyValues={false}
            options={!isEmpty(newSubLabel) ? [...fetchedOptions, newSubLabel] : fetchedOptions}
            isLoading={loading || isLoading}
            onNewOption={setNewSubLabel}
            menuStyle={{ minWidth: 400 }}
            formatOptionLabel={SelectOptionWithStatus}
            formatCreateLabel={(value) => (
                <span>
                    <b>Create sub-product</b>
                    {` "${value}"`}
                </span>
            )}
            placeholder="Select or enter..."
        />
    );
};

export default FormProductSubLabelSelect;
