import { MultiValueProps } from 'react-select';
import StatisticTooltip, { Tooltip } from 'platform/common/components/StatisticTooltip/StatisticTooltip';
import SelectSortableMultiValue from './SelectSortableMultiValue';

const SelectSortableMultiValueWithTooltip = <T extends Tooltip>({
    children,
    ...props
}: MultiValueProps<T>) => (
    <SelectSortableMultiValue<T>
        {...props}
        id={props.data.key}
        endAddon={<StatisticTooltip target={props.data.key} tooltip={props.data} />}
    >
        {children}
    </SelectSortableMultiValue>
);

export default SelectSortableMultiValueWithTooltip;
