import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Button } from 'reactstrap';
import { push } from 'redux-first-history';
import { flow, orderBy } from 'lodash-es';
import {
    ALGORITHM_AREA_LABELS,
    ALGORITHM_SECTION_LABELS,
} from 'platform/algorithms/algorithmManager.constant';
import { ALGORITHM_TEMPLATES } from 'platform/algorithms/algorithms.navigation';
import { listAlgorithmTemplates } from 'platform/algorithms/algorithms.service';
import { AlgorithmTemplate } from 'platform/algorithms/algorithms.types';
import AlgorithmTemplateCreate from 'platform/algorithms/components/AlgorithmTemplateForm/AlgorithmTemplateCreate';
import AlgorithmTemplateEdit from 'platform/algorithms/components/AlgorithmTemplateForm/AlgorithmTemplateEdit';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import Search from 'platform/common/components/Search/Search';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { Status, STATUS_OPTIONS } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { filterBySearchQuery } from 'platform/common/utils/search.util';

const { path } = ALGORITHM_TEMPLATES;

type QueryParams = {
    status: Status[];
    searchQuery: string;
};

const AlgorithmTemplateList = () => {
    const dispatch = useDispatch();
    const {
        queryParams: { status, searchQuery },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>({
        status: ['ACTIVE'],
        searchQuery: '',
    });
    const canEdit = useFeature('ROLE_ADMINPANEL_EDIT');

    const [{ data, loading }, refetch] = usePromise([], listAlgorithmTemplates, []);

    const columns: TableColumn<AlgorithmTemplate>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            width: 90,
            Cell: ({ original }) => <StatusBadge status={original.status} />,
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 90,
        },
        {
            Header: 'Section',
            accessor: 'display.section',
            minWidth: 300,
            Cell: ({ original }) => ALGORITHM_SECTION_LABELS[original.display.section] || null,
        },
        {
            Header: 'Area',
            accessor: 'display.area',
            minWidth: 300,
            Cell: ({ original }) => ALGORITHM_AREA_LABELS[original.display.area] || null,
        },
        {
            Header: 'Name',
            accessor: 'name',
            minWidth: 300,
        },
        {
            Header: 'Order',
            accessor: 'display.order',
            width: 60,
            sortMethod: (a, b) => a - b,
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 160,
            Cell: (row) => <UpdatedOn date={row.original.updatedOn} updatedBy={row.original.updatedBy} />,
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: ({ original }) => {
                const items: { label: string; action: () => void }[] = [
                    {
                        label: canEdit ? 'Edit' : 'View',
                        action: () => dispatch(push(`${ALGORITHM_TEMPLATES.path}/${original.id}`)),
                    },
                ];

                return <InlineDropdown items={items} />;
            },
        },
    ];

    const filteredData = flow(
        (filteredTemplates: AlgorithmTemplate[]) =>
            status
                ? filteredTemplates.filter((t: AlgorithmTemplate) => status.includes(t.status))
                : filteredTemplates,
        (filteredTemplates: AlgorithmTemplate[]) =>
            filterBySearchQuery(
                orderBy(filteredTemplates, [(d) => Number(d.display.order)], ['asc']),
                columns.map((column) => column.accessor),
                searchQuery
            )
    )(data);

    return (
        <>
            <HeaderContainer>
                <PageHeader title="Algorithm Templates" />
            </HeaderContainer>
            <BodyContainer helpKey="algorithm_template_list">
                <SelectWithAddon
                    name="Status"
                    className="mb-3"
                    value={status}
                    options={STATUS_OPTIONS}
                    isMulti
                    onChange={(value) => setQueryParams({ status: value })}
                />
                <FormattedTable
                    topToolbar={
                        <>
                            {canEdit && (
                                <Button
                                    className="me-2"
                                    color="primary"
                                    onClick={() => dispatch(push(`${path}/new`))}
                                >
                                    Add new
                                </Button>
                            )}
                            <div>
                                <Search
                                    value={searchQuery}
                                    minLength={0}
                                    onSearch={(value) => setQueryParams({ searchQuery: value })}
                                />
                            </div>
                        </>
                    }
                    columns={columns}
                    loading={loading}
                    data={filteredData}
                />
                <Routes>
                    <Route
                        path="new"
                        element={
                            <AlgorithmTemplateCreate
                                canEdit={canEdit}
                                redirectTo={returnUrl}
                                afterSubmit={refetch}
                            />
                        }
                    />
                    <Route
                        path=":id"
                        element={
                            <AlgorithmTemplateEdit
                                canEdit={canEdit}
                                redirectTo={returnUrl}
                                afterSubmit={refetch}
                            />
                        }
                    />
                </Routes>
            </BodyContainer>
        </>
    );
};

export default AlgorithmTemplateList;
