import { orderBy, uniq } from 'lodash-es';
import { DataSharingTemplate } from '../../adminPanel/adminPanel.types';
import { Advertiser, AdvertiserExternalIntegration } from '../../advertisers/advertiser.types';
import { Vendor } from '../../vendors/vendors.types';
import {
    DataSharingRuleSnippet,
    DataSharingRuleSnippetModel,
    Segment,
    SnippetType,
    StandardRuleSnippet,
    StandardRuleSnippetModel,
    ThirdPartyTrackingRule,
    ThirdPartyTrackingRuleModel,
} from '../advertiserTag.types';
import { FirePixelType } from '../constants/advertiserTag.constant';
import { RuleSource } from '../constants/ruleSource.constant';

const toStandardRuleSnippetModel = (snippet: StandardRuleSnippet): StandardRuleSnippetModel => ({
    snippetType: SnippetType.STANDARD,
    type: snippet.type,
    active: snippet.status === 'ACTIVE',
    codeIntegralPart: snippet.codeIntegralPart,
    vendorId: snippet.vendorId,
});

const getFloodlightConfigurationId = (integrations: AdvertiserExternalIntegration[]) => {
    const id = integrations.find((i) => i.externalSystem === 'GCM')?.accounts?.[0]?.floodlightConfigurationId;
    return id ? String(id) : undefined;
};

export const getGlobalVariableValue = (
    key: string,
    advertiser: Advertiser,
    snippet?: {
        globalVariableOverrides?: Record<string, string>;
        globalVariableDefaults?: Record<string, string>;
    }
): string => {
    const override = snippet?.globalVariableOverrides?.[key];

    if (override) {
        return override;
    }

    const value =
        key === 'floodlight_configuration_id'
            ? getFloodlightConfigurationId(advertiser.externalSystemIntegrations)
            : advertiser.additionalAttributes?.[key];

    return value ?? snippet?.globalVariableDefaults?.[key] ?? '';
};

const toDataSharingSnippetModel = (
    snippet: DataSharingRuleSnippet,
    templates: DataSharingTemplate[],
    advertiser: Advertiser
): DataSharingRuleSnippetModel => {
    const template = templates.find((t) => t.id === snippet.dataSharingTemplateId);
    const userVariableKeys: string[] = uniq(template?.variablesInTemplate || []);
    const globalVariableKeys: string[] = uniq(template?.globalVariablesInTemplate || []);
    return {
        snippetType: SnippetType.DATA_SHARING,
        vendorId: snippet.vendorId,
        dataSharingTemplateId: snippet.dataSharingTemplateId,
        active: snippet.status === 'ACTIVE',
        userVariables: userVariableKeys.map((key) => ({
            key,
            value: snippet.userVariables[key] || '',
        })),
        globalVariables: globalVariableKeys.map((key) => ({
            key,
            value: getGlobalVariableValue(key, advertiser, snippet),
            override: !!snippet.globalVariableOverrides?.[key],
        })),
    };
};

const toFormModel = (data: {
    advertiserTagId: number;
    rulesData: {
        rules: ThirdPartyTrackingRule[];
    };
    segments: Segment[];
    vendors: Vendor[];
    templates: DataSharingTemplate[];
    advertiser: Advertiser;
}) => {
    const { advertiserTagId, rulesData = { rules: [] }, segments, vendors, templates, advertiser } = data;
    const rules = rulesData.rules || [];
    const rulesGroups = {
        rules: [] as any[],
        system: [] as any[],
    };
    rules.forEach((rule) => {
        if (rule.ruleSource === RuleSource.RULE_BUILDER) {
            rulesGroups.rules.push({
                active: rule.active,
                tempId: rule.tempId,
                isNew: false,
                codeSnippets: (rule.codeSnippets as StandardRuleSnippet[]).map(toStandardRuleSnippetModel),
                includedSegments: rule.includedSegments,
                excludedSegments: rule.excludedSegments,
                ruleSource: rule.ruleSource,
                ruleName: rule.ruleName,
                firePixels: rule.includedSegments.length === 0 ? FirePixelType.ALWAYS : FirePixelType.CUSTOM,
                fireOncePerDay: rule.fireOncePerDay,
                priority: rule.priority,
            });
        } else if (rule.ruleSource === RuleSource.DATA_SHARING_TEMPLATE) {
            rulesGroups.rules.push({
                active: rule.active,
                tempId: rule.tempId,
                isNew: false,
                codeSnippets: (rule.codeSnippets as DataSharingRuleSnippet[]).map((snippet) =>
                    toDataSharingSnippetModel(snippet, templates, advertiser)
                ),
                includedSegments: rule.includedSegments,
                excludedSegments: rule.excludedSegments,
                ruleSource: rule.ruleSource,
                ruleName: rule.ruleName,
                description: rule.description,
                firePixels: rule.includedSegments.length === 0 ? FirePixelType.ALWAYS : FirePixelType.CUSTOM,
                fireOncePerDay: rule.fireOncePerDay,
                priority: rule.priority,
            });
        } else {
            rulesGroups.system.push({
                active: rule.active,
                tempId: rule.tempId,
                isNew: false,
                codeSnippets: (rule.codeSnippets as StandardRuleSnippet[]).map(toStandardRuleSnippetModel),
                includedSegments: rule.includedSegments,
                excludedSegments: rule.excludedSegments,
                ruleSource: rule.ruleSource,
                ruleName: rule.ruleName,
                firePixels: rule.includedSegments.length === 0 ? FirePixelType.ALWAYS : FirePixelType.CUSTOM,
                fireOncePerDay: rule.fireOncePerDay,
                priority: rule.priority,
            });
        }
    });

    rulesGroups.rules = orderBy(rulesGroups.rules, ['priority', 'ruleName'], ['desc', 'asc']);

    return {
        advertiserTagId,
        segments,
        vendors,
        templates,
        advertiser,
        ...rulesGroups,
    };
};

const toApi = (data: {
    advertiserTagId: string;
    rules: ThirdPartyTrackingRuleModel[];
    system: ThirdPartyTrackingRuleModel[];
}): { advertiserTagId: string; rules: ThirdPartyTrackingRule[] } => ({
    advertiserTagId: data.advertiserTagId,
    rules: data.rules.concat(data.system).map((rule) => ({
        active: rule.active,
        codeSnippets: rule.codeSnippets.map((snippet) =>
            snippet.snippetType === SnippetType.DATA_SHARING
                ? ({
                      snippetType: SnippetType.DATA_SHARING,
                      vendorId: snippet.vendorId,
                      dataSharingTemplateId: snippet.dataSharingTemplateId,
                      status: snippet.active ? 'ACTIVE' : 'INACTIVE',
                      userVariables: Object.fromEntries(snippet.userVariables.map((v) => [v.key, v.value])),
                      globalVariableOverrides: Object.fromEntries(
                          snippet.globalVariables.filter((v) => v.override).map((v) => [v.key, v.value])
                      ),
                      globalVariableDefaults: Object.fromEntries(
                          snippet.globalVariables.filter((v) => !v.override).map((v) => [v.key, v.value])
                      ),
                  } as DataSharingRuleSnippet)
                : ({
                      snippetType: SnippetType.STANDARD,
                      type: snippet.type,
                      status: snippet.active ? 'ACTIVE' : 'INACTIVE',
                      codeIntegralPart: snippet.codeIntegralPart,
                      vendorId: snippet.vendorId,
                  } as StandardRuleSnippet)
        ),
        includedSegments: rule.firePixels === FirePixelType.CUSTOM ? rule.includedSegments : [],
        excludedSegments: rule.firePixels === FirePixelType.CUSTOM ? rule.excludedSegments : [],
        ruleSource: rule.ruleSource,
        ruleName: rule.ruleName,
        description: rule.description,
        fireOncePerDay: rule.fireOncePerDay,
        priority: rule.priority ?? 0,
    })),
});

const ThirdPartyCodesMapper = {
    toFormModel,
    toApi,
};

export default ThirdPartyCodesMapper;
