import { useSelector } from 'react-redux';
import { authSelectors } from 'platform/app/ducks/auth.duck';

export type LogBookAccess = {
    isAdmin: boolean;
    isClient: boolean;
    isBudgetShiftingClient: boolean;
    isOptimizationClient: boolean;
    isAllowed: boolean;
};

export const useLogBookAccess = (): LogBookAccess => {
    const hasFeature = useSelector(authSelectors.hasFeatureFn);
    const isAdmin = hasFeature('LOGBOOK_ADMIN');
    const isClient = hasFeature('LOGBOOK_VIEW');
    const isBudgetShiftingClient = hasFeature('LOGBOOK_BUDGET_SHIFTING_VIEW');
    const isOptimizationClient = hasFeature('LOGBOOK_OPTIMIZATION_VIEW');
    return {
        isAdmin,
        isClient,
        isBudgetShiftingClient,
        isOptimizationClient,
        isAllowed: isAdmin || isClient || isBudgetShiftingClient || isOptimizationClient,
    };
};
