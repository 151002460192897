import { orderBy } from 'lodash-es';
import DataExportButton from 'platform/common/components/DataExportButton/DataExportButton';
import { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import SearchNotification from 'platform/common/components/SearchNotification/SearchNotification';
import { LogEntryTableView } from '../../logBook.types';
import { getCategoryLabel } from '../../logBook.utils';
import BodyCell from './BodyCell';
import HeadCell from './HeadCell';
import LogBookListTableSkeleton from './LogBookListTableSkeleton';
import LogEntryCard from './LogEntryCard';
import useTableSort from './useTableSort';
import './LogBookListTable.scss';

interface Props {
    advertiserId: number;
    logs: LogEntryTableView[];
    isLoading: boolean;
    canEdit: boolean;
    refetchLogs: () => void;
}

const LogBookListTable = ({ advertiserId, logs, isLoading, canEdit, refetchLogs }: Props) => {
    const { sort, handleSort } = useTableSort<LogEntryTableView>({
        column: 'timestamp',
        direction: 'desc',
    });
    const sortedLogs = orderBy(logs, [sort.column], [sort.direction]);

    return (
        <table className="LogBookListTable">
            <thead>
                <tr>
                    <HeadCell columnKey="timestamp" onClick={handleSort} sort={sort}>
                        Date
                    </HeadCell>
                    <HeadCell columnKey="category" onClick={handleSort} sort={sort}>
                        Category
                    </HeadCell>
                    <HeadCell columnKey="mediaplans" onClick={handleSort} sort={sort}>
                        Mediaplan
                    </HeadCell>
                    <HeadCell columnKey="mediaInsertions" onClick={handleSort} sort={sort}>
                        Media insertion
                    </HeadCell>
                    <HeadCell columnKey="channel" onClick={handleSort} sort={sort}>
                        Channel
                    </HeadCell>
                    <HeadCell columnKey="periodFrom" onClick={handleSort} sort={sort}>
                        Period
                    </HeadCell>
                    <HeadCell>
                        <DataExportButton
                            disabled={false}
                            columns={EXPORT_COLUMNS}
                            exportOptions={{ filename: `${advertiserId}_logBook`, loadData: () => logs }}
                        />
                    </HeadCell>
                </tr>
            </thead>
            <tbody>
                {isLoading && <LogBookListTableSkeleton />}
                {!isLoading && !logs.length && (
                    <tr>
                        <BodyCell colSpan={7}>
                            <SearchNotification
                                header="No results found"
                                text="Nothing matches your filters or search query"
                            />
                        </BodyCell>
                    </tr>
                )}
                {sortedLogs.map((log, i) => (
                    <LogEntryCard refetchLogs={refetchLogs} key={i} log={log} canEdit={canEdit} />
                ))}
            </tbody>
        </table>
    );
};

const EXPORT_COLUMNS: TableColumn<LogEntryTableView>[] = [
    {
        Header: 'Date',
        accessor: 'timestamp',
    },
    {
        Header: 'Category',
        accessor: 'category',
    },
    {
        Header: 'Mediaplan',
        accessor: (log) => log.mediaplans?.map((m) => m.name).join(', '),
    },
    {
        Header: 'Media Insertion',
        accessor: (log) => log.mediaInsertions?.map((m) => m.name).join(', '),
    },
    {
        Header: 'Channel',
        accessor: 'channel',
    },
    {
        Header: 'Period from',
        accessor: 'periodFrom',
    },
    {
        Header: 'Period to',
        accessor: 'periodTo',
    },
    {
        Header: 'Subject',
        accessor: getCategoryLabel,
    },
    { Header: 'Product', accessor: 'classification.productLabel' },
    { Header: 'Flight', accessor: 'classification.flightLabel' },
    { Header: 'Country', accessor: (log) => log.classification?.countryLabels?.join(', ') },
];

export default LogBookListTable;
