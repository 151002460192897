import { useState } from 'react';
import { round } from 'lodash-es';
import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { usePromise } from 'platform/common/hooks/usePromise';
import ChartTabs from 'platform/segments/components/SegmentCharts/ChartTabs';
import TimeToActionChart from 'platform/segments/components/SegmentCharts/TimeToActionChart';
import VisitFrequency from 'platform/segments/components/SegmentCharts/VisitFrequency/VisitFrequency';
import VisitTime from 'platform/segments/components/SegmentCharts/VisitTime/VisitTime';
import VisitedPages from 'platform/segments/components/SegmentCharts/VisitedPages/VisitedPages';
import { CHART_TYPES } from 'platform/segments/constants/chart.constants';
import { SegmentListItem } from 'platform/segments/segments.types';
import { fetchSegmentChartData } from 'platform/segments/services/segments.service';
import { calculateAverage, calculateChartData } from 'platform/segments/utils/cdpChart.util';
import './SegmentCharts.scss';

type Props = {
    advertiserId: number;
    segments: SegmentListItem[];
    loading: boolean;
};

const SegmentCharts = ({ advertiserId, segments, loading }: Props) => {
    const [activeTab, setActiveTab] = useState<string>(CHART_TYPES.VISIT_TIME);

    const [{ data: segmentChartData, loading: segmentChartDataLoading }] = usePromise(
        {},
        () => fetchSegmentChartData(advertiserId),
        [advertiserId]
    );

    if (loading || segmentChartDataLoading) return <Placeholder height="420px" />;

    const averageVisitFrequency =
        segmentChartData.visitFrequency.length &&
        round(calculateAverage(segmentChartData.visitFrequency, segmentChartData.allUniqueUsers));
    const averageVisitTime =
        segmentChartData.visitTime.length &&
        round(calculateAverage(segmentChartData.visitTime, segmentChartData.allUniqueUsers));
    const averageVisitedPages =
        segmentChartData.visitedPages.length &&
        round(calculateAverage(segmentChartData.visitedPages, segmentChartData.allUniqueUsers));

    return (
        <InsetBox className="custom-tabs p-3 h-100">
            <div className="ChartNavbar-navigation mb-3">
                <div className="ChartNavbar-tabs">
                    <ChartTabs
                        tabs={[
                            {
                                chartType: CHART_TYPES.VISIT_TIME,
                                label: 'Avg. Visit time',
                                value: DATA_TYPES.DURATION_SECONDS.format(averageVisitTime, {
                                    isHumanized: true,
                                }),
                            },
                            {
                                chartType: CHART_TYPES.VISIT_FREQUENCY,
                                label: 'Avg. Visit frequency',
                                value: averageVisitFrequency,
                            },
                            {
                                chartType: CHART_TYPES.VISITED_PAGES,
                                label: 'Avg. Visited pages',
                                value: averageVisitedPages,
                            },
                            {
                                chartType: CHART_TYPES.TIME_TO_ACTION,
                                label: 'Avg. Time to action',
                                value: '<1 day',
                            },
                        ]}
                        currentTab={activeTab}
                        loading={segmentChartDataLoading}
                        setTab={setActiveTab}
                    />
                </div>
            </div>
            {activeTab === CHART_TYPES.VISIT_TIME && (
                <VisitTime
                    data={calculateChartData(segmentChartData.visitTime)}
                    loading={segmentChartDataLoading}
                />
            )}
            {activeTab === CHART_TYPES.TIME_TO_ACTION && (
                <TimeToActionChart
                    segments={segments.map((s) => ({
                        value: s.id,
                        label: s.name,
                    }))}
                    advertiserId={advertiserId}
                    segmentId={segments.find((s) => s.name === 'PageView')?.id}
                />
            )}
            {activeTab === CHART_TYPES.VISIT_FREQUENCY && (
                <VisitFrequency
                    data={calculateChartData(segmentChartData.visitFrequency)}
                    loading={segmentChartDataLoading}
                />
            )}
            {activeTab === CHART_TYPES.VISITED_PAGES && (
                <VisitedPages
                    data={calculateChartData(segmentChartData.visitedPages)}
                    loading={segmentChartDataLoading}
                />
            )}
        </InsetBox>
    );
};

export default SegmentCharts;
