import { useState } from 'react';
import { Form } from 'reactstrap';
import { Formik } from 'formik';
import { Section, SECTION_OPTIONS } from 'platform/app/app.types';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { usePromise } from 'platform/common/hooks/usePromise';
import useToggle from 'platform/common/hooks/useToggle';
import { required } from 'platform/common/utils/validators.util';
import ReportCopyModal from 'platform/customReports/ReportCopyModal';
import { fetchCustomReports } from 'platform/customReports/customReport.service';
import { CustomReport } from 'platform/customReports/customReport.types';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import { fetchSeats } from 'platform/userManagement/services/userManagement.service';

interface ReportSelect {
    seatId: number | undefined;
    section: Section[] | undefined;
}

interface Props {
    section: Section;
    seatId: number;
    toggle: () => void;
    onOk?: (reports: CustomReport[]) => Promise<void>;
}

const INITIAL_REPORT_SELECT: ReportSelect = {
    seatId: undefined,
    section: undefined,
};

const ReportSelectModal = ({ section, seatId, toggle, onOk }: Props) => {
    const [reports, setReports] = useState<CustomReport[]>([]);
    const [loading, toggleLoading] = useToggle(false);

    const [{ loading: loadingSeats, data: seatOptions }] = usePromise(
        [],
        async () => {
            const seats = await fetchSeats({ states: ['ACTIVE'] });
            return seats.map((s) => ({ label: s.name, value: s.id }));
        },
        []
    );

    const fetchSeatReports = async (reportSelect: ReportSelect) => {
        toggleLoading(true);
        const customReports = await fetchCustomReports({
            seatId: reportSelect.seatId,
            section: reportSelect.section,
            pinned: true,
            status: ['ACTIVE'],
        });
        setReports(customReports);
        toggleLoading(false);
    };

    return (
        <ReportCopyModal
            title="Copy from existing reports"
            reports={reports}
            section={section}
            seatId={seatId}
            loading={loading}
            disabled={!reports.length}
            toggle={toggle}
            onOk={onOk}
        >
            <Formik initialValues={INITIAL_REPORT_SELECT} onSubmit={fetchSeatReports}>
                {({ submitForm, setFieldValue }) => (
                    <Form>
                        <FormRow label="Seat" helpKey="report_copy_seatId">
                            <FormSelect
                                options={seatOptions}
                                name="seatId"
                                isLoading={loadingSeats}
                                validate={[required]}
                                onChange={async (value) => {
                                    await setFieldValue('seatId', value);
                                    submitForm();
                                }}
                            />
                        </FormRow>
                        <FormRow label="Section" helpKey="report_copy_section">
                            <FormSelect
                                options={SECTION_OPTIONS}
                                isMulti
                                name="section"
                                onChange={async (value) => {
                                    await setFieldValue('section', value);
                                    submitForm();
                                }}
                            />
                        </FormRow>
                    </Form>
                )}
            </Formik>
        </ReportCopyModal>
    );
};

export default ReportSelectModal;
