import { CSSProperties } from 'react';
import { MultiValueProps, StylesConfig } from 'react-select';
import styles from 'platform/common/constants/styles.constant.scss';

export const COLLAPSED_MORE_VALUE = '__more__';

const isChipHighlighted = ({ selectProps, data }: MultiValueProps<any>) => {
    const optionValue = selectProps?.getOptionValue?.(data);
    if (optionValue) {
        return selectProps.highlightedValues?.includes(optionValue);
    }

    return false;
};

export const getSelectStyles = ({
    invalid,
    selectStyle,
    menuStyle,
}: {
    invalid: boolean;
    selectStyle?: CSSProperties;
    menuStyle?: CSSProperties;
}): StylesConfig<any, boolean> => ({
    control: (provided, state) => ({
        ...provided,
        borderBottomLeftRadius: '0.5rem',
        borderTopLeftRadius: '0.5rem',
        borderBottomRightRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
        minHeight: 33,
        height: '100%',
        boxShadow: state.isFocused ? 'none' : provided.boxShadow,
        borderColor: `${invalid ? '#f86c6b' : '#cbd0d9'} !important`,
        ...selectStyle,
        minWidth: selectStyle?.minWidth || 150,
    }),
    placeholder: (provided) => ({
        ...provided,
        padding: '2px',
        color: '#aaa',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '2px 2px 2px 10px',
        maxHeight: 340,
        overflowY: 'auto',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: '6px',
        visibility: 'hidden',
        cursor: 'pointer',
        transform: 'scale(0.8)',
        '&:hover': {
            color: '#f86c6b',
        },
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: '6px',
        cursor: 'pointer',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : undefined,
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        cursor: 'pointer',
        color: '#394251',
        height: '100%',
        opacity: 0.6,
        '&:hover': {
            background: 'transparent',
            color: '#171717',
        },
    }),
    multiValue: (provided, props) => ({
        ...provided,
        backgroundColor: '#ebedf0',
        alignItems: 'center',
        height: '1.25rem',
        borderRadius: '0.75rem',
        border: isChipHighlighted(props) ? `1px solid ${styles.primary}` : 'none',
    }),
    multiValueLabel: (provided, props) => ({
        ...provided,
        lineHeight: 'normal !important',
        color: '#667085',
        paddingRight: props?.data?.value === COLLAPSED_MORE_VALUE ? 0 : provided?.padding,
        marginTop: 1,
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: '0.5rem',
        marginTop: 4,
        zIndex: 200,
        overflow: 'auto', // so that border-radius is respected by children
        ...menuStyle,
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? styles.primary : 'transparent',
        fontWeight: state.isSelected ? 700 : provided.fontWeight,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: state.isSelected ? styles.primary : styles.hover,
        },
    }),
});

export const isGroupSelect = (options: { [key: string]: any }) =>
    Array.isArray(options) && options.length > 0 && options[0].options;
