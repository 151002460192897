import { curry, isEmpty, isNil, isObject, memoize } from 'lodash-es';
import { RangeObj } from 'platform/common/common.types';
import { isPositiveWholeNumber } from './number.util';

export const minLength = memoize(
    (length: number) => (value: string) =>
        value && value.length < length ? `must be ${length} characters or more` : undefined
);

export const percentage = memoize((value: string) =>
    value && parseInt(value, 10) > 100 ? `cannot be more than 100%` : undefined
);

const isStringEmptyOrWhiteSpace = (value: string) => /^\s*$/.test(value);

const isValueValid = (value: any) => {
    if ((Array.isArray(value) || isObject(value)) && isEmpty(value)) {
        return false;
    }

    if (typeof value === 'string') {
        return !isStringEmptyOrWhiteSpace(value);
    }

    return value === 0 || !!value;
};

export const required = (value: any) => (isValueValid(value) ? undefined : 'required');

export const requiredNumber = (value: any) => (value !== undefined ? undefined : 'required');

export const requiredDatepickerValues = (value: RangeObj<string | undefined>) =>
    value && value.from && value.to ? undefined : 'required';

export const requiredDatepickerFromValue = (value: RangeObj<string | undefined>) =>
    value.from ? undefined : 'required';

export const number = (value: any) => (value && isNaN(Number(value)) ? 'must be a number' : undefined);

export const nonNegativeNumber = (value: any) =>
    value && (isNaN(value) || Number(value) < 0) ? 'must be a positive number or zero' : undefined;

export const positiveNumber = (value: any) =>
    !isNil(value) && (isNaN(value) || Number(value) <= 0) ? 'must be a positive number' : undefined;

export const positiveWholeNumber = (value: any) =>
    value && !isPositiveWholeNumber(value) ? 'must be a positive whole number' : undefined;

export const noWhiteSpace = (value: any) =>
    value && /\s/.test(value) ? 'should not contain spaces' : undefined;

export const email = (value: any) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
        ? 'must be a valid email address'
        : undefined;

const URL_PATTERN = /^(http|https):\/\/\S+$/i;
const SECURE_URL_PATTERN = /^https:\/\/\S+$/i;

export const urlHttps = (value: any) =>
    value && !SECURE_URL_PATTERN.test(value)
        ? 'must be a valid secure url (should start with https://)'
        : undefined;

export const url = (value: any) => (value && !URL_PATTERN.test(value) ? 'must be a valid url' : undefined);

/** value should be safe to use inside a URL path after encoding it with encodeURIComponent() */
export const uriComponent = (value: any) =>
    value?.includes('%') || value?.includes('/') ? 'must not contain % or / symbols' : undefined;

export const maxFileSize = memoize(
    (maxSizeInMb) => (value: any) =>
        value && value.size > maxSizeInMb * 1024 * 1024 ? `must be less than ${maxSizeInMb} MB` : undefined
);

export const secureUrls = (value: any) =>
    value && value.toLowerCase().includes('http://') ? 'URLs must be secure (https)' : undefined;

const hasStartTag = curry((value: string, tag: string) => value.includes(`<${tag}`));
const hasEndTag = curry((value: string, tag: string) => value.includes(`</${tag}>`));
const hasStartButNoEndTag = curry(
    (value: string, tag: string) => hasStartTag(value, tag) && !hasEndTag(value, tag)
);

const containsOneOfTags =
    (openTags: string[] = [], closedTags: string[] = []) =>
    (value: string) => {
        if (!value) {
            return undefined;
        }

        const allTags = [...closedTags, ...openTags];
        if (!allTags.map(hasStartTag(value)).some(Boolean)) {
            return `must have ${allTags.map((tag) => `<${tag}>`).join(' or ')} tag`;
        }

        const errors = closedTags
            .map((tag) => hasStartButNoEndTag(value, tag) && `must have closing </${tag}> tag`)
            .filter(Boolean)
            .join(', ');

        return errors || undefined;
    };

export const containsScriptIframeImg = containsOneOfTags(['img'], ['script', 'iframe']);
export const containsScriptOrIframeTag = containsOneOfTags([], ['script', 'iframe']);
export const containsImageTag = containsOneOfTags(['img']);

export const limitFloatTo = memoize((decimalPlaces) => (value?: number) => {
    const wholePartAndReminder = value?.toString().split('.');

    if (wholePartAndReminder && wholePartAndReminder[1] && wholePartAndReminder[1].length > decimalPlaces) {
        return `Number must not have more than ${decimalPlaces} decimal places`;
    }
    return undefined;
});

export const maxArraySize = memoize(
    (size: number) =>
        (values: unknown[] = []) =>
            values.length > size ? `must be less than ${size + 1} elements selected` : undefined
);

export type Validator = (value: any) => string | undefined;

export const validator =
    (validators: Validator | Validator[]) =>
    (value: any): string | undefined => {
        if (Array.isArray(validators)) {
            const errors = validators.filter((v) => v(value));
            return errors.length ? errors[0](value) : undefined;
        }

        return validators(value);
    };
