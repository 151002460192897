import { useEffect, useRef, KeyboardEvent, useState } from 'react';
import { Input } from 'reactstrap';
import { Boundary } from '@popperjs/core';
import classNames from 'classnames';
import IconButton from 'platform/common/components/IconButton/IconButton';
import { useChatLanguageContext } from '../ChatProviders/ChatLanguageProvider';
import { useChatMessageContext } from '../ChatProviders/ChatMessageProvider';
import { MessagePrompt } from '../chat.types';
import ChatFileUploadButton from './ChatFileUploadButton';
import ChatLanguageSelect from './ChatLanguageSelect';
import ChatPromptsButton from './ChatPromptsButton/ChatPromptsButton';

interface Props {
    className?: string;
    iconClassName?: string;
    chatBoundary: Boundary | null;
}

const ChatInput = ({ className, iconClassName, chatBoundary }: Props) => {
    const { language } = useChatLanguageContext();
    const [input, setInput] = useState('');
    const ref = useRef<HTMLInputElement | null>(null);
    const { submit, submitLoading, messages } = useChatMessageContext();

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);

    // hack to make textarea auto resize
    useEffect(() => {
        if (ref.current) {
            ref.current.style.height = '0px';
            const scrollHeight = ref.current.scrollHeight;

            ref.current.style.height = `${scrollHeight}px`;
        }
    }, [ref, input]);

    const handleSubmit = (text: string, prompt?: MessagePrompt, fileName?: string) => {
        if (!text) return;

        submit([{ text, prompt, fileName, languageKey: language }]);
        setInput('');
    };

    const handleInputEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault();
            handleSubmit(input);
        } else if (e.key === 'ArrowUp' && input === '') {
            const latestMessageNotInInput = messages
                .filter((m) => m.role === 'user')
                .find(
                    (_, i, msgs) => i === msgs.length - 1 || input === msgs[i + 1].contentParts?.[0]?.content
                );

            setInput(latestMessageNotInInput?.contentParts?.[0]?.content ?? input);
        }
    };

    return (
        <div className={className}>
            <Input
                type="textarea"
                innerRef={ref}
                placeholder="Your question here..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleInputEnterPress}
            />
            <div className="px-1 pb-2 d-flex w-100 justify-content-end">
                <ChatLanguageSelect />
                <ChatPromptsButton chatBoundary={chatBoundary} />
                <ChatFileUploadButton />
                <IconButton
                    disabled={submitLoading || !input.length}
                    className={iconClassName}
                    onClick={() => handleSubmit(input)}
                >
                    <i
                        className={classNames(
                            'far fa-paper-plane-top fs-5',
                            input.length ? 'text-primary' : 'text-light-slate-gray'
                        )}
                    />
                </IconButton>
            </div>
        </div>
    );
};

export default ChatInput;
