import { useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Card } from 'reactstrap';
import classNames from 'classnames';
import IconButton from 'platform/common/components/IconButton/IconButton';

const LANGUAGE_CODES = ['us', 'de', 'es', 'fr', 'it', 'jp'] as const;
type VideoLang = typeof LANGUAGE_CODES[number];

const VIDEO_URLS: Record<VideoLang, string> = {
    us: 'https://dap-prod-images.s3.eu-west-1.amazonaws.com/admin/tutorials/deeply-intro-2-en.mp4',
    de: 'https://dap-prod-images.s3.eu-west-1.amazonaws.com/admin/tutorials/deeply-intro-2-de.mp4',
    es: 'https://dap-prod-images.s3.eu-west-1.amazonaws.com/admin/tutorials/deeply-intro-2-es.mp4',
    fr: 'https://dap-prod-images.s3.eu-west-1.amazonaws.com/admin/tutorials/deeply-intro-2-fr.mp4',
    it: 'https://dap-prod-images.s3.eu-west-1.amazonaws.com/admin/tutorials/deeply-intro-2-it.mp4',
    jp: 'https://dap-prod-images.s3.eu-west-1.amazonaws.com/admin/tutorials/deeply-intro-2-jp.mp4',
};

const ChatIntroVideo = () => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [videoLang, setVideoLang] = useState<VideoLang>('us');
    const currentUrl = VIDEO_URLS[videoLang];

    return (
        <div className="ChatLanding-introVideo">
            <Card className="p-2">
                <video ref={videoRef} className="rounded" width="100%" controls preload="auto">
                    <source src={currentUrl} type="video/mp4" />
                </video>

                <div className="d-flex align-items-center gap-2 mt-2">
                    <span>Video language:</span>
                    <div className="d-flex">
                        {LANGUAGE_CODES.map((code) => (
                            <IconButton
                                key={code}
                                className={classNames('ChatLanding-introVideo-languageBtn', {
                                    active: code === videoLang,
                                })}
                                onClick={() => {
                                    setVideoLang(code);
                                    if (videoRef.current) {
                                        videoRef.current.load();
                                        videoRef.current.play();
                                    }
                                }}
                            >
                                <ReactCountryFlag
                                    countryCode={code}
                                    svg
                                    style={{
                                        width: 24,
                                        height: 20,
                                        border: code === 'jp' ? '1px solid grey' : undefined,
                                    }}
                                />
                            </IconButton>
                        ))}
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ChatIntroVideo;
