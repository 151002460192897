import { CSSProperties, ReactNode } from 'react';
import { ANALYTICS_TABLE_PAGE_SIZE_OPTIONS } from 'platform/analytics/analytics.constants';
import { PivotOptions, PivotReport, Template } from 'platform/analytics/analytics.types';
import { buildPivotColumns } from 'platform/analytics/reportComponents/ReportTableContainer/columnBuilders';
import ReportTracingTips from 'platform/analytics/reportComponents/ReportTracingTips/ReportTracingTips';
import { Sort } from 'platform/common/common.types';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';

interface Props {
    report: PivotReport;
    template: Template;
    dimensions: string[];
    metrics: string[];
    pivot: PivotOptions;
    pageSize: number;
    pageNumber: number;
    summaryTable?: boolean;
    sort: Sort[];
    TopToolbar: ReactNode;
    style?: CSSProperties;
    containerStyle?: CSSProperties;
    exportName: string;
    onPageChange: (pageNumber: number) => void;
    onPageSizeChange: (pageSize: number) => void;
    onSortChange: (sort: Sort[]) => void;
    onDataExplorerClick?: () => void;
    compareReport?: PivotReport;
}

const ReportPivotTable = ({
    report,
    template,
    dimensions,
    metrics,
    pivot,
    pageSize,
    pageNumber,
    summaryTable = false,
    sort,
    TopToolbar,
    style,
    containerStyle,
    exportName,
    onDataExplorerClick,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    compareReport,
}: Props) => {
    if (summaryTable) {
        return (
            <>
                <FormattedTable
                    pageSizeOptions={ANALYTICS_TABLE_PAGE_SIZE_OPTIONS}
                    className="SummaryTable"
                    columns={buildPivotColumns(report, template, metrics, dimensions, pivot, false, true)}
                    data={report.rows}
                    sorted={sort}
                    containerClass="shadow-none"
                    style={style}
                    containerStyle={containerStyle}
                    showPagination={false}
                    manual
                    multiSort
                    onSortedChange={onSortChange}
                />
                <ReportTracingTips report={report} compareReport={compareReport} />
            </>
        );
    }

    return (
        <>
            <FormattedTable
                pageSizeOptions={ANALYTICS_TABLE_PAGE_SIZE_OPTIONS}
                columns={buildPivotColumns(report, template, metrics, dimensions, pivot)}
                data={report.rows.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize)}
                pages={Math.ceil(report.rows.length / pageSize)}
                page={pageNumber}
                pageSize={pageSize}
                sorted={sort}
                exportData={{
                    loadData: () => report.rows,
                    filename: exportName,
                }}
                className="-striped"
                containerClass="shadow-none"
                style={style}
                containerStyle={containerStyle}
                topToolbar={TopToolbar}
                manual
                multiSort
                resizable
                onDataExplorerClick={onDataExplorerClick}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                onSortedChange={onSortChange}
            />
            <ReportTracingTips report={report} />
        </>
    );
};

export default ReportPivotTable;
