import { useRef, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';
import { ModalHeader, ModalBody, Modal, Button } from 'reactstrap';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import { FileInfo } from 'platform/common/utils/file.util';

interface Props {
    file: FileInfo;
    onSave: (file: FileInfo) => void;
    toggle: () => void;
}

const ImgCropModal = ({ file, toggle, onSave }: Props) => {
    const saveBtnRef = useRef<HTMLButtonElement | null>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);

    const onCropChange = (newCrop: Crop) => {
        setCrop(newCrop);
    };

    const onCropComplete = (newCrop: Crop) => {
        if (newCrop.width && newCrop.height) {
            setCompletedCrop(newCrop);
            saveBtnRef.current?.focus();
        }
    };

    const handleSave = async () => {
        toggle();
        const image = imgRef.current;
        if (!image || !completedCrop) {
            throw new Error('No image or croping present');
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY);
        const ctx = offscreen.getContext('2d');
        if (!ctx) {
            throw new Error('No 2d context');
        }

        const cropX = completedCrop.x * scaleX;
        const cropY = completedCrop.y * scaleY;

        const centerX = imgRef.current.naturalWidth / 2;
        const centerY = imgRef.current.naturalHeight / 2;

        ctx.save();
        ctx.translate(-cropX, -cropY);
        ctx.translate(centerX, centerY);
        ctx.translate(-centerX, -centerY);
        ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight
        );
        const blob = await offscreen.convertToBlob({ type: 'image/jpeg', quality: 1 });
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64data = reader.result;
            onSave({ ...file, content: base64data as string });
        };
        reader.readAsDataURL(blob);
    };

    return (
        <Modal isOpen toggle={toggle}>
            <ModalHeader toggle={toggle}>Crop image</ModalHeader>
            <ModalBody>
                <ReactCrop aspect={1} crop={crop} onChange={onCropChange} onComplete={onCropComplete}>
                    <img ref={imgRef} src={file.content.toString()} alt="To crop" />
                </ReactCrop>
                <div className="d-flex justify-content-end">
                    <Button onClick={toggle}>Cancel</Button>
                    <AnimatedButton ref={saveBtnRef} className="ms-2" onClick={handleSave}>
                        Save
                    </AnimatedButton>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ImgCropModal;
