import { Button } from 'reactstrap';
import Algorithms from 'platform/app/components/Intro/images/Algorithms.png';
import Analytics from 'platform/app/components/Intro/images/Analytics.png';
import Audience from 'platform/app/components/Intro/images/Audience.png';
import Dashboard from 'platform/app/components/Intro/images/Dashboard.png';
import './DemoVideos.scss';

const demoVideos = [
    {
        src: 'https://dap-dev-images.s3.amazonaws.com/admin/videos/DAP_Demo_Algorithms.mp4',
        imgSrc: Algorithms,
        title: 'Algorithms',
    },
    {
        src: 'https://dap-dev-images.s3.amazonaws.com/admin/videos/DAP_Demo_Analytics.mp4',
        imgSrc: Analytics,
        title: 'Analytics',
    },
    {
        src: 'https://dap-dev-images.s3.amazonaws.com/admin/videos/DAP_Demo_Audience.mp4',
        imgSrc: Audience,
        title: 'Audience',
    },
    {
        src: 'https://dap-dev-images.s3.amazonaws.com/admin/videos/DAP_Demo_Dashboard.mp4',
        imgSrc: Dashboard,
        title: 'Dashboard',
    },
];

interface Props {
    onVideoClick: (src: string) => void;
    onButtonClick: () => void;
}

const DemoVideos = ({ onVideoClick, onButtonClick }: Props) => (
    <div className="DemoVideos">
        <div className="DemoVideos-title">Einführungsvideos</div>
        <div className="DemoVideos-container">
            {demoVideos.map((video) => (
                <div
                    role="button"
                    tabIndex={0}
                    className="DemoVideos-videoContainer"
                    onClick={() => {
                        onVideoClick(video.src);
                    }}
                >
                    <div className="DemoVideos-playIcon">
                        <i className="fal fa-play" />
                    </div>
                    <img src={video.imgSrc} height={155} alt={video.title} className="DemoVideos-image" />
                </div>
            ))}
        </div>
        <div>
            <Button className="DemoVideos-button" onClick={onButtonClick}>
                Direkt zur Plattform
                <i className="fal fa-arrow-right ms-3" />
            </Button>
            <div
                className="mt-2"
                style={{
                    fontSize: 14,
                    color: '#98A2B3',
                    lineHeight: 1.3,
                }}
            >
                Das Demo-Login zeigt nur einen Ausschnitt des Funktionsumfangs
            </div>
        </div>
    </div>
);

export default DemoVideos;
