import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Input } from 'reactstrap';
import classNames from 'classnames';
import { AdvertiserAccountKey } from 'platform/advertisers/advertiser.types';
import WarningIcon from 'platform/assets/icons/WARNING.svg';
import { getCampaignImportDetailsUrl } from 'platform/campaign/campaign.navigation';
import { campaignClassificationToApi } from 'platform/campaign/campaign/campaignClassification.mapper';
import { CampaignClassificationModel } from 'platform/campaign/campaign/campaignClassification.types';
import { ImportedCampaign } from 'platform/campaign/campaign/campaignImport.types';
import CampaignBatchImportModal from 'platform/campaign/campaign/components/CampaignImport/CampaignBatchImportModal';
import {
    CampaignImportDefaultFilters,
    importExternalCampaigns,
} from 'platform/campaign/campaign/services/campaignImport.service';
import ChannelLabel from 'platform/channels/components/ChannelLabel';
import { TableCell } from 'platform/common/common.types';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import { IntegrationStatusLabel } from 'platform/common/components/DspIntegrationIcon/IntegrationStatusLabel';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import { useModal } from 'platform/common/components/Modal/Modal';
import Popover from 'platform/common/components/Popover/Popover';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import { getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import { formatDateTime } from 'platform/common/utils/date.util';
import { CampaignBatchImportPanel } from './CampaignBatchImportPanel';
import { CampaignImportTableFilters } from './CampaignImportTableFilters';

interface Props {
    accountKey: AdvertiserAccountKey;
    campaigns: ImportedCampaign[];
    onCampaignsImport: (externalIds: string[]) => void;
    filters: CampaignImportDefaultFilters;
    onFilterChange: (dataObject: { name: string; value: string | number | boolean }) => void;
    loading?: boolean;
}

const CampaignImportTable = ({
    accountKey,
    campaigns,
    onCampaignsImport,
    filters,
    onFilterChange,
    loading,
}: Props) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCampaigns, setSelectedCampaigns] = useState<ImportedCampaign[]>([]);
    const { showModal } = useModal();

    const filteredCampaigns = campaigns.filter(
        (c) =>
            !searchQuery ||
            c.externalId?.toString().includes(searchQuery) ||
            c.campaignId?.toString().includes(searchQuery) ||
            c.name?.toLowerCase().includes(searchQuery?.toLowerCase())
    );

    const handleSelectAll = () => {
        setSelectedCampaigns(
            selectedCampaigns.length ? [] : filteredCampaigns.filter((c) => !c.importStatus) ?? []
        );
    };

    const handleSelect = (campaign: ImportedCampaign) => {
        setSelectedCampaigns(
            isCampaignSelected(campaign)
                ? selectedCampaigns.filter((c) => c.externalId !== campaign.externalId)
                : [...selectedCampaigns, campaign]
        );
    };

    const handleCampaignsImport = (classification: CampaignClassificationModel) => {
        const externalIds = selectedCampaigns.map((c) => c.externalId);
        importExternalCampaigns(
            { ...accountKey, externalIds },
            { importMode: 'SKIP_CREATIVES', classification: campaignClassificationToApi(classification) }
        );
        setSelectedCampaigns([]);
        onCampaignsImport(externalIds);
    };

    const isCampaignSelected = (campaign: ImportedCampaign) =>
        !!selectedCampaigns.find((c) => c.externalId === campaign.externalId);

    const campaignColumns: TableColumn<ImportedCampaign>[] = [
        {
            Header: () => (
                <Input
                    type="checkbox"
                    className="position-static m-0"
                    checked={!!selectedCampaigns.length}
                    onChange={handleSelectAll}
                />
            ),
            width: 70,
            headerClassName: 'cell-align-center',
            className: 'cell-align-center',
            Cell: (props) =>
                props.original?.importStatus ? null : (
                    <Input
                        type="checkbox"
                        className="position-static m-0"
                        onClick={() => handleSelect(props.original)}
                        checked={isCampaignSelected(props.original)}
                        readOnly
                    />
                ),
            sortable: false,
        },
        {
            Header: 'Channel',
            maxWidth: 120,
            sortable: false,
            Cell: ({ original: { channel } }) => <ChannelLabel channel={channel} />,
        },
        {
            Header: 'Campaign status',
            maxWidth: 100,
            sortable: false,
            Cell: ({ original: { active } }) => <StatusBadge status={active ? 'ACTIVE' : 'INACTIVE'} />,
        },
        {
            Header: 'Import status',
            maxWidth: 150,
            accessor: 'importStatus',
            Cell: ({ original: { type, importStatus, importError, warnings } }) => (
                <div className="d-flex">
                    {type !== 'REDIRECT' && importStatus && importStatus !== 'NEVER' && (
                        <IntegrationStatusLabel status={importStatus} />
                    )}
                    {type === 'REDIRECT' && (
                        <>
                            <span className="text-warning">IO mapped</span>
                            <img src={WarningIcon} alt="Warning" className="ms-2" />
                        </>
                    )}
                    {importError && (
                        <div className="ms-3 text-danger">
                            <Popover
                                preventOverflow
                                renderPopover={() => <div className="p-2">{importError}</div>}
                            >
                                <i role="button" tabIndex={-1} className="fa fa-exclamation-circle" />
                            </Popover>
                        </div>
                    )}
                    {!!warnings?.length && (
                        <div className="ms-3 text-warning">
                            <Popover
                                preventOverflow
                                renderPopover={() => (
                                    <div className="p-2">
                                        {warnings?.map((warning, index) => (
                                            <p key={`warning-${index}`}>{warning}</p>
                                        ))}
                                    </div>
                                )}
                            >
                                <i role="button" tabIndex={-1} className="fa fa-exclamation-circle" />
                            </Popover>
                        </div>
                    )}
                </div>
            ),
        },
        {
            Header: `${getIntegrationLabel(accountKey.externalSystem)} campaign`,
            accessor: 'name',
            Cell: ({ original: { externalId, name } }) => (
                <div className="flex-column">
                    <div>
                        <Link to={getCampaignImportDetailsUrl(externalId, accountKey)}>{name}</Link>
                    </div>
                    <span className="text-muted font-xs">{externalId}</span>
                </div>
            ),
        },
        {
            Header: 'DAP campaign',
            width: 140,
            accessor: 'campaignId',
        },
        {
            Header: 'Last imported',
            width: 150,
            accessor: 'importedOn',
            Cell: ({ original: { importedOn } }) =>
                importedOn ? <div>{formatDateTime(importedOn)}</div> : null,
        },
    ];

    return (
        <ControlledCard title="Campaigns" hideControl>
            <Alert color="primary">
                {`Choose the campaigns you wish to import and proceed by clicking 'Import
                                    Campaigns'`}
            </Alert>
            <FormattedTable
                loading={loading}
                columns={campaignColumns}
                data={filteredCampaigns}
                defaultSorted={[{ orderBy: 'name', direction: 'ASC' }]}
                getTrProps={(_: any, rowInfo?: TableCell<ImportedCampaign>) => ({
                    className: classNames('cursor-pointer', {
                        'CampaignImport-pending': !rowInfo?.original?.importStatus,
                        selected: rowInfo && isCampaignSelected(rowInfo.original),
                    }),
                })}
                topToolbar={
                    <>
                        <CampaignImportTableFilters
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            filters={filters}
                            onFilterChange={onFilterChange}
                            externalSystem={accountKey.externalSystem}
                        />
                        <div className="d-flex gap-2 mt-2 justify-content-end w-100">
                            <Button
                                className="text-nowrap"
                                color="primary"
                                disabled={!selectedCampaigns.length}
                                onClick={() =>
                                    showModal((toggle) => (
                                        <CampaignBatchImportModal
                                            advertiserId={accountKey.advertiserId}
                                            campaigns={selectedCampaigns}
                                            toggle={toggle}
                                            onImport={handleCampaignsImport}
                                        />
                                    ))
                                }
                            >
                                Import campaigns ({selectedCampaigns.length})
                            </Button>

                            <CampaignBatchImportPanel
                                accountKey={accountKey}
                                onFilterCampaigns={(importStatus) => {
                                    onFilterChange({
                                        name: 'importStatusFilter',
                                        value: importStatus,
                                    });
                                }}
                            />
                        </div>
                    </>
                }
            />
        </ControlledCard>
    );
};

export default CampaignImportTable;
