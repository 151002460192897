import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { NavigationItem } from 'platform/app/app.types';
import NavigationIcon from 'platform/app/components/Sidebar/NavigationIcon';
import ExternalReportLink from 'platform/customReports/ExternalReportLink';
import { hideMobileSidebar } from './NavigationDropdown';

interface Props {
    item: NavigationItem;
}

const NavigationLink = ({ item }: Props) => {
    const location = useLocation();
    const linkTo = item.redirectTo || item.path;
    if (!linkTo) {
        return null;
    }

    if (item.isExternal) {
        return (
            <ExternalReportLink
                name={item.name}
                icon={item.icon}
                url={linkTo}
                display={item.display}
                className={classNames('nav-link', { active: item.active, 'py-2 px-3': !item.icon })}
            />
        );
    }

    return (
        <Link
            to={linkTo}
            replace={linkTo === location.pathname}
            className={classNames('nav-link', { active: item.active, 'py-2 px-3': !item.icon })}
            onClick={hideMobileSidebar}
        >
            <NavigationIcon Icon={item.icon} />
            {item.section === 'CENTRAL_ANALYTICS' && item.disabled && (
                <i className="me-2 opacity-75 text-muted font-xs fal fa-eye-slash" />
            )}
            {item.name}
        </Link>
    );
};

export default NavigationLink;
