import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'redux-first-history';
import {
    activeAdvertiserActions,
    activeAdvertiserSelectors,
} from 'platform/common/ducks/activeAdvertiser.duck';

export const useAdvertiserUrlSync = () => {
    const dispatch = useDispatch();
    const initialRender = useRef(true);
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const advertiserOptions = useSelector(activeAdvertiserSelectors.options);
    const searchParams = new URLSearchParams(location.search);
    const queryAdvertiserId = Number(searchParams.get('advertiserId'));
    const idChanged = !!(queryAdvertiserId && queryAdvertiserId !== advertiserId);
    const idExists = !!advertiserOptions.find((x) => x.id === queryAdvertiserId);

    const replaceAdvertiserQueryParam = () => {
        searchParams.set('advertiserId', advertiserId.toString());
        dispatch(replace(`${location.pathname}?${searchParams.toString()}`));
    };

    useEffect(() => {
        if (!location.search) {
            replaceAdvertiserQueryParam();
            return;
        }

        if (idExists && idChanged) {
            if (initialRender.current) {
                dispatch(activeAdvertiserActions.changeAdvertisers([queryAdvertiserId]));
                initialRender.current = false;
            } else {
                replaceAdvertiserQueryParam();
            }
        }
    }, [advertiserId, location.search]);
};
