import * as CSV from 'csv-string';
import FileSaver from 'file-saver';
import { get, identity, isFunction } from 'lodash-es';
import XLSX from 'xlsx';
import { TableColumn } from '../FormattedTable/FormattedTable';

// https://docs.microsoft.com/en-us/scripting/javascript/advanced/special-characters-javascript#escape-sequences
// Add Byte Order Mark to indicate Unicode (recognized by MS Excel)
export const BOM = '\uFEFF';

const determineColumnValue = (col: TableColumn, row: any) => {
    const formatValue = col.type ? col.type.parseForExport : identity;

    if (col.exportCell) {
        return formatValue(col.exportCell(row));
    }

    if (col.accessor) {
        return formatValue(isFunction(col.accessor) ? col.accessor(row) : get(row, col.accessor));
    }

    return '';
};

const getExportPreparedData = (
    columns: TableColumn[],
    data: any[],
    exportHeader: boolean,
    headers: string[][] = []
) => {
    const filteredColumns = columns.filter((col) => !!col.accessor || !!col.exportCell);

    const tableBody = data.map((row) => filteredColumns.map((col) => determineColumnValue(col, row)));
    const tableHeader = headers.length ? headers : [filteredColumns.map((col) => col.Header)];

    return exportHeader ? [...tableHeader, ...tableBody] : tableBody;
};

export const getCsvString = (
    columns: TableColumn[],
    data: any[],
    exportHeader: boolean = true,
    headers: string[][] = []
) => CSV.stringify(getExportPreparedData(columns, data, exportHeader, headers), ';');

const downloadCsv = (
    columns: TableColumn[],
    data: any[],
    fileName: string,
    exportHeader: boolean = true,
    headers: string[][] = []
) => {
    const csv = getCsvString(columns, data, exportHeader, headers);
    const blob = new Blob([BOM, csv], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, `${fileName}.csv`);
};

const downloadXlsx = (
    columns: TableColumn[],
    data: any[],
    fileName: string,
    exportHeader: boolean = true,
    headers: string[][] = []
) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(getExportPreparedData(columns, data, exportHeader, headers));
    // Max sheet name length is 31 chars
    const sheetName = fileName.substring(0, 30);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const workbookOut = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    FileSaver.saveAs(new Blob([workbookOut], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
};

export default {
    downloadCsv,
    downloadXlsx,
};
