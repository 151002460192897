import { Button, Card, CardBody } from 'reactstrap';
import chevronsCollapse from 'platform/assets/icons/journey/chevrons2.svg';
import useToggle from 'platform/common/hooks/useToggle';
import { CustomerJourneyReport } from '../../dashboard.types';
import { ItemGroup } from './CustomerJourney';
import JourneyRow from './JourneyRow';
import { CardSeparator, ItemSeparator } from './Separators';
import './JourneyList.scss';

const SingleRowGroup = ({ rows }: { rows: CustomerJourneyReport[] }) => {
    if (rows.length !== 1) {
        throw new Error('Group element should get array with 1 item');
    }

    return (
        <Card className="mb-0">
            <CardBody>
                <JourneyRow row={rows[0]} />
            </CardBody>
        </Card>
    );
};

const CollapsibleGroup = ({ rows }: { rows: CustomerJourneyReport[] }) => {
    if (rows.length < 3) throw new Error('CollapsibleGroup element should get array of at least 3 items');
    const [isOpen, toggle] = useToggle(false);
    const first = rows[0];
    const last = rows[rows.length - 1];
    const rest = rows.slice(1, rows.length - 1);
    return (
        <Card className="mb-0 CollapsibleGroup">
            <CardBody>
                <JourneyRow row={first} />
                {isOpen ? (
                    <>
                        <Button onClick={() => toggle()} className="CollapsibleGroup-showLess">
                            <img src={chevronsCollapse} width={16} height={32} alt="collapse" />
                        </Button>
                        {rest.map((row, index) => (
                            <div key={row.journey_datetime}>
                                <ItemSeparator
                                    startTime={rows[index].journey_datetime}
                                    endTime={row.journey_datetime}
                                />
                                <JourneyRow row={row} />
                            </div>
                        ))}
                        <ItemSeparator
                            startTime={rest[rest.length - 1].journey_datetime}
                            endTime={last.journey_datetime}
                        />
                    </>
                ) : (
                    <div className="CollapsibleGroup-showMoreContainer">
                        <div className="CollapsibleGroup-showMoreLine" />
                        <Button onClick={() => toggle()} className="CollapsibleGroup-showMore">
                            <i className="fal fa-chevron-down" /> {rest.length} more visit
                            {rest.length > 1 && 's'}
                        </Button>
                    </div>
                )}
                <JourneyRow row={last} />
            </CardBody>
        </Card>
    );
};

const JourneyList = ({ groups }: { groups: ItemGroup[] }) => (
    <div>
        {groups.map((group, index) => (
            <div key={group.startTime} id={`JourneyListGroup-${index}`}>
                {index !== 0 && (
                    <CardSeparator startTime={groups[index - 1].endTime} endTime={group.startTime} />
                )}
                {group.rows.length > 1 ? (
                    <CollapsibleGroup rows={group.rows} />
                ) : (
                    <SingleRowGroup rows={group.rows} />
                )}
            </div>
        ))}
    </div>
);

export default JourneyList;
