import ChannelLabel from 'platform/channels/components/ChannelLabel';
import { CustomerJourneyReportCM360 } from 'platform/dashboard/dashboard.types';

interface Props {
    row: CustomerJourneyReportCM360;
}

const JourneyRowChannelVendorBlock = ({ row }: Props) => (
    <div className="d-flex align-items-center font-lg">
        <div className="me-3">
            <ChannelLabel withIcon channel={row.channel} searchTermStrategy={row.search_term_strategy} />
        </div>
        {row.vendor_name && row.vendor_name !== row.channel && (
            <>
                <img
                    alt="vendor icon"
                    src={row.vendor_icon_url}
                    className="mx-1 mb-0"
                    height={24}
                    width={24}
                    style={{ objectFit: 'contain' }}
                />
                {row.vendor_name}
            </>
        )}
    </div>
);

export default JourneyRowChannelVendorBlock;
