import { NavigationItem } from 'platform/app/app.types';

export const SEGMENTS: NavigationItem = {
    name: 'Segments',
    path: '/segments',
    icon: 'fal fa-puzzle',
    requiresFeature: 'ROLE_CDP_VIEW',
};

export const SEGMENT_DETAILS: NavigationItem = {
    name: 'Segment Details',
    path: '/segment-details/:segmentId',
    requiresFeature: 'ROLE_CDP_VIEW',
};

export const SEGMENT_OVERVIEW: NavigationItem = {
    name: 'Segment Overview',
    path: '/segment-details/:segmentId/overview',
    requiresFeature: 'ROLE_CDP_VIEW',
};

export const SEGMENT_ANALYSIS: NavigationItem = {
    name: 'Segment Analysis',
    path: '/segment-details/:segmentId/analysis',
    requiresFeature: 'ROLE_CDP_VIEW',
};

export const SEGMENT_SIMILARITY: NavigationItem = {
    name: 'Segment Similarity',
    path: '/segment-details/:segmentId/similarity',
    requiresFeature: 'ROLE_CDP_VIEW',
};
