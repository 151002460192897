import { AxiosResponse } from 'axios';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { useLoading } from 'platform/common/hooks/useLoading';
import { TaskAttachment } from '../task.types';
import KanbanAttachment from './KanbanAttachment';
import KanbanCardLabel from './KanbanCardLabel';
import KanbanToggleButton from './KanbanToggleButton';

interface Props {
    attachments: TaskAttachment[];
    onUpload: (name: string, content: Blob) => Promise<void>;
    onDelete: (attachmentId: number) => Promise<void>;
    onDownload: (attachmentId: number) => Promise<AxiosResponse<Blob>>;
}

const KanbanAttachmentsEdit = ({ attachments, onUpload, onDelete, onDownload }: Props) => {
    const [uploading, toggleUploading] = useLoading();

    return (
        <>
            <div className="d-flex">
                <KanbanCardLabel icon="fa fa-paperclip">Attachments</KanbanCardLabel>
                <UploadInput
                    readFileAs="blob"
                    onFileUpload={(file) => toggleUploading(() => onUpload(file.name, file.content as Blob))}
                >
                    <KanbanToggleButton />
                </UploadInput>
            </div>
            {!!attachments.length && (
                <div className="d-flex justify-content-start align-items-center flex-wrap gap-2 position-relative mt-3">
                    {uploading && <OverlayLoader />}
                    {attachments.map((attachment) => (
                        <KanbanAttachment
                            key={attachment.id}
                            attachment={attachment}
                            onDownload={onDownload}
                            onDelete={() => onDelete(attachment.id)}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export default KanbanAttachmentsEdit;
