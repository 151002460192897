import { pick } from 'lodash-es';
import {
    CampaignClassifications,
    LabelClassification,
    MediaplanClassifications,
} from 'platform/campaign/campaign/campaignClassification.types';
import { Status } from 'platform/common/constants/status.constant';
import { makeOptions } from 'platform/common/utils/option.util';
import { PricingType } from 'platform/units/units.type';
import { Vendor } from 'platform/vendors/vendors.types';

type VolumeType = 'IMPRESSIONS' | 'CLICKS' | 'LEADS';

export const VOLUME_TYPE_LABELS: Record<VolumeType, string> = {
    IMPRESSIONS: 'Impressions',
    CLICKS: 'Clicks',
    LEADS: 'Leads',
};

export interface ChangeTrackingModel {
    reason?: ChangeReason;
    comment?: string;
    classification?: LabelClassification;
}

export interface MediaplanCreatePayload {
    advertiserId: number;
    name: string;
    status: Status;
    dateFrom: string;
    dateTo: string;
    budget: number;
    notes?: string;
    projectNumber: string;
    poNumber: string;
    adservingFee?: number;
    agencyFee?: number;
    classification?: MediaplanClassifications;
}

export interface MediaplanUpdatePayload {
    advertiserId: number;
    name: string;
    status: Status;
    dateFrom: string;
    dateTo: string;
    budget: number;
    version: number;
    notes?: string;
    projectNumber?: string;
    poNumber?: string;
    adservingFee?: number;
    agencyFee?: number;
    changeTracking?: ChangeTrackingModel;
    classification?: MediaplanClassifications;
}

export interface Mediaplan {
    id: number;
    createdOn: string;
    updatedOn: string;
    updatedBy: string;
    advertiserId: number;
    name: string;
    version: number;
    status: Status;
    dateFrom: string;
    dateTo: string;
    budget: number;
    allocatedBudget?: number;
    notes?: string;
    projectNumber?: string;
    poNumber?: string;
    adservingFee?: number;
    agencyFee?: number;
    changeTracking?: ChangeTrackingModel;
    classification?: MediaplanClassifications;
}

export interface MediaplanFormModel {
    id?: number;
    advertiserId: number;
    name: string;
    version?: number;
    status: Status;
    runtime: { from: string; to: string };
    budget: number | undefined;
    notes?: string;
    projectNumber?: string;
    poNumber?: string;
    adservingFee?: number;
    agencyFee?: number;
    previousChanges?: MediaplanChange[];
    newChange?: ChangeTrackingModel;
    classification?: MediaplanClassifications;
}

export interface MediaplanCopy {
    name: string;
    dateFrom: string;
    dateTo: string;
}

type WorkflowType = 'IO' | 'SIMPLIFIED';

export interface MediaInsertion {
    id?: number;
    name: string;
    vendorSystems?: string[];
    workflowType: WorkflowType;
    dateFrom: string;
    dateTo: string;
    budget: number;
    classification: CampaignClassifications;
    campaignIds?: number[];
    notes?: string;
    changeTracking?: ChangeTrackingModel;
    targetKpiValue?: number;
    targetKpiMetric?: string;
    pricingType?: PricingType;
    pricing?: number;
    volume?: number;
    volumeType?: VolumeType;
}

export interface MediaplanChangeData {
    budget: number;
    dateFrom: string;
    dateTo: string;
}

export interface MediaplanChange {
    mediaInsertionId: number;
    timestamp: string;
    changedBy: string;
    reason?: ChangeReason;
    comment?: string;
    oldData?: MediaplanChangeData;
    newData?: MediaplanChangeData;
}

export interface MediaplanTreeFormModel {
    mediaplan: Mediaplan;
    advertiserId?: number;
    treeData: MediaNodeFormModel[];
    groups: { [key: string]: MediaGroupFormModel };
    insertions: { [key: string]: MediaInsertionFormModel };
}

export interface MediaInsertionFormModel {
    key: string;
    id?: number;
    name?: string;
    vendorSystems: string[];
    workflowType: WorkflowType;
    runtime?: { from: string; to: string };
    budget?: number;
    classificationEntries?: string[];
    campaignLinks: MediaInsertionLink[];
    notes?: string;
    previousChanges?: MediaplanChange[];
    newChange?: ChangeTrackingModel;
    targetKpiValue?: number;
    targetKpiMetric?: string;
    pricingType?: PricingType;
    pricing?: number;
    volume?: number;
    volumeType?: VolumeType;
}

export interface MediaNode {
    type: MediaNodeType;
    name: string;
    children?: MediaNode[];
}

export interface MediaGroupFormModel {
    key: string;
    name?: string;
}

export interface MediaplanTree {
    insertions: MediaInsertion[];
    nodes: MediaNode[];
}

export interface MediaInsertionSearchResult {
    id: number;
    name: string;
    mediaplanId: number;
    mediaplanName: string;
    advertiserId: number;
    dateFrom: string;
    dateTo: string;
    classification: CampaignClassifications;
}

export interface MediaInsertionLink {
    campaignId: number;
    campaignName: string;
    mediaplanId: number;
    mediaplanName: string;
    mediaInsertionId: number;
    mediaInsertionName: string;
    mediaInsertionDateFrom: string;
    mediaInsertionDateTo: string;
    matchType: MatchType;
}

type MatchType = 'IMPLICIT' | 'EXPLICIT';

export const MATCH_TYPE_LABELS: Record<MatchType, string> = {
    IMPLICIT: 'Automatic',
    EXPLICIT: 'Manual',
};

export type MediaNodeType = 'GROUP' | 'INSERTION';

export type ChangeReason =
    | 'REVISION'
    | 'OVERDELIVERY'
    | 'UNDERDELIVERY'
    | 'ABOVE_TARGET_KPI'
    | 'BELOW_TARGET_KPI'
    | 'OPTIMIZATION'
    | 'OVERALL_UPSELL'
    | 'OVERALL_DOWNSELL'
    | 'ADJUSTED_RUNTIME'
    | 'ADJUSTED_PUBLISHER_AVAILABILITIES';

export const CHANGE_REASON_LABELS: Record<ChangeReason, string> = {
    REVISION: 'Revision',
    OVERDELIVERY: 'Overdelivery',
    UNDERDELIVERY: 'Underdelivery',
    ABOVE_TARGET_KPI: 'Good performance',
    BELOW_TARGET_KPI: 'Weak performance',
    OPTIMIZATION: 'Optimization',
    OVERALL_UPSELL: 'Upsell',
    OVERALL_DOWNSELL: 'Downsell',
    ADJUSTED_RUNTIME: 'Adjusted runtime',
    ADJUSTED_PUBLISHER_AVAILABILITIES: 'Adjusted publisher availabilities',
};

export const CHANGE_REASON_OPTIONS = makeOptions(
    pick(CHANGE_REASON_LABELS, [
        'REVISION',
        'OVERALL_UPSELL',
        'OVERALL_DOWNSELL',
        'ADJUSTED_RUNTIME',
        'ADJUSTED_PUBLISHER_AVAILABILITIES',
    ])
);

export interface MediaNodeFormModel {
    key: string;
    type: MediaNodeType;
    expanded?: boolean;
    name?: string;
    children?: MediaNodeFormModel[];
}

export type MediaplanStepType = 'CUSTOMER_APPROVAL' | 'INVOICE_SENT' | 'ADDITIONAL_TRACKING_REQUIREMENT';

export type MediaInsertionStepType =
    | 'CREATIVES_CHECKED'
    | 'IO_REQUESTED'
    | 'IO_CHECKED'
    | 'IO_SIGNED'
    | 'IO_BOOKED'
    | 'CAMPAIGN_SETUP'
    | 'IO_SCRIPTS_SENT'
    | 'IO_PREVIEW_APPROVED'
    | 'LIVE'
    | 'IO_VENDOR_FINAL_STATS'
    | 'VENDOR_INVOICE_CHECKED';

export const MEDIAPLAN_STEPS: Record<MediaplanStepType, { label: string }> = {
    CUSTOMER_APPROVAL: { label: 'Customer approval' },
    INVOICE_SENT: { label: 'Invoice sent' },
    ADDITIONAL_TRACKING_REQUIREMENT: { label: 'Additional tracking requirement' },
};

export const MEDIA_INSERTION_STEPS: Record<
    MediaInsertionStepType,
    {
        label: string;
        simplified?: boolean;
    }
> = {
    CREATIVES_CHECKED: { label: 'Creatives checked', simplified: true },
    IO_REQUESTED: { label: 'IO requested' },
    IO_CHECKED: { label: 'IO checked' },
    IO_SIGNED: { label: 'IO signed' },
    IO_BOOKED: { label: 'IO booked' },
    CAMPAIGN_SETUP: { label: 'Campaign setup', simplified: true },
    IO_SCRIPTS_SENT: { label: 'IO scripts sent' },
    IO_PREVIEW_APPROVED: { label: 'IO preview approved' },
    LIVE: { label: 'Live', simplified: true },
    IO_VENDOR_FINAL_STATS: { label: 'IO vendor final stats' },
    VENDOR_INVOICE_CHECKED: { label: 'Vendor invoice checked', simplified: true },
};

export interface WorkflowStep {
    type: MediaplanStepType | MediaInsertionStepType;
    completed: boolean;
    notes?: string;
    classification?: LabelClassification;
    updatedBy?: string;
    updatedOn?: string;
}

interface MediaInsertionLane {
    mediaInsertionId: number;
    vendorSystem: string;
}

export type MediaInsertionStep = MediaInsertionLane & WorkflowStep;

export interface WorkflowEvent {
    timestamp: string;
    changedBy: string;
    mediaInsertionId?: number;
    vendorSystem?: string;
    stepType: string;
    notes: string;
}

export interface WorkflowLaneModel {
    mediaInsertion: MediaInsertion;
    vendor: Vendor;
    assignee?: string;
    steps: { [key: string]: MediaInsertionStep };
}

export interface WorkflowModel {
    mediaplanSteps: WorkflowStep[];
    mediaInsertionSteps: MediaInsertionStep[];
    mediaInsertionLanes: (MediaInsertionLane & { assignee?: string })[];
    events: WorkflowEvent[];
}

export interface MediaplanFileMetadata {
    id: number;
    mediaplanId: number;
    name: string;
    uploadedBy: string;
    uploadedOn: string;
}

export interface WorkflowNotesFormModel {
    notes: string;
    classification?: LabelClassification;
}
