import { useDispatch, useSelector } from 'react-redux';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import {
    analyticsCustomReportsActions,
    CustomReportState,
} from 'platform/analytics/ducks/analyticsCustomReports.duck';
import { fetchAccessibleReports } from 'platform/app/components/Sidebar/navigation.util';
import { authSelectors } from 'platform/app/ducks/auth.duck';

type Operations = {
    reloadCustomReports: (advertiserSeatId: number | undefined) => Promise<CustomReportState>;
};

export const useCustomReports = (): CustomReportState & Operations => {
    const customReports = useSelector(analyticsSelectors.customReports);
    const profile = useSelector(authSelectors.ready.profile);
    const hasFeature = useSelector(authSelectors.hasFeatureFn);
    const dispatch = useDispatch();

    return {
        ...customReports,
        reloadCustomReports: (advertiserSeatId: number | undefined) =>
            fetchAccessibleReports(profile, advertiserSeatId, hasFeature('ROLE_ANALYTICS_VIEW'))
                .then((reports) => dispatch(analyticsCustomReportsActions.setCustomReports(reports)))
                .then((action) => action.payload),
    };
};
