import { ChatMessage } from 'platform/chat/chat.types';
import ChatTitleMessage from './ChatTitleMessage';

interface Props {
    messageData: ChatMessage;
    className?: string;
    titleMessageClassName?: string;
}

const ChatUserMessage = ({
    messageData: { prompt, fileName, contentParts },
    className,
    titleMessageClassName,
}: Props) => {
    const content = contentParts?.[0]?.content || '';
    if (contentParts?.[0]?.mimeType?.includes('image')) {
        return (
            <>
                {prompt?.name && (
                    <ChatTitleMessage
                        icon={<i className="far fa-book-open-cover text-primary me-1" />}
                        className={titleMessageClassName}
                    >
                        {prompt.name}
                    </ChatTitleMessage>
                )}
                <img
                    style={{ maxWidth: 500, objectFit: 'scale-down', width: '100%' }}
                    src={content ? `data:${contentParts?.[0]?.mimeType};base64,${content}` : ''}
                    alt="no"
                />
            </>
        );
    }
    if (prompt?.name) {
        return (
            <ChatTitleMessage
                icon={<i className="far fa-book-open-cover text-primary me-1" />}
                className={titleMessageClassName}
            >
                {prompt.name}
            </ChatTitleMessage>
        );
    }

    if (fileName) {
        return (
            <ChatTitleMessage
                icon={<i className="far fa-files text-primary me-1" />}
                className={titleMessageClassName}
            >
                {fileName}
            </ChatTitleMessage>
        );
    }

    return <div className={className}>{content}</div>;
};

export default ChatUserMessage;
