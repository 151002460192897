import { ExternalSystem, IntegrationStatus } from 'platform/common/constants/externalIntegration.constant';
import { makeOptions } from 'platform/common/utils/option.util';
import { AccessType } from 'platform/segments/constants/segmentAccess.constant';
import { SegmentType } from 'platform/segments/constants/segmentTypes.constant';
import { DataSharingTemplate } from 'platform/segments/segments.types';
import { Vendor } from 'platform/vendors/vendors.types';
import { Advertiser } from '../advertisers/advertiser.types';
import { Status } from '../common/constants/status.constant';
import {
    CodeType,
    EventType,
    FirePixelType,
    ServerCodeHost,
    UrlDetectionType,
    UrlSegmentTracking,
} from './constants/advertiserTag.constant';

export type TriggerType = 'INPUT_NAME' | 'DATA_ATTRIBUTE_NAME' | 'ELEMENT_ID' | 'ELEMENT_CLASS';

export type TriggerEventType = 'CLICK' | 'SUBMIT';

type InternalLogDataIdType = 'TIMESTAMP';

type FireLoggingPixelType = 'ALWAYS' | 'ACTION_PAGES_ONLY';

export type AdvertiserTagLoaderType = 'javascript' | 'iframe' | 'json';

const FIRE_LOGGING_PIXEL_LABELS: Record<FireLoggingPixelType, string> = {
    ALWAYS: 'Always',
    ACTION_PAGES_ONLY: 'On Action & Micro Action events only',
};

const INTERNAL_LOG_DATA_ID_TYPE_LABELS: Record<InternalLogDataIdType, string> = {
    TIMESTAMP: 'Timestamp',
};

export const INTERNAL_LOG_DATA_ID_TYPE_OPTIONS = makeOptions(INTERNAL_LOG_DATA_ID_TYPE_LABELS);

export const FIRE_LOGGING_PIXEL_OPTIONS = makeOptions(FIRE_LOGGING_PIXEL_LABELS);

export interface AdvertiserTagTrackersInfo {
    VISIT_TIME: {
        [key: string]: {
            maxTimeOnPage: number;
            minTimeOnPage: number;
            segmentName: string;
        };
    };
}

interface AdvertiserTagTrackers {
    toggles: {
        VISIT_TIME: boolean;
        VISIT_FREQUENCY: boolean;
        VISITED_PAGES: boolean;
        ABC: boolean;
        PRODUCT: boolean;
    };
    visitTimeIntervals: string[];
}

export interface AdvertiserTag {
    id: number;
    advertiserId: number;
    description: string;
    domain: string;
    debugPanelEnabled: boolean;
    internalLogDataEnabled: boolean;
    internalLogDataIdTypes: InternalLogDataIdType[];
    fireLoggingPixel: FireLoggingPixelType;
    oneActionPerOrderIdEnabled: boolean;
    oneActionPerOrderIdExpirationDays: number;
    externalLocationUrlEnabled: boolean;
    urlSegmentTracking: UrlSegmentTracking;
    state: Status;
    trackers: AdvertiserTagTrackers;
    updatedOn: string;
    lastUploadOn: string;
    updatedUserName: string;
    serverCodeHost: ServerCodeHost;
    cookieSwitch: boolean;
    clickIdSystems: string[];
}

export interface Segment extends Partial<SegmentAlertingRules> {
    advertiserId: number;
    advertiserTagId: number;
    advertiserTagVarName: string;
    availability: AccessType;
    classificationCodes: string[];
    convertible: boolean;
    customVarsCount: number;
    createdOn: string;
    description: string;
    eventParams: string[];
    id: number;
    integrations: {
        [key in ExternalSystem]?: {
            conversionPixelId?: string;
            externalId?: string;
            externalReadOnly?: boolean;
            state?: Status;
            status?: IntegrationStatus;
            targetingId?: string;
            type?: 'FULL' | 'PARTIAL';
            recencies?: {
                days: number;
            }[];
        };
    };
    name: string;
    performanceAdvertiserId?: string;
    performanceMediaId?: string;
    state: Status;
    type: SegmentType;
    updatedOn: string;
    updatedUserName: string;
    urls?: string[];
    visitFrequency?: string;
    triggerEventType?: TriggerEventType;
    triggerType?: TriggerType;
    triggerValues?: string[];
    floodlightActivityIds?: string[];
    googleAdsConversionIds?: string[];
    googleAnalyticsEventNames?: string[];
    bingGoalNames?: string[];
}

export type SegmentAlertingRules = {
    dailyVisitsAlertingThreshold: number;
    missingOrderIdsAlertingThreshold: number;
};

export enum SnippetType {
    STANDARD = 'STANDARD',
    DATA_SHARING = 'DATA_SHARING',
}

export interface StandardRuleSnippet {
    snippetType: SnippetType.STANDARD;
    type: CodeType;
    codeIntegralPart: string;
    vendorId: number;
    status: Status;
}

export interface StandardRuleSnippetModel {
    tempId?: string;
    snippetType: SnippetType.STANDARD;
    type: CodeType;
    codeIntegralPart: string;
    vendorId?: number;
    active: boolean;
}

export interface DataSharingRuleSnippet {
    snippetType: SnippetType.DATA_SHARING;
    vendorId: number;
    dataSharingTemplateId: number;
    status: Status;
    userVariables: Record<string, string>;
    globalVariableOverrides?: Record<string, string>;
    globalVariableDefaults?: Record<string, string>;
}

export interface DataSharingRuleSnippetModel {
    tempId?: string;
    snippetType: SnippetType.DATA_SHARING;
    vendorId?: number;
    dataSharingTemplateId?: number;
    active: boolean;
    userVariables: { key: string; value: string }[];
    globalVariables: { key: string; value: string; override: boolean }[];
}

export interface ThirdPartyTrackingRule {
    tempId?: string;
    active: boolean;
    ruleName: string;
    description: string;
    ruleSource: string;
    includedSegments: number[];
    excludedSegments: number[];
    fireOncePerDay: boolean;
    codeSnippets: (StandardRuleSnippet | DataSharingRuleSnippet)[];
    priority: number;
}

export interface ThirdPartyTrackingRuleModel {
    active: boolean;
    ruleName: string;
    description: string;
    ruleSource: string;
    includedSegments: number[];
    excludedSegments: number[];
    codeSnippets: (StandardRuleSnippetModel | DataSharingRuleSnippetModel)[];
    isNew?: boolean;
    tempId?: string;
    firePixels?: FirePixelType;
    fireOncePerDay: boolean;
    priority?: number;
}

export interface Event {
    advertiserTagVarName: string;
    name: string;
}

export interface AdvertiserTagFormEvent {
    id?: number;
    type: EventType;
    availability: AccessType;
    advertiserTagVarName: string;
    name: string;
    selected: boolean;
    disabled: boolean;
    customVarsCount?: number;
    editable: {
        convertible: boolean;
        productData: boolean;
        purchaseData: boolean;
    };
    convertible: boolean;
    productData: boolean;
    purchaseData: boolean;
}

export interface AdvertiserTagFormModel {
    advertiserTag: Partial<{
        id: number;
        advertiserId: number;
        domain: string;
        description: string;
        debugPanelEnabled: boolean;
        internalLogDataEnabled: boolean;
        internalLogDataIdTypes: InternalLogDataIdType[];
        fireLoggingPixel: FireLoggingPixelType;
        oneActionPerOrderIdEnabled: boolean;
        oneActionPerOrderIdExpirationDays: number;
        urlDetection: UrlDetectionType;
        urlSegmentTracking: UrlSegmentTracking;
        trackers: AdvertiserTagTrackers;
        serverCodeHost: ServerCodeHost;
        clickIdSystems: string[];
        clickIdsToggle: boolean;
    }>;
    events: AdvertiserTagFormEvent[];
    advertiser: Advertiser;
    trackersInfo: AdvertiserTagTrackersInfo;
    clickIdVendors: Vendor[];
}

export interface AdvertiserTagLite {
    actionMap: string;
    integrationMap: string;
    customVarConfig: string;
    urlSegmentList: string;
}

export interface TagLiteTrackingCodeRequest {
    urlSegmentList: string;
    includeLoaderHtml: boolean;
    includeLoaderJs: boolean;
}

export interface AdvertiserTagLiteIncludes {
    includeLoaderHtml: boolean;
    includeLoaderJs: boolean;
}

export interface AdvertiserTagDownloadFormModel {
    advertiserTag: Partial<AdvertiserTag>;
    advertiserTagId: number;
    events: AdvertiserTagFormEvent[];
    advertiserTagLite: AdvertiserTagLite;
    advertiserTagLoaderType: AdvertiserTagLoaderType;
    includes: AdvertiserTagLiteIncludes;
}

export interface ThirdPartyCodesFormValues {
    advertiserTagId: number;
    rules: ThirdPartyTrackingRuleModel[];
    segments: Segment[];
    vendors: Vendor[];
    templates: DataSharingTemplate[];
    advertiser: Advertiser;
}
