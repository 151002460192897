import { sortBy } from 'lodash-es';
import axiosBff from 'platform/axios/axiosBff';
import { TimedOffer } from './timedOffer.types';

export const fetchTimedOffers = ({ advertiserId }: { advertiserId: number }) =>
    axiosBff
        .get<TimedOffer[]>('/campaign/api/time-offers', { params: { advertiserId } })
        .then((r) => sortBy(r.data, ['status', 'name']));

export const fetchTimedOffer = (id: number) =>
    axiosBff.get<TimedOffer>(`/campaign/api/time-offers/${id}`).then((r) => r.data);

export const createTimedOffer = (timedOffer: TimedOffer) =>
    axiosBff.post<TimedOffer>('/campaign/api/time-offers', timedOffer).then((r) => r.data);

export const updateTimedOffer = (timedOffer: TimedOffer) =>
    axiosBff.put<TimedOffer>(`/campaign/api/time-offers/${timedOffer.id}`, timedOffer).then((r) => r.data);
