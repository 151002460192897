import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import numeral from 'numeral';
import Popover from 'platform/common/components/Popover/Popover';
import SwitchWithChildren from 'platform/common/components/SwitchWithChildren/SwitchWithChildren';
import { CURRENCY_SYMBOL } from 'platform/common/constants/common.constant';
import { formatBudget } from 'platform/common/utils/number.util';
import { limitFloatTo, nonNegativeNumber } from 'platform/common/utils/validators.util';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import { MediaplanTreeFormModel } from 'platform/mediaplan/mediaplan.types';
import './MediaInsertionBudget.scss';

interface Props {
    field: string;
    newValue: number | undefined;
    oldValue: number | undefined;
    className?: string;
    onChange: FormikProps<MediaplanTreeFormModel>['setFieldValue'];
}

const MediaInsertionBudget = ({ field, newValue, oldValue, className, onChange }: Props) => {
    const [inc, setInc] = useState(true);
    const [delta, setDelta] = useState(Number(newValue) - Number(oldValue));
    const deltaRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const newBudget = numeral(oldValue)
            .add(inc ? delta : -delta)
            .value();
        onChange(field, newBudget);
    }, [inc, delta]);

    const changeInc = (value: boolean) => {
        setInc(value);

        if (deltaRef.current) {
            deltaRef.current.select();
        }
    };

    const changeDelta = (value: number) => setDelta(Math.abs(value));

    const changeNewBudget = (value: number) => {
        const diff = numeral(value)
            .subtract(oldValue ?? 0)
            .value();
        if (diff === null) {
            return;
        }

        setDelta(Math.abs(diff));
        setInc(diff >= 0);
    };

    const budgetValidators = [limitFloatTo(2), nonNegativeNumber];
    const invalid = budgetValidators.some((validator) => validator(newValue));

    return (
        <Popover
            placement="top-end"
            className={className}
            preventOverflow
            preventToggle={invalid}
            renderPopover={() => (
                <div className="MediaInsertionBudget-popover">
                    {oldValue && (
                        <div className="mb-3">
                            <div className="d-flex mb-3 align-items-center">
                                <div className="MediaNode-popoverTitle mb-0">Old budget /</div>
                                <div className="text-muted ms-1">{formatBudget(oldValue)}</div>
                            </div>
                            <div className="d-flex align-items-end">
                                <InputGroup className="me-3">
                                    <Input
                                        innerRef={deltaRef}
                                        value={delta || ''}
                                        type="number"
                                        className="border-right-0"
                                        autoFocus
                                        onFocus={(e) => e.currentTarget.select()}
                                        onChange={(e) => changeDelta(Number(e.currentTarget.value))}
                                    />
                                    <InputGroupText className="bg-white">{CURRENCY_SYMBOL}</InputGroupText>
                                </InputGroup>
                                <SwitchWithChildren
                                    checked={!inc}
                                    uncheckedIconName="far fa-arrow-trend-up text-success"
                                    checkedIconName="far fa-arrow-trend-down text-danger"
                                    uncheckedText="Inc"
                                    checkedText="Dec"
                                    onChange={(value) => changeInc(!value)}
                                />
                            </div>
                        </div>
                    )}
                    <div className="mb-2">New budget</div>
                    <FormNumberInput
                        name={field}
                        type="number"
                        label={CURRENCY_SYMBOL}
                        step="0.01"
                        validate={budgetValidators}
                        onFocus={(e: SyntheticEvent<HTMLInputElement>) => e.currentTarget.select()}
                        onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                            changeNewBudget(Number(e.currentTarget.value))
                        }
                    />
                </div>
            )}
        >
            <div
                role="button"
                tabIndex={-1}
                className={classNames('MediaInsertionBudget', {
                    'Mediaplan-changed': oldValue && newValue && oldValue !== newValue,
                })}
            >
                {formatBudget(newValue)}
            </div>
        </Popover>
    );
};

export default MediaInsertionBudget;
