import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import classNames from 'classnames';
import { isEqual, keys } from 'lodash-es';
import qs from 'qs';
import { NavigationParams, ReportFilterWithOperator } from 'platform/analytics/analytics.types';
import { getCompatibleFilters } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { FilterInputProps } from 'platform/analytics/modes/Analytics';
import AnalyticsFilter from 'platform/analytics/reportComponents/ReportFilters/AnalyticsFilter';
import FiltersContainer from 'platform/common/components/FiltersContainer/FiltersContainer';
import { useFeature } from 'platform/common/hooks/useFeature';
import { sortByName } from 'platform/common/utils/array.util';
import { parseQuery } from 'platform/common/utils/url.util';
import store from 'platform/store';
import { areFilterValuesResolved, useReportFilterOptions } from './useReportFilterOptions';

interface Props {
    filterInputProps?: { [key: string]: FilterInputProps };
    showCampaignFilters?: boolean;
}

const ReportFilters = ({ filterInputProps, showCampaignFilters }: Props) => {
    const isSuperuser = useFeature('ADVANCED_ADMIN_SETTINGS');
    const filtersWithOperator = useSelector(
        analyticsSelectors.filtersWithOperator,
        (oldValue, newValue) =>
            newValue.length === oldValue.length && newValue.every((o, i) => isEqual(o, oldValue[i]))
    );
    const settings = useSelector(analyticsSelectors.settings);
    const { definitions } = useAnalyticsMetadata();
    const dispatch = useDispatch();
    const canEditFilters = useFeature('ANALYTICS_FILTERS_EDIT');
    const { filterOptionsMap, resolveFilterOptions, changeFilter, loading } = useReportFilterOptions();
    const queryParams: NavigationParams = parseQuery(location.search);

    const handleFiltersResolve = async (filters: ReportFilterWithOperator[]) => {
        if (
            loading ||
            !filters.length ||
            areFilterValuesResolved(filters) ||
            (!!filterOptionsMap && keys(filterOptionsMap).length === filters.length)
        ) {
            return;
        }
        const resolvedFilters = await resolveFilterOptions(filters);

        // we need to fetch filters after resolve cause "filtersWithOperator" will have values that where on "resolveFilterOptions" call
        const currentFilters = store.getState().analyticsReducer.settings.filters;
        // Update filters only if keys matches
        // This is to prevent filters override when user changes between reports
        // and filters of previous report are not resolved yet
        if (
            !areFilterValuesResolved(currentFilters) &&
            isEqual(
                resolvedFilters.map(({ key }) => key),
                currentFilters.map(({ key }) => key)
            )
        ) {
            dispatch(analyticsSettingsActions.changeFilters(resolvedFilters));
        }
    };

    const handleFilterChange = async (filter: ReportFilterWithOperator) => {
        const filters = await changeFilter(filter);
        dispatch(analyticsSettingsActions.changeFilters(filters));
        addFiltersToUrl(filters);
    };

    // adding filters to url to support drills and linked reports
    const addFiltersToUrl = (filters: ReportFilterWithOperator[]) => {
        const nonEmptyFilters = filters
            .filter((f) => !!f.values.length)
            .map(({ resolvedValues, ...rest }) => rest);

        dispatch(
            push({
                search: qs.stringify({
                    ...queryParams,
                    settings: JSON.stringify({
                        filters: nonEmptyFilters,
                    }),
                }),
            })
        );
    };
    const handleFilterRemove = (filter: ReportFilterWithOperator) => {
        dispatch(analyticsSettingsActions.removeFilter(filter));
        addFiltersToUrl(filtersWithOperator.filter((fil) => fil.key !== filter.key));
    };

    useEffect(() => {
        handleFiltersResolve(filtersWithOperator);
    }, [filtersWithOperator]);

    const getAvailableFilters = () => {
        const compatibleFilters = getCompatibleFilters(settings.components);

        const availableFilters = sortByName(
            definitions.filters
                // remove advertiser filter cause it comes from active advertiser filter
                .filter(
                    (f) =>
                        f.target !== 'advertiser_id' && !filtersWithOperator.find((v) => v.key === f.target)
                )
                .filter((f) => {
                    const dimension = definitions.dimensions.find((d) => d.key === f.target);
                    return !dimension?.isSystem || isSuperuser;
                })
                .filter((f) =>
                    compatibleFilters ? compatibleFilters.some((cf) => cf.target === f.target) : true
                )
                .filter((filter) => {
                    if (!showCampaignFilters && filter?.target) {
                        return !(
                            filter.target.startsWith('campaign_product_') ||
                            filter.target.startsWith('strategy_product_') ||
                            filter.target.startsWith('campaign_flight_') ||
                            filter.target.startsWith('strategy_flight_')
                        );
                    }

                    return true;
                })
        );

        return availableFilters.map((f) => ({
            label: `${f.name} [${f.target}]`,
            key: f.target,
            action: () => {
                const filter: ReportFilterWithOperator = {
                    key: f.target,
                    operator: 'IS',
                    values: [],
                };
                handleFilterChange(filter);
            },
        }));
    };

    const filters = filtersWithOperator.filter((f) => {
        const filterDefinition = definitions.filters.find((fd) => fd.target === f.key);
        return !!filterDefinition && f.key !== 'advertiser_id';
    });

    return (
        <FiltersContainer
            filterOptions={getAvailableFilters()}
            filtersCount={filters.length}
            canEdit={canEditFilters}
        >
            {({ filtersContainerRef, isFilterHidden }) =>
                filters.map((filter, index) => {
                    const filterDefinition = definitions.filters.find((fd) => fd.target === filter.key)!;
                    const filterOptions = filterOptionsMap?.[filter.key];
                    return (
                        <div
                            key={filter.key}
                            className={classNames({
                                'FiltersContainer-hidden': isFilterHidden(index),
                            })}
                        >
                            <AnalyticsFilter
                                container={filtersContainerRef}
                                filter={filter}
                                filterDefinition={filterDefinition}
                                options={filterOptions?.options ?? []}
                                error={filterOptions?.error}
                                disabled={!canEditFilters}
                                loading={loading}
                                onChange={(newFilter) => {
                                    if (isEqual(newFilter, filter)) {
                                        return;
                                    }
                                    handleFilterChange(newFilter);
                                }}
                                filterInputProps={filterInputProps?.[filter?.key]}
                                refetch={() => resolveFilterOptions(filtersWithOperator)}
                                onRemove={() => handleFilterRemove(filter)}
                            />
                        </div>
                    );
                })
            }
        </FiltersContainer>
    );
};

export default ReportFilters;
