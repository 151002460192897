import { memo, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { isEqual } from 'lodash-es';
import { TableState } from 'platform/analytics/analytics.types';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import CustomFilterIndicator from 'platform/analytics/reportComponents/CustomFilterIndicator';

interface Props {
    Toolbar: ReactNode;
    componentState: TableState;
}

const ReportTableTopToolbar = memo(
    ({ Toolbar, componentState }: Props) => {
        const dispatch = useDispatch();

        const handleTablePresetChange = (index: number) =>
            dispatch(
                analyticsSettingsActions.changeComponentState({
                    ...componentState,
                    activePresetIndex: index,
                })
            );

        return (
            <div className="custom-tabs">
                {Toolbar && <div className="mb-3">{Toolbar}</div>}
                <Nav tabs className="border-bottom-0">
                    {componentState.presets.map((preset, index) =>
                        preset.name ? (
                            <NavItem key={preset.name}>
                                <NavLink
                                    className="p-0"
                                    active={componentState.activePresetIndex === index}
                                    onClick={() => handleTablePresetChange(index)}
                                >
                                    <div
                                        className={classNames('pb-2 pe-3', {
                                            'ps-3': !preset.customFilters?.length,
                                        })}
                                    >
                                        {!!preset.customFilters?.length && (
                                            <CustomFilterIndicator
                                                className="me-2"
                                                filters={preset.customFilters}
                                            />
                                        )}
                                        {preset.name}
                                    </div>
                                </NavLink>
                            </NavItem>
                        ) : null
                    )}
                </Nav>
            </div>
        );
    },
    (prevProps, nextProps) => isEqual(prevProps.componentState, nextProps.componentState)
);

export default ReportTableTopToolbar;
