import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Nav, NavItem } from 'reactstrap';
import { difference, max, uniqueId } from 'lodash-es';
import moment from 'moment';
import { NavigationItem } from 'platform/app/app.types';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { isDefined } from 'platform/common/common.types';
import SwitchWithChildren from 'platform/common/components/SwitchWithChildren/SwitchWithChildren';
import { NAVIGATION_STYLE_CLASSES } from 'platform/customReports/customReports.constant';
import NavigationDropdown from './NavigationDropdown';
import NavigationLink from './NavigationLink';
import { NavigationMode } from './useNavMode';

interface Props {
    items: NavigationItem[];
    navMode: NavigationMode;
    onChangeNavMode: (checked: boolean) => void;
}

const ActionButton = ({ onClick, label }: { label: string; onClick?: () => void }) => (
    <Button size="sm" className="text-muted border-0" color="none" onClick={onClick}>
        {label}
    </Button>
);

const isActive = (item: NavigationItem): boolean => item.active || (item.children ?? []).some(isActive);

const recentlyAccessed = (items: NavigationItem[]): NavigationItem[] => {
    const recentItems = items.filter((item) => {
        if (isActive(item)) return true;
        const lastAccessed = max(item.children?.map((c) => c.lastAccessedOn)) ?? item.lastAccessedOn;
        return lastAccessed && moment().diff(moment(lastAccessed), 'days') < 60;
    });

    const deficit = 5 - recentItems.length;
    if (deficit > 0) {
        const fillerItems = difference(items, recentItems).slice(0, deficit);
        return [...recentItems, ...fillerItems].sort((a, b) => items.indexOf(a) - items.indexOf(b));
    }

    return recentItems;
};

const Navigation = ({ items, navMode, onChangeNavMode }: Props) => {
    const { adminUser } = useSelector(authSelectors.ready.profile);
    const [showAll, setShowAll] = useState(false);

    const renderItems = (menuItems: NavigationItem[], depth: number = 0) =>
        menuItems.map((item) => {
            const { type, children, name, display, showRecentChildren } = item;
            const key = uniqueId(name);

            if (type === 'SEPARATOR') {
                return <div key={key} className="nav-separator" />;
            }

            if (type === 'PLACEHOLDER') {
                return <div key={key} className="flex-fill" />;
            }

            if (children?.length) {
                const active = isActive(item);
                const shownChildren = showRecentChildren && !showAll ? recentlyAccessed(children) : children;
                return (
                    <NavigationDropdown
                        key={key}
                        item={item}
                        isActive={active}
                        isOpen={active || (!!showRecentChildren && showAll)}
                        isNested={depth > 0}
                    >
                        {renderItems(shownChildren, depth + 1)}
                        {shownChildren.length < children.length && (
                            <ActionButton label="... show more" onClick={() => setShowAll(true)} />
                        )}
                    </NavigationDropdown>
                );
            }

            const classNames = display?.labelStyles?.map((s) => NAVIGATION_STYLE_CLASSES[s]);
            return (
                <NavItem key={key} className={classNames?.filter(isDefined).join(' ')}>
                    <NavigationLink item={item} />
                </NavItem>
            );
        });

    return (
        <>
            {adminUser && (
                <SwitchWithChildren
                    checked={navMode === 'admin'}
                    className="mx-4 mb-2"
                    checkedIconName="fa-user-crown"
                    checkedText="Admin"
                    uncheckedIconName="fa-border-all"
                    uncheckedText="General"
                    style={{ height: '36px' }}
                    onChange={onChangeNavMode}
                />
            )}

            <nav className="sidebar-nav mt-1">
                <Nav className="ps-4 pe-3">{renderItems(items)}</Nav>
            </nav>
        </>
    );
};

export default Navigation;
