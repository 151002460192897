import { useDispatch, useSelector } from 'react-redux';
import { Card, Input, Label } from 'reactstrap';
import { analyticsModeOptions, datePickerModeOptions } from 'platform/analytics/analytics.constants';
import { AnalyticsMode, AnalyticsSettings, DatePickerMode } from 'platform/analytics/analytics.types';
import { makeGridColumnOptions } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { activeAdvertiserSelectors, AdvertiserOption } from 'platform/common/ducks/activeAdvertiser.duck';

interface Props {
    settings: AnalyticsSettings;
    isAdmin: boolean;
    onModeChange: (mode: AnalyticsMode) => void;
}

const findAttribute = (
    advertiserIds: number[],
    advertiserOptions: AdvertiserOption[],
    attributeName: string
) =>
    advertiserIds.length === 1
        ? advertiserOptions.find((option) => option.id === advertiserIds[0])?.additionalAttributes?.[
              attributeName
          ]
        : undefined;

const ReportSettings = ({ settings, onModeChange, isAdmin }: Props) => {
    const dispatch = useDispatch();
    const advertiserIds = useSelector(activeAdvertiserSelectors.ids);
    const advertiserOptions = useSelector(activeAdvertiserSelectors.options);
    const { hasDefaultMetrics } = useSelector(analyticsSelectors.meta);
    const vatRate = findAttribute(advertiserIds, advertiserOptions, 'analytics.vat-rate');
    const optInRate = findAttribute(advertiserIds, advertiserOptions, 'analytics.opt-in-rate');
    const canToggleDefaultMetrics = settings.section === 'CENTRAL_ANALYTICS' && hasDefaultMetrics;

    const onDatePickerModeChange = (datePickerMode: DatePickerMode) => {
        dispatch(
            analyticsSettingsActions.changeSettings({
                ...settings,
                datePickerMode,
            })
        );
    };

    return (
        <Card className="my-3 px-3 flex-row flex-wrap align-items-center border-primary shadow-none">
            <div className="d-flex my-3">
                <SelectWithAddon
                    value={settings.mode}
                    options={analyticsModeOptions}
                    className="me-3"
                    isDisabled={!isAdmin}
                    isClearable={false}
                    onChange={onModeChange}
                />
                {settings.mode !== 'INTERACTIVE' && (
                    <SelectWithAddon
                        value={settings.datePickerMode}
                        options={datePickerModeOptions}
                        className="me-3"
                        isDisabled={!isAdmin}
                        isClearable={false}
                        onChange={onDatePickerModeChange}
                    />
                )}
                <SelectWithAddon
                    value={settings.gridColumns}
                    options={makeGridColumnOptions(12)}
                    className="me-3"
                    onChange={(value) => dispatch(analyticsSettingsActions.changeGridColumns(value))}
                />
            </div>

            <div className="d-flex my-3">
                <Label className="d-flex align-items-center mb-0 me-3 cursor-pointer">
                    <Input
                        type="checkbox"
                        className="position-static m-0"
                        checked={vatRate ? !!settings.includeVatRate : false}
                        disabled={!vatRate}
                        onChange={(e) =>
                            dispatch(analyticsSettingsActions.changeIncludeVatRate(e.target.checked))
                        }
                    />
                    <Tooltip
                        tooltipVisible={advertiserIds.length > 1 || !vatRate}
                        renderTooltip={() => {
                            if (advertiserIds.length > 1) {
                                return 'Only one Advertiser can be selected in the filter to display data with VAT';
                            }

                            if (!vatRate) {
                                return 'VAT is not defined for this Advertiser';
                            }

                            return null;
                        }}
                    >
                        <span className="ms-1 h-1">VAT included</span>
                    </Tooltip>
                </Label>
                <Label className="d-flex align-items-center mb-0 me-3 cursor-pointer">
                    <Input
                        type="checkbox"
                        className="position-static m-0"
                        disabled={!canToggleDefaultMetrics}
                        checked={canToggleDefaultMetrics ? settings.includeDefaultMetrics : false}
                        onChange={(e) =>
                            dispatch(
                                analyticsSettingsActions.changeSettings({
                                    ...settings,
                                    includeDefaultMetrics: e.target.checked,
                                })
                            )
                        }
                    />
                    <Tooltip
                        renderTooltip={() => {
                            if (!hasDefaultMetrics) {
                                return 'Default metrics are not defined for this advertiser';
                            }

                            if (settings.section !== 'CENTRAL_ANALYTICS') {
                                return 'Default metrics can be added only to central analytics';
                            }

                            return 'Adds default advertiser metrics to corresponding sections';
                        }}
                    >
                        <span className="ms-1 h-1">Default metrics</span>
                    </Tooltip>
                </Label>
                <Label className="d-flex align-items-center mb-0 me-3 cursor-pointer">
                    <Input
                        type="checkbox"
                        className="position-static m-0"
                        checked={optInRate ? !!settings.modelOptIn : false}
                        disabled={!optInRate}
                        onChange={(e) =>
                            dispatch(analyticsSettingsActions.changeModelOptIn(e.target.checked))
                        }
                    />
                    <Tooltip
                        tooltipVisible={advertiserIds.length > 1 || !optInRate}
                        renderTooltip={() => {
                            if (advertiserIds.length > 1) {
                                return 'Only one Advertiser can be selected in the filter to model opt-in numbers';
                            }

                            if (!optInRate) {
                                return 'Opt-in rate is not defined for this Advertiser';
                            }

                            return null;
                        }}
                    >
                        <span className="ms-1 h-1">Opt-In Modelling</span>
                    </Tooltip>
                </Label>
                {isAdmin && (
                    <Label className="d-flex align-items-center mb-0 me-3 cursor-pointer">
                        <Input
                            type="checkbox"
                            className="position-static m-0"
                            checked={settings.debugMode}
                            onChange={(e) =>
                                dispatch(
                                    analyticsSettingsActions.changeSettings({
                                        ...settings,
                                        debugMode: e.target.checked,
                                    })
                                )
                            }
                        />
                        <span className="ms-1 h-1">Debug mode</span>
                    </Label>
                )}
                <Label className="d-flex align-items-center mb-0 me-3 cursor-pointer">
                    <Input
                        type="checkbox"
                        className="position-static m-0"
                        checked={settings.inlineEditing}
                        onChange={(e) =>
                            dispatch(
                                analyticsSettingsActions.changeSettings({
                                    ...settings,
                                    inlineEditing: e.target.checked,
                                })
                            )
                        }
                    />
                    <span className="ms-1 h-1">Inline editing</span>
                </Label>
                <Label className="d-flex align-items-center mb-0 me-3 cursor-pointer">
                    <Input
                        type="checkbox"
                        className="position-static m-0"
                        checked={settings.showLogBookLink === undefined || settings.showLogBookLink} // checking undefined for backward compatibility
                        onChange={(e) =>
                            dispatch(
                                analyticsSettingsActions.changeSettings({
                                    ...settings,
                                    showLogBookLink: e.target.checked,
                                })
                            )
                        }
                    />
                    <span className="ms-1 h-1">Log book link</span>
                </Label>
            </div>
        </Card>
    );
};

export default ReportSettings;
