import { useSelector } from 'react-redux';
import { Button, Card } from 'reactstrap';
import {
    ALGORITHM_AREA_LABELS,
    ALGORITHM_LANGUAGE_LABELS,
    ALGORITHM_LANGUAGE_VALUE,
    ALGORITHM_SECTION_LABELS,
} from 'platform/algorithms/algorithmManager.constant';
import { AlgorithmTemplateFormModel } from 'platform/algorithms/algorithms.types';
import AlgorithmSettings from 'platform/algorithms/components/AlgorithmSettings/AlgorithmSettings';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import SupportedMacrosPopover from 'platform/common/components/SupportedMacrosPopover/SupportedMacrosPopover';
import { STATUS_OPTIONS } from 'platform/common/constants/status.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { makeOptions } from 'platform/common/utils/option.util';
import { required } from 'platform/common/utils/validators.util';
import FormButtonArray from 'platform/formik/FormButtonArray/FormButtonArray';
import FormCodeEditor from 'platform/formik/FormCodeEditor/FormCodeEditor';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormStateSelect from 'platform/formik/FormStateSelect/FormStateSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { FormTextEditor } from 'platform/formik/FormTextEditor/FormTextEditor';
import generateGASWithoutExternalFeatures from 'platform/generateGASWithoutExternalFeatures';

export const ALGORITHM_URL_MACROS = [
    { key: '${ADVERTISER_ID}', label: 'Advertiser ID management' },
    { key: '${ALGORITHM_ID}', label: 'Algorithm ID' },
];

const AlgorithmTemplateForm = ({
    onCancel,
    labels,
    canEdit,
    formikProps: {
        initialValues,
        submitForm,
        values: {
            hasSettings,
            hasCode,
            hasMultipleSettings,
            settings,
            code,
            display,
            googleAdScript,
            externallyAvailable,
        },
        setFieldValue,
    },
}: FormProps<AlgorithmTemplateFormModel>) => {
    const advertiserOptions = useSelector(activeAdvertiserSelectors.options);
    return (
        <CardForm
            title={`${labels.prefix} Algorithm Template`}
            subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
            submitLabel={labels.submit}
            animationDelay={0}
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info" subtitle="required">
                <FormRow label="Status">
                    <FormStateSelect name="status" options={STATUS_OPTIONS} validate={required} />
                </FormRow>
                <FormRow label="Name">
                    <FormInput name="name" type="text" validate={required} />
                </FormRow>
                <FormRow label="Show for">
                    <FormSelect
                        name="display.onlyAdvertiserIds"
                        options={advertiserOptions}
                        isMulti
                        valueKey="id"
                        labelKey="name"
                    />
                </FormRow>
                <FormRow label="Section">
                    <FormSelect
                        name="display.section"
                        options={makeOptions(ALGORITHM_SECTION_LABELS)}
                        validate={required}
                    />
                </FormRow>
                <FormRow label="Area">
                    <FormSelect
                        name="display.area"
                        options={makeOptions(ALGORITHM_AREA_LABELS)}
                        validate={required}
                    />
                </FormRow>
                <FormRow label="Short Description">
                    <FormInput name="display.shortDescription" type="text" validate={required} />
                </FormRow>
                <FormRow label="HTML Description">
                    <FormTextEditor name="display.htmlDescription" />
                </FormRow>
                <FormRow label="Algorithm has settings">
                    <FormSwitch name="hasSettings" />
                </FormRow>
                {hasSettings && (
                    <FormRow label="Multiple settings">
                        <FormSwitch name="hasMultipleSettings" />
                    </FormRow>
                )}
                <FormRow label="Algorithm has code">
                    <FormSwitch name="hasCode" />
                </FormRow>
            </ControlledCard>
            {hasSettings && (
                <ControlledCard title="Settings">
                    <AlgorithmSettings
                        settings={settings}
                        settingsLanguage={display.settingsLanguage}
                        hasMultipleSettings={hasMultipleSettings}
                        onChange={(value) => setFieldValue('settings', value)}
                    />
                </ControlledCard>
            )}
            {hasCode && (
                <ControlledCard title="Code">
                    <FormCodeEditor
                        name="code"
                        options={{
                            mode: ALGORITHM_LANGUAGE_VALUE[display.codeLanguage],
                            lineWrapping: true,
                            readOnly: false,
                            lineNumbers: true,
                        }}
                    />
                    <FormRow label="Language">
                        <FormSelect
                            name="display.codeLanguage"
                            options={makeOptions(ALGORITHM_LANGUAGE_LABELS)}
                            onChange={() => setFieldValue('externallyAvailable', false)}
                            validate={required}
                            isClearable={false}
                        />
                    </FormRow>
                </ControlledCard>
            )}
            <ControlledCard title="Additional Settings">
                <FormRow label="Code version">
                    <FormInput name="version" type="text" validate={required} />
                </FormRow>
                <FormRow label="Order">
                    <FormInput name="display.order" type="text" validate={required} />
                </FormRow>
                <FormRow label="Setup URL">
                    <FormInput name="display.setupUrl" type="text" />
                </FormRow>
                <Card className="bg-light pt-3 pe-3">
                    <FormRow label="Log URL">
                        <FormInput name="display.logUrl" type="text" />
                    </FormRow>
                    <FormRow label="History URL">
                        <FormInput name="display.historyUrl" type="text" />
                    </FormRow>
                    <FormRow label="Preview URL">
                        <FormInput name="display.previewUrl" type="text" />
                    </FormRow>
                    <FormRow label="Run URL">
                        <FormInput name="display.runUrl" type="text" />
                    </FormRow>
                    <FormRow>
                        <SupportedMacrosPopover list={ALGORITHM_URL_MACROS} />
                    </FormRow>
                </Card>
                <FormRow label="Demo mode">
                    <FormSwitch name="display.onlyDemoMode" />
                </FormRow>
                <FormRow label="Google Ad Script">
                    <FormSwitch
                        name="googleAdScript"
                        onChange={(e) => {
                            if (e.target.checked) {
                                setFieldValue('externallyAvailable', false);
                            }
                        }}
                    />
                </FormRow>
                {!googleAdScript && display.codeLanguage === 'BIG_QUERY' && (
                    <FormRow label="Externally available">
                        <FormSwitch name="externallyAvailable" />
                    </FormRow>
                )}
                {externallyAvailable && (
                    <FormRow label="External File Format">
                        <FormButtonArray
                            buttons={[
                                { label: 'Excel', value: 'EXCEL' },
                                { label: 'Csv', value: 'CSV' },
                                { label: 'Json', value: 'JSON' },
                            ]}
                            name="format"
                        />
                    </FormRow>
                )}
                {googleAdScript && (
                    <div className="d-flex flex-column">
                        <Button
                            onClick={() => {
                                const generatedCode = generateGASWithoutExternalFeatures({
                                    settings,
                                    code,
                                });
                                if (navigator) navigator.clipboard.writeText(generatedCode);
                            }}
                        >
                            Copy algorithm code without external features
                        </Button>
                    </div>
                )}
            </ControlledCard>
            <ControlledCard isExpanded={false} title="Admin notes">
                <FormRow label="Admin notes">
                    <FormInput name="adminNotes" type="textarea" />
                </FormRow>
            </ControlledCard>
        </CardForm>
    );
};

export default AlgorithmTemplateForm;
