import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push, replace } from 'redux-first-history';
import { isEmpty, isEqual } from 'lodash-es';
import { isDefined } from 'platform/common/common.types';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { stringifyParams } from 'platform/common/utils/url.util';

type QueryParamValue = string | number | boolean | undefined | (string | number)[];
type QueryParams = Record<string, QueryParamValue>;

type Props<T> = {
    queryParams: T;
    setQueryParams: (queryParams: Partial<T>) => void;
    returnUrl: string;
};

const isNotEmpty = (value: QueryParamValue) => {
    if (Array.isArray(value)) {
        return value.length > 0;
    }

    return isDefined(value) && value !== '';
};

const formatValue = (value: QueryParamValue) =>
    Array.isArray(value) ? value.map(encodeURIComponent).join(',') : encodeURIComponent(value ?? '');

const parseNumber = (value: string) => {
    const decoded = decodeURIComponent(value);
    return isNaN(Number(decoded)) ? decoded : Number(decoded);
};

const removeEmptyValues = (params: QueryParams): QueryParams =>
    Object.fromEntries(Object.entries(params).filter(([, value]) => isNotEmpty(value)));

const toArray = (initialValue: QueryParamValue, value: QueryParamValue): QueryParamValue =>
    Array.isArray(initialValue) && !Array.isArray(value) && typeof value !== 'boolean' && value !== undefined
        ? [value]
        : value;

const parseValue = (value: string | null, initialValue: QueryParamValue): QueryParamValue => {
    if (value === null) {
        return initialValue;
    }

    if (typeof initialValue === 'string') {
        return value;
    }
    return value.includes(',') ? value.split(',').map(parseNumber) : parseNumber(value);
};

const mergeQueryWithCurrentParams = (currentParams: QueryParams): QueryParams => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('advertiserId');

    return Object.fromEntries(
        Object.entries(currentParams).map(([key, value]) => [
            key,
            toArray(currentParams[key], parseValue(searchParams.get(key), value)),
        ])
    );
};

const resolveValue = (value: QueryParamValue, queryParamValue?: QueryParamValue) => {
    if (!queryParamValue) {
        // we want to keep same reference to avoid rerenders
        if (isEmpty(value)) {
            return value;
        }

        return Array.isArray(value) ? [] : '';
    }

    return isEqual(queryParamValue, value) ? value : queryParamValue;
};

const updateParamValues = (currentParams: QueryParams, queryParams: QueryParams): QueryParams =>
    Object.fromEntries(
        Object.entries(currentParams).map(([key, value]) => [key, resolveValue(value, queryParams[key])])
    );

const getReturnUrl = (advertiserId: number, currentParams: QueryParams) => {
    const queryParams = mergeQueryWithCurrentParams(currentParams);

    const url = formatUrl({
        advertiserId,
        ...removeEmptyValues({
            ...currentParams,
            ...queryParams,
        }),
    });

    return url;
};

const resolveParams = (currentParams: QueryParams) => {
    const queryParams = mergeQueryWithCurrentParams(currentParams);

    if (isEqual(currentParams, queryParams)) {
        return undefined;
    }

    return updateParamValues(currentParams, queryParams);
};

const formatUrl = (params: QueryParams) => `${location.pathname}?${stringifyParams(params)}`;

export const useUrlSync = <T extends QueryParams>(initialParams: T): Props<T> => {
    const dispatch = useDispatch();
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    // callback signature used to call function only on first render
    const [returnUrl, setReturnUrl] = useState(() => {
        const url = getReturnUrl(advertiserId, initialParams);
        dispatch(replace(url));
        return url;
    });
    const [currentParams, setCurrentParams] = useState<QueryParams>(
        () => resolveParams(initialParams) ?? initialParams
    );

    useEffect(() => {
        const params = resolveParams(currentParams);
        if (params) {
            setCurrentParams(params);
        }
    }, [location.search]);

    const handleQueryParamsChange = (newParams: Partial<T>) => {
        if (Object.entries(newParams).every(([key, value]) => isEqual(value, currentParams[key]))) {
            return;
        }

        const searchParams = new URLSearchParams(location.search);
        const params = Object.entries(newParams);

        params.forEach(([key, value]) => {
            if (isNotEmpty(value)) {
                searchParams.set(key, formatValue(value));
            } else {
                searchParams.delete(key);
            }
        });

        const url = formatUrl(Object.fromEntries(Array.from(searchParams.entries())));

        setReturnUrl(url);
        setCurrentParams({
            ...currentParams,
            ...newParams,
        });

        dispatch(push(url));
    };

    return {
        queryParams: currentParams as T,
        setQueryParams: handleQueryParamsChange,
        returnUrl,
    };
};
