import { ChangeEvent, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import classNames from 'classnames';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import { useFeature } from 'platform/common/hooks/useFeature';
import CanNotEditWarning from '../../../common/components/CanNotEditWarning/CanNotEditWarning';
import Switch from '../../../common/components/Switch/Switch';
import TwoFactorAuthenticationService from '../../services/twoFactorAuthentication.service';

interface State {
    isSwitchOn: boolean;
    pending: boolean;
}

const TwoFactorAuthentication = () => {
    const canEdit = useFeature('ROLE_ADMINPANEL_EDIT');
    const [{ isSwitchOn, pending }, setState] = useState<State>({ isSwitchOn: false, pending: true });

    useEffect(() => {
        TwoFactorAuthenticationService.is2FAEnabled().then((enabled) =>
            setState({ isSwitchOn: enabled, pending: false })
        );
    }, []);

    const onSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setState((s) => ({ ...s, pending: true }));
        TwoFactorAuthenticationService.set2FAEnabled(checked).then((enabled) =>
            setState({ isSwitchOn: enabled, pending: false })
        );
    };

    return (
        <>
            <BodyContainer helpKey="two_factor_authentication">
                {!canEdit && <CanNotEditWarning className="mb-3" />}
                <Card>
                    <CardBody>
                        <div className={classNames('loadable', { 'loadable--loading': pending })}>
                            <p>
                                2FA authentication is currently{' '}
                                <strong>{isSwitchOn ? 'active' : 'inactive'}</strong>.
                            </p>
                            <p className="d-flex align-items-center">
                                <Switch
                                    className="me-2"
                                    disabled={pending || !canEdit}
                                    input={{
                                        value: isSwitchOn,
                                        onChange: onSwitchChange,
                                    }}
                                />
                                Use 2FA authentication
                            </p>
                            <p>Changing this setting will affect all user accounts on the platform.</p>
                            <p>
                                Enabling 2FA authentication will add additional security level - token sent by
                                EMAIL. Successful authentication will be valid for 30 days.
                            </p>
                        </div>
                    </CardBody>
                </Card>
            </BodyContainer>
        </>
    );
};

export default TwoFactorAuthentication;
