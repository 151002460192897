import { groupBy } from 'lodash-es';
import { FloodlightActivity } from 'platform/advertisers/advertiser.types';
import { fetchFloodlightActivities } from 'platform/advertisers/services/advertiser.service';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { ExternalSystem, getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import { usePromise } from 'platform/common/hooks/usePromise';
import { positiveWholeNumber, required } from 'platform/common/utils/validators.util';
import FormDynamicOptionList from 'platform/formik/FormDynamicOptionList/FormDynamicOptionList';
import FormSelectTree from 'platform/formik/FormSelectTree/FormSelectTree';
import { SegmentFormAdvertiser } from 'platform/segments/components/SegmentForm/SegmentForm.types';

interface Props {
    advertiser: SegmentFormAdvertiser;
}

interface FloodlightActivityTreeOption {
    id?: number;
    name: string;
    nodes: FloodlightActivity[];
}

const Convertible = ({ advertiser }: Props) => {
    const integration = (system: ExternalSystem) =>
        advertiser.externalSystemIntegrations.find((i) => i.externalSystem === system);

    const [{ data: options, loading }] = usePromise<FloodlightActivity[]>(
        [],
        async () => {
            const gcmAdvertiserId = integration('GCM')?.accounts[0]?.externalId;
            return gcmAdvertiserId ? fetchFloodlightActivities(gcmAdvertiserId) : [];
        },
        []
    );

    const groupedActivities = Object.values(groupBy(options, (o) => o.floodlightActivityGroupId)).map(
        (activities): FloodlightActivityTreeOption => ({
            id: activities[0].floodlightActivityGroupId,
            name: activities[0].floodlightActivityGroupName,
            nodes: activities,
        })
    );

    return (
        <>
            {integration('GCM')?.enabled && (
                <FormRow label={getIntegrationLabel('GCM')}>
                    <FormSelectTree<FloodlightActivityTreeOption>
                        name="floodlightActivityIds"
                        options={groupedActivities}
                        isLoading={loading}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id?.toString()}
                        getOptionParentLabel={(option) => option.name}
                        isMulti
                    />
                </FormRow>
            )}
            {integration('GOOGLE')?.enabled && (
                <FormRow label={getIntegrationLabel('GOOGLE')}>
                    <FormDynamicOptionList
                        name="googleAdsConversionIds"
                        placeholder="GoogleAds conversion ID"
                        buttonTitle="Add"
                        validateInput={positiveWholeNumber}
                    />
                </FormRow>
            )}
            {integration('GOOGLE_ANALYTICS')?.enabled && (
                <FormRow label={getIntegrationLabel('GOOGLE_ANALYTICS')}>
                    <FormDynamicOptionList
                        name="googleAnalyticsEventNames"
                        placeholder="Event name"
                        buttonTitle="Add"
                        validateInput={required}
                    />
                </FormRow>
            )}
            {integration('BING')?.enabled && (
                <FormRow label={getIntegrationLabel('BING')}>
                    <FormDynamicOptionList
                        name="bingGoalNames"
                        placeholder="Goal name"
                        buttonTitle="Add"
                        validateInput={required}
                    />
                </FormRow>
            )}
        </>
    );
};

export default Convertible;
