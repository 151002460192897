import { Location } from 'history';
import { createSelector } from 'reselect';
import { RootState } from 'platform/rootState.type';
import { Action } from '../../common/common.types';
import { currentTimeSeconds } from '../../common/utils';
import { fulfilled, pending, rejected } from '../../common/utils/actionSuffixes.util';
import { Account, Feature, Profile } from '../app.types';
import { Authority } from '../constants/authority.constant';

const HIDE_MAINTENANCE_MODE_MESSAGE = 'app/auth/HIDE_MAINTENANCE_MODE_MESSAGE';
const SAVE_RETURN_LOCATION = 'auth/SAVE_RETURN_LOCATION';
const CLEAR_RETURN_LOCATION = 'auth/CLEAR_RETURN_LOCATION';

export const LOGIN = 'auth/LOGIN';
export const LOGOUT = 'app/auth/LOGOUT';
export const IMPERSONATE = 'auth/IMPERSONATE';
export const STOP_IMPERSONATION = 'auth/STOP_IMPERSONATION';
export const VERIFY_2FA_CODE = 'auth/VERIFY_2FA_CODE';
const CODE_FOR_2FA_SENT = 'auth/CODE_FOR_2FA_SENT';
export const PROFILE_WAS_UPDATED = 'auth/PROFILE_WAS_UPDATED';

export const FETCH_TOKEN = 'auth/FETCH_TOKEN';
export const FETCH_CURRENT_USER_ACCOUNT = 'auth/FETCH_CURRENT_USER_ACCOUNT';
export const FETCH_CURRENT_USER_PROFILE = 'auth/FETCH_CURRENT_USER_PROFILE';
export const USER_UNAUTHORIZED = 'app/auth/USER_UNAUTHORIZED';

interface Persisted {
    returnLocation?: Location;
    maintenanceModeMessageHidden: boolean;
    errorMessage: string | undefined;
}

interface TokenData {
    token: string;
    tokenExpires: number;
}

interface LoggedOut {
    type: 'LOGGED_OUT';
}

interface TokenPending {
    type: 'TOKEN_PENDING';
}

type TokenReady = TokenData & { type: 'TOKEN_READY' };
type AccountPending = TokenData & { type: 'ACCOUNT_PENDING' };
type AccountReady = TokenData & { type: 'ACCOUNT_READY'; account: Account };
type Verifying2fa = TokenData & {
    type: 'VERIFYING_2FA';
    account: Account;
    codeSent: boolean;
};
type Verifying2faPending = TokenData & {
    type: 'VERIFYING_2FA_PENDING';
    account: Account;
    codeSent: boolean;
};
type ProfilePending = TokenData & { type: 'PROFILE_PENDING'; account: Account };
type Ready = TokenData & { type: 'READY'; account: Account; profile: Profile };

export type AuthState = Persisted &
    (
        | LoggedOut
        | TokenPending
        | TokenReady
        | AccountPending
        | AccountReady
        | Verifying2fa
        | Verifying2faPending
        | ProfilePending
        | Ready
    );

export const defaultAuthState: AuthState = {
    type: 'LOGGED_OUT',
    maintenanceModeMessageHidden: false,
    errorMessage: undefined,
};

const reducer = (state: AuthState = defaultAuthState, action: Action): AuthState => {
    // common actions
    switch (action.type) {
        case SAVE_RETURN_LOCATION: {
            return {
                ...state,
                returnLocation: action.payload,
            };
        }
        case CLEAR_RETURN_LOCATION: {
            return {
                ...state,
                returnLocation: undefined,
            };
        }
        case HIDE_MAINTENANCE_MODE_MESSAGE: {
            return {
                ...state,
                maintenanceModeMessageHidden: true,
            };
        }
        default:
    }
    // state specific action handling
    switch (state.type) {
        case 'LOGGED_OUT': {
            switch (action.type) {
                case pending(FETCH_TOKEN):
                    return {
                        ...state,
                        type: 'TOKEN_PENDING',
                    };
                default:
                    return state;
            }
        }
        case 'TOKEN_PENDING': {
            switch (action.type) {
                case fulfilled(FETCH_TOKEN): {
                    const { accessToken, expiresInMillis } = action.payload.data;
                    return {
                        ...state,
                        type: 'TOKEN_READY',
                        token: accessToken,
                        tokenExpires: expiresInMillis + currentTimeSeconds(),
                    };
                }
                case rejected(FETCH_TOKEN): {
                    const { message, response } = action.payload;
                    return {
                        ...state,
                        type: 'LOGGED_OUT',
                        errorMessage:
                            response.status >= 500
                                ? 'Oops! Something went wrong. Try again later.'
                                : message || 'Authentication failed',
                    };
                }
                default:
                    return state;
            }
        }
        case 'TOKEN_READY': {
            switch (action.type) {
                case pending(FETCH_CURRENT_USER_ACCOUNT): {
                    return { ...state, type: 'ACCOUNT_PENDING', errorMessage: undefined };
                }
                default:
                    return state;
            }
        }
        case 'ACCOUNT_PENDING': {
            switch (action.type) {
                case fulfilled(FETCH_CURRENT_USER_ACCOUNT): {
                    const account: Account = action.payload.data;
                    if (account.needed2FA) {
                        return {
                            ...state,
                            type: 'VERIFYING_2FA',
                            account,
                            codeSent: false,
                            errorMessage: undefined,
                        };
                    }
                    return {
                        ...state,
                        type: 'ACCOUNT_READY',
                        account: action.payload.data,
                        errorMessage: undefined,
                    };
                }
                case rejected(FETCH_CURRENT_USER_ACCOUNT): {
                    return {
                        ...state,
                        type: 'LOGGED_OUT',
                        errorMessage: 'Error fetching account',
                    };
                }
                default:
                    return state;
            }
        }
        case 'ACCOUNT_READY': {
            switch (action.type) {
                case pending(FETCH_CURRENT_USER_PROFILE): {
                    return {
                        ...state,
                        type: 'PROFILE_PENDING',
                        errorMessage: undefined,
                    };
                }
                default:
                    return state;
            }
        }
        case 'PROFILE_PENDING': {
            switch (action.type) {
                case fulfilled(FETCH_CURRENT_USER_PROFILE): {
                    return {
                        ...state,
                        type: 'READY',
                        profile: action.payload.data,
                        errorMessage: undefined,
                    };
                }
                case rejected(FETCH_CURRENT_USER_PROFILE): {
                    return {
                        ...state,
                        type: 'LOGGED_OUT',
                        errorMessage: 'Error fetching user profile',
                    };
                }
                default:
                    return state;
            }
        }
        case 'VERIFYING_2FA': {
            switch (action.type) {
                case pending(VERIFY_2FA_CODE): {
                    return {
                        ...state,
                        type: 'VERIFYING_2FA_PENDING',
                        errorMessage: undefined,
                    };
                }
                case CODE_FOR_2FA_SENT: {
                    return state.codeSent ? state : { ...state, codeSent: true, errorMessage: undefined };
                }
                default:
                    return state;
            }
        }
        case 'VERIFYING_2FA_PENDING': {
            switch (action.type) {
                case fulfilled(VERIFY_2FA_CODE): {
                    // After code verification we go back to refetch account, so we go to previous step
                    return {
                        ...state,
                        type: 'TOKEN_READY',
                        errorMessage: undefined,
                    };
                }
                case rejected(VERIFY_2FA_CODE): {
                    return {
                        ...state,
                        type: 'VERIFYING_2FA',
                        errorMessage: 'Verification code does not match. Please try again or resend.',
                    };
                }
                default:
                    return state;
            }
        }
        case 'READY': {
            switch (action.type) {
                case LOGOUT: {
                    return defaultAuthState;
                }
                case fulfilled(STOP_IMPERSONATION):
                case fulfilled(IMPERSONATE): {
                    return {
                        ...state,
                        type: 'TOKEN_READY',
                        returnLocation: undefined,
                        errorMessage: undefined,
                    };
                }
                case rejected(STOP_IMPERSONATION):
                case rejected(IMPERSONATE): {
                    return {
                        ...state,
                        type: 'LOGGED_OUT',
                        errorMessage: action.payload?.response?.data?.error,
                        returnLocation: undefined,
                    };
                }
                case PROFILE_WAS_UPDATED: {
                    return { ...state, type: 'TOKEN_READY', errorMessage: undefined };
                }
                default:
                    return state;
            }
        }
        default:
            return state;
    }
};

export default reducer;

export const authActions = {
    login: ({ username, password }: { username: string; password: string }) => ({
        type: LOGIN,
        payload: { username, password },
    }),
    logout: () => ({ type: LOGOUT }),
    verify2FaCode: ({ verificationCode }: { verificationCode: string }) => ({
        type: VERIFY_2FA_CODE,
        payload: verificationCode,
    }),
    codeFor2faSent: () => ({ type: CODE_FOR_2FA_SENT }),
    impersonate: (userToImpersonate: string) => ({ type: IMPERSONATE, payload: userToImpersonate }),
    stopImpersonation: () => ({ type: STOP_IMPERSONATION }),
    hideMaintenanceModeMessage: () => ({ type: HIDE_MAINTENANCE_MODE_MESSAGE }),
    userUnauthorized: (source: any) => ({ type: USER_UNAUTHORIZED, payload: source }),
    saveReturnLocation: (location: Location) => ({ type: SAVE_RETURN_LOCATION, payload: location }),
    clearReturnLocation: () => ({ type: SAVE_RETURN_LOCATION, payload: undefined }),
    profileWasUpdated: () => ({ type: PROFILE_WAS_UPDATED }),
};

const rootSelector = (state: RootState) => state.session;

const featureListSelector = (state: RootState) => {
    const authState = rootSelector(state);
    if (authState.type !== 'READY') return [];
    return authState.account.features;
};

const authorityListSelector = (state: RootState) => {
    const authState = rootSelector(state);
    if (authState.type !== 'READY') return [];
    return authState.account.authorities;
};

const getAssertedReady = (state: RootState): Ready => {
    const authState = authSelectors.root(state);
    if (authState.type !== 'READY') {
        throw new Error('Logged in state selector used for non ready state');
    }
    return authState;
};

// Helper to check token expirtation
export const tokenExpired = (tokenExpires?: number) => {
    if (!tokenExpires) {
        return true;
    }
    const currentTime = currentTimeSeconds();
    return tokenExpires < currentTime;
};

export const authSelectors = {
    root: rootSelector,
    isLoggedIn: (state: RootState) => authSelectors.root(state).type === 'READY',

    hasFeature: (feature: Feature) => (state: RootState) => featureListSelector(state).includes(feature),

    // more usefull when you need memoized feature checking function
    hasFeatureFn: createSelector(
        featureListSelector,
        (features: Feature[]) => (feature: Feature) => features.includes(feature)
    ),

    hasAnalyticsAuthority: createSelector(
        authorityListSelector,
        (authorities: Authority[]) => () => authorities.includes(Authority.ROLE_ANALYTICS_VIEW)
    ),

    canImpersonate: (state: RootState) => {
        const authState = authSelectors.root(state);
        if (authState.type !== 'READY') return false;
        return authState.account.canImpersonate;
    },
    accountName: (state: RootState) => {
        const authState = authSelectors.root(state);
        if (!('account' in authState)) return '';
        return authState.account.name;
    },
    isDemoModeEnabled: (state: RootState) => {
        const authState = authSelectors.root(state);
        if (!('profile' in authState)) return false;
        return authState.profile.demoModeEnabled;
    },
    isAdmin: (state: RootState) => {
        const authState = authSelectors.root(state);
        if (!('profile' in authState)) return false;
        return authState.profile.adminUser;
    },
    impersonating: (state: RootState) => {
        const authState = authSelectors.root(state);
        if (authState.type !== 'READY') return false;
        return authState.account.impersonate;
    },
    returnLocation: (state: RootState) => authSelectors.root(state).returnLocation,
    returnLocationQuery: (state: RootState) => {
        const returnLocation = authSelectors.returnLocation(state) ?? '';
        return returnLocation && `${returnLocation.pathname}${returnLocation.search}`;
    },

    // these will trow if used not in logged in environment
    ready: {
        token: (state: RootState) => getAssertedReady(state).token,
        profile: (state: RootState) => getAssertedReady(state).profile,
        account: (state: RootState) => getAssertedReady(state).account,
    },
};
