import { DEFAULT_ANALYTICS_SETTINGS } from 'platform/analytics/ducks/analyticsSettings.duck';
import { Section } from 'platform/app/app.types';
import axiosBff from 'platform/axios/axiosBff';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { CustomReportWithSettings, CustomReport } from 'platform/customReports/customReport.types';

const setDefaults = (customReport: CustomReportWithSettings): CustomReportWithSettings => ({
    ...customReport,
    analyticsSettings: {
        ...DEFAULT_ANALYTICS_SETTINGS,
        ...(customReport.analyticsSettings ?? {}),
    },
});

export const createCustomReport = (customReport: CustomReportWithSettings) =>
    axiosBff.post<{ id: number }>('/admin/api/customizable-reports', customReport).then((res) => res.data.id);

export const updateCustomReport = (customReport: CustomReportWithSettings) =>
    axiosBff
        .put<void>(`/admin/api/customizable-reports/${customReport.id}`, customReport)
        .then((res) => res.data);

export type CustomReportFilter = {
    status?: ActiveOrArchived[];
    pinned?: boolean;
    section?: Section[];
    userId?: number;
    seatId?: number;
    templateId?: string;
    olapColumn?: string;
    usedAsTemplate?: boolean;
    systemReport?: boolean;
    ownerUserId?: number[];
};

export const fetchCustomReports = (params: CustomReportFilter) =>
    axiosBff.get<CustomReport[]>('/admin/api/customizable-reports', { params }).then((res) => res.data);

export const fetchCustomReport = (id: number) =>
    axiosBff
        .get<CustomReportWithSettings>(`/admin/api/customizable-reports/${id}`)
        .then((res) => setDefaults(res.data));

// should pass all seat reports, otherwise report will be unassigned
export const assignReportsToUser = (params: { userId: number } | { seatId: number }, reportIds: number[]) =>
    axiosBff
        .put<void>('/admin/api/customizable-reports/assignments', { reportIds }, { params })
        .then((res) => res.data);

export const assignUsersToReport = (params: { reportId: number }, assigneeIds: number[]) =>
    axiosBff
        .put<void>('/admin/api/customizable-reports/assignments', { assigneeIds }, { params })
        .then((res) => res.data);

export const fetchAssignedCustomReports = (params: { userId: number } | { seatId: number }) =>
    axiosBff
        .get<{ reportIds: number[] }>('/admin/api/customizable-reports/assignments', { params })
        .then((res) => res.data.reportIds);
