import { useState } from 'react';
import ReactTable from 'react-table';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { sortBy, uniqueId } from 'lodash-es';
import moment from 'moment';
import { TableCell } from 'platform/common/common.types';
import DateRangePicker from 'platform/common/components/DateRangePicker/DateRangePicker';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import InlineEditInput from 'platform/common/components/InlineEditInput/InlineEditInput';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { datesOverlap } from 'platform/common/utils/date.util';
import { precisionRound } from 'platform/common/utils/formatters.util';
import { Pricing, PricingType } from 'platform/units/units.type';
import './SpendTable.scss';

type Spend = {
    key?: string | null;
    dateFrom: string;
    dateTo: string;
    spend?: number;
    impressions?: number;
    clicks?: number;
};

const getOverlaps = (spends: Spend[] = []) =>
    spends.reduce<Spend[]>((overlaps, spend, index) => {
        if (index !== spends.length - 1) {
            const tail = spends.slice(index + 1, spends.length);
            const localOverlaps = tail.filter((otherRange) =>
                datesOverlap(spend.dateFrom, spend.dateTo, otherRange.dateFrom, otherRange.dateTo)
            );
            if (localOverlaps.length) {
                overlaps.push(spend);
                overlaps.push(...localOverlaps);
            }
        }

        return overlaps;
    }, []);

interface Props {
    value: Spend[];
    datesRange?: {
        from?: string;
        to?: string;
    };
    error?: string;
    disabled?: boolean;
    bookedPricing?: Pricing;
    onChange: (val: Spend[], error?: string) => void;
}

const today = moment.utc().startOf('day').format(ISO_DATE_FORMAT);

const SpendTable = ({
    value,
    bookedPricing,
    datesRange = {
        from: today,
        to: today,
    },
    error,
    disabled,
    onChange,
}: Props) => {
    const [[emptySpends, overlaps], setSpends] = useState<Spend[][]>([[], []]);

    const spends = sortBy(value, (spend) => spend.dateFrom);
    const isSameSpendObject = (spend1: Spend, spend2: Spend) =>
        Boolean(spend1.key && spend1.key === spend2.key);
    const rangeHasError = (range: Spend) => overlaps.some((overlap) => isSameSpendObject(range, overlap));
    const spendHasError = (spend: Spend) => emptySpends.some((overlap) => isSameSpendObject(spend, overlap));

    const handleChange = (val: Spend[]) => {
        const empty = val.filter((range: Spend) => range.spend !== 0 && !range.spend);
        const overlap = getOverlaps(val);
        setSpends([empty, overlap]);

        const datesError = overlap.length ? 'Dates cannot overlap' : undefined;
        const spendsError = empty.length ? 'Spend must be non empty number' : undefined;
        onChange(val, datesError || spendsError);
    };

    const onRowChange = (index: number, changes: Partial<Spend>) => {
        const newSpends = [...spends];
        newSpends[index] = { ...spends[index], ...changes };
        handleChange(newSpends);
    };

    const deleteRow = (rowIndex: number) => {
        handleChange(spends.filter((val, index) => index !== rowIndex));
    };

    const cellClassName = (row: TableCell<Spend>) =>
        classNames(
            'SpendTable-spendCell',
            spendHasError(row.original) ? 'SpendTable-error' : 'SpendTable-errorDiv'
        );

    const columns = [
        {
            Header: 'Date',
            id: 'date',
            accessor: 'dateFrom',
            minWidth: 200,
            className: 'SpendTable-dateCell',
            Cell: (row: TableCell<Spend>) => (
                <div
                    className={classNames(
                        rangeHasError(row.original) ? 'SpendTable-error' : 'SpendTable-errorDiv'
                    )}
                >
                    <DateRangePicker
                        disabled={disabled}
                        minDate={datesRange.from}
                        maxDate={datesRange.to}
                        from={row.original.dateFrom}
                        to={row.original.dateTo}
                        inputGroupStyle={{ minWidth: '200px' }}
                        onChange={(dateFrom, dateTo) => onRowChange(row.index, { dateFrom, dateTo })}
                    />
                </div>
            ),
        },
        {
            Header: 'Spend',
            accessor: 'spend',
            minWidth: 120,
            Cell: (row: TableCell<Spend>) => {
                const calculateSpend = () => {
                    if (!bookedPricing?.multiplier || !bookedPricing?.type) {
                        return 0;
                    }

                    return precisionRound(
                        bookedPricing.multiplier *
                            (bookedPricing.type === PricingType.CPC
                                ? row.original.clicks ?? 0
                                : (row.original.impressions ?? 0) / 1000),
                        2
                    );
                };
                const isSpendCalculated = row.original.spend === calculateSpend();
                return (
                    <div className="d-flex align-items-center">
                        <div className={cellClassName(row)}>
                            <InlineEditInput
                                inputType="number"
                                className="text-end pe-0"
                                disabled={disabled}
                                onChange={(newValue: string) =>
                                    onRowChange(row.index, {
                                        spend:
                                            precisionRound(Number(newValue), 2) !== 0
                                                ? Number(newValue)
                                                : undefined,
                                    })
                                }
                                value={row.original.spend}
                                placeholder="---"
                                dataType={DATA_TYPES.EUR}
                            />
                        </div>
                        {bookedPricing?.multiplier && bookedPricing?.type && (
                            <Tooltip
                                renderTooltip={() =>
                                    isSpendCalculated
                                        ? 'Spend is automatically calculated'
                                        : 'Calculate spend'
                                }
                            >
                                <i
                                    role="button"
                                    tabIndex={-1}
                                    className={classNames('fal fa-calculator cursor-pointer', {
                                        'text-primary': isSpendCalculated,
                                        'text-muted': !isSpendCalculated,
                                    })}
                                    onClick={() => {
                                        onRowChange(row.index, {
                                            spend: calculateSpend(),
                                        });
                                    }}
                                />
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'Impr. (Vendor)',
            accessor: 'impressions',
            Cell: (row: TableCell<Spend>) => (
                <div className={cellClassName(row)}>
                    <InlineEditInput
                        inputType="number"
                        typeOptions={{ notRounded: true }}
                        className="text-end"
                        disabled={disabled}
                        onChange={(newValue) =>
                            onRowChange(row.index, { impressions: Number(newValue) || undefined })
                        }
                        value={row.original.impressions}
                        placeholder="---"
                        dataType={DATA_TYPES.INT}
                    />
                </div>
            ),
        },
        {
            Header: 'Clicks (Vendor)',
            accessor: 'clicks',
            Cell: (row: TableCell<Spend>) => (
                <div className={cellClassName(row)}>
                    <InlineEditInput
                        inputType="number"
                        typeOptions={{ notRounded: true }}
                        className="text-end"
                        disabled={disabled}
                        onChange={(newValue) =>
                            onRowChange(row.index, { clicks: Number(newValue) || undefined })
                        }
                        value={row.original.clicks}
                        placeholder="---"
                        dataType={DATA_TYPES.INT}
                    />
                </div>
            ),
        },
        {
            id: 'delete',
            maxWidth: 50,
            className: 'edit-dropdown',
            Cell: (row: TableCell<Spend>) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Delete',
                            action: () => {
                                deleteRow(row.index);
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <>
            {!disabled && (
                <Button
                    className="mb-3"
                    color="secondary"
                    onClick={() => {
                        handleChange([
                            {
                                dateFrom: today,
                                dateTo: today,
                                key: uniqueId('spendKey'),
                            },
                            ...spends,
                        ]);
                    }}
                >
                    Add new
                </Button>
            )}
            {!!spends.length && (
                <ReactTable
                    className="SpendTable"
                    loading={false}
                    showPagination={false}
                    pageSize={spends.length}
                    data={spends}
                    columns={columns}
                />
            )}
            {error && <div className="text-danger">{error}</div>}
        </>
    );
};

export default SpendTable;
