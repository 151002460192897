import { SelectItem } from 'platform/common/common.types';
import { Status } from 'platform/common/constants/status.constant';

export const makeOptions = <T extends string>(labels: { [key in T]: string }): SelectItem<T>[] =>
    Object.keys(labels).map((key: T) => ({
        value: key,
        label: labels[key],
    }));

export const namesToOptions = (
    items: { name: string; status?: Status }[] | undefined
): SelectItem<string>[] =>
    (items ?? []).map(({ name, status }) => ({
        label: name,
        value: name,
        status,
    }));
