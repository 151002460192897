import { ChangeEvent } from 'react';
import { Input, Label } from 'reactstrap';
import { InteractionComponentState, InteractionDatePicker } from 'platform/analytics/analytics.types';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';

interface Props {
    componentState: InteractionDatePicker;
    onChange: (state: InteractionComponentState) => void;
}

const ReportInteractionDatePickerFields = ({ componentState, onChange }: Props) => (
    <>
        <InputWithAddOn
            className="mb-3"
            value={componentState.fromKey}
            type="text"
            leftAddOn={{ title: 'From date macro' }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange({ ...componentState, fromKey: e.target.value })
            }
        />
        <InputWithAddOn
            className="mb-3"
            value={componentState.toKey}
            type="text"
            leftAddOn={{ title: 'To date macro' }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange({ ...componentState, toKey: e.target.value })
            }
        />
        <div>
            <Label check className="me-2">
                <Input
                    type="checkbox"
                    checked={componentState.onlyPast}
                    onChange={(e) =>
                        onChange({ ...componentState, onlyPast: e.target.checked, onlyFuture: false })
                    }
                />
                Only past dates
            </Label>
            <Label check>
                <Input
                    type="checkbox"
                    checked={componentState.onlyFuture}
                    onChange={(e) =>
                        onChange({ ...componentState, onlyFuture: e.target.checked, onlyPast: false })
                    }
                />
                Only future dates
            </Label>
        </div>
    </>
);

export default ReportInteractionDatePickerFields;
