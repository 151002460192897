import { FormGroup, Input, Label } from 'reactstrap';
import {
    ComponentSettings,
    ReportFilterWithOperator,
    SummaryBarState,
    Template,
} from 'platform/analytics/analytics.types';
import {
    customFiltersToReportFilters,
    reportFiltersToCustomFilters,
} from 'platform/analytics/analytics.util';
import ReportCustomDateRange from 'platform/analytics/reportComponents/ReportCustomDateRange';
import ReportCustomFilters from 'platform/analytics/reportComponents/ReportFilters/ReportCustomFilters';
import ReportSelectTree from 'platform/analytics/reportComponents/ReportSelectTree';
import { SummaryBarProps } from 'platform/analytics/reportComponents/ReportSummaryBar/ReportSummaryBar';
import TemplateSelect from 'platform/analytics/reportComponents/TemplateSelect';
import RadioList from 'platform/common/components/RadioList/RadioList';
import '../reportComponents.scss';

const ReportSummaryBarSettings = ({
    componentState,
    template,
    compatibleFilters,
    dimensionOptions,
    metricOptions,
    filterOptionsMap,
    loading,
    filtersLoading,
    canEdit,
    isChartEnabled = true,
    onChange,
    onFiltersChange,
}: ComponentSettings<SummaryBarState> & SummaryBarProps) => {
    const handleTemplateChange = (newTemplate: Template) =>
        onChange({
            ...componentState,
            templateId: newTemplate.id,
            rows: componentState.rows.map((metrics) =>
                metrics.filter((metric) => newTemplate.metrics.some((m) => m.key === metric))
            ),
        });

    const handleRowsChange = (value: number) => {
        if (value === componentState.rows.length) {
            return;
        }

        onChange({
            ...componentState,
            rows:
                value > componentState.rows.length
                    ? [...componentState.rows, ...new Array(value - componentState.rows.length).fill([])]
                    : componentState.rows.filter((_, index) => index + 1 <= value),
        });
    };

    const handleFiltersChange = async (filters: ReportFilterWithOperator[]) => {
        const resolvedFilters = await onFiltersChange(filters);
        onChange({
            ...componentState,
            customFilters: reportFiltersToCustomFilters(resolvedFilters),
        });
    };

    return (
        <div>
            <hr />
            <TemplateSelect
                templateId={componentState.templateId}
                canEdit={canEdit}
                onChange={handleTemplateChange}
            />
            <RadioList
                name="Rows"
                className="d-flex"
                options={[
                    { label: '1 Row', value: 1 },
                    { label: '2 Rows', value: 2 },
                    { label: '3 Rows', value: 3 },
                ]}
                value={componentState.rows.length}
                onRadioChange={handleRowsChange}
            />
            {componentState.rows.map((values, index) => (
                <ReportSelectTree
                    key={index}
                    name={`Row ${index + 1}`}
                    className="mb-3"
                    value={values}
                    options={[
                        { key: 'Metrics', name: 'Metrics', nodes: metricOptions },
                        { key: 'Dimensions', name: 'Dimensions', nodes: dimensionOptions },
                    ]}
                    isLoading={loading}
                    isMulti
                    onChange={(columns) =>
                        onChange({
                            ...componentState,
                            rows: componentState.rows.map((row, rowIndex) =>
                                rowIndex === index ? columns : row
                            ),
                        })
                    }
                />
            ))}
            <FormGroup className="mt-3 mb-0">
                <ReportCustomDateRange
                    customDateRange={componentState.customDateRange}
                    onChange={(customDateRange) => onChange({ ...componentState, customDateRange })}
                />
            </FormGroup>
            <FormGroup className="mt-3">
                <ReportCustomFilters
                    template={template}
                    compatibleFilters={compatibleFilters}
                    filters={customFiltersToReportFilters(componentState.customFilters)}
                    filterOptionsMap={filterOptionsMap}
                    loading={filtersLoading}
                    onChange={handleFiltersChange}
                />
            </FormGroup>
            {isChartEnabled && (
                <div className="d-flex justify-content-start align-items-center">
                    <Label check className="d-flex align-items-center">
                        <Input
                            className="position-static my-0 ms-0 me-2"
                            type="checkbox"
                            checked={componentState.isChartVisible}
                            onChange={(e) =>
                                onChange({ ...componentState, isChartVisible: e.target.checked })
                            }
                        />
                        Show chart
                    </Label>
                </div>
            )}
            <hr />
        </div>
    );
};

export default ReportSummaryBarSettings;
