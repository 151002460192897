import { useMemo } from 'react';
import { ChartData, ChartDataset, ChartOptions, TooltipItem } from 'chart.js';
import { isNil } from 'lodash-es';
import { ReportSettings } from 'platform/analytics/analytics.service';
import { PeriodDimension } from 'platform/analytics/analytics.types';
import { findColumn } from 'platform/analytics/analytics.util';
import ReportTracingTips from 'platform/analytics/reportComponents/ReportTracingTips/ReportTracingTips';
import { useReport } from 'platform/analytics/reportComponents/useReport';
import { TypeDefinition } from 'platform/common/common.types';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import LinePure from 'platform/common/components/LinePure/LinePure';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { typeOf } from 'platform/common/dataTypes';

const color = '#1A90D7';
const COMPARE_LABEL = 'Compare';

const getTooltipLabel = (
    compareLabels: string[] | undefined,
    type: TypeDefinition,
    item: TooltipItem<'line'>
) => {
    const { label: chartLabel, raw, dataIndex, datasetIndex, dataset } = item;
    const label =
        !compareLabels || isNil(dataIndex) || isNil(datasetIndex) || dataset.label !== COMPARE_LABEL
            ? String(chartLabel)
            : compareLabels[dataIndex];

    return `${label} ${type.format(raw)}`;
};

const generateChartOptions = (
    type: TypeDefinition,
    dimension: PeriodDimension,
    compareLabels: string[] | undefined
): ChartOptions<'line'> => ({
    animation: undefined,
    hover: { intersect: false },
    maintainAspectRatio: false,

    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            cornerRadius: 3,
            caretSize: 6,
            intersect: false,
            mode: 'index',
            caretPadding: 10,
            callbacks: {
                title: () => '',
                label: (tooltipItem) => getTooltipLabel(compareLabels, type, tooltipItem),
            },
        },
    },
    layout: {
        padding: {
            left: 16,
            right: 24,
            top: 24,
            bottom: 16,
        },
    },
    elements: {
        point: { radius: 0, hoverRadius: 4 },
    },

    scales: {
        x: {
            time: {
                tooltipFormat: 'yyyy-MM-DD', // Format for tooltip display
            },
            ticks: {
                maxRotation: 0, // Sets the angle of the label text to 0 degrees
                autoSkip: true, // Automatically skips labels to avoid overlapping
                autoSkipPadding: 15,
            },
        },
        y: {
            min: 0,
            suggestedMax: 1,
            ticks: {
                stepSize: 1,
                maxTicksLimit: 6,
                callback: (label) => type.format(label, { useMetricPrefix: true, showFraction: false }),
            },
        },
    },
});

interface Props {
    column: string;
    settings: ReportSettings;
    dimension: PeriodDimension;
}

const ReportSummaryBarChart = ({ column, settings, dimension }: Props) => {
    const chartSettings = useMemo<ReportSettings>(
        () => ({
            ...settings,
            dimensions: [dimension],
            sort: [{ orderBy: dimension, direction: 'ASC' }],
        }),
        [dimension, settings]
    );

    const { loading, report, error, compareReport, refetchReport } = useReport({
        settings: chartSettings,
        type: 'DEFAULT',
    });

    if (loading) {
        return <Placeholder className="p-3" minHeight="300px" />;
    }

    if (error) {
        return <ErrorMessage error={error} onRetry={refetchReport} />;
    }

    if (!report) {
        return null;
    }

    const cell = findColumn(report, column);
    if (!cell) {
        return null;
    }

    const getDatasets = () => {
        const sets: ChartDataset<'line'>[] = [
            {
                data: report.rows.map((row) => row[column]),
                label: cell.name,
                borderColor: color,
                borderWidth: 2,
                tension: 0,
                backgroundColor: color,
                fill: false,
                spanGaps: true,
            },
        ];

        if (compareReport) {
            sets.push({
                data: compareReport.rows.map((row) => row[column]),
                label: COMPARE_LABEL,
                borderColor: color,
                borderWidth: 1,
                borderDash: [5, 5],
                tension: 0,
                backgroundColor: 'transparent',
                fill: false,
                spanGaps: true,
            });
        }

        return sets;
    };

    const data: ChartData<'line'> = {
        labels: report.rows.map((row) => row[dimension]),
        datasets: getDatasets(),
    };

    const type = typeOf(cell);
    const compareLabels = compareReport?.rows.map<string>((row) => row[dimension]);

    return (
        <>
            <div>
                <LinePure
                    data={data}
                    height={300}
                    options={generateChartOptions(type, dimension, compareLabels)}
                />
            </div>
            <ReportTracingTips report={report} compareReport={compareReport} />
        </>
    );
};

export default ReportSummaryBarChart;
