import { createContext, useContext, useMemo } from 'react';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { sortByOrder } from 'platform/common/utils/chart.utils';
import {
    getPredefinedPrompts,
    getContextPrompts,
    getPersonalPrompts,
    getSystemPrompts,
} from '../chat.service';
import { PredefinedPrompt, ContextPrompt, PersonalPrompt, SystemPrompt } from '../chat.types';
import { useChatAgentContext } from './ChatAgentProvider';

interface ContextType {
    prompts: PredefinedPrompt[];
    contextPrompts: ContextPrompt[];
    personalPrompts: PersonalPrompt[];
    systemPrompts: SystemPrompt[];
    promptsLoading: boolean;
}

const ChatPromptContext = createContext<ContextType>({
    prompts: [],
    contextPrompts: [],
    personalPrompts: [],
    systemPrompts: [],
    promptsLoading: true,
});

export const ChatPromptProvider = ({ children }: { children: React.ReactNode }) => {
    const isAdmin = useFeature('ROLE_ADMINPANEL_VIEW');
    const canViewPrompts = useFeature('CHATBOT_PROMPTS_VIEW');
    const { selectedAgent } = useChatAgentContext();

    const [
        {
            data: [prompts, contextPrompts, personalPrompts, systemPrompts],
            loading,
        },
    ] = usePromise<[PredefinedPrompt[], ContextPrompt[], PersonalPrompt[], SystemPrompt[]]>(
        [[], [], [], []],
        () => {
            if (!canViewPrompts) return Promise.resolve([[], [], [], []]);

            return Promise.all([
                getPredefinedPrompts({ status: ['ACTIVE'] }).then(sortByOrder),
                getContextPrompts({ status: ['ACTIVE'] }),
                getPersonalPrompts(),
                getSystemPrompts({ status: ['ACTIVE'] }),
            ]);
        },
        []
    );

    const values = useMemo(
        () => ({
            promptsLoading: loading,
            prompts: prompts
                .filter((prompt) => prompt.agentId === selectedAgent?.id)
                .filter((prompt) => (prompt.adminOnly ? isAdmin : true)),
            contextPrompts,
            personalPrompts: selectedAgent?.personalPrompts
                ? personalPrompts.filter((prompt) => prompt.agentId === selectedAgent?.id)
                : [],
            systemPrompts,
        }),
        [loading, prompts, contextPrompts, personalPrompts, systemPrompts, selectedAgent?.id]
    );

    return <ChatPromptContext.Provider value={values}>{children}</ChatPromptContext.Provider>;
};

export const useChatPromptContext = () => {
    const context = useContext(ChatPromptContext);
    if (!context) {
        throw new Error('ChatPromptContext must be used within an ChatPromptProvider');
    }

    return context;
};
