import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import ObserverForm from './ObserverForm/ObserverForm';
import { INITIAL_CRON_EXPRESSION } from './observer.constants';
import { parseCron, toCreateUpdatePayload } from './observer.mapper';
import { createObserverReport } from './observer.service';
import { ObserverFormModel } from './observer.types';

const ObserverCreate = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const onOpen = (): Promise<ObserverFormModel> =>
        Promise.resolve({
            scheduling: { ...parseCron(INITIAL_CRON_EXPRESSION) },
            dateRange: { from: '', to: '' },
            description: '',
            name: '',
            reportType: 'ALERT',
            mailText: '',
            mailSubject: '',
            rowsThreshold: null,
            dailyAlertsThreshold: null,
            attachFile: true,
            fileFormat: undefined,
            destinations: ['EMAIL'],
            source: undefined,
            accessToken: undefined,
            staticRecipients: [],
            dynamicRecipients: false,
            deeplyEnabled: false,
            deeplyPrompt: '',
            deeplyAgentId: undefined,
            deeplyRowsLimit: 1000,
            query: '',
        });

    const onSubmit = (formValues: ObserverFormModel) =>
        createObserverReport(toCreateUpdatePayload(formValues));

    return (
        <FormContainer
            helpKey="observer_form"
            onSubmit={onSubmit}
            onOpen={onOpen}
            canEdit={canEdit}
            onSubmitFinish={afterSubmit}
            redirectTo={redirectTo}
            isEdit={false}
            sidePanel
        >
            {(props) => <ObserverForm {...props} />}
        </FormContainer>
    );
};

export default ObserverCreate;
