import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import { push } from 'redux-first-history';
import classNames from 'classnames';
import { capitalize, flow, groupBy } from 'lodash-es';
import qs from 'qs';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { fetchFlightLabels } from 'platform/campaign/advertiserManagement/FlightLabels/flightLabel.service';
import { fetchProducts } from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.service';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import ButtonDropdown from 'platform/common/components/ButtonDropdown/ButtonDropdown';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import ConceptCreativeStatus from 'platform/common/components/ConceptCreativeStatus/ConceptCreativeStatus';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import { useModal } from 'platform/common/components/Modal/Modal';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import Search from 'platform/common/components/Search/Search';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { Status, STATUS_OPTIONS } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { namesToOptions } from 'platform/common/utils/option.util';
import { toastSuccess } from 'platform/common/utils/toast.util';
import { CONCEPT_TYPE_OPTIONS } from 'platform/creatives/constants/conceptTypes';
import { getCreativeTypeName } from 'platform/creatives/constants/creativeTypes';
import {
    duplicateConcept,
    fetchConcepts,
    fetchCreatives,
    patchCreatives,
    resyncConceptCreatives,
    resyncCreative,
    updateConceptState,
} from 'platform/creatives/services/creative.service';
import { Concept, Creative, DeliveryStatus } from '../../types/creative.types';
import { isCreativeRow, isTrackerCreative } from '../../utils/creativeList.utils';
import CreativeFormContainer from '../CreativeForm/CreativeFormContainer';
import CreativeIntegrationIcon from '../CreativeIntegrations/CreativeIntegrationIcon';
import CreativesPreview from '../CreativesPreview/CreativesPreview';
import CreativeListActions from './CreativeListActions';
import './CreativeList.scss';

const STATE_CHANGE_ACTIONS: { [key in Status]: { label: string; changeTo: Status }[] } = {
    ACTIVE: [{ label: 'Deactivate', changeTo: 'INACTIVE' }],
    INACTIVE: [
        { label: 'Activate', changeTo: 'ACTIVE' },
        { label: 'Archive', changeTo: 'ARCHIVED' },
    ],
    ARCHIVED: [{ label: 'Restore', changeTo: 'INACTIVE' }],
};

const CREATIVE_URL = '/creatives';

const filterByState = (states: string[]) => (concepts: Concept[]) =>
    states.length ? concepts.filter((concept) => states.includes(concept.state)) : concepts;

const filterByTypes = (types: string[]) => (concepts: Concept[]) =>
    types.length ? concepts.filter((concept) => types.includes(concept.type)) : concepts;

const filterByProductLabel = (productLabels: string[]) => (concepts: Concept[]) =>
    productLabels.length
        ? concepts.filter((concept) => concept.productLabel && productLabels.includes(concept.productLabel))
        : concepts;

const filterByFlightLabel = (flightLabels: string[]) => (concepts: Concept[]) =>
    flightLabels.length
        ? concepts.filter((concept) => concept.flightLabel && flightLabels.includes(concept.flightLabel))
        : concepts;

type QueryParams = {
    status: Status[];
    searchQuery: string;
    types: string[];
    productLabels: string[];
    flightLabels: string[];
};

const CreativeList = () => {
    const {
        queryParams: { status, searchQuery, productLabels, flightLabels, types },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>({
        status: ['ACTIVE'],
        searchQuery: '',
        types: [],
        productLabels: [],
        flightLabels: [],
    });

    const dispatch = useDispatch();
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const { showModal } = useModal();

    const canEdit = useFeature('ROLE_CREATIVES_EDIT');
    const isDemoMode = useSelector(authSelectors.isDemoModeEnabled);

    const [{ data, loading }, refetch] = usePromise(
        [],
        () =>
            fetchConcepts({
                advertiserId,
                state: status,
                view: 'without-creatives',
                query: qs.stringify({ searchQuery })?.split('=')[1],
            }),
        [advertiserId, status, types, searchQuery]
    );

    const [
        {
            data: [productOptions, flightLabelOptions],
            loading: labelLoading,
        },
    ] = usePromise(
        [[], []],
        async () => {
            const labels = await Promise.all([fetchProducts(advertiserId), fetchFlightLabels(advertiserId)]);
            return labels.map(namesToOptions);
        },
        [advertiserId]
    );

    const columns: TableColumn<Concept | Creative>[] = [
        {
            Header: 'Status',
            accessor: (row) => (isCreativeRow(row) ? undefined : row.state),
            width: 75,
            Cell: ({ original: row }) =>
                isCreativeRow(row) ? (
                    <StatusBadge status={row.deliveryStatus} />
                ) : (
                    <StatusBadge status={row.state} />
                ),
        },
        {
            Header: 'Type',
            accessor: 'type',
            width: 80,
            Cell: ({ original: row }) => {
                const creativeTypeName = capitalize(isCreativeRow(row) ? getCreativeTypeName(row) : row.type);

                return (
                    <Badge className="text-truncate" title={creativeTypeName}>
                        {creativeTypeName}
                    </Badge>
                );
            },
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 75,
        },
        {
            Header: 'Name',
            accessor: 'name',
            minWidth: 200,
            Cell: ({ original: row }) => {
                const { id, name = '' } = row;
                const processedName = (() => {
                    if (!isDemoMode) return name;
                    if (isCreativeRow(row)) return `Creative_${id}`;
                    return `Creative_concept_${id}`;
                })();

                const showPreviewIcon =
                    isCreativeRow(row) && !isTrackerCreative(row) && row.type !== 'FACEBOOK';
                return (
                    <>
                        {showPreviewIcon && id && (
                            <Button
                                color="link"
                                onClick={() =>
                                    showModal((toggle) => (
                                        <CreativesPreview creativeId={id} toggle={toggle} />
                                    ))
                                }
                            >
                                <i className="fa fa-eye me-2" />
                            </Button>
                        )}
                        <span
                            className={classNames('text-truncate', {
                                isInactive:
                                    isCreativeRow(row) &&
                                    (row.deliveryStatus === DeliveryStatus.PAUSED ||
                                        row.deliveryStatus === DeliveryStatus.REMOVED),
                            })}
                            title={processedName}
                        >
                            {processedName}
                        </span>
                    </>
                );
            },
        },
        {
            Header: 'Creatives',
            width: 80,
            Cell: ({ original }) =>
                isCreativeRow(original) ? (
                    ''
                ) : (
                    <>
                        <div>{original.creativeCount}</div>
                        <ConceptCreativeStatus
                            creativeCount={original.creativeCount}
                            conceptCreativeStatus={original.creativeSyncSummaryStatus}
                        />
                    </>
                ),
        },
        {
            screenOnly: true,
            Header: 'Integrations',
            width: 140,
            Cell: ({ original: row }) => {
                if (!isCreativeRow(row) || !row.integration) {
                    return null;
                }
                return <CreativeIntegrationIcon id={row.id} integration={row.integration} />;
            },
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 160,
            Cell: ({ original: row }) => <UpdatedOn date={row.updatedOn} updatedBy={row.updatedBy} />,
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: ({ original: row }) => {
                if (isCreativeRow(row)) {
                    return row.integration?.externalSystem === 'GCM' ? (
                        <InlineDropdown
                            items={[
                                {
                                    label: 'Resync',
                                    action: () => row.id && resyncCreative(row.id).then(refetch),
                                },
                            ]}
                        />
                    ) : null;
                }

                const concept = row;
                const conceptId = concept.id;

                const items: { label: string; action: () => void }[] = [
                    {
                        label: canEdit ? 'Edit' : 'View',
                        action: () =>
                            dispatch(push(`${CREATIVE_URL}/${conceptId}?advertiserId=${advertiserId}`)),
                    },
                ];

                if (concept.creatives.length) {
                    items.push({
                        label: 'Preview',
                        action: () =>
                            showModal((toggle) => (
                                <CreativesPreview creativeId={concept.creatives[0].id!} toggle={toggle} />
                            )),
                    });
                }

                if (canEdit && conceptId) {
                    items.push({
                        label: 'Duplicate',
                        action: () => duplicateConcept(conceptId).then(refetch),
                    });

                    STATE_CHANGE_ACTIONS[concept.state].forEach((item) => {
                        items.push({
                            label: item.label,
                            action: () => updateConceptState(conceptId, item.changeTo).then(refetch),
                        });
                    });

                    items.push({
                        label: 'Resync creatives',
                        action: () => resyncConceptCreatives(conceptId).then(refetch),
                    });
                }

                return <InlineDropdown items={items} />;
            },
        },
    ];

    const openNewConceptForm = () => {
        dispatch(
            push({
                pathname: `${CREATIVE_URL}/new`,
                search: qs.stringify({ advertiserId, type: 'STANDARD' }),
            })
        );
    };

    const filterCreativeTree = (concepts: Concept[]): Concept[] => {
        if (!concepts || !concepts.length) return [];
        return flow(
            filterByState(status),
            filterByTypes(types),
            filterByProductLabel(productLabels),
            filterByFlightLabel(flightLabels)
        )(concepts);
    };

    const filteredList = filterCreativeTree(data);

    return (
        <>
            <HeaderContainer>
                <PageHeader title="Creatives" />
            </HeaderContainer>
            <BodyContainer helpKey="creative_list">
                {!canEdit && <CanNotEditWarning />}
                <div className="d-flex flex-row mb-3">
                    <ListFilters className="flex-grow-1">
                        <SelectWithAddon
                            name="Status"
                            value={status}
                            options={STATUS_OPTIONS}
                            isMulti
                            onChange={(value) => setQueryParams({ status: value })}
                        />
                        <SelectWithAddon
                            name="Type"
                            value={types}
                            options={CONCEPT_TYPE_OPTIONS}
                            isMulti
                            onChange={(value) => setQueryParams({ types: value })}
                        />
                        <SelectWithAddon
                            name="Product"
                            value={productLabels}
                            options={productOptions}
                            isLoading={labelLoading}
                            isMulti
                            onChange={(value) => setQueryParams({ productLabels: value })}
                        />
                        <SelectWithAddon
                            name="Flight"
                            value={flightLabels}
                            options={flightLabelOptions}
                            isLoading={labelLoading}
                            isMulti
                            onChange={(value) => setQueryParams({ flightLabels: value })}
                        />
                    </ListFilters>
                    <div className="d-flex flex-row align-items-baseline">
                        <CreativeListActions
                            advertiserId={advertiserId}
                            updateCreatives={(updatedCreatives) => {
                                Object.entries(groupBy(updatedCreatives, (c) => c.conceptId)).forEach(
                                    ([conceptId, creatives]) =>
                                        patchCreatives(
                                            Number(conceptId),
                                            creatives.map(
                                                ({ id, clickThroughUrl, clickThroughUrlSuffix }) => ({
                                                    id,
                                                    clickThroughUrl,
                                                    clickThroughUrlSuffix,
                                                })
                                            )
                                        ).then(() =>
                                            toastSuccess(
                                                `Successfully updated ${updatedCreatives.length} creatives`
                                            )
                                        )
                                );
                            }}
                            concepts={filteredList}
                        />
                    </div>
                </div>

                {advertiserId && (
                    <FormattedTable
                        isTree
                        className="CreativeList"
                        topToolbar={
                            <>
                                {canEdit && (
                                    <ButtonDropdown
                                        className="me-2"
                                        items={[
                                            {
                                                label: 'Standard creative concept',
                                                action: openNewConceptForm,
                                            },
                                        ]}
                                    >
                                        Add new
                                    </ButtonDropdown>
                                )}
                                <div>
                                    <Search
                                        onSearch={(value) => setQueryParams({ searchQuery: value })}
                                        value={searchQuery}
                                        minLength={0}
                                    />
                                </div>
                            </>
                        }
                        columns={columns}
                        loading={loading}
                        data={filteredList}
                        childrenOffset={0}
                        childrenCountField="creativeCount"
                        childrenLoaders={[
                            {
                                field: 'creatives',
                                lazyLoad: ({ id, advertiserId: advId }) =>
                                    fetchCreatives({ advertiserId: advId, conceptId: Number(id) }).then(
                                        (creatives) => {
                                            const filteredCreatives = creatives.filter(
                                                (item) =>
                                                    (item.id && item.id.toString().includes(searchQuery)) ||
                                                    item.name.includes(searchQuery)
                                            );
                                            return filteredCreatives.length > 0
                                                ? filteredCreatives
                                                : creatives;
                                        }
                                    ),
                            },
                        ]}
                        defaultSorted={[
                            {
                                orderBy: 'updatedOn',
                                direction: 'DESC',
                            },
                        ]}
                    />
                )}
                <Routes>
                    <Route
                        path="new"
                        element={
                            <CreativeFormContainer
                                isEdit={false}
                                canEdit={canEdit}
                                isDemoMode={isDemoMode}
                                redirectTo={returnUrl}
                                afterSubmit={refetch}
                            />
                        }
                    />
                    <Route
                        path=":id"
                        element={
                            <CreativeFormContainer
                                isEdit
                                canEdit={canEdit}
                                isDemoMode={isDemoMode}
                                redirectTo={returnUrl}
                                afterSubmit={refetch}
                            />
                        }
                    />
                </Routes>
            </BodyContainer>
        </>
    );
};

export default CreativeList;
