import { useSelector } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { ColumnDefinition, ColumnGroup, Template } from 'platform/analytics/analytics.types';
import { getColumnDefinitionOptions, isOlapReport } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';

const getOptions = (
    columnDefinitions: ColumnDefinition[],
    columnGroups: ColumnGroup[],
    compatibleColumns: string[],
    selectedColumns: string[],
    useCompatibleColumns: boolean
) => {
    const columns = useCompatibleColumns
        ? columnDefinitions.filter(({ key }) =>
              compatibleColumns.length ? compatibleColumns.includes(key) : selectedColumns.includes(key)
          )
        : columnDefinitions;

    return getColumnDefinitionOptions(columns, columnGroups);
};

export interface CompatibleColumns {
    reportCompatibleColumns: string[];
}

interface Props {
    template: Template;
    columns: string[];
    skip?: boolean;
}

export const QUERY = gql`
    query AnalyticsCompatibleColumns($templateId: String!, $fields: [String]!, $filter: ReportFilter!) {
        reportCompatibleColumns(templateId: $templateId, fields: $fields, filter: $filter)
    }
`;

export const useCompatibleColumns = ({ template, columns, skip = false }: Props) => {
    const settings = useSelector(analyticsSelectors.settings);
    const filters = useSelector(analyticsSelectors.filters);
    const { columnGroups } = useAnalyticsMetadata();

    const { data, loading } = useQuery<CompatibleColumns>(QUERY, {
        variables: {
            fields: [...columns].sort(),
            filter: { dimensionFilters: filters ?? settings.filters },
            templateId: template.id,
        },
        fetchPolicy: 'network-only',
        skip: skip || !isOlapReport(template),
    });

    const compatibleColumns = data?.reportCompatibleColumns ?? [];

    return {
        compatibleColumns,
        dimensionOptions: getOptions(
            template.dimensions,
            columnGroups,
            compatibleColumns,
            columns,
            isOlapReport(template)
        ),
        metricOptions: getOptions(
            template.metrics,
            columnGroups,
            compatibleColumns,
            columns,
            isOlapReport(template)
        ),
        loading,
    };
};
