import { ChangeEvent, useCallback } from 'react';
import { Alert, Card, CardBody, Input, InputGroup, InputGroupText } from 'reactstrap';
import { last, sum, uniq } from 'lodash-es';
import { generateOlapReport } from 'platform/analytics/analytics.service';
import { OlapReport, Template } from 'platform/analytics/analytics.types';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { addDependentDimensions } from 'platform/analytics/reportComponents/ReportTableContainer/columnBuilders';
import ChannelLabel from 'platform/channels/components/ChannelLabel';
import { isNotEmpty } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import { DATA_TYPES } from 'platform/common/dataTypes';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useDebounce } from 'platform/common/hooks/useDebounce';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { getDuration } from 'platform/common/utils/date.util';
import { CustomerJourneyReport, JourneyEventType } from 'platform/dashboard/dashboard.types';
import JourneyList from './JourneyList';
import JourneySummaryBar from './JourneySummaryBar';

export type ItemGroup = {
    eventType: JourneyEventType;
    startTime: string;
    endTime: string;
    rows: CustomerJourneyReport[];
};

const fetchData = (
    reportTemplates: Template[],
    advertiserId: number,
    orderId: string,
    orderSession: string
) => {
    const templateId = 'customer_journey';

    const template = reportTemplates.find((t) => t.id === templateId);
    if (!template) {
        throw new Error(`Could not find template with id: ${templateId}`);
    }

    const reportSettings = {
        templateId,
        dimensions: addDependentDimensions(
            [
                'event_type',
                'journey_datetime',
                'journey_pageviews',
                'journey_funnel_levels',
                'channel',
                'vendor_name',
                'google_keyword',
                'google_source',
                'google_medium',
                'domain',
                'action_status',
                'journey_product_category_ids',
                'journey_visited_segments',
                'search_term_strategy',
            ],
            template
        ),
        metrics: ['journey_visit_time'],

        // BE ignores dates for this report, so do we
        from: '',
        to: '',
        dimensionFilters: [
            { key: 'advertiser_id', values: [advertiserId] },
            { key: 'tag_order_id', values: [orderId] },
            { key: 'order_session', values: [orderSession] },
        ],
        sort: [{ orderBy: 'journey_datetime', direction: 'ASC' as const }],
    };
    return generateOlapReport(reportSettings);
};

type QueryParams = {
    orderId?: string;
    orderSession?: string;
};

const CustomerJourney = () => {
    const {
        queryParams: { orderId, orderSession },
        setQueryParams,
    } = useUrlSync<QueryParams>({
        orderId: undefined,
        orderSession: undefined,
    });

    const { id: advertiserId } = useActiveAdvertiserComponent();
    const { templates } = useAnalyticsMetadata();

    const debouncedOrderId = useDebounce(orderId, 1000);
    const debouncedOrderSession = useDebounce(orderSession, 1000);

    const [{ data: report, loading: reportLoading, error: reportError }, refetchReport] = usePromise<
        OlapReport<CustomerJourneyReport> | undefined
    >(
        undefined,
        () => {
            if (templates.length === 0 || !debouncedOrderId || !debouncedOrderSession) {
                return Promise.resolve(undefined);
            }

            return fetchData(templates, advertiserId, debouncedOrderId, debouncedOrderSession);
        },
        [advertiserId, templates, debouncedOrderId, debouncedOrderSession]
    );

    const onOrderIdFilterChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setQueryParams({ orderId: event.target.value });
        },
        [orderId]
    );

    const onOrderSessionFilterChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setQueryParams({ orderSession: event.target.value });
        },
        [orderSession]
    );

    return (
        <>
            <HeaderContainer>
                <PageHeader title="Customer Journey" />
            </HeaderContainer>
            <BodyContainer helpKey="customer_journey" className="position-relative">
                <div className="d-flex flex-wrap mb-3">
                    <div className="me-2">
                        <InputGroup style={{ maxWidth: '350px' }}>
                            <InputGroupText>Order ID</InputGroupText>
                            <Input type="text" value={orderId} onChange={onOrderIdFilterChange} />
                        </InputGroup>
                    </div>

                    <div className="me-2">
                        <InputGroup style={{ maxWidth: '350px' }}>
                            <InputGroupText>Order Session</InputGroupText>
                            <Input type="text" value={orderSession} onChange={onOrderSessionFilterChange} />
                        </InputGroup>
                    </div>
                </div>

                {(() => {
                    if (reportLoading) {
                        return <OverlayLoader />;
                    }

                    if (reportError) {
                        return <ErrorMessage error={reportError} onRetry={refetchReport} />;
                    }

                    if (!report || !report.rows.length) {
                        return <Alert color="warning">No information found</Alert>;
                    }

                    const lastRow = last(report.rows);

                    if (!lastRow) {
                        return null;
                    }

                    // Groups have single or more than 3 rows. If there are 2 items of same event type, they are split into separate groups
                    const groups = report.rows.reduce<ItemGroup[]>((acc, row, index) => {
                        const lastGroup = acc[acc.length - 1];
                        const nextRow = report.rows[index + 1];

                        if (
                            index === 0 ||
                            row.event_type !== lastGroup.eventType ||
                            (lastGroup.rows.length === 1 &&
                                (!nextRow || nextRow.event_type !== lastGroup.eventType))
                        ) {
                            return [
                                ...acc,
                                {
                                    eventType: row.event_type,
                                    startTime: row.journey_datetime,
                                    endTime: row.journey_datetime,
                                    rows: [row],
                                },
                            ];
                        }

                        return [
                            ...acc.slice(0, acc.length - 1),
                            {
                                ...lastGroup,
                                endTime: row.journey_datetime,
                                rows: [...lastGroup.rows, row],
                            },
                        ];
                    }, []);

                    return (
                        <>
                            <Card>
                                <CardBody className="p-0">
                                    <div className="d-flex align-items-center justify-content-between p-3">
                                        <div className="border-right px-4 py-2 flex-fit">
                                            <div>
                                                {lastRow.action_status === 'missed'
                                                    ? 'Last channel'
                                                    : 'Winning channel'}
                                            </div>
                                            <div className="font-xl fw-bold d-flex align-items-center">
                                                <ChannelLabel withIcon channel={lastRow.channel} />
                                            </div>
                                        </div>
                                        <div className="border-right px-4 py-2 flex-fit">
                                            <div>Total Channels</div>
                                            <div className="font-xl fw-bold">
                                                {
                                                    uniq(
                                                        report.rows
                                                            .map((row) => row.channel)
                                                            .filter(isNotEmpty)
                                                    ).length
                                                }
                                            </div>
                                        </div>
                                        <div className="border-right px-4 py-2 flex-fit">
                                            <div>Total Pageviews</div>
                                            <div className="font-xl fw-bold">
                                                {sum(
                                                    report.rows
                                                        .map((row) => row.journey_pageviews)
                                                        .filter(isNotEmpty)
                                                )}
                                            </div>
                                        </div>
                                        <div className="border-right px-4 py-2 flex-fit">
                                            <div>Total Visit Time</div>
                                            <div className="font-xl fw-bold">
                                                {DATA_TYPES.DURATION_SECONDS.format(
                                                    sum(
                                                        report.rows
                                                            .map((row) => row.journey_visit_time)
                                                            .filter(isNotEmpty)
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <div className="px-4 py-2 flex-fit">
                                            <div>Journey Duration</div>
                                            <div className="font-xl fw-bold">
                                                {getDuration(
                                                    report.rows[0].journey_datetime,
                                                    lastRow.journey_datetime
                                                ).humanize()}
                                            </div>
                                        </div>
                                    </div>
                                    {groups.length > 1 && (
                                        <>
                                            <div className="border-top" />
                                            <JourneySummaryBar groups={groups} />
                                        </>
                                    )}
                                </CardBody>
                            </Card>
                            <div className="my-5">
                                <JourneyList groups={groups} />
                            </div>
                        </>
                    );
                })()}
            </BodyContainer>
        </>
    );
};

export default CustomerJourney;
