import { useEffect } from 'react';
import { AppChildren, useAppContext } from 'platform/app/AppContext';

export const useSetAppChildren = (components: AppChildren) => {
    const [, setAppChildren] = useAppContext();
    useEffect(() => {
        setAppChildren(components);
        return () => setAppChildren({ appHeaderChildren: undefined });
    }, []);
};
