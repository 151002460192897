import { useState, useEffect, KeyboardEvent } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { ApolloError } from '@apollo/client';
import { isEqual } from 'lodash-es';
import {
    ReportFilterWithOperator,
    FilterOperator,
    DimensionFilterOption,
} from 'platform/analytics/analytics.types';
import ErrorIndicator from 'platform/common/components/Errors/ErrorIndicator';
import FilterRemoveIcon from 'platform/common/components/FilterRemoveIcon/FilterRemoveIcon';
import FilterValuesPopover from 'platform/common/components/FilterValuesPopover/FilterValuesPopover';
import { PopoverContainerType } from 'platform/common/components/Popover/Popover';
import TextWithEllipsis from 'platform/common/components/TextWithEllipsis/TextWithEllipsis';
import FilterOperatorDropdown from './FilterOperatorDropdown';

interface Props {
    name: string;
    filter: ReportFilterWithOperator;
    operators: FilterOperator[];
    options: DimensionFilterOption[];
    error?: ApolloError;
    multi: boolean;
    disabled?: boolean;
    loading: boolean;
    refetch: () => void;
    onChange: (filter: ReportFilterWithOperator) => void;
    onRemove: () => void;
    container?: PopoverContainerType;
}

const AnalyticsContainFilter = ({
    name,
    filter,
    operators,
    options,
    error,
    disabled,
    loading,
    refetch,
    onChange,
    onRemove,
    container,
}: Props) => {
    const [filterValues, setFilterValues] = useState(filter.values);

    useEffect(() => {
        if (isEqual(filterValues, filter.values)) {
            return;
        }

        setFilterValues(filter.values);
    }, [filter.values]);

    const handleOperatorChange = (newFilter: ReportFilterWithOperator) => {
        const values = ['CONTAINS', 'CONTAINS_NOT'].includes(newFilter.operator!)
            ? newFilter.values
            : options
                  .filter((option) =>
                      newFilter.values.some((value) =>
                          option.label.toLowerCase().includes(String(value).toLocaleLowerCase())
                      )
                  )
                  .map((option) => option.value);

        onChange({
            ...newFilter,
            values,
        });
    };

    return (
        <InputGroup>
            <InputGroupText>
                <TextWithEllipsis>{name}</TextWithEllipsis>
            </InputGroupText>
            <InputGroupText>
                <FilterOperatorDropdown
                    filter={filter}
                    supportedOperators={operators}
                    container={container}
                    onChange={(newFilter) =>
                        handleOperatorChange({
                            ...newFilter,
                            values: filterValues,
                        })
                    }
                />
            </InputGroupText>
            <Input
                type="text"
                style={{ height: 'auto' }}
                value={filterValues.join(' ')}
                readOnly={disabled}
                onChange={(e) => setFilterValues(e.target.value.split(' '))}
                onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                        onChange({
                            ...filter,
                            values: filterValues,
                        });
                    }
                }}
                onBlur={() =>
                    onChange({
                        ...filter,
                        values: filterValues,
                    })
                }
            />
            <ErrorIndicator
                className="w-100 d-flex justify-content-center rounded"
                loading={loading}
                error={error}
                onRetry={refetch}
            />
            {!!filter.resolvedValues?.length && (
                <FilterValuesPopover values={filter.resolvedValues} options={options} />
            )}
            {!disabled && <FilterRemoveIcon onClick={onRemove} />}
        </InputGroup>
    );
};

export default AnalyticsContainFilter;
