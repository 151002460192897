import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findFilter } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { AnalyticsProps } from 'platform/analytics/modes/Analytics';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { COMPARE_OPTIONS } from '../analytics.constants';
import { MEDIA_PLAN_ID, addMediaPlanFilterValues } from '../hooks/useMediaplanFilter';

interface Props {
    children: (props: AnalyticsProps) => ReactNode;
}

const BudgetReporting = ({ children }: Props) => {
    const filters = useSelector(analyticsSelectors.ownFilters);
    useActiveAdvertiserComponent();
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const { mediaPlans } = useAnalyticsMetadata();
    const dispatch = useDispatch();

    useEffect(() => {
        const mediaPlanFilter = addMediaPlanFilterValues(mediaPlans, findFilter(filters, MEDIA_PLAN_ID));
        if (!mediaPlanFilter) {
            return;
        }

        const values =
            mediaPlanFilter.values.length > 1
                ? [mediaPlanFilter.values.map(Number)[0]]
                : mediaPlanFilter.values;
        dispatch(
            analyticsSettingsActions.saveFilter({
                ...mediaPlanFilter,
                values,
                resolvedValues: values,
            })
        );
    }, [advertiserId, mediaPlans]);

    return (
        <>
            {children({
                datePickerDisabled: true,
                compareOptions: COMPARE_OPTIONS.filter((option) => option.value === 'AVERAGE'),
                componentsProps: {
                    SUMMARY_BAR: {
                        canChangeColumn: false,
                    },
                },
                filterInputProps: { media_plan_id: { isMulti: false } },
            })}
        </>
    );
};

export default BudgetReporting;
