import { ReactNode, useRef } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { get, isArray, isEqual } from 'lodash-es';
import { DimensionKey } from 'platform/analytics/analytics.types';
import FormCountryLabelsSelect from 'platform/campaign/common/FormCountryLabelsSelect';
import FormFlightLabelSelect from 'platform/campaign/common/FormFlightLabelSelect';
import FormProductLabelSelect, {
    FormProductLabelSelectProps,
} from 'platform/campaign/common/FormProductLabelSelect';
import FormProductSubLabelSelect from 'platform/campaign/common/FormProductSubLabelSelect';
import { SelectItem } from 'platform/common/common.types';
import { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineEditContainer from 'platform/common/components/InlineEditContainer/InlineEditContainer';
import { EMPTY_SYMBOL } from 'platform/common/constants/common.constant';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import { useLabelOptionsContext } from '../LabelOptionsContext';
import { ReportTableFormModel } from '../ReportTableFormContainer';
import './EditableReportTableCell.scss';

const isElementXPixelsAwayFromBottom = (element: HTMLElement | null, x: number) => {
    if (!element) {
        return false;
    }
    const windowHeight = window.innerHeight;
    const scrollTop = window.scrollY || window.pageYOffset;

    const rect = element.getBoundingClientRect();
    const elementTop = rect.top + scrollTop;
    const elementHeight = rect.height;

    return windowHeight + scrollTop - (elementTop + elementHeight) <= x;
};

type ProductSubLabelMap = Record<
    Extract<DimensionKey, 'campaign_product_sub_label' | 'strategy_product_sub_label'>,
    Extract<DimensionKey, 'campaign_product_label' | 'strategy_product_label'>
>;

const EMPTY_OPTION = {
    label: EMPTY_SYMBOL,
    value: '',
};

const PRODUCT_SUB_LABEL_FIELD_PATH_MAP: ProductSubLabelMap = {
    campaign_product_sub_label: 'campaign_product_label',
    strategy_product_sub_label: 'strategy_product_label',
};

export const isValueChanged = (
    formValue: SelectItem<string> | SelectItem<string>[] | number | undefined,
    defaultValue: string | number | string[]
): boolean => {
    if (!formValue) return false;

    if (isArray(formValue)) {
        return !isEqual(
            formValue.map(({ label }) => label),
            defaultValue
        );
    }
    if (typeof formValue === 'number') {
        return formValue !== defaultValue;
    }

    return (formValue.value || null) !== defaultValue;
};

interface Props {
    cellNode: ReactNode;
    column: TableColumn;
    original: any;
    rowIndex: number;
    defaultValue: string | number | string[];
}

const EditableReportTableCell = ({ cellNode, column, original, rowIndex, defaultValue }: Props) => {
    const cellRef = useRef(null);
    const isCellCloseToPageBottom = isElementXPixelsAwayFromBottom(cellRef.current, 400);
    const baseFieldPath = `rowChanges.${rowIndex}`;
    const path = `rowChanges.${rowIndex}.${column.id}`;
    const { setFieldValue, values } = useFormikContext<ReportTableFormModel>();
    const formValue = get(values, path) as SelectItem<string> | SelectItem<string>[] | undefined;
    const { isLoading, productLabels, flightLabels, countryLabels } = useLabelOptionsContext();
    const advertiserId = useTypedSelector(activeAdvertiserSelectors.id);
    const productLabelDimension = PRODUCT_SUB_LABEL_FIELD_PATH_MAP[column.id as keyof ProductSubLabelMap];
    const selectedProductLabel = (get(values, `${baseFieldPath}.${productLabelDimension}`) as
        | SelectItem<string>
        | undefined) ?? {
        label: original[productLabelDimension],
        value: original[productLabelDimension],
        __isNew__: false,
        nodes: [],
    };
    const isChanged = isValueChanged(formValue, defaultValue);
    const setIds = () => {
        setFieldValue(`${baseFieldPath}.strategy_id`, original?.strategy_id);
        setFieldValue(`${baseFieldPath}.campaign_id`, original?.campaign_id);
    };
    const getCommonProps = (toggleEditMode: () => void): FormProductLabelSelectProps => ({
        isClearable: false,
        autoFocus: true,
        defaultMenuIsOpen: true,
        menuPlacement: isCellCloseToPageBottom ? 'top' : 'bottom', // workaround: Flipping menu manually as react-select 'auto' option doesn't work in this case
        menuPosition: 'fixed',
        className: 'w-100',
        name: path,
        isLoading,
        advertiserId,
        onBlur: toggleEditMode,
        onChange: () => {
            toggleEditMode();
            setIds();
        },
    });

    const nodesByColumn: Record<DimensionKey, (props: { toggleEditMode: () => void }) => ReactNode> = {
        campaign_product_label: ({ toggleEditMode }) => (
            <FormProductLabelSelect
                {...getCommonProps(toggleEditMode)}
                options={productLabels}
                onChange={() => {
                    setIds();
                    setFieldValue(`${baseFieldPath}.campaign_product_sub_label`, EMPTY_OPTION);
                }}
            />
        ),
        campaign_flight_label: ({ toggleEditMode }) => (
            <FormFlightLabelSelect {...getCommonProps(toggleEditMode)} options={flightLabels} />
        ),
        country_labels: ({ toggleEditMode }) => (
            <FormCountryLabelsSelect
                {...getCommonProps(toggleEditMode)}
                options={countryLabels}
                onChange={() => setIds()}
            />
        ),
        strategy_product_label: ({ toggleEditMode }) => (
            <FormProductLabelSelect
                {...getCommonProps(toggleEditMode)}
                options={productLabels}
                onChange={() => {
                    setIds();
                    setFieldValue(`${baseFieldPath}.strategy_product_sub_label`, EMPTY_OPTION);
                }}
            />
        ),
        strategy_flight_label: ({ toggleEditMode }) => (
            <FormFlightLabelSelect {...getCommonProps(toggleEditMode)} options={flightLabels} />
        ),
        campaign_product_sub_label: ({ toggleEditMode }) => (
            <FormProductSubLabelSelect
                {...getCommonProps(toggleEditMode)}
                options={selectedProductLabel.nodes}
                productLabel={selectedProductLabel}
            />
        ),
        strategy_product_sub_label: ({ toggleEditMode }) => (
            <FormProductSubLabelSelect
                {...getCommonProps(toggleEditMode)}
                options={selectedProductLabel.nodes}
                productLabel={selectedProductLabel}
            />
        ),
    };

    const DefaultValueContainer = ({ children }: { children: ReactNode }) => (
        <div className="d-flex align-items-center">
            <span className="flex-grow-1 text-truncate">{children}</span>
            {formValue && isChanged && (
                <>
                    <i
                        role="button"
                        tabIndex={0}
                        className="EditableReportTableCell-icon fal fa-arrows-rotate ms-1 me-2"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (Object.keys(get(values, `rowChanges.${rowIndex}`)).length === 2) {
                                // if two fields left clear whole object, resetting changes
                                setFieldValue(`rowChanges.${rowIndex}`, undefined);
                            } else setFieldValue(path, undefined);
                        }}
                    />
                    |
                </>
            )}
            <i className="EditableReportTableCell-icon fal fa-chevron-down ms-2" />
        </div>
    );

    const renderCellText = () => {
        if (!formValue) return <DefaultValueContainer>{cellNode}</DefaultValueContainer>;

        const text = Array.isArray(formValue)
            ? formValue.map(({ label }) => label).join(', ')
            : formValue?.label;

        return <DefaultValueContainer>{text}</DefaultValueContainer>;
    };

    return (
        <div
            className={classNames('EditableReportTableCell', isChanged && 'EditableReportTableCell-changed')}
        >
            <InlineEditContainer ref={cellRef} style={{ color: undefined }} renderValue={renderCellText}>
                {nodesByColumn[column.id as DimensionKey]}
            </InlineEditContainer>
        </div>
    );
};

export default EditableReportTableCell;
