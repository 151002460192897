import { Route } from 'react-router-dom';
import { Dispatch } from 'redux';
import { push } from 'redux-first-history';
import { Location } from 'history';
import AdvertiserFormContainer from 'platform/advertisers/components/AdvertiserForm/AdvertiserFormContainer';
import { Feature } from 'platform/app/app.types';
import { createAndEditStrategy } from 'platform/campaign/strategy/utils/strategy.util';
import { isDefined } from 'platform/common/common.types';
import UnitFormContainer from 'platform/units/components/UnitForm/UnitFormContainer';
import { activeAdvertiserActions } from '../../../../common/ducks/activeAdvertiser.duck';
import { ActionConnect, ActionType, InlineActionConnect, RedirectActionConnect } from './InlineActions.types';

const registeredActions: ActionConnect[] = [
    { action: 'change_unit_state', type: ActionType.INLINE },
    { action: 'change_unit_bid', type: ActionType.INLINE },
    { action: 'change_unit_budget', type: ActionType.INLINE },
    { action: 'change_unit_daily_budget', type: ActionType.INLINE },
    { action: 'change_strategy_state', type: ActionType.INLINE },
    { action: 'change_campaign_state', type: ActionType.INLINE },
    { action: 'change_campaign_budget', type: ActionType.INLINE },
    { action: 'change_google_keyword_bid_factor', type: ActionType.INLINE },
    {
        action: 'edit_advertiser',
        label: 'Edit advertiser',
        operation: (row, dispatch) => {
            const { advertiser_id } = row;
            if (advertiser_id) {
                dispatch(push(`/analyticsPro/advertisers/edit/${advertiser_id}${window.location.search}`));
            }
        },
        route: (returnLocation, dispatch) => (
            <Route
                key="edit_advertiser"
                path="advertiser/edit/:id"
                element={
                    <AdvertiserFormContainer
                        type="ADVERTISER"
                        redirectTo={`/analyticsPro${returnLocation ? returnLocation.search : ''}`}
                        canEdit
                        afterSubmit={() => dispatch(activeAdvertiserActions.reloadAdvertiserOptions())}
                    />
                }
            />
        ),
        requiresFeature: 'ROLE_ADVERTISERS_EDIT',
        type: ActionType.REDIRECT,
    },
    {
        action: 'add_campaign',
        label: 'Add campaign',
        operation: (row, dispatch) => {
            const { advertiser_id } = row;
            if (advertiser_id) {
                dispatch(activeAdvertiserActions.changeAdvertisers([advertiser_id]));
            }
            dispatch(push('/campaigns/new'));
        },
        requiresFeature: 'ROLE_CAMPAIGNS_EDIT',
        type: ActionType.REDIRECT,
    },
    {
        action: 'edit_campaign',
        label: 'Edit campaign',
        operation: (row, dispatch) => {
            const { campaign_id } = row;
            if (campaign_id) {
                dispatch(push(`/campaigns/${campaign_id}`));
            }
        },
        requiresFeature: 'ROLE_CAMPAIGNS_EDIT',
        type: ActionType.REDIRECT,
    },
    {
        label: 'Add strategy',
        action: 'add_strategy',
        operation: (row, dispatch, { campaign } = {}) => {
            if (campaign?.id) {
                createAndEditStrategy(dispatch, campaign.id, campaign.type);
            }
        },
        requiresFeature: 'ROLE_CAMPAIGNS_EDIT',
        type: ActionType.REDIRECT,
    },
    {
        label: 'Edit strategy',
        action: 'edit_strategy',
        operation: ({ strategy_id }, dispatch) => dispatch(push(`/strategies/${strategy_id}`)),
        requiresFeature: 'ROLE_CAMPAIGNS_EDIT',
        type: ActionType.REDIRECT,
    },
    {
        label: 'Show creatives',
        action: 'show_creatives',
        operation: ({ strategy_id }, dispatch) => dispatch(push(`/strategies/${strategy_id}/creatives`)),
        requiresFeature: 'ROLE_CAMPAIGNS_EDIT',
        type: ActionType.REDIRECT,
    },
    {
        label: 'Edit units',
        action: 'edit_units',
        operation: ({ strategy_id }, dispatch) => dispatch(push(`/strategies/${strategy_id}/units`)),
        requiresFeature: 'ROLE_CAMPAIGNS_EDIT',
        type: ActionType.REDIRECT,
    },
    {
        label: 'Edit IO Spend',
        action: 'edit_io_spend_setup',
        operation: ({ unit_id }, dispatch) => {
            dispatch(push(`/analyticsPro/unit-spend/${unit_id}${window.location.search}`));
        },
        route: (returnLocation) => (
            <Route
                key="edit_io_spend"
                path="unit-spend/:id"
                element={
                    <UnitFormContainer
                        canEdit
                        redirectTo={`/analyticsPro${returnLocation ? returnLocation.search : ''}`}
                    />
                }
            />
        ),
        requiresFeature: 'ROLE_CAMPAIGNS_EDIT',
        type: ActionType.REDIRECT,
    },
];

export const isRedirectAction = (action: ActionConnect): action is RedirectActionConnect =>
    action.type === ActionType.REDIRECT;
export const isInlineAction = (action: ActionConnect): action is InlineActionConnect =>
    action.type === ActionType.INLINE;

export const renderInlineActionRoutes = (
    returnLocation: Location | undefined,
    hasFeature: (feature: Feature) => boolean,
    dispatch: Dispatch
) =>
    registeredActions
        .filter(isRedirectAction)
        .map((action) => {
            if ((action.requiresFeature && !hasFeature(action.requiresFeature)) || !action.route) return null;
            return action.route(returnLocation, dispatch);
        })
        .filter(isDefined);

export const findRegisteredActionById = (actionId: string) =>
    registeredActions.find((action) => action.action === actionId);
