import { useState } from 'react';
import { Label } from 'reactstrap';
import { useFormikContext } from 'formik';
import InfoIconTooltip from 'platform/common/components/InfoIconTooltip/InfoIconTooltip';
import Switch from 'platform/common/components/Switch/Switch';
import FormInput from 'platform/formik/FormInput/FormInput';
import { CampaignModel } from '../../campaign.types';

type Props = {
    campaign: CampaignModel;
};
export const CampaignFormLandingPageSuffix = ({ campaign: { clickThroughUrlSuffix } }: Props) => {
    const [suffixEnabled, setSuffixEnabled] = useState(!!clickThroughUrlSuffix);
    const { setFieldValue } = useFormikContext<CampaignModel>();
    return (
        <div className="mb-3">
            <div className="d-flex align-items-center gap-2 mb-1">
                <InfoIconTooltip>
                    <div>
                        Changing this suffix will automatically re-sync all units in this campaign, appending
                        the suffix to the landing pages of their ads.
                    </div>
                    <div className="mt-2">
                        For creatives that already have a landing page suffix, it will be appended next to
                        campaign suffix. In the case of parameter name conflict, creative-level parameters
                        will override campaign-level parameters with the same name.
                    </div>
                </InfoIconTooltip>

                <Label className="m-0">Landing page suffix</Label>

                <Switch
                    input={{
                        value: suffixEnabled,
                        onChange: ({ target }) => {
                            setSuffixEnabled(target.checked);
                            if (!target.checked) {
                                setFieldValue('clickThroughUrlSuffix', null);
                            }
                        },
                    }}
                />
            </div>
            {suffixEnabled && (
                <div className="mt-2">
                    <FormInput
                        leftAddOn={{ title: 'Suffix' }}
                        name="clickThroughUrlSuffix"
                        placeholder="Example: utm_campaign=Campaign"
                    />
                </div>
            )}
        </div>
    );
};
