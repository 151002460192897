import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import { getContextPrompts, updateContextPromptStatus } from 'platform/chat/chat.service';
import { ContextPrompt } from 'platform/chat/chat.types';
import { TableCell } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import { PopoverDropdownItem } from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { ACTIVE_OR_ARCHIVED, ActiveOrArchived } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import ContextPromptFormContainer from './ContextPromptFormContainer';

type QueryParams = {
    status: ActiveOrArchived[];
};

const ContextPromptList = () => {
    const {
        queryParams: { status },
        setQueryParams,
    } = useUrlSync<QueryParams>({
        status: ['ACTIVE'],
    });

    const showConfirmationModal = useConfirmationModal();
    const dispatch = useDispatch();
    const [{ data: contextPrompts, loading }, refetch] = usePromise([], () => getContextPrompts({ status }), [
        status,
    ]);
    const canEdit = useFeature('CHATBOT_MANAGEMENT_EDIT');

    const columns: TableColumn<ContextPrompt>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            width: 100,
            depth: 0,
            Cell: (row: TableCell<ContextPrompt>) => <StatusBadge status={row.value} />,
        },
        { Header: 'ID', accessor: 'id', width: 100, type: DATA_TYPES.ID },
        { Header: 'Order', accessor: 'order', width: 70, type: DATA_TYPES.INT },
        { Header: 'Name', accessor: 'name', minWidth: 200, type: DATA_TYPES.TEXT },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 200,
            Cell: (row) => <UpdatedOn date={row.original.updatedOn} updatedBy={row.original.updatedBy} />,
        },
        {
            className: 'pull-right cell-align-right',
            sortable: false,
            width: 50,
            Cell: ({ original }) => {
                const items: PopoverDropdownItem[] = [
                    {
                        label: canEdit ? 'Edit' : 'View',
                        action: () => {
                            dispatch(push(`/deeply-panel/context-prompts/${original.id}`));
                        },
                    },
                ];

                if (canEdit) {
                    items.push({
                        label: original.status === 'ACTIVE' ? 'Archive' : 'Activate',
                        action: () =>
                            showConfirmationModal(
                                () =>
                                    updateContextPromptStatus(
                                        original.id,
                                        original.status === 'ACTIVE' ? 'ARCHIVED' : 'ACTIVE'
                                    ).then(refetch),
                                {
                                    title: `${original.status === 'ACTIVE' ? 'Archive' : 'Activate'} Prompt`,
                                    text: `Prompt ${name} will be ${
                                        original.status === 'ACTIVE' ? 'archived' : 'activated'
                                    }. Are you sure?`,
                                }
                            ),
                    });
                }

                return <InlineDropdown items={items} />;
            },
        },
    ];

    return (
        <>
            <BodyContainer helpKey="context_prompts_list">
                {!canEdit && <CanNotEditWarning />}
                <ListFilters className="mb-3">
                    <SelectWithAddon
                        name="Status"
                        value={status}
                        options={ACTIVE_OR_ARCHIVED}
                        onChange={(value) => setQueryParams({ status: value })}
                        isMulti
                    />
                </ListFilters>
                <FormattedTable
                    topToolbar={
                        <ListToolbar
                            onCreate={
                                canEdit
                                    ? () => {
                                          dispatch(push(`/deeply-panel/context-prompts/new`));
                                      }
                                    : undefined
                            }
                        />
                    }
                    columns={columns}
                    loading={loading}
                    data={contextPrompts}
                    defaultSorted={[
                        {
                            orderBy: 'order',
                            direction: 'ASC',
                        },
                    ]}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <ContextPromptFormContainer
                            redirectTo="/deeply-panel/context-prompts"
                            canEdit={canEdit}
                            afterSubmit={refetch}
                        />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <ContextPromptFormContainer
                            redirectTo="/deeply-panel/context-prompts"
                            canEdit={canEdit}
                            afterSubmit={refetch}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default ContextPromptList;
