import bubble from 'platform/assets/icons/journey/bubble.svg';
import tripleChevrons from 'platform/assets/icons/journey/chevrons3.svg';
import chevrons from 'platform/assets/icons/journey/chevrons.svg';
import { getDuration } from 'platform/common/utils/date.util';
import { DurationInterval, getDurationInterval } from '../CustomerJourneyCM360/customerJourney.utils';
import './Separators.scss';

const INTERVAL_ICONS: { [K in DurationInterval]: string } = {
    SECONDS: tripleChevrons,
    MINUTES: chevrons,
    HOURS: bubble,
    DAYS: bubble,
};

export const CardSeparator = ({ startTime, endTime }: { startTime: string; endTime: string }) => {
    const intervalType = getDurationInterval(startTime, endTime);

    return (
        <div className="d-flex align-items-center">
            <img style={{ padding: '0 20px 0 35px' }} alt={intervalType} src={INTERVAL_ICONS[intervalType]} />
            <span className="text-muted">
                {intervalType === 'SECONDS' ? '' : getDuration(startTime, endTime).humanize()}
            </span>
        </div>
    );
};

export const ItemSeparator = ({ startTime, endTime }: { startTime: string; endTime: string }) => (
    <div className="ItemSeparator">
        <div className="ItemSeparator-line border-top" />
        <div className="ItemSeparator-text text-muted">{getDuration(startTime, endTime).humanize()}</div>
    </div>
);
