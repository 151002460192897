import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { TaskAttachment } from '../task.types';

interface Props {
    attachments?: TaskAttachment[];
}

const KanbanAttachmentsIcon = ({ attachments }: Props) =>
    attachments?.length ? (
        <div className="text-nowrap text-muted">
            <Tooltip renderTooltip={() => attachments.map((a, index) => <p key={index}>{a.name}</p>)}>
                {attachments.length} <i className="fa fa-paperclip" />
            </Tooltip>
        </div>
    ) : null;

export default KanbanAttachmentsIcon;
