import { CSSProperties, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { gregorianEaster } from 'date-easter';
import moment from 'moment';
import LoginFormContainer from 'platform/app/components/Login/LoginFormContainer';
import loginBgEaster from 'platform/assets/images/loginBackgrounds/login-bg-easter.png';
import loginBgHalloween from 'platform/assets/images/loginBackgrounds/login-bg-halloween.jpg';
import loginBgNewYear from 'platform/assets/images/loginBackgrounds/login-bg-newYear.jpg';
import loginBgXmas from 'platform/assets/images/loginBackgrounds/login-bg-xmas.jpg';
import loginBG from 'platform/assets/images/loginBackgrounds/login-bg.png';
import IntroMessage from '../IntroMessage/IntroMessage';
import Fireworks from './Fireworks';
import ForgotPasswordForm from './ForgotPasswordForm';
import LoginLogo from './LoginLogo';
import SetPasswordForm from './SetPasswordForm';
import Snowflakes from './Snowflakes';
import './LoginPage.scss';

const easterDate = gregorianEaster(new Date());

interface HolidayBgRange {
    start: {
        month: number;
        date: number;
    };
    end: {
        month: number;
        date: number;
    };
}

const HOLIDAY_RANGES: Record<'halloween' | 'xmas' | 'newYear' | 'easter', HolidayBgRange> = {
    halloween: {
        start: { month: 9, date: 31 },
        end: { month: 10, date: 1 },
    },
    xmas: {
        start: { month: 11, date: 19 },
        end: { month: 11, date: 26 },
    },
    newYear: {
        start: { month: 11, date: 27 },
        end: { month: 11, date: 31 },
    },
    easter: {
        start: { month: easterDate.month - 1, date: easterDate.day - 4 },
        end: { month: easterDate.month - 1, date: easterDate.day + 1 },
    },
} as const;

const isHolidayDate = ({ start, end }: HolidayBgRange) => {
    const today = moment();

    const holidayStart = moment().month(start.month).date(start.date).startOf('day');
    const holidayEnd = moment().month(end.month).date(end.date).endOf('day');

    return today.isBetween(holidayStart, holidayEnd);
};

const getBackgroundImageStyle = (): CSSProperties | undefined => {
    if (isHolidayDate(HOLIDAY_RANGES.halloween)) {
        return { backgroundImage: `url(${loginBgHalloween})` };
    }

    if (isHolidayDate(HOLIDAY_RANGES.xmas)) {
        return { backgroundImage: `url(${loginBgXmas})` };
    }

    if (isHolidayDate(HOLIDAY_RANGES.newYear)) {
        return { backgroundImage: `url(${loginBgNewYear})` };
    }

    if (isHolidayDate(HOLIDAY_RANGES.easter)) {
        return { backgroundImage: `url(${loginBgEaster})` };
    }

    return undefined;
};

const LoginPage = () => {
    useEffect(() => {
        document.title = 'Login';
    }, []);

    const holidayStyle = getBackgroundImageStyle();

    return (
        <div className="LoginPage" style={holidayStyle}>
            {isHolidayDate(HOLIDAY_RANGES.xmas) && <Snowflakes />}
            {isHolidayDate(HOLIDAY_RANGES.newYear) && <Fireworks />}
            {!holidayStyle && (
                <video
                    className="LoginPage-video"
                    autoPlay
                    loop
                    muted
                    poster={loginBG}
                    playsInline
                    controlsList={'nofullscreen'}
                >
                    <source
                        src="https://s3-eu-west-1.amazonaws.com/dap-prod-images/admin/images/453a74ba-2de3-43b2-9efa-33f329d9a7cd_login-video.mp4"
                        type="video/mp4"
                    />
                </video>
            )}
            <Row className="LoginPage-container g-0">
                <Col lg={1} className="d-none d-lg-block" />
                <Col lg={4} className="d-none d-lg-block">
                    <IntroMessage className="text-white" />
                </Col>
                <Col lg={5}>
                    <div className="LoginPage-container-column d-flex align-items-center mx-auto h-100">
                        <div className="LoginPage-container-form">
                            <LoginLogo />
                            <Routes>
                                <Route path="" element={<LoginFormContainer />} />
                                <Route path="forgot-password" element={<ForgotPasswordForm />} />
                                <Route
                                    path="set-password/:activationKey"
                                    element={<SetPasswordForm formType="set" />}
                                />
                                <Route
                                    path="password-reminder/:activationKey"
                                    element={<SetPasswordForm formType="reset" />}
                                />
                            </Routes>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default LoginPage;
