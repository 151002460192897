import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import { TableCell } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { STATUS_OPTIONS } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { filterBySearchQuery } from 'platform/common/utils/search.util';
import SeatForm from 'platform/userManagement/components/SeatForm/SeatFormContainer';
import { fetchSeats } from 'platform/userManagement/services/userManagement.service';
import { Seat } from '../../types/seat.type';

const SeatList = () => {
    const {
        queryParams: { searchQuery, status },
        setQueryParams,
        returnUrl,
    } = useUrlSync({
        status: ['ACTIVE'],
        searchQuery: '',
    });

    const dispatch = useDispatch();
    const canEdit = useFeature('ROLE_ADMINPANEL_EDIT');
    const [{ data: seatList, loading }, refetch] = usePromise([], () => fetchSeats({ states: status }), [
        status,
    ]);

    const columns = [
        {
            Header: 'Status',
            accessor: 'status',
            type: DATA_TYPES.TEXT,
            maxWidth: 80,
            Cell: (props: TableCell<Seat>) => <StatusBadge status={props.value} />,
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            maxWidth: 100,
        },
        {
            Header: 'Name',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Platform name',
            accessor: 'platformName',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Platform access URL',
            accessor: 'platformAccessUrl',
            type: DATA_TYPES.TEXT,
            minWidth: 250,
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 160,
            Cell: (row: TableCell<Seat>) => (
                <UpdatedOn date={row.original.updatedOn} updatedBy={row.original.updatedBy} />
            ),
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: (props: TableCell<Seat>) => (
                <InlineDropdown
                    items={[
                        {
                            label: canEdit ? 'Edit' : 'View',
                            action: () => {
                                dispatch(push(`/user-management/seats/${props.row.id}`));
                            },
                        },
                    ]}
                />
            ),
        },
    ];
    return (
        <>
            <BodyContainer helpKey="seat_list">
                {!canEdit && <CanNotEditWarning />}
                <SelectWithAddon
                    name="Status"
                    className="mb-3"
                    value={status}
                    options={STATUS_OPTIONS}
                    isMulti
                    onChange={(value) => setQueryParams({ status: value })}
                />
                <FormattedTable
                    topToolbar={
                        <ListToolbar
                            onCreate={
                                canEdit ? () => dispatch(push('/user-management/seats/new')) : undefined
                            }
                            onSearch={(value) => setQueryParams({ searchQuery: value })}
                            value={searchQuery}
                        />
                    }
                    columns={columns}
                    data={filterBySearchQuery(
                        seatList,
                        [...columns.map((column) => column.accessor), 'updatedBy'],
                        searchQuery
                    )}
                    loading={loading}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={<SeatForm redirectTo={returnUrl} canEdit={canEdit} afterSubmit={refetch} />}
                />
                <Route
                    path=":id"
                    element={<SeatForm redirectTo={returnUrl} canEdit={canEdit} afterSubmit={refetch} />}
                />
            </Routes>
        </>
    );
};
export default SeatList;
