import axiosBff from 'platform/axios/axiosBff';
import { IdResponse } from 'platform/common/common.types';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { FILE_UPLOAD_HEADERS, fileUploadData } from 'platform/common/utils/file.util';
import {
    TaskBoard,
    TaskBoardChange,
    TaskBoardChangeInclude,
    TaskBoardState,
    TaskComment,
    TaskCreationData,
    TaskData,
    TaskLaneData,
    TaskLocation,
} from './task.types';

export const getTaskBoards = (params: { advertiserId: number; status?: ActiveOrArchived[] }) =>
    axiosBff.get<TaskBoard[]>(`/planner/api/boards`, { params }).then((r) => r.data);

export const getTaskBoard = (id: number) =>
    axiosBff.get<TaskBoard>(`/planner/api/boards/${id}`).then((r) => r.data);

export const createTaskBoard = (advertiserId: number, data: Omit<TaskBoard, 'id'>) =>
    axiosBff.post<IdResponse>(`/planner/api/boards`, data, { params: { advertiserId } }).then((r) => r.data);

export const updateTaskBoard = (id: number, board: Omit<TaskBoard, 'id'>) =>
    axiosBff.put<TaskBoardChange[]>(`/planner/api/boards/${id}`, board).then((r) => r.data);

export const updateTaskBoardLabel = (boardId: number, labelKey: string, data: { name: string }) =>
    axiosBff
        .put<TaskBoardChange[]>(`/planner/api/boards/${boardId}/labels/${labelKey}`, data)
        .then((r) => r.data);

export const getTaskBoardState = (boardId: number) =>
    axiosBff.get<TaskBoardState>(`/planner/api/boards/${boardId}/state`).then((r) => r.data);

export const getTaskBoardChanges = (boardId: number, params: { sinceVersion: number }) =>
    axiosBff.get<TaskBoardChange[]>(`/planner/api/boards/${boardId}/changes`, { params }).then((r) => r.data);

export const getLaneChanges = (boardId: number, laneId: number) =>
    axiosBff
        .get<TaskBoardChange[]>(`/planner/api/boards/${boardId}/lanes/${laneId}/changes`)
        .then((r) => r.data);

export const getTaskChanges = (boardId: number, taskId: number) =>
    axiosBff
        .get<TaskBoardChange[]>(`/planner/api/boards/${boardId}/tasks/${taskId}/changes`)
        .then((r) => r.data);

export const getTaskBoardChangesByPeriod = (
    boardId: number,
    params: {
        dateFrom?: string;
        dateTo?: string;
        include: TaskBoardChangeInclude;
    }
) =>
    axiosBff
        .get<TaskBoardChange[]>(`/planner/api/boards/${boardId}/changes/period`, { params })
        .then((r) => r.data);

export const createTaskLane = (boardId: number, params: { position: number }, data: TaskLaneData) =>
    axiosBff
        .post<TaskBoardChange[]>(`/planner/api/boards/${boardId}/lanes`, data, { params })
        .then((r) => r.data);

export const updateTaskLane = (boardId: number, laneId: number, data: TaskLaneData) =>
    axiosBff
        .put<TaskBoardChange[]>(`/planner/api/boards/${boardId}/lanes/${laneId}`, data)
        .then((r) => r.data);

export const moveTaskLane = (boardId: number, laneId: number, params: { position: number }) =>
    axiosBff
        .put<TaskBoardChange[]>(`/planner/api/boards/${boardId}/lanes/${laneId}/position`, params)
        .then((r) => r.data);

export const deleteTaskLane = (boardId: number, laneId: number) =>
    axiosBff.delete<TaskBoardChange[]>(`/planner/api/boards/${boardId}/lanes/${laneId}`).then((r) => r.data);

export const createTask = (boardId: number, params: TaskLocation, data: TaskCreationData) =>
    axiosBff
        .post<TaskBoardChange[]>(`/planner/api/boards/${boardId}/tasks`, data, { params })
        .then((r) => r.data);

export const updateTask = (boardId: number, taskId: number, data: Partial<TaskData>) =>
    axiosBff
        .patch<TaskBoardChange[]>(`/planner/api/boards/${boardId}/tasks/${taskId}`, data)
        .then((r) => r.data);

export const updateTaskNotificationPreferences = (
    boardId: number,
    taskId: number,
    preferences: { watch: boolean }
) =>
    axiosBff
        .put<TaskBoardChange[]>(
            `/planner/api/boards/${boardId}/tasks/${taskId}/notification-preferences`,
            preferences
        )
        .then((r) => r.data);

export const moveTask = (boardId: number, taskId: number, params: TaskLocation) =>
    axiosBff
        .put<TaskBoardChange[]>(`/planner/api/boards/${boardId}/tasks/${taskId}/position`, params)
        .then((r) => r.data);

export const deleteTask = (boardId: number, taskId: number) =>
    axiosBff.delete<TaskBoardChange[]>(`/planner/api/boards/${boardId}/tasks/${taskId}`).then((r) => r.data);

export const uploadTaskAttachment = (boardId: number, taskId: number, name: string, content: Blob) =>
    axiosBff
        .post<TaskBoardChange[]>(
            `/planner/api/boards/${boardId}/tasks/${taskId}/attachments`,
            fileUploadData(content, name),
            FILE_UPLOAD_HEADERS
        )
        .then((r) => r.data);

export const deleteTaskAttachment = (boardId: number, taskId: number, attachmentId: number) =>
    axiosBff
        .delete<TaskBoardChange[]>(
            `/planner/api/boards/${boardId}/tasks/${taskId}/attachments/${attachmentId}`
        )
        .then((r) => r.data);

export const downloadTaskAttachment = (boardId: number, taskId: number, attachmentId: number) =>
    axiosBff.get<Blob>(`/planner/api/boards/${boardId}/tasks/${taskId}/attachments/${attachmentId}/content`, {
        responseType: 'blob',
    });

export const createTaskComment = (boardId: number, taskId: number, text: string) =>
    axiosBff
        .post<TaskBoardChange[]>(`/planner/api/boards/${boardId}/tasks/${taskId}/comments`, { text })
        .then((r) => r.data);

export const deleteTaskComment = (boardId: number, taskId: number, commentId: number) =>
    axiosBff
        .delete<TaskBoardChange[]>(`/planner/api/boards/${boardId}/tasks/${taskId}/comments/${commentId}`)
        .then((r) => r.data);

export const getTaskComments = (boardId: number, taskId: number) =>
    axiosBff
        .get<TaskComment[]>(`/planner/api/boards/${boardId}/tasks/${taskId}/comments`)
        .then((r) => r.data);
