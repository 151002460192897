import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import qs from 'qs';
import { DEFAULT_ANALYTICS_SETTINGS } from 'platform/analytics/ducks/analyticsSettings.duck';
import ReportSaveModal from 'platform/analytics/reportComponents/ReportSave/ReportSaveModal';
import { Section } from 'platform/app/app.types';
import { ButtonDropdownOption } from 'platform/common/components/ButtonDropdown/ButtonDropdown';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import { useModal } from 'platform/common/components/Modal/Modal';
import { PopoverDropdownItem } from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import ReportCopyModal from 'platform/customReports/ReportCopyModal';
import ReportSelectModal from 'platform/customReports/ReportSelectModal';
import { CustomReport, CustomReportType } from 'platform/customReports/customReport.types';
import FolderSaveModal from './FolderAccess/FolderSaveModal';

interface Props {
    seatId: number;
    section: Section;
    templates?: CustomReport[];
    onReportChange: (report: CustomReport) => void;
    onReportsCreate?: (reports: CustomReport[]) => Promise<void>;
    refetchReports: () => void;
}

const SHARED_SORT_ID = 'sharedSortId';

const COPY_MODAL_TITLE: Record<string, string> = {
    CUSTOM_DASHBOARD: 'Copy from Dashboards templates',
    OPTIMIZATION: 'Copy from Optimization templates',
    CUSTOM_ANALYTICS: 'Copy from Custom templates',
};

type SectionSettings = {
    createReportActions?: ButtonDropdownOption[];
    getEditReportActions?: (report: CustomReport) => PopoverDropdownItem[];
    sortId?: string;
};

export const useSectionSettings = ({
    seatId,
    section,
    templates,
    refetchReports,
    onReportChange,
    onReportsCreate,
}: Props) => {
    const dispatch = useDispatch();
    const { showModal } = useModal();
    const showConfirmationModal = useConfirmationModal();
    const usedAsTemplate = !templates;

    const addReport: ButtonDropdownOption = {
        label: 'New',
        action: () => {
            showModal((toggle) => (
                <ReportSaveModal
                    seatIds={[seatId]}
                    section={section}
                    analyticSettings={DEFAULT_ANALYTICS_SETTINGS}
                    usedAsTemplate={usedAsTemplate}
                    toggle={() => {
                        toggle();
                        refetchReports();
                    }}
                />
            ));
        },
    };

    const addReportFromTemplate: ButtonDropdownOption = {
        label: 'From template',
        action: () => {
            showModal((toggle) => (
                <ReportCopyModal
                    reports={templates ?? []}
                    title={COPY_MODAL_TITLE[section]}
                    seatId={seatId}
                    toggle={toggle}
                    onOk={onReportsCreate}
                />
            ));
        },
    };

    const addReportFromExistingReport: ButtonDropdownOption = {
        label: 'From existing reports',
        action: () => {
            showModal((toggle) => (
                <ReportSelectModal seatId={seatId} section={section} toggle={toggle} onOk={onReportsCreate} />
            ));
        },
    };

    const addLink = (type: CustomReportType, label: string): ButtonDropdownOption => ({
        label,
        action: () => dispatch(push(`/report-management/new?${qs.stringify({ type, section, seatId })}`)),
    });

    const addFolder: ButtonDropdownOption = {
        label: 'Folder',
        action: () =>
            showModal((toggle) => (
                <FolderSaveModal
                    onCreate={async (report) => onReportsCreate?.([report])}
                    seatId={seatId!}
                    section={section}
                    toggle={toggle}
                />
            )),
    };

    const editAction = (report: CustomReport): PopoverDropdownItem => {
        if (report.type === 'FOLDER') {
            return {
                label: 'Assign users',
                action: () =>
                    showModal((toggle) => (
                        <FolderSaveModal
                            reportId={report.id}
                            onCreate={async (r) => onReportsCreate?.([r])}
                            seatId={seatId!}
                            section={section}
                            toggle={toggle}
                        />
                    )),
            };
        }

        return {
            label: 'Edit',
            action: () => dispatch(push(`/report-management/${report.id}`)),
        };
    };

    const statusChangeAction = (report: CustomReport): PopoverDropdownItem => {
        const active = report.status === 'ACTIVE';
        return {
            label: active ? 'Archive' : 'Activate',
            action: () =>
                showConfirmationModal(
                    () => onReportChange({ ...report, status: active ? 'ARCHIVED' : 'ACTIVE' }),
                    {
                        title: 'Change report status',
                        text: `The report ${report.name} will be ${
                            active ? 'archived' : 'activated'
                        }. Do you want to continue?`,
                    }
                ),
        };
    };

    const createReportActions: ButtonDropdownOption[] = usedAsTemplate
        ? [addReport]
        : [
              addReport,
              addReportFromTemplate,
              addReportFromExistingReport,
              addLink('EXTERNAL', 'External link'),
              addLink('INTERNAL_LINK', 'Internal link'),
              addFolder,
          ];

    const getEditReportActions = (report: CustomReport): PopoverDropdownItem[] =>
        report.section === 'CENTRAL_ANALYTICS'
            ? [statusChangeAction(report)]
            : [editAction(report), statusChangeAction(report)];

    const advertiserReportSettings: SectionSettings = {
        createReportActions,
        getEditReportActions,
        sortId: SHARED_SORT_ID,
    };

    const adminReportSettings: SectionSettings = {
        createReportActions,
        getEditReportActions,
        sortId: SHARED_SORT_ID,
    };

    const SECTION_SETTINGS: Record<Section, SectionSettings> = {
        CUSTOM_DASHBOARD: advertiserReportSettings,
        BUDGET: adminReportSettings,
        OPTIMIZATION: advertiserReportSettings,
        CUSTOM_ANALYTICS: advertiserReportSettings,
        PERSONAL: {},
        CAMPAIGNS: {},
        CENTRAL_ANALYTICS: {
            getEditReportActions,
        },
        COLLABORATION: {
            createReportActions: [addLink('EXTERNAL', 'Add planner link')],
            getEditReportActions,
            sortId: SHARED_SORT_ID,
        },
        MANAGEMENT: adminReportSettings,
        TESTING: adminReportSettings,
        MONITORING: adminReportSettings,
        FINANCE: adminReportSettings,
    };

    const sectionSettings = SECTION_SETTINGS[section];

    return {
        createReportActions: sectionSettings.createReportActions ?? [],
        getEditReportActions: (report: CustomReport) => sectionSettings.getEditReportActions?.(report) ?? [],
        sortId: sectionSettings.sortId,
    };
};
