import { useDispatch } from 'react-redux';
import { ReportComponentState } from 'platform/analytics/analytics.types';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { ComponentsProps } from 'platform/analytics/modes/Analytics';
import CopyButton from 'platform/common/components/CopyButton/CopyButton';
import IconButton from 'platform/common/components/IconButton/IconButton';
import ReportComponentEdit from './ReportComponentEdit';
import ReportComponentRemove from './ReportComponentRemove';

interface Props {
    index: number;
    itemCount: number;
    canEdit: boolean;
    isHighlighted: boolean;
    ANALYTICS_OVERLAY_Z_INDEX: number;
    toggleHighlight: (newState?: boolean | undefined) => void;
    handleComponentCopy: () => void;
    component: ReportComponentState;
    componentsProps: ComponentsProps | undefined;
}

const ReportComponentActions = ({
    index,
    isHighlighted,
    ANALYTICS_OVERLAY_Z_INDEX,
    toggleHighlight,
    handleComponentCopy,
    component,
    componentsProps,
    canEdit,
    itemCount,
}: Props) => {
    const dispatch = useDispatch();

    return (
        <>
            {!isHighlighted && (
                <CopyButton
                    title="Copy component"
                    className="p-1"
                    modalProps={{
                        zIndex: ANALYTICS_OVERLAY_Z_INDEX,
                        onCancel: toggleHighlight,
                    }}
                    onCopy={handleComponentCopy}
                    onClick={toggleHighlight}
                />
            )}
            <ReportComponentEdit
                initialState={component}
                componentsProps={componentsProps}
                canEdit={canEdit}
                toggleHighlight={toggleHighlight}
            />
            {index > 0 && !isHighlighted && (
                <IconButton
                    title="Move left"
                    className="p-1"
                    onClick={() =>
                        dispatch(
                            analyticsSettingsActions.moveComponent({
                                from: index,
                                to: index - 1,
                            })
                        )
                    }
                >
                    <i className="fal fa-arrow-left" />
                </IconButton>
            )}
            {index < itemCount - 1 && !isHighlighted && (
                <IconButton
                    title="Move right"
                    className="p-1"
                    onClick={() =>
                        dispatch(
                            analyticsSettingsActions.moveComponent({
                                from: index,
                                to: index + 1,
                            })
                        )
                    }
                >
                    <i className="fal fa-arrow-right" />
                </IconButton>
            )}
            {!isHighlighted && <ReportComponentRemove id={component.id} toggleHighlight={toggleHighlight} />}
        </>
    );
};

export default ReportComponentActions;
