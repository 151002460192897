import { InputGroup, InputGroupText } from 'reactstrap';
import classNames from 'classnames';
import FiltersContainer from 'platform/common/components/FiltersContainer/FiltersContainer';
import Search from 'platform/common/components/Search/Search';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import UserSelect from 'platform/common/components/UserSelect/UserSelect';
import { User } from 'platform/userManagement/types/user.type';
import { LabelNames, TASK_STATUS_OPTIONS, TaskLane, TaskStatus } from '../task.types';
import KanbanColumnSelect from './KanbanColumnSelect';
import KanbanLabelSelect from './KanbanLabelSelect';
import './Kanban.scss';

export interface KanbanFilterValues {
    search: string;
    assignees: number[];
    labels: string[];
    lanes: number[];
    status: TaskStatus[];
    date: [string, string];
}

interface Props {
    labelNames: LabelNames;
    lanes: TaskLane[];
    userOptions: User[];
    values: KanbanFilterValues;
    onChange: (filter: Partial<KanbanFilterValues>) => void;
}

const KanbanFilters = ({ labelNames, lanes, userOptions, values, onChange }: Props) => (
    <div className="Kanban-filters">
        <FiltersContainer filtersCount={5} canEdit={false}>
            {({ isFilterHidden }) => (
                <>
                    <Search
                        value={values.search}
                        minLength={0}
                        placeholder="Filter by task name..."
                        className={classNames('Kanban-search', {
                            'FiltersContainer-hidden': isFilterHidden(0),
                        })}
                        onSearch={(search) => onChange({ search })}
                    />
                    <InputGroup
                        className={classNames({
                            'FiltersContainer-hidden': isFilterHidden(1),
                        })}
                    >
                        <InputGroupText>Label</InputGroupText>
                        <KanbanLabelSelect
                            labelKeys={values.labels}
                            labelNames={labelNames}
                            onChange={(labels) => onChange({ labels })}
                        />
                    </InputGroup>
                    <InputGroup
                        className={classNames({
                            'FiltersContainer-hidden': isFilterHidden(2),
                        })}
                    >
                        <InputGroupText>Assigned to</InputGroupText>
                        <UserSelect
                            userIds={values.assignees}
                            options={userOptions}
                            closeMenuOnSelect
                            onChange={(assignees) => onChange({ assignees })}
                        />
                    </InputGroup>
                    <SelectWithAddon
                        name="Status"
                        className={classNames({
                            'FiltersContainer-hidden': isFilterHidden(3),
                        })}
                        value={values.status}
                        options={TASK_STATUS_OPTIONS}
                        isMulti
                        onChange={(status: TaskStatus[]) => onChange({ status })}
                    />
                    <InputGroup
                        className={classNames({
                            'FiltersContainer-hidden': isFilterHidden(4),
                        })}
                    >
                        <InputGroupText>Columns</InputGroupText>
                        <KanbanColumnSelect
                            value={values.lanes}
                            options={lanes}
                            onChange={(ids) => onChange({ lanes: ids })}
                        />
                    </InputGroup>
                </>
            )}
        </FiltersContainer>
    </div>
);

export default KanbanFilters;
