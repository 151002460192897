import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import { fetchAnalyticsColumns } from 'platform/analytics/analytics.service';
import { ColumnDefinition } from 'platform/analytics/analytics.types';
import SidePanelExpandButton from 'platform/campaign/common/SidePanelExpandButton';
import ScoringCreate from 'platform/campaign/globalPreset/components/Scoring/ScoringCreate';
import ScoringEdit from 'platform/campaign/globalPreset/components/Scoring/ScoringEdit';
import { Scoring } from 'platform/campaign/globalPreset/globalPreset.types';
import { getScoring } from 'platform/campaign/globalPreset/services/scoring.service';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import SidePanel from 'platform/common/components/SidePanel/SidePanel';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import SIDE_PANEL_SIZES from 'platform/common/constants/sidePanelSizes.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import useToggle from 'platform/common/hooks/useToggle';
import { formatDateTime } from 'platform/common/utils/date.util';
import CanNotEditWarning from '../../../../common/components/CanNotEditWarning/CanNotEditWarning';
import FormattedTable, { TableColumn } from '../../../../common/components/FormattedTable/FormattedTable';
import InlineDropdown from '../../../../common/components/InlineDropdown/InlineDropdown';
import ListToolbar from '../../../../common/components/ListToolbar/ListToolbar';

export const PATH = '/advertiser-settings/scoring';

const ScoringFormContainer = ({ children }: { children: ReactNode }) => {
    const [isPanelExpanded, toggleIsPanelExpanded] = useToggle(false);
    return (
        <SidePanel size={isPanelExpanded ? SIDE_PANEL_SIZES.FULL : SIDE_PANEL_SIZES.WIDE}>
            <SidePanelExpandButton
                isExpanded={isPanelExpanded}
                onClick={toggleIsPanelExpanded}
                style={{ right: 'auto' }}
            />
            {children}
        </SidePanel>
    );
};

const ScoringList = () => {
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const dispatch = useDispatch();
    const canEdit = useFeature('ADVANCED_CAMPAIGN_SETTINGS_EDIT');
    const [{ loading: isMetricsLoading, data: metrics }] = usePromise<ColumnDefinition[]>(
        [],
        () => fetchAnalyticsColumns(advertiserId).then((columns) => columns.metrics),
        []
    );

    const [{ loading, data }, fetchData] = usePromise<Scoring | undefined>(
        undefined,
        () => getScoring(advertiserId),
        [advertiserId]
    );

    const columns: TableColumn<Scoring>[] = [
        {
            Header: 'ID',
            accessor: (scoring) => scoring.id,
            type: DATA_TYPES.ID,
            maxWidth: 80,
        },
        {
            Header: 'Name',
            accessor: (scoring) => scoring.name,
            type: DATA_TYPES.TEXT,
        },
        {
            Header: 'Metrics',
            Cell: ({ original }) => {
                const metricNames = original.rules[0]?.metrics
                    .map((metric) => metrics.find((m) => m.key === metric.metric)?.name)
                    .join(', ');
                return (
                    <div className="text-truncate" title={metricNames}>
                        {metricNames}
                    </div>
                );
            },
            minWidth: 200,
            type: DATA_TYPES.TEXT,
        },
        {
            Header: 'Edited',
            accessor: (scoring) => scoring.updatedOn || scoring.createdOn,
            autoWidth: true,
            maxWidth: 200,
            Cell: ({ original }) => (
                <UpdatedOn
                    date={formatDateTime(original.updatedOn || original.createdOn)}
                    updatedBy={original.updatedBy}
                />
            ),
        },
        {
            className: 'pull-right cell-align-right',
            sortable: false,
            maxWidth: 50,
            Cell: ({ original }) => {
                const items = [
                    {
                        label: canEdit ? 'Edit' : 'View',
                        action: () => {
                            dispatch(push(`${PATH}/${original.id}`));
                        },
                    },
                ];

                return <InlineDropdown items={items} />;
            },
        },
    ];

    return (
        <>
            {(!canEdit || advertiserId) && (
                <BodyContainer helpKey="scoring_list">
                    {!canEdit && <CanNotEditWarning />}
                    {advertiserId && (
                        <FormattedTable
                            topToolbar={
                                loading || isMetricsLoading || !canEdit || data ? undefined : (
                                    <ListToolbar onCreate={() => dispatch(push(`${PATH}/new`))} />
                                )
                            }
                            columns={columns}
                            loading={loading || isMetricsLoading}
                            data={data ? [data] : []}
                        />
                    )}
                </BodyContainer>
            )}
            <Routes>
                <Route
                    path="new"
                    element={
                        <ScoringFormContainer>
                            <ScoringCreate
                                redirectTo={PATH}
                                canEdit={canEdit}
                                afterSubmit={fetchData}
                                advertiserId={advertiserId}
                            />
                        </ScoringFormContainer>
                    }
                />
                <Route
                    path=":id"
                    element={
                        <ScoringFormContainer>
                            <ScoringEdit
                                redirectTo={PATH}
                                canEdit={canEdit}
                                afterSubmit={fetchData}
                                advertiserId={advertiserId}
                            />
                        </ScoringFormContainer>
                    }
                />
            </Routes>
        </>
    );
};

export default ScoringList;
