import { CSSProperties } from 'react';
import classNames from 'classnames';
import './SwitchWithChildren.scss';

interface Props {
    checked: boolean;
    className?: string;
    checkedIconName: string;
    uncheckedIconName: string;
    checkedText?: string;
    uncheckedText: string;
    style?: CSSProperties;
    onChange: (checked: boolean) => void;
}

const SwitchWithChildren = ({
    checked,
    className,
    checkedIconName,
    uncheckedIconName,
    checkedText,
    uncheckedText,
    style,
    onChange,
}: Props) => {
    const checkedContent = (
        <div className="SwitchWithChildren-checked--content">
            <i className={classNames('icon-grey fal', checkedIconName)} />
            {checkedText && <div className="mx-2">{checkedText}</div>}
        </div>
    );

    const uncheckedContent = (
        <div className="SwitchWithChildren-unchecked--content">
            <i className={classNames('icon-grey fal', uncheckedIconName)} />
            <div className="mx-2">{uncheckedText}</div>
        </div>
    );

    return (
        <div className={className}>
            <button
                style={style}
                className={classNames('SwitchWithChildren', { checked })}
                aria-label="Switch"
                type="button"
                onClick={() => onChange(!checked)}
            >
                <div className="SwitchWithChildren-unchecked">{uncheckedContent}</div>
                <div className="SwitchWithChildren-checked">{checkedContent}</div>
            </button>
        </div>
    );
};

export default SwitchWithChildren;
