import SwitchWithChildren from '../SwitchWithChildren/SwitchWithChildren';

interface Props {
    className?: string;
    toggled: boolean;
    onClick: () => void;
}

const ShowSettingsButton = ({ className = '', toggled, onClick }: Props) => (
    <SwitchWithChildren
        className={className}
        checked={toggled}
        checkedIconName="far fa-pen-to-square"
        uncheckedIconName="far fa-eye"
        checkedText="Edit"
        uncheckedText="View"
        onChange={onClick}
    />
);

export default ShowSettingsButton;
