import { Dispatch } from 'redux';
import { ReportFilter } from 'platform/analytics/analytics.types';
import { getFilterNumberValue } from 'platform/analytics/analytics.util';
import {
    findRegisteredActionById,
    isRedirectAction,
} from 'platform/analytics/reportComponents/ReportTableContainer/InlineActions/inlineActionConnect';
import { Feature } from 'platform/app/app.types';
import { isDefined } from 'platform/common/common.types';
import ButtonDropdown from 'platform/common/components/ButtonDropdown/ButtonDropdown';

type Props = {
    dispatch: Dispatch;
    hasFeature: (feature: Feature) => boolean;
    filters: ReportFilter[];
};

const CampaignActions = ({ dispatch, hasFeature, filters }: Props) => {
    const context = {
        advertiser_id: getFilterNumberValue(filters, 'advertiser_id'),
        campaign_id: getFilterNumberValue(filters, 'campaign_id'),
    };

    const actions = [findRegisteredActionById('add_campaign')];

    const items = actions
        .filter(isDefined)
        .filter(isRedirectAction)
        .filter((action) => !action.requiresFeature || hasFeature(action.requiresFeature))
        .map((action) => ({
            label: action.label,
            action: () => action.operation(context, dispatch),
        }));

    if (!items.length) {
        return null;
    }

    return <ButtonDropdown items={items}>Add new</ButtonDropdown>;
};

export default CampaignActions;
