import { useEffect, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { isEmpty } from 'lodash-es';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
    AnalyticsMode,
    NavigationParams,
    AnalyticsUrlSettings,
    ReportFilter,
} from 'platform/analytics/analytics.types';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { useApplyUrlSettings } from 'platform/analytics/hooks/useApplyUrlSettings';
import { useFetchReport } from 'platform/analytics/hooks/useFetchReport';
import Analytics, { AnalyticsProps } from 'platform/analytics/modes/Analytics';
import BudgetReporting from 'platform/analytics/modes/BudgetReporting';
import CampaignAnalytics from 'platform/analytics/modes/CampaignAnalytics/CampaignAnalytics';
import InsightAnalytics from 'platform/analytics/modes/InsightAnalytics';
import { authActions } from 'platform/app/ducks/auth.duck';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import { AdvertiserOption } from 'platform/common/ducks/activeAdvertiser.duck';
import { dateFilterActions } from 'platform/common/ducks/dateFilter.duck';
import useActiveAdvertisersComponent from 'platform/common/hooks/useActiveAdvertisers';
import useToggle from 'platform/common/hooks/useToggle';
import { parseQuery } from 'platform/common/utils/url.util';
import LoadingReportIndicator from './LoadingReportIndicator';
import useMediaplanFilter from './hooks/useMediaplanFilter';
import InteractiveAnalytics from './modes/InteractiveAnalytics';

const DefaultAnalyticsWrapper = ({ children }: { children: ReactNode }) => {
    useActiveAdvertisersComponent();

    return <>{children}</>;
};

const DEFAULT_BUDGET_REPORTING_FILTERS = [{ key: 'media_plan_id', values: [] }];

const DEFAULT_CAMPAIGN_FILTERS = [
    { key: 'campaign_id', values: [] },
    { key: 'campaign_state', values: ['NEW', 'RUNNING'] },
    { key: 'vendor_name', values: [] },
];

const DEFAULT_FILTERS: { [key in AnalyticsMode]: { add: ReportFilter[]; remove: ReportFilter[] } } = {
    BUDGET: {
        add: [],
        remove: DEFAULT_BUDGET_REPORTING_FILTERS,
    },
    CAMPAIGN: {
        add: DEFAULT_CAMPAIGN_FILTERS,
        remove: DEFAULT_CAMPAIGN_FILTERS,
    },
    DEFAULT: {
        add: [],
        remove: [],
    },
    INSIGHT: {
        add: [],
        remove: [],
    },
    INTERACTIVE: {
        add: [],
        remove: [],
    },
};

interface Props {
    onRedirect: () => void;
    onAdvertisersChange: (advertisers: AdvertiserOption[]) => void;
}

const AnalyticsContainer = ({ onRedirect, onAdvertisersChange }: Props) => {
    const location = useLocation();
    const settings = useSelector(analyticsSelectors.settings);
    const filters = useSelector(analyticsSelectors.ownFilters);
    const dispatch = useDispatch();
    const queryParams: NavigationParams = parseQuery(location.search);
    const urlSettings: AnalyticsUrlSettings = queryParams.settings ? JSON.parse(queryParams.settings) : {};
    const reportId = queryParams.reportId;
    const showConfirmModal = useConfirmationModal();

    const [editMode, toggleEditMode] = useToggle(!reportId);

    useMediaplanFilter();

    const { report, isReportLoading, refetchReport } = useFetchReport({
        advertiserId: queryParams.advertiserId,
        reportId,
        algorithmId: queryParams.algorithmId,
        onRedirect,
        urlSettings,
        toggleEditMode,
        onAdvertisersChange,
    });

    useApplyUrlSettings({ settings, urlSettings, isReportLoading, reportId });

    useEffect(() => {
        dispatch(authActions.saveReturnLocation(location));
    }, [dispatch, location]);

    useDeepCompareEffect(() => {
        if (isEmpty(urlSettings) && !isReportLoading) {
            refetchReport();
        }
    }, [urlSettings]);

    const handleModeChange = (mode: AnalyticsMode) => {
        if (mode === 'INTERACTIVE') {
            dispatch(
                dateFilterActions.changePeriods({
                    primaryFrom: undefined,
                    primaryTo: undefined,
                    secondaryFrom: undefined,
                    secondaryTo: undefined,
                    preset: undefined,
                })
            );
        }

        if (settings.components.some((comp) => comp.type === 'INTERACTION') && mode !== 'INTERACTIVE') {
            showConfirmModal(() => applySettings(mode), {
                title: 'Interactive elements detected',
                text: 'Interactive elements are supported only in Interactive mode. Do you want to remove these elements to proceed?',
            });
        } else {
            applySettings(mode);
        }
    };

    const applySettings = (mode: AnalyticsMode) => {
        const filtersToAdd = DEFAULT_FILTERS[mode].add.filter(
            (defaultFilter) => !filters.some((filter) => filter.key === defaultFilter.key)
        );
        const filtersToRemove = Object.keys(DEFAULT_FILTERS)
            .filter((key) => key !== mode)
            .flatMap((key: AnalyticsMode) => DEFAULT_FILTERS[key].remove);

        dispatch(
            analyticsSettingsActions.changeSettings({
                ...settings,
                mode,
                components:
                    mode === 'INTERACTIVE'
                        ? settings.components
                        : settings.components.filter((comp) => comp.type !== 'INTERACTION'),
                filters:
                    mode === 'INTERACTIVE'
                        ? []
                        : [
                              ...filtersToAdd,
                              ...filters.filter(
                                  (filter) =>
                                      !filtersToRemove.some(
                                          (filterToRemove) => filter.key === filterToRemove.key
                                      )
                              ),
                          ],
            })
        );
    };

    if (isReportLoading) {
        return <LoadingReportIndicator />;
    }

    const renderAnalytics = (props?: AnalyticsProps) => (
        <Analytics
            report={report}
            location={location}
            editMode={editMode}
            onSettingsToggle={toggleEditMode}
            onModeChange={handleModeChange}
            {...props}
        />
    );

    switch (settings.mode) {
        case 'BUDGET': {
            return <BudgetReporting>{renderAnalytics}</BudgetReporting>;
        }
        case 'CAMPAIGN': {
            return <CampaignAnalytics>{renderAnalytics}</CampaignAnalytics>;
        }
        case 'INSIGHT': {
            return <InsightAnalytics settings={settings}>{renderAnalytics}</InsightAnalytics>;
        }
        case 'INTERACTIVE': {
            return <InteractiveAnalytics>{renderAnalytics}</InteractiveAnalytics>;
        }
        default:
            return <DefaultAnalyticsWrapper>{renderAnalytics()}</DefaultAnalyticsWrapper>;
    }
};

export default AnalyticsContainer;
