import { useState } from 'react';
import { groupBy, last, max, min, sortBy } from 'lodash-es';
import moment from 'moment';
import DataIngestionJobModal from 'platform/adminPanel/components/DataIngestion/DataIngestionJobModal';
import DataIngestionJobStatusIcon from 'platform/adminPanel/components/DataIngestion/DataIngestionJobStatusIcon';
import {
    DataIngestionJobExecution,
    fetchDataIngestionJobExecutions,
    fetchDataIngestionJobs,
    JobStatus,
    runDataIngestionJob,
} from 'platform/adminPanel/components/DataIngestion/dataIngestion.service';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import DateRangePicker from 'platform/common/components/DateRangePicker/DateRangePicker';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import IconButton from 'platform/common/components/IconButton/IconButton';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import LabelWithSubtext from 'platform/common/components/LabelWithSubtext/LabelWithSubtext';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import { useModal } from 'platform/common/components/Modal/Modal';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import { usePromise } from 'platform/common/hooks/usePromise';
import { formatDateTime, formatDuration } from 'platform/common/utils/date.util';
import './DataIngestion.scss';

interface VendorExecutions {
    vendor: string;
    executions: DataIngestionJobExecution[];
}

const groupByVendor = (executions: DataIngestionJobExecution[]): VendorExecutions[] =>
    sortBy(
        Object.entries(groupBy(executions, (e) => e.vendor)).map(([vendor, vendorExecutions]) => ({
            vendor,
            executions: sortBy(vendorExecutions, [(e) => e.name, (e) => e.startedOn]),
        })),
        (s) => s.vendor
    );

const summarizeVendorStatus = (executions: DataIngestionJobExecution[]) => {
    const jobRuns = Object.values(groupBy(executions, (e) => e.name));
    const bestJobStatuses = jobRuns.map((runs) => min(runs.map((e) => e.status)));
    return max(bestJobStatuses);
};

const findLast = (status: JobStatus, executions: DataIngestionJobExecution[]) => {
    const filtered = executions.filter((e) => e.status === status);
    return last(sortBy(filtered, (e) => e.endedOn));
};

const DataIngestionJobList = () => {
    const [jobName, setJobName] = useState<string | undefined>();
    const today = moment().format(ISO_DATE_FORMAT);
    const { showModal } = useModal();
    const [dateRange, setDateRange] = useState<{ since: string | undefined; until: string | undefined }>({
        since: today,
        until: today,
    });

    const [{ data: jobs }] = usePromise([], fetchDataIngestionJobs, []);
    const [{ data: executions, loading }, refetch] = usePromise(
        [],
        () => fetchDataIngestionJobExecutions({ name: jobName, ...dateRange }).then(groupByVendor),
        [jobName, dateRange]
    );

    const vendorColumns: TableColumn<VendorExecutions>[] = [
        {
            Header: 'Vendor',
            width: 130,
            Cell: ({ original: { vendor } }) => (vendor === 'google' ? 'google (CM360)' : vendor),
        },
        {
            Header: 'Status summary',
            Cell: ({ original: s }) => {
                const status = summarizeVendorStatus(s.executions) ?? 'RUNNING';
                const failureCount = s.executions.filter((e) => e.status === 'FAILED').length;
                return (
                    <>
                        <DataIngestionJobStatusIcon status={status} />
                        {status === 'COMPLETED' && failureCount > 0 && (
                            <span className="ms-2">(after {failureCount} retries)</span>
                        )}
                    </>
                );
            },
            width: 230,
        },
        {
            Header: 'Last error',
            accessor: (s) => findLast('FAILED', s.executions)?.message,
            className: 'text-danger',
            Cell: CellWithEllipsis,
        },
        {
            Header: 'Last successful update',
            accessor: (s) => formatDateTime(findLast('COMPLETED', s.executions)?.endedOn),
            width: 150,
        },
    ];

    const executionColumns: TableColumn<DataIngestionJobExecution>[] = [
        {
            Header: 'Job',
            accessor: 'name',
            width: 220,
            depth: 1,
        },
        {
            Header: 'Status',
            accessor: 'status',
            width: 150,
            Cell: ({ original: { status } }) => <DataIngestionJobStatusIcon status={status} />,
            depth: 1,
        },
        {
            Header: 'Error',
            accessor: 'message',
            className: 'text-danger',
            Cell: CellWithEllipsis,
            depth: 1,
        },
        {
            Header: 'Data',
            width: 270,
            Cell: ({ original: { params, result } }) => {
                const totalDays = params ? moment(params?.periodTo).diff(params?.periodFrom, 'days') + 1 : 0;
                return (
                    <LabelWithSubtext
                        label={result ? `${result.totalRows} rows from ${result.totalAccounts} accounts` : ''}
                        subtext={
                            params
                                ? `Range: ${params.periodFrom} - ${params.periodTo} (${totalDays} days)`
                                : ''
                        }
                    />
                );
            },
            depth: 1,
        },
        {
            Header: 'Started',
            accessor: 'startedOn',
            width: 140,
            Cell: ({ original: { startedOn, startedBy } }) => (
                <LabelWithSubtext label={formatDateTime(startedOn)} subtext={startedBy || '(on schedule)'} />
            ),
            depth: 1,
        },
        {
            Header: 'Ended',
            width: 140,
            accessor: 'endedOn',
            Cell: ({ original: { startedOn, endedOn } }) =>
                endedOn ? (
                    <LabelWithSubtext
                        label={formatDateTime(endedOn)}
                        subtext={formatDuration(startedOn, endedOn)}
                    />
                ) : null,
            depth: 1,
        },
        {
            Header: '',
            className: 'pull-right cell-align-right',
            width: 50,
            Cell: ({ original: { name } }) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Run...',
                            action: () =>
                                showModal((toggle) => (
                                    <DataIngestionJobModal
                                        jobName={name}
                                        toggle={toggle}
                                        onSubmit={(params) =>
                                            runDataIngestionJob(name, params).then(toggle).then(refetch)
                                        }
                                    />
                                )),
                        },
                    ]}
                />
            ),
            depth: 1,
        },
    ];

    return (
        <>
            <BodyContainer helpKey="data_ingestion_job_list">
                <ListFilters className="mb-3 d-flex align-items-center justify-content-between">
                    <SelectWithAddon
                        selectStyle={{ minWidth: 270 }}
                        name="Job"
                        value={jobName}
                        options={jobs.map(({ name }) => ({ label: name, value: name }))}
                        onChange={setJobName}
                    />
                    <div className="d-flex align-items-center gap-3">
                        <IconButton onClick={refetch}>
                            <i className="fa fa-refresh" />
                        </IconButton>
                        <DateRangePicker
                            label="Run date"
                            from={dateRange.since}
                            to={dateRange.until}
                            maxDate={today}
                            onChange={(since, until) => setDateRange({ since, until })}
                        />
                    </div>
                </ListFilters>
                <FormattedTable
                    containerClass="DataIngestionJobs"
                    columns={[...vendorColumns, ...executionColumns]}
                    data={executions}
                    isTree
                    nodesExpanded={!!jobName}
                    childrenLoaders={[{ field: 'executions' }]}
                    childrenOffset={0}
                    sortable={false}
                    stickyHeader={false}
                />
                {loading && <OverlayLoader />}
            </BodyContainer>
        </>
    );
};

export default DataIngestionJobList;
