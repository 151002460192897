import { MouseEvent } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Card } from 'reactstrap';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { truncate } from 'lodash-es';
import IconButton from 'platform/common/components/IconButton/IconButton';
import RawHtml from 'platform/common/components/RawHtml/RawHtml';
import TaskTitle from 'platform/task/Common/TaskTitle';
import { User } from 'platform/userManagement/types/user.type';
import { LabelNames, Task, TaskBoardChange, TaskData, TaskLane, TaskLocation } from '../task.types';
import KanbanCardActionsDropdown from './KanbanCardActionsDropdown';
import KanbanCardFooter from './KanbanCardFooter';
import KanbanCardLabelEdit from './KanbanCardLabelEdit';

type Props = {
    boardId: number;
    task: Task;
    lane: TaskLane;
    isBlocked: boolean;
    taskIndex: number;
    totalTasksInLane: number;
    labelNames: LabelNames;
    userOptions: User[];
    expanded: boolean;
    onOpen: (taskId: number) => void;
    onAttachmentDownload: (taskId: number, attachmentId: number) => Promise<AxiosResponse<Blob>>;
    onUpdate: (taskId: number, data: Partial<TaskData>) => void;
    onMove: (taskId: number, location: TaskLocation) => void;
    onDelete: (taskId: number) => void;
    onExpand: () => void;
    onTaskCopy: (task: Task) => void;
    onTaskWatchChange: (taskId: number, watch: boolean) => Promise<TaskBoardChange[]>;
};

const handleDescriptionClick = (e: MouseEvent<HTMLDivElement>) => {
    // when user clicks a link inside description, open it in a new window/tab
    if ((e.target as Element).tagName?.toLowerCase() === 'a') {
        e.preventDefault();
        e.stopPropagation();
        window.open((e.target as HTMLAnchorElement).href, '_blank');
    }
};

const KanbanCard = ({
    boardId,
    task,
    lane,
    isBlocked,
    taskIndex,
    totalTasksInLane,
    labelNames,
    userOptions,
    expanded,
    onOpen,
    onUpdate,
    onMove,
    onDelete,
    onExpand,
    onTaskCopy,
    onTaskWatchChange,
}: Props) => (
    <Draggable draggableId={`${task.id}`} index={taskIndex}>
        {({ dragHandleProps, draggableProps, innerRef }, { isDragging }) => (
            <div ref={innerRef} {...draggableProps} {...dragHandleProps}>
                <Card
                    className={classNames('Kanban-card', {
                        'Kanban-card--muted': task.status === 'ARCHIVED',
                        'Kanban-card--dragging': isDragging,
                    })}
                    onClick={() => onOpen(task.id)}
                >
                    <div className="d-flex">
                        <KanbanCardLabelEdit
                            keys={task.labelKeys ?? []}
                            names={labelNames}
                            onUpdate={(data) => onUpdate(task.id, data)}
                        />
                        <KanbanCardActionsDropdown
                            className="ms-auto"
                            boardId={boardId}
                            task={task}
                            currentPosition={taskIndex}
                            bottomPosition={totalTasksInLane}
                            onMove={(position) => onMove(task.id, { laneId: lane.id, position })}
                            onUpdate={(data) => onUpdate(task.id, data)}
                            onDelete={() => onDelete(task.id)}
                            onTaskCopy={onTaskCopy}
                        />
                    </div>
                    <TaskTitle task={task} lane={lane} isBlocked={isBlocked} />
                    {!!task.description && !!task.display?.showDescriptionOnCard && (
                        <div role="button" tabIndex={-1} onClick={handleDescriptionClick}>
                            <RawHtml className="Kanban-card-description">
                                {truncate(task.description, { length: 80 })}
                            </RawHtml>
                        </div>
                    )}
                    <hr className="dashed-line my-2" />
                    <KanbanCardFooter
                        task={task}
                        parentLane={lane}
                        userOptions={userOptions}
                        onTaskWatchChange={onTaskWatchChange}
                        onTaskUpdate={onUpdate}
                    />
                    {!!task.subtasks?.length && (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                onExpand();
                            }}
                            className="Kanban-card-expand"
                        >
                            <i
                                className={classNames('fa-solid', {
                                    'fa-chevron-up': expanded,
                                    'fa-chevron-down': !expanded,
                                })}
                            />
                        </IconButton>
                    )}
                </Card>
            </div>
        )}
    </Draggable>
);

export default KanbanCard;
