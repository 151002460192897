import { useState } from 'react';
import { isEmpty } from 'lodash-es';
import { fetchFlightLabels } from 'platform/campaign/advertiserManagement/FlightLabels/flightLabel.service';
import { OptionType } from 'platform/common/components/Select/select.types';
import { usePromise } from 'platform/common/hooks/usePromise';
import { namesToOptions } from 'platform/common/utils/option.util';
import FormCreatableSelect, {
    FormCreatableSelectProps,
} from 'platform/formik/FormCreatableSelect/FormCreatableSelect';

type Props = { advertiserId: number; options?: FormCreatableSelectProps['options'] } & Omit<
    FormCreatableSelectProps,
    'options' | 'onNewOption'
>;

const FormFlightLabelSelect = ({ advertiserId, isLoading, options, isClearable = true, ...rest }: Props) => {
    const [newLabel, setNewLabel] = useState<OptionType>({});
    const [{ data: fetchedOptions, loading }] = usePromise(
        [],
        async () => {
            if (options?.length || isLoading) return options ?? [];

            return fetchFlightLabels(advertiserId).then(namesToOptions);
        },
        [advertiserId, isLoading, options]
    );

    return (
        <FormCreatableSelect
            {...rest}
            isClearable={isClearable}
            returnOnlyValues={false}
            options={!isEmpty(newLabel) ? [...fetchedOptions, newLabel] : fetchedOptions}
            isLoading={loading || isLoading}
            onNewOption={setNewLabel}
            formatCreateLabel={(value) => (
                <span>
                    <b>Create label</b>
                    {` "${value}"`}
                </span>
            )}
            placeholder="Select or enter..."
        />
    );
};

export default FormFlightLabelSelect;
