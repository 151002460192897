import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import CustomActionRuleFormContainer from 'platform/campaign/globalPreset/components/CustomActionRule/CustomActionRuleFormContainer';
import { CustomActionRuleListModel } from 'platform/campaign/globalPreset/globalPreset.types';
import { fetchCustomActionRules } from 'platform/campaign/globalPreset/services/globaCustomActionRule.service';
import { TableCell } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import { DATA_TYPES } from 'platform/common/dataTypes';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';

export const PATH = '/advertiser-settings/custom-action-rules';

const GlobalCustomActionRuleList = () => {
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const dispatch = useDispatch();
    const canEdit = useFeature('ADVANCED_CAMPAIGN_SETTINGS_EDIT');

    const [{ loading, data }, fetchData] = usePromise<CustomActionRuleListModel[]>(
        [],
        () => fetchCustomActionRules(advertiserId),
        [advertiserId]
    );

    const openCustomActionRuleCreateForm = () => dispatch(push(`${PATH}/new`));
    const openCustomActionRuleEditForm = (id: number) => dispatch(push(`${PATH}/${id}`));

    const renderTopToolbar = () => (
        <ListToolbar onCreate={canEdit ? openCustomActionRuleCreateForm : undefined} />
    );

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            maxWidth: 80,
        },
        {
            Header: 'Name',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
        },
        {
            Header: 'Default dimension',
            minWidth: 150,
            type: DATA_TYPES.INT,
            Cell: ({ original }: TableCell<CustomActionRuleListModel>) => original?.ruleSet?.default,
        },
        {
            Header: 'Dimensions',
            minWidth: 150,
            type: DATA_TYPES.INT,
            Cell: ({ original }: TableCell<CustomActionRuleListModel>) =>
                original?.ruleSet?.rules?.map((r) => r.dimension).join(','),
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: ({ original }: TableCell<CustomActionRuleListModel>) => {
                const items = [
                    {
                        label: canEdit ? 'Edit' : 'View',
                        action: () => {
                            openCustomActionRuleEditForm(original.id);
                        },
                    },
                ];

                return <InlineDropdown items={items} />;
            },
        },
    ];

    return (
        <>
            {(!canEdit || advertiserId) && (
                <BodyContainer helpKey="custom_action_rules_list">
                    {!canEdit && <CanNotEditWarning />}
                    {advertiserId && (
                        <FormattedTable
                            topToolbar={renderTopToolbar()}
                            columns={columns}
                            loading={loading}
                            data={data}
                        />
                    )}
                </BodyContainer>
            )}
            <Routes>
                <Route
                    path="new"
                    element={
                        <CustomActionRuleFormContainer
                            redirectTo={PATH}
                            canEdit={canEdit}
                            afterSubmit={fetchData}
                        />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <CustomActionRuleFormContainer
                            redirectTo={PATH}
                            canEdit={canEdit}
                            afterSubmit={fetchData}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default GlobalCustomActionRuleList;
