import { useEffect } from 'react';
import { sortBy } from 'lodash-es';
import {
    ColumnDefinition,
    FilterDefinition,
    ReportFilterWithOperator,
    Template,
} from 'platform/analytics/analytics.types';
import { isOlapReport } from 'platform/analytics/analytics.util';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { FilterInputProps } from 'platform/analytics/modes/Analytics';
import AnalyticsFilter from 'platform/analytics/reportComponents/ReportFilters/AnalyticsFilter';
import { isDefined } from 'platform/common/common.types';
import AddItemButton from 'platform/common/components/AddItemButton/AddItemButton';
import { ButtonDropdownOption } from 'platform/common/components/ButtonDropdown/ButtonDropdown';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import { typeOf } from 'platform/common/dataTypes';
import { FilterOptionsMap } from './useReportFilterOptions';

interface Props {
    template: Template;
    compatibleFilters: FilterDefinition[];
    filters: ReportFilterWithOperator[];
    filterOptionsMap?: FilterOptionsMap;
    filterInputProps?: FilterInputProps;
    loading: boolean;
    onChange: (filters: ReportFilterWithOperator[]) => void;
}

const ReportCustomFilters = ({
    template,
    compatibleFilters,
    filters,
    filterOptionsMap,
    loading,
    filterInputProps,
    onChange,
}: Props) => {
    const { definitions } = useAnalyticsMetadata();

    useEffect(() => {
        onChange(filters);
    }, []);

    const availableColumns: ColumnDefinition[] = isOlapReport(template)
        ? compatibleFilters.map((f) => ({ key: f.target, name: f.name }))
        : [...template.dimensions, ...template.metrics];

    const options = availableColumns
        .filter((column) => !filters.find((f) => f.key === column.key))
        .map(
            ({ key, name }): ButtonDropdownOption => ({
                key,
                label: `${name ?? ''} [${key}]`,
                action: () => onChange([...filters, { key, operator: 'IS', values: [] }]),
            })
        );

    const getFilterDefinition = (columnKey: string): FilterDefinition => {
        const standardFilter = definitions.filters.find((f) => f.target === columnKey);
        if (!standardFilter) {
            const column = [...template.dimensions, ...template.metrics].find((c) => c.key === columnKey);
            return {
                type: typeOf(column).isNumeric ? 'NUMBER' : 'TEXT',
                source: columnKey,
                target: columnKey,
                name: column?.name || columnKey,
                isMulti: true,
            };
        }
        return standardFilter;
    };

    return (
        <>
            {filters.length > 0 && <p className="mt-0 mb-1 text-muted fw-bold">Additional filters:</p>}
            <ListFilters>
                {filters
                    .map((filter) => {
                        const filterOptions = filterOptionsMap?.[filter.key];
                        return (
                            <AnalyticsFilter
                                key={filter.key}
                                filter={filter}
                                filterDefinition={getFilterDefinition(filter.key)}
                                options={filterOptions?.options ?? []}
                                error={filterOptions?.error}
                                template={template}
                                loading={loading}
                                onChange={(newFilter) => {
                                    onChange(filters.map((f) => (f.key === newFilter.key ? newFilter : f)));
                                }}
                                onRemove={() => onChange(filters.filter((f) => f.key !== filter.key))}
                                refetch={() => onChange(filters)}
                                filterInputProps={filterInputProps}
                            />
                        );
                    })
                    .filter(isDefined)}
                <AddItemButton label="Add filter" options={sortBy(options, (o) => o.label)} />
            </ListFilters>
        </>
    );
};

export default ReportCustomFilters;
