import { mapValues } from 'lodash-es';
import { Segment, SegmentAlertingRules } from 'platform/advertiserTag/advertiserTag.types';
import { isDefined } from 'platform/common/common.types';
import { ExternalSystem } from 'platform/common/constants/externalIntegration.constant';
import { Status } from 'platform/common/constants/status.constant';
import {
    SegmentFormAdvertiser,
    SegmentFormModel,
} from 'platform/segments/components/SegmentForm/SegmentForm.types';
import { SegmentType } from '../constants/segmentTypes.constant';

const STANDARD_EVENT_SECTIONS: Record<string, string> = {
    ViewProduct: 'SS_PRODUCT_1',
    ViewList: 'SS_PRODUCT_1',
    AddToCart: 'SS_FUNNEL_1',
    InitiateCheckout: 'SS_FUNNEL_2',
    UserDetails: 'SS_FUNNEL_3',
    AddPayment: 'SS_FUNNEL_4',
    ReviewOrder: 'SS_FUNNEL_5',
    Purchase: 'SS_PURCHASE_ATT',
    PurchaseUnattributed: 'SS_PURCHASE_UNATT',
};

const SOURCE_1ST_PARTY = 'SSO1P';
const SECTION_MISC_1 = 'SS_MISC_1';

export const getClassificationCodesBySegmentType = (
    segmentType: SegmentType,
    advertiserTagVarName: string
): string[] => {
    switch (segmentType) {
        case 'STANDARD_EVENT':
            return [SOURCE_1ST_PARTY, STANDARD_EVENT_SECTIONS[advertiserTagVarName]].filter(isDefined);
        case 'TRIGGER':
            return [SOURCE_1ST_PARTY, SECTION_MISC_1];
        case 'CUSTOM_EVENT':
        case 'URL_VISIT':
        case 'VISIT_TIME':
            return [SOURCE_1ST_PARTY];
        default:
            return [];
    }
};

export const SEGMENT_ALERTING_RULE_LABELS: Record<keyof SegmentAlertingRules, string> = {
    dailyVisitsAlertingThreshold: "Alert if yesterday's daily segment visits fall by more than X%",
    missingOrderIdsAlertingThreshold: 'Alert if more than X% of order-ids are missing',
};

export const getSegmentAlertingRuleLabels = (rules: Partial<SegmentAlertingRules>): string[] =>
    Object.entries(SEGMENT_ALERTING_RULE_LABELS).flatMap(([key, label]) => {
        const value = rules[key as keyof SegmentAlertingRules];
        return value ? [label.replace('X%', `${value}%`)] : [];
    });

export const isSegmentAlertingEnabled = (rules: Partial<SegmentAlertingRules>) =>
    Object.keys(SEGMENT_ALERTING_RULE_LABELS).some((key) => !!rules[key as keyof SegmentAlertingRules]);

export const isGoogleOrFacebook = (externalSystem: ExternalSystem) =>
    externalSystem === 'FACEBOOK' || externalSystem === 'GOOGLE';

const supportsSegmentSync = (advertiser: SegmentFormAdvertiser, externalSystem: string) =>
    advertiser.externalSystemIntegrations
        ?.find((i) => i.externalSystem === externalSystem)
        ?.accounts.some((account) => account.segmentSyncEnabled) ?? false;

export const maskUrls = (urls?: string[] | null) => (urls ?? []).map((url, index) => `URL ${index + 1}`);

export const toFormModel = ({
    segment,
    advertiser,
}: {
    segment: Partial<Segment>;
    advertiser: SegmentFormAdvertiser;
}): SegmentFormModel => {
    const {
        id,
        name,
        classificationCodes,
        description,
        state,
        type,
        availability,
        urls,
        advertiserTagVarName,
        convertible,
        advertiserTagId,
        advertiserId,
        triggerEventType,
        triggerType,
        triggerValues,
        floodlightActivityIds,
        googleAdsConversionIds,
        googleAnalyticsEventNames,
        bingGoalNames,
        dailyVisitsAlertingThreshold,
        missingOrderIdsAlertingThreshold,
    } = segment;

    const advertiserIntegrations = advertiser.externalSystemIntegrations
        ?.filter((item) => item.enabled && isGoogleOrFacebook(item.externalSystem))
        .map((integration) => ({
            externalSystem: integration.externalSystem,
            state: (isGoogleOrFacebook(integration.externalSystem) || !integration.enabled
                ? 'INACTIVE'
                : 'ACTIVE') as Status,
            recencies: undefined,
            isToggleEnabled: false,
        }))
        .reduce<{
            [key: string]: {
                externalSystem: ExternalSystem;
                state: Status;
                recencies: undefined;
                isToggleEnabled: boolean;
            };
        }>((result, item) => ({ ...result, [item.externalSystem]: item }), {});

    const integrationsWithDefaultOptions = mapValues(advertiserIntegrations, (obj, key: ExternalSystem) => {
        const integration = segment.integrations ? segment.integrations[key] : obj;
        return {
            ...integration,
            externalReadOnly: false,
            changeMapping: false,
            externalSystem: key,
            enabled: integration?.state === 'ACTIVE',
            recencies: integration?.recencies?.map((recency) => recency.days),
            isToggleEnabled: isGoogleOrFacebook(key) && supportsSegmentSync(advertiser, key),
        };
    });

    return {
        id,
        name: name || '',
        classificationCodes: classificationCodes ?? [],
        integrations: integrationsWithDefaultOptions,
        description: description || '',
        state: state || 'ACTIVE',
        type: type || 'STANDARD_EVENT',
        availability: availability || 'PRIVATE',
        urls: urls || [],
        advertiserTagVarName: advertiserTagVarName || '',
        eventType: advertiserTagVarName || '',
        convertible: convertible || false,
        advertiserTagId: advertiserTagId || advertiser.advertiserTagId,
        advertiserId: advertiserId || advertiser.id,
        triggerEventType,
        triggerType,
        triggerValues,
        advertiser,
        floodlightActivityIds,
        googleAdsConversionIds,
        googleAnalyticsEventNames,
        bingGoalNames,
        dailyVisitsAlertingThreshold,
        missingOrderIdsAlertingThreshold,
        isAlertingOn: isSegmentAlertingEnabled(segment),
    };
};

export const toApiModel = (data: SegmentFormModel): Partial<Segment> => {
    const {
        id,
        advertiserId,
        classificationCodes,
        integrations,
        description,
        type,
        availability,
        urls,
        convertible,
        eventType,
        advertiserTagId,
        name,
        triggerEventType,
        triggerType,
        triggerValues,
        floodlightActivityIds,
        googleAdsConversionIds,
        googleAnalyticsEventNames,
        bingGoalNames,
        dailyVisitsAlertingThreshold,
        missingOrderIdsAlertingThreshold,
        isAlertingOn,
    } = data;

    const advertiserTagVarName = type === 'STANDARD_EVENT' ? eventType : data.advertiserTagVarName;

    return {
        id,
        advertiserId,
        advertiserTagId,
        name,
        advertiserTagVarName,
        classificationCodes,
        integrations: mapValues(integrations, (integration) => ({
            ...integration,
            recencies: integration?.recencies?.map((recency) => ({ days: recency })),
            state: (integration?.enabled ? 'ACTIVE' : 'INACTIVE') as Status,
        })),
        description,
        type,
        availability,
        urls,
        convertible,
        triggerEventType,
        triggerType,
        triggerValues,
        floodlightActivityIds,
        googleAdsConversionIds,
        googleAnalyticsEventNames,
        bingGoalNames,
        dailyVisitsAlertingThreshold: isAlertingOn ? dailyVisitsAlertingThreshold : undefined,
        missingOrderIdsAlertingThreshold: isAlertingOn ? missingOrderIdsAlertingThreshold : undefined,
    };
};
