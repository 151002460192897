import { useState } from 'react';
import { isEmpty } from 'lodash-es';
import { fetchProducts } from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.service';
import SelectOptionWithStatus from 'platform/common/components/Select/SelectOptionWithStatus';
import { OptionType } from 'platform/common/components/Select/select.types';
import { usePromise } from 'platform/common/hooks/usePromise';
import { namesToOptions } from 'platform/common/utils/option.util';
import FormCreatableSelect, {
    FormCreatableSelectProps,
} from 'platform/formik/FormCreatableSelect/FormCreatableSelect';

export type FormProductLabelSelectProps = {
    advertiserId: number;
    options?: FormCreatableSelectProps['options'];
} & Omit<FormCreatableSelectProps, 'options' | 'onNewOption'>;

const FormProductLabelSelect = ({
    advertiserId,
    isLoading,
    options,
    isClearable = true,
    ...rest
}: FormProductLabelSelectProps) => {
    const [newLabel, setNewLabel] = useState<OptionType>({});
    const [{ data: fetchedOptions, loading }] = usePromise(
        [],
        async () => {
            if (options?.length || isLoading) return options ?? [];

            return fetchProducts(advertiserId).then(namesToOptions);
        },
        [advertiserId, options, isLoading]
    );

    return (
        <FormCreatableSelect
            {...rest}
            menuStyle={{ minWidth: 400 }}
            isClearable={isClearable}
            returnOnlyValues={false}
            options={!isEmpty(newLabel) ? [...fetchedOptions, newLabel] : fetchedOptions}
            isLoading={loading || isLoading}
            onNewOption={(newOption) => {
                setNewLabel(newOption);
                rest?.onChange?.(newOption.value);
            }}
            formatOptionLabel={SelectOptionWithStatus}
            formatCreateLabel={(value) => (
                <span>
                    <b>Create label</b>
                    {` "${value}"`}
                </span>
            )}
            placeholder="Select or enter..."
        />
    );
};

export default FormProductLabelSelect;
