import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { uniq } from 'lodash-es';
import { CHART_PREVIEW_HEIGHT } from 'platform/analytics/analytics.constants';
import { ReportSettings } from 'platform/analytics/analytics.service';
import { WordCloudState, Template } from 'platform/analytics/analytics.types';
import { exportChartData, exportedFilename, findColumn } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import ReportChartTitle from 'platform/analytics/reportComponents/ReportChartContainer/ReportChartTitle';
import ReportWordCloudChart from 'platform/analytics/reportComponents/ReportChartContainer/ReportWordCloudChart/ReportWordCloudChart';
import { Word } from 'platform/analytics/reportComponents/ReportChartContainer/ReportWordCloudChart/useReportWordCloudChart';
import ReportTracingTips from 'platform/analytics/reportComponents/ReportTracingTips/ReportTracingTips';
import { useReport } from 'platform/analytics/reportComponents/useReport';
import { isDefined } from 'platform/common/common.types';
import ChartEmptyView from 'platform/common/components/ChartEmptyView/ChartEmptyView';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import InformationModal from 'platform/common/components/InformationModal/InformationModal';
import { useModal } from 'platform/common/components/Modal/Modal';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { typeOf } from 'platform/common/dataTypes';
import { dateFilterSelectors } from 'platform/common/ducks/dateFilter.duck';
import { layoutSelectors } from 'platform/common/ducks/layout.duck';
import { CHART_COLORS } from 'platform/common/utils/color.util';

const mapRowsToWords = ({
    textDimension,
    valueMetric,
    colorDimension,
    rows,
}: {
    textDimension: string;
    valueMetric: string;
    colorDimension?: string;
    rows: any[];
}) => {
    const colorDimensionValues = colorDimension ? uniq(rows.map((row) => row[colorDimension])) : [];
    const colors =
        colorDimensionValues.length > 10
            ? {}
            : colorDimensionValues.reduce(
                  (acc, colorDimensionValue, index) => ({
                      ...acc,
                      [colorDimensionValue]: CHART_COLORS[index],
                  }),
                  {}
              );

    return rows.map((row) => ({
        text: row[textDimension],
        value: row[valueMetric] ?? 0,
        color: colors[row[colorDimension ?? '']],
        colorValue: colorDimension && row[colorDimension],
    }));
};

interface Props {
    reportName: string;
    template: Template;
    componentState: WordCloudState;
}

const ReportWordCloudChartContainer = ({ reportName, template, componentState }: Props) => {
    const {
        title,
        metrics,
        dimensions,
        customFilters,
        customDateRange,
        pageSize,
        colorDimension,
        placement,
    } = componentState;
    const dimension = dimensions[0];
    const { showModal } = useModal();
    const { includeVatRate, modelOptIn } = useSelector(analyticsSelectors.settings);
    const isMobile = useSelector(layoutSelectors.isMobile);
    const periods = useSelector(dateFilterSelectors.periods, shallowEqual);

    const settings = useMemo<ReportSettings>(
        () => ({
            dimensions: [dimension, colorDimension].filter(isDefined),
            metrics,
            customFilters,
            customDateRange,
            templateId: template.id,
            includeVatRate,
            modelOptIn,
            sort: [{ orderBy: metrics[0], direction: 'DESC' }],
            paging: pageSize ? { pageNumber: 0, pageSize } : undefined,
        }),
        [
            dimension,
            metrics,
            customFilters,
            customDateRange,
            template.id,
            includeVatRate,
            modelOptIn,
            pageSize,
            colorDimension,
        ]
    );

    const { loading, report, error, refetchReport } = useReport({
        settings,
        type: template.type,
    });

    const metricCell = findColumn(report, metrics[0]);
    const dimensionCell = findColumn(report, dimension);
    const colorDimensionCell = findColumn(report, colorDimension);

    if (loading) return <Placeholder minHeight="290px" />;
    if (error) return <ErrorMessage error={error} onRetry={refetchReport} />;
    if (!report || !metricCell || !dimensionCell) {
        return <ChartEmptyView />;
    }

    const words = mapRowsToWords({
        valueMetric: metrics[0],
        textDimension: dimension,
        rows: report.rows.slice(0, pageSize),
        colorDimension,
    });

    const getColorDimensionText = (word: Word) =>
        colorDimension && colorDimensionCell?.name && word?.colorValue && word?.color
            ? `, ${colorDimensionCell?.name} - ${word.colorValue}`
            : '';

    const type = typeOf(metricCell);
    const getTooltipText = (word: Word) =>
        `${word.text}, ${metricCell?.name}: ${type.format(word.value)} ${getColorDimensionText(word)}`;

    const titleText = title || `${metricCell.name} by ${dimensionCell.name}`;

    return (
        <>
            <ReportChartTitle title={titleText} template={template} componentState={componentState}>
                <div className="ReportChart-buttonContainer ms-1">
                    <Button
                        className="ReportChart-button"
                        title="View in modal"
                        onClick={() => {
                            showModal((toggle) => (
                                <InformationModal
                                    isOpen
                                    toggle={toggle}
                                    title={titleText}
                                    style={{ maxWidth: '80%' }}
                                >
                                    <ReportWordCloudChart
                                        words={words}
                                        height={CHART_PREVIEW_HEIGHT}
                                        getTooltipText={getTooltipText}
                                        colorDimension={colorDimension}
                                    />
                                </InformationModal>
                            ));
                        }}
                    >
                        <i className="fal fa-expand" />
                    </Button>
                    {!isMobile && (
                        <Button
                            className="ReportChart-button"
                            title="Download chart data"
                            onClick={exportChartData(
                                metrics,
                                dimension,
                                report,
                                exportedFilename(reportName, titleText, periods)
                            )}
                        >
                            <i className="fa fa-download" />
                        </Button>
                    )}
                </div>
            </ReportChartTitle>
            <ReportWordCloudChart
                words={words}
                height={285}
                gridColumnCount={placement.width}
                getTooltipText={getTooltipText}
                colorDimension={colorDimension}
            />
            <ReportTracingTips report={report} />
        </>
    );
};

export default ReportWordCloudChartContainer;
