import { uniq } from 'lodash-es';
import { sortByName } from 'platform/common/utils/array.util';
import { isDefined } from '../../common/common.types';

type AdvertiserTag = {
    thirdPartyTrackingRules: {
        codeSnippets: {
            vendorId?: number;
            dataSharingTemplateId?: number;
        }[];
    }[];
};

type Segment = {
    id: number;
};

type Vendors = {
    id: number;
    iconUrl?: string;
    name: string;
    useInCDP: boolean;
}[];

const getActiveVendorIds = (activeAdvertiserTag: AdvertiserTag | undefined) => {
    if (!activeAdvertiserTag) {
        return [];
    }

    const codeSnippets = activeAdvertiserTag.thirdPartyTrackingRules.flatMap((r) => r.codeSnippets);
    return uniq(codeSnippets.map((s) => s.vendorId).filter(isDefined));
};

export const shapeVendors = (
    vendors: Vendors,
    segment: Segment,
    activeAdvertiserTag: AdvertiserTag | undefined
) => {
    const activeVendorIds = getActiveVendorIds(activeAdvertiserTag);
    return sortByName(vendors)
        .filter((vendor) => vendor.useInCDP)
        .map(({ id, name, iconUrl }) => ({
            id,
            name,
            iconUrl,
            indicatorColorClass: activeVendorIds.includes(id) ? 'indicator--active' : 'indicator--inactive',
        }));
};
