import { FormGroup } from 'reactstrap';
import { StandardRuleSnippetModel } from 'platform/advertiserTag/advertiserTag.types';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import SupportedMacrosPopover from 'platform/common/components/SupportedMacrosPopover/SupportedMacrosPopover';
import FormCodeEditor from 'platform/formik/FormCodeEditor/FormCodeEditor';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import FormRow from '../../../common/components/FormRow/FormRow';
import {
    containsImageTag,
    containsScriptIframeImg,
    containsScriptOrIframeTag,
    required,
    secureUrls,
    urlHttps,
} from '../../../common/utils/validators.util';
import { Vendor } from '../../../vendors/vendors.types';
import {
    getDataSharingMacros,
    SCRIPT_TYPE_LABELS,
    SCRIPT_TYPE_OPTIONS,
} from '../../constants/advertiserTag.constant';
import { THIRD_PARTY_CODES_FORM_CODE_SNIPPET_HELP_KEY } from '../ThirdPartyCodesForm/ThirdPartyCodesForm';
import './ThirdPartyRulesCodeSnippets.scss';

interface Props {
    readOnly: boolean;
    onCodeSnippetRemove: (index: number) => void;
    isFiredAlways: boolean;
    vendors: Vendor[];
    ruleIsActive: boolean;
    codeSnippets: StandardRuleSnippetModel[];
    fieldName: string;
}

const getTypeOptions = (isFiredAlways = false) =>
    !isFiredAlways
        ? SCRIPT_TYPE_OPTIONS
        : SCRIPT_TYPE_OPTIONS.filter(({ value }) => value !== 'PARAMETERIZED_URL_HTML');

const ThirdPartyRulesCodeSnippets = ({
    readOnly,
    onCodeSnippetRemove,
    isFiredAlways,
    vendors,
    ruleIsActive,
    codeSnippets,
    fieldName,
}: Props) => (
    <>
        {codeSnippets.map((snippet, index) => {
            const vendorOptions = vendors.map((item) => ({
                value: item.id,
                label: item.name,
            }));

            const snippetFieldName = `${fieldName}.${index}`;

            return (
                <div className="ThirdPartyRulesCodeSnippets" key={snippet.tempId}>
                    <ControlledCard
                        color="secondary"
                        title={SCRIPT_TYPE_LABELS[snippet.type]}
                        toggleable={false}
                        onRemove={readOnly ? undefined : () => onCodeSnippetRemove(index)}
                    >
                        <FormRow
                            label="Vendor"
                            helpKey={`${THIRD_PARTY_CODES_FORM_CODE_SNIPPET_HELP_KEY}_vendorId`}
                        >
                            <FormSelect
                                name={`${snippetFieldName}.vendorId`}
                                options={vendorOptions}
                                validate={required}
                                disabled={readOnly}
                            />
                        </FormRow>
                        <FormRow
                            label="Active"
                            helpKey={`${THIRD_PARTY_CODES_FORM_CODE_SNIPPET_HELP_KEY}_active`}
                        >
                            <FormSwitch
                                name={`${snippetFieldName}.active`}
                                disabled={!ruleIsActive || readOnly}
                            />
                        </FormRow>
                        <FormRow
                            label="Script type"
                            helpKey={`${THIRD_PARTY_CODES_FORM_CODE_SNIPPET_HELP_KEY}_type`}
                        >
                            <FormSelect
                                id={`${snippetFieldName}.type`}
                                name={`${snippetFieldName}.type`}
                                options={getTypeOptions(isFiredAlways)}
                                isClearable={false}
                                validate={required}
                                disabled={readOnly}
                            />
                        </FormRow>
                        {snippet.type !== 'IMAGE_URL' && (
                            <>
                                <FormCodeEditor
                                    name={`${snippetFieldName}.codeIntegralPart`}
                                    options={{
                                        mode: 'htmlmixed',
                                        lineWrapping: true,
                                        readOnly,
                                    }}
                                    validate={[
                                        required,
                                        secureUrls,
                                        (() => {
                                            switch (snippet.type) {
                                                case 'IMAGE_HTML':
                                                    return containsImageTag;
                                                case 'JAVASCRIPT_HTML':
                                                    return containsScriptOrIframeTag;
                                                case 'PARAMETERIZED_URL_HTML':
                                                    return containsScriptIframeImg;
                                                default:
                                                    return () => undefined;
                                            }
                                        })(),
                                    ]}
                                />
                                <SupportedMacrosPopover list={getDataSharingMacros(vendors)} />
                            </>
                        )}
                        {snippet.type === 'IMAGE_URL' && (
                            <FormGroup>
                                <FormInput
                                    id={`${snippetFieldName}.codeIntegralPart`}
                                    name={`${snippetFieldName}.codeIntegralPart`}
                                    validate={urlHttps}
                                    disabled={readOnly}
                                />
                            </FormGroup>
                        )}
                    </ControlledCard>
                </div>
            );
        })}
    </>
);

export default ThirdPartyRulesCodeSnippets;
