import classNames from 'classnames';

interface Props {
    icon?: string;
    className?: string;
    placeholderIcon?: string;
}

const ChatAgentIcon = ({ icon, className, placeholderIcon }: Props) => {
    if (icon) {
        return <img width={36} height={36} className={className} src={icon} alt="Agent icon" />;
    }
    if (placeholderIcon) return <i className={classNames('Placeholder-icon fa-solid', placeholderIcon)} />;
    return <i className={classNames('fa-solid fa-circle-question', className)} />;
};

export default ChatAgentIcon;
