import { Reducer } from 'redux';
import { RouterState } from 'redux-first-history';
import { taskReducer } from 'platform/task/reducers/task.duck';
import analyticsReducer from './analytics/ducks/analytics.duck';
import auth, { IMPERSONATE, LOGOUT, STOP_IMPERSONATION } from './app/ducks/auth.duck';
import { combineReducersWithRoot } from './common/combineReducersWithRoot';
import common from './common/common.reducer';
import { Action } from './common/common.types';
import { fulfilled } from './common/utils/actionSuffixes.util';
import { RootState } from './rootState.type';

export default (routerReducer: Reducer<RouterState>) => {
    const combinedReducers = combineReducersWithRoot<RootState>({
        analyticsReducer,
        taskReducer,
        common,
        session: auth,
        router: routerReducer,
    });

    const rootReducer = (state: RootState | undefined, action: Action): RootState => {
        switch (action.type) {
            case LOGOUT:
            case fulfilled(IMPERSONATE):
            case fulfilled(STOP_IMPERSONATION):
                if (!state) {
                    throw new Error('state is not defined');
                }

                return {
                    ...combinedReducers(undefined, action),
                    session: auth(state.session, action),
                    router: state.router,
                };
            default:
                return combinedReducers(state, action);
        }
    };

    return (state: RootState | undefined, action: Action): RootState => rootReducer(state, action);
};
