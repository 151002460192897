import classNames from 'classnames';

interface Props {
    className?: string;
    icon: string;
    children: React.ReactNode;
}

const KanbanCardLabel = ({ className, icon, children }: Props) => (
    <div className={classNames('Kanban-card-modal-label', className)}>
        <i className={classNames(icon, 'me-1')} />
        {children}
    </div>
);

export default KanbanCardLabel;
