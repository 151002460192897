import StrategyFormContainer from './containers/StrategyFormContainer/StrategyFormContainer';
import { STRATEGIES } from './strategy.navigation';

const strategyRoutes = [
    {
        ...STRATEGIES,
        component: StrategyFormContainer,
    },
];

export default strategyRoutes;
