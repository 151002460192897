import { createSelector } from 'reselect';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { authSelectors } from './auth.duck';

export type AppState =
    | { type: 'READY' }
    | { type: 'PENDING' }
    | { type: 'ERROR'; message: string; originalError?: any };

export const appStateSelectors = {
    getState: createSelector(
        authSelectors.isLoggedIn,
        activeAdvertiserSelectors.root,
        authSelectors.hasFeatureFn,
        (isLoggedIn, activeAdvertiserState, hasFeature): AppState => {
            // App login part is responsible for its error state
            if (!isLoggedIn) return { type: 'PENDING' };

            switch (activeAdvertiserState.type) {
                case 'IDLE':
                case 'PENDING':
                    return { type: 'PENDING' };
                case 'ERROR':
                    return {
                        type: 'ERROR',
                        message: 'Failed to load advertisers',
                        originalError: activeAdvertiserState.error,
                    };
                case 'READY': {
                    // continue
                    break;
                }

                default:
                    return {
                        type: 'ERROR',
                        message: 'Invalid state',
                    };
            }

            const analyticsEnabled =
                hasFeature('ROLE_ANALYTICS_VIEW') ||
                hasFeature('CUSTOM_DASHBOARDS') ||
                hasFeature('CUSTOM_ANALYTICS');

            if (!analyticsEnabled) return { type: 'READY' };

            return { type: 'READY' };
        }
    ),
};
