import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { push } from 'redux-first-history';
import { flow } from 'lodash-es';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import DspIntegrationIconList from 'platform/common/components/DspIntegrationIconList/DspIntegrationIconList';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { STATUS_OPTIONS } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { VENDORS } from 'platform/vendors/vendors.navigation';
import { fetchVendors } from 'platform/vendors/vendors.service';
import { Vendor } from 'platform/vendors/vendors.types';
import VendorCommissionForm from './VendorCommissionForm';
import VendorFormContainer from './VendorFormContainer';

const booleanColumn = (header: string, property: keyof Vendor): TableColumn<Vendor> => ({
    screenOnly: true,
    Header: header,
    accessor: property,
    type: DATA_TYPES.BOOLEAN,
    maxWidth: 100,
    Cell: ({ original: vendor }) => (
        <Badge className="badge-outline" color={vendor[property] ? 'primary' : 'light'}>
            {vendor[property] ? 'Enabled' : 'Disabled'}
        </Badge>
    ),
});

const VendorList = () => {
    const {
        queryParams: { status, searchQuery },
        setQueryParams,
        returnUrl,
    } = useUrlSync({
        status: ['ACTIVE'],
        searchQuery: '',
    });

    const dispatch = useDispatch();
    const canEdit = useFeature('ROLE_ADMINPANEL_EDIT');
    const [{ data, loading }, refetch] = usePromise([], fetchVendors, []);

    const columns: TableColumn<Vendor>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            type: DATA_TYPES.TEXT,
            maxWidth: 60,
            Cell: ({ value }) => <StatusBadge status={value} />,
        },
        {
            Header: 'ID',
            accessor: 'id',
            maxWidth: 70,
            type: DATA_TYPES.ID,
        },
        {
            Header: 'Name',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
            Cell: CellWithEllipsis,
        },
        {
            Header: 'System Code',
            accessor: 'externalSystem',
            type: DATA_TYPES.TEXT,
            Cell: CellWithEllipsis,
        },
        booleanColumn('Advertiser-level tracking', 'advertiserLevelTracking'),
        booleanColumn('Automated IO spend', 'costIntegrationEnabled'),
        booleanColumn('Reporting API for vendor', 'privateNetwork'),
        booleanColumn('AdvertiserTag Click ID', 'clickIdEnabled'),
        {
            screenOnly: true,
            Header: 'Integrations',
            accessor: 'integrations',
            type: DATA_TYPES.TEXT,
            maxWidth: 90,
            sortable: false,
            Cell: ({ original }) => (
                <DspIntegrationIconList id={original.id} integrations={original.integrations} />
            ),
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            type: DATA_TYPES.TEXT,
            maxWidth: 160,
            Cell: ({ original }) => (
                <UpdatedOn date={original.updatedOn} updatedBy={original.updatedUserName} />
            ),
        },
        {
            Header: '',
            className: 'pull-right cell-align-right',
            maxWidth: 80,
            sortable: false,
            Cell: (props) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () => {
                                dispatch(push(`${VENDORS.path}/${props.row.id}`));
                            },
                        },
                        {
                            label: 'Manage commissions',
                            action: () => {
                                dispatch(push(`${VENDORS.path}/${props.row.id}/vendor-commission`));
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    const vendors = flow(
        (filteredVendors: Vendor[]) =>
            status.length
                ? filteredVendors.filter((item: Vendor) => status.includes(item.status))
                : filteredVendors,
        (filteredVendors: Vendor[]) =>
            searchQuery
                ? filteredVendors.filter((item: Vendor) =>
                      item?.name?.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                : filteredVendors
    )(data);

    return (
        <>
            <HeaderContainer>
                <PageHeader title="Vendors" />
            </HeaderContainer>
            <BodyContainer helpKey="vendor_list">
                {!canEdit && <CanNotEditWarning />}
                <SelectWithAddon
                    name="Status"
                    className="mb-3"
                    value={status}
                    options={STATUS_OPTIONS}
                    isMulti
                    onChange={(value) => setQueryParams({ status: value })}
                />

                <FormattedTable
                    topToolbar={
                        <ListToolbar
                            onCreate={canEdit ? () => dispatch(push(`${VENDORS.path}/new`)) : undefined}
                            value={searchQuery}
                            onSearch={(value) => setQueryParams({ searchQuery: value })}
                        />
                    }
                    columns={columns}
                    data={vendors}
                    loading={loading}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <VendorFormContainer redirectTo={returnUrl} canEdit={canEdit} afterSubmit={refetch} />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <VendorFormContainer redirectTo={returnUrl} canEdit={canEdit} afterSubmit={refetch} />
                    }
                />
                <Route
                    path=":id/vendor-commission"
                    element={
                        <VendorCommissionForm
                            redirectTo={returnUrl}
                            canEdit={canEdit}
                            afterSubmit={refetch}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default VendorList;
