import { isNil } from 'lodash-es';

export const removeEmptyKeys = (object: any) => {
    const objectCopy = { ...object };
    Object.keys(objectCopy).forEach((key) => {
        if (isNil(objectCopy[key])) {
            delete objectCopy[key];
        }
    });

    return objectCopy;
};
