import { ChangeEvent } from 'react';
import ReportDeleteButton from 'platform/analytics/reportComponents/ReportSave/ReportDeleteButton';
import ReportSaveAdvanced from 'platform/analytics/reportComponents/ReportSave/ReportSaveAdvanced';
import { ReportSaveOperationType } from 'platform/analytics/reportComponents/ReportSave/ReportSaveForm';
import { Section, SECTION_OPTIONS } from 'platform/app/app.types';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { SelectItem } from 'platform/common/common.types';
import ControlledCardBorderless from 'platform/common/components/ControlledCard/ControlledCardBorderless';
import FormHelp from 'platform/common/components/FormHelp/FormHelp';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import { required } from 'platform/common/utils/validators.util';
import { CustomReport } from 'platform/customReports/customReport.types';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormRadioList from 'platform/formik/FormRadioList/FormRadioList';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';

interface Props {
    report: CustomReport;
    section?: Section;
    operationType: ReportSaveOperationType;
    seatOptions: SelectItem[];
    usedAsSystemReport: boolean;
    canEdit: boolean;
    isLinked: boolean;
    onSectionChange: (section: Section) => void;
    onUsedAsSystemReportChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onDelete: () => void;
}

const ReportAdminSave = ({
    report,
    section,
    operationType,
    seatOptions,
    usedAsSystemReport,
    canEdit,
    onSectionChange,
    onUsedAsSystemReportChange,
    onDelete,
}: Props) => {
    const profile = useTypedSelector(authSelectors.ready.profile);
    const isCentral = report.section === 'CENTRAL_ANALYTICS';
    const isPersonal = section === 'PERSONAL';
    const changeToPersonal = isPersonal && report.section !== section;
    const isLink = !!report.id && !report.section && !report.systemReportKey && !report.usedAsTemplate;
    const hideSection = isLink && operationType === 'UPDATE';
    const canUpdate =
        report.section === 'PERSONAL' ? report.ownerUserId === profile?.id : !!report.id && !changeToPersonal;

    return (
        <>
            {canUpdate && (
                <FormRadioList
                    disabled={usedAsSystemReport && !canEdit}
                    name="operationType"
                    options={[
                        { label: 'Update existing', value: 'UPDATE' },
                        { label: 'As new report', value: 'CREATE' },
                    ]}
                    className="d-flex flex-fill mb-3"
                />
            )}
            <FormHelp iconClassName="mt-2">
                <FormInput
                    name="report.name"
                    type="text"
                    className="flex-grow-1"
                    leftAddOn={{ title: 'Name' }}
                    validate={required}
                />
            </FormHelp>
            {!hideSection && (
                <FormHelp iconClassName="mt-2">
                    <FormSelect
                        name="report.section"
                        firstAddon="Section"
                        className="flex-fill"
                        containerClassName="flex-grow-1"
                        options={SECTION_OPTIONS.filter(
                            (op) => !(profile?.roleId !== 100 && op.value === 'CENTRAL_ANALYTICS')
                        )}
                        isClearable={false}
                        validate={usedAsSystemReport ? undefined : required}
                        onChange={onSectionChange}
                    />
                </FormHelp>
            )}
            <ReportDeleteButton report={report} onDelete={onDelete} />
            <ReportSaveAdvanced isCentral={isCentral} />
            <ControlledCardBorderless title="Admin" className="mb-3" bodyClassName="mt-4" isExpanded={false}>
                <FormHelp iconClassName="mt-2">
                    <FormSelect
                        name="report.seatIds"
                        firstAddon="Seat"
                        className="flex-fill"
                        containerClassName="flex-grow-1"
                        options={seatOptions}
                        isClearable
                        validate={usedAsSystemReport || isPersonal ? undefined : required}
                        isMulti
                        closeMenuOnSelect={false}
                    />
                </FormHelp>
                <FormHelp className="align-items-center my-3">
                    <FormSwitch name="useReportAdvertiser" className="me-2" disabled={isCentral} />
                    <span>Save selected advertiser</span>
                </FormHelp>
                <FormHelp className="align-items-center mb-3">
                    <FormSwitch name="report.usedAsTemplate" className="me-2" />
                    <span>Save as template</span>
                </FormHelp>
                <FormHelp className="align-items-center mb-3">
                    <FormSwitch name="maintenanceMode" className="me-2" />
                    <span>System Notice</span>
                </FormHelp>
                <FormHelp className="align-items-center mb-3">
                    <FormSwitch
                        name="usedAsSystemReport"
                        className="me-2"
                        onChange={onUsedAsSystemReportChange}
                        disabled={!canEdit}
                    />
                    <span>Save as system report</span>
                </FormHelp>
                {usedAsSystemReport && (
                    <FormHelp className="align-items-center">
                        <FormInput
                            name="report.systemReportKey"
                            leftAddOn={{ title: 'System report key' }}
                            validate={required}
                        />
                    </FormHelp>
                )}
            </ControlledCardBorderless>
        </>
    );
};

export default ReportAdminSave;
