import { useState } from 'react';
import { ContactInformation } from 'platform/advertisers/advertiser.types';
import {
    fetchContactInformation,
    updateContactInformation,
} from 'platform/advertisers/services/advertiser.service';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { usePromise } from 'platform/common/hooks/usePromise';
import { fetchUsers } from 'platform/userManagement/services/userManagement.service';
import { User } from 'platform/userManagement/types/user.type';

export interface ContactData {
    name: string;
    login: string;
    phone: string;
    imageUrl?: string;
    title?: string;
}

const emptyContactData = { clientService: [], media: [], internalEmail: '' };

const mapUserDataByFieldName = (contactFieldUsers: number[], users: User[]) =>
    users
        .filter((user) => contactFieldUsers.includes(user.id))
        .map<ContactData>((u) => ({
            name: u.name,
            login: u.login,
            imageUrl: u.imageUrl,
            phone: u.phone,
            title: u.title,
        }));

const userIdsToContacts = (userData: User[], contactInfo: ContactInformation | null) => {
    if (!userData.length || !contactInfo) {
        return emptyContactData;
    }

    return {
        clientService: mapUserDataByFieldName(contactInfo.clientService, userData),
        media: mapUserDataByFieldName(contactInfo.media, userData),
        internalEmail: contactInfo.internalEmail,
    };
};
export const useContacts = () => {
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const [isEdit, setIsEdit] = useState(false);

    const [
        {
            data: [users, contactInformation],
            loading,
        },
        fetchData,
    ] = usePromise(
        [[], emptyContactData],
        () =>
            Promise.all([
                fetchUsers({ states: ['ACTIVE'], seatId: 100 }),
                fetchContactInformation(advertiserId),
            ]),
        [advertiserId]
    );

    const { clientService, media, internalEmail } = userIdsToContacts(users, contactInformation);

    const toggleEdit = () => setIsEdit((prev) => !prev);

    const cancel = () => setIsEdit(false);

    const submit = async (data: ContactInformation) => {
        await updateContactInformation(advertiserId, data);
        setIsEdit(false);
        fetchData();
    };

    return {
        clientService,
        media,
        internalEmail,
        contactInformation: contactInformation || emptyContactData,
        users,
        isEdit,
        loading,
        toggleEdit,
        cancel,
        submit,
    };
};
