import { getCountryOptions } from 'platform/category/category.service';
import { usePromise } from 'platform/common/hooks/usePromise';
import FormSelect, { FormSelectProps } from 'platform/formik/FormSelect/FormSelect';

type Props = { advertiserId: number } & FormSelectProps;

const FormCountryLabelsSelect = ({
    advertiserId,
    isLoading,
    options,
    isClearable = true,
    ...rest
}: Props) => {
    const [{ data: fetchedOptions, loading }] = usePromise(
        [],
        async () => {
            if (options?.length || isLoading) return options ?? [];
            return getCountryOptions();
        },
        [advertiserId, isLoading, options]
    );

    return (
        <FormSelect
            {...rest}
            isMulti
            isClearable={isClearable}
            containerClassName="flex-grow-1"
            returnOnlyValues={false}
            options={fetchedOptions}
            placeholder="Select..."
            isLoading={loading || isLoading}
        />
    );
};

export default FormCountryLabelsSelect;
