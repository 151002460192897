import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { sortByOrder } from 'platform/common/utils/chart.utils';
import { getChatAgents, getDefaultChatAgent } from '../chat.service';
import { ChatAgent } from '../chat.types';

interface ContextType {
    agents: ChatAgent[];
    agentsLoading: boolean;
    selectedAgent: ChatAgent | undefined;
    setSelectedAgent: (agent: ChatAgent | undefined) => void;
}

const ChatAgentContext = createContext<ContextType>({
    agents: [],
    selectedAgent: undefined,
    agentsLoading: true,
    setSelectedAgent: () => {},
});

export const ChatAgentProvider = ({ children }: { children: ReactNode }) => {
    const isAdmin = useFeature('ROLE_ADMINPANEL_VIEW');
    const canViewAgents = useFeature('CHATBOT_AGENTS_VIEW');
    const canViewBetaAgents = useFeature('CHATBOT_BETA_TESTING');
    const [selectedAgent, setSelectedAgent] = useState<ChatAgent | undefined>();

    const [{ data: agents, loading }] = usePromise(
        [],
        async () => {
            const agentsResponse = await (canViewAgents
                ? getChatAgents({ status: ['ACTIVE'] }).then(sortByOrder)
                : getDefaultChatAgent().then((a) => [a]));

            return agentsResponse
                .filter((a) => (a.adminOnly ? isAdmin : true))
                .filter((a) => !a.systemOnly)
                .filter((a) => (a.betaTesting ? canViewBetaAgents : true));
        },
        []
    );

    const values = useMemo(
        () => ({ agents, agentsLoading: loading, selectedAgent, setSelectedAgent }),
        [agents, loading, selectedAgent, setSelectedAgent]
    );

    return <ChatAgentContext.Provider value={values}>{children}</ChatAgentContext.Provider>;
};

export const useChatAgentContext = () => {
    const context = useContext(ChatAgentContext);
    if (!context) {
        throw new Error('ChatAgentContext must be used within a ChatAgentProvider');
    }

    return context;
};
