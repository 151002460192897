import { createContext, ReactNode, useContext, useMemo } from 'react';
import { fetchFlightLabels } from 'platform/campaign/advertiserManagement/FlightLabels/flightLabel.service';
import { fetchProducts } from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.service';
import { getCountryOptions } from 'platform/category/category.service';
import { SelectItem } from 'platform/common/common.types';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import { namesToOptions } from 'platform/common/utils/option.util';

interface LabelOptionsContextType {
    isLoading: boolean;
    countryLabels: SelectItem<string>[];
    flightLabels: SelectItem<string>[];
    productLabels: SelectItem<string>[];
}

const LabelOptionsContext = createContext<LabelOptionsContextType>({
    isLoading: true,
    countryLabels: [],
    flightLabels: [],
    productLabels: [],
});

interface Props {
    children: ReactNode;
    selectedProductLabel?: string;
}

export const LabelOptionsProvider = ({ children }: Props) => {
    const advertiserId = useTypedSelector(activeAdvertiserSelectors.id);

    const [
        {
            data: [countryLabels, flightLabels, productLabels],
            loading,
        },
    ] = usePromise([[]], async () => {
        const requests = [
            getCountryOptions(),
            fetchFlightLabels(advertiserId).then(namesToOptions),
            fetchProducts(advertiserId).then((res) =>
                res.map(({ name, subLabels }) => ({
                    label: name,
                    value: name,
                    nodes: namesToOptions(subLabels),
                }))
            ),
        ];

        return Promise.all(requests);
    });

    const contextValue = useMemo(
        () => ({ isLoading: loading, countryLabels, flightLabels, productLabels }),
        [loading, countryLabels, flightLabels, productLabels]
    );

    return <LabelOptionsContext.Provider value={contextValue}>{children}</LabelOptionsContext.Provider>;
};

export const useLabelOptionsContext = () => {
    const context = useContext(LabelOptionsContext);

    if (!context) {
        throw new Error('CampaignLabelOptionsContext must be used within an CampaignLabelOptionsProvider');
    }

    return context;
};
