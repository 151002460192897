import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { ACTIVE_OR_ARCHIVED } from 'platform/common/constants/status.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { required, requiredDatepickerValues } from 'platform/common/utils/validators.util';
import FormDateRangePicker from 'platform/formik/FormDateRangePicker/FormDateRangePicker';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import { TimedOfferFormModel } from './timedOffer.types';

const TimedOfferForm = ({
    onCancel,
    labels,
    canEdit,
    formikProps: { submitForm },
}: FormProps<TimedOfferFormModel>) => (
    <CardForm
        title={`${labels.prefix} Time offer`}
        submitLabel={labels.submit}
        disabled={!canEdit}
        onCancel={onCancel}
        onSubmit={submitForm}
    >
        <ControlledCard title="General info" subtitle="required">
            <FormRow label="Timed offer name">
                <FormInput name="name" type="text" validate={[required]} />
            </FormRow>
            <FormRow label="Timed offer status">
                <FormSelect
                    name="status"
                    options={ACTIVE_OR_ARCHIVED}
                    validate={required}
                    isClearable={false}
                />
            </FormRow>
            <FormRow label="Timed offer date range">
                <FormDateRangePicker name="dateRange" validate={requiredDatepickerValues} />
            </FormRow>
        </ControlledCard>
    </CardForm>
);

export default TimedOfferForm;
