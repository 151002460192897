import { CSSProperties, ReactNode } from 'react';
import Creatable from 'react-select/creatable';
import { InputGroup, InputGroupText } from 'reactstrap';
import classNames from 'classnames';
import { isNil, isObject } from 'lodash-es';
import { OptionType } from 'platform/common/components/Select/select.types';
import { getSelectStyles } from 'platform/common/components/Select/select.utils';
import TextWithEllipsis from '../TextWithEllipsis/TextWithEllipsis';
import { SelectProps } from './Select';
import './CreatableSelect.scss';

export type CreatableSelectProps<T = OptionType> = {
    inputClassName?: string;
    createOptionPosition?: 'first' | 'last';
    selectStyle?: CSSProperties;
    formatCreateLabel?: (value: string) => ReactNode;
    onNewOption: (value: any) => void;
    firstAddon?: string;
    secondAddon?: string;
} & SelectProps<T>;

const CreatableSelect = <T extends OptionType>({
    value,
    inputClassName,
    createOptionPosition = 'first',
    onNewOption = (option) => option,
    firstAddon,
    secondAddon,
    formatCreateLabel,
    options = [],
    className,
    selectStyle,
    menuStyle,
    isMulti,
    returnOnlyValues,
    invalid = false,
    isClearable = true,
    menuPlacement = 'auto',
    getOptionLabel = (option: T) => option.label,
    getOptionValue = (option: T) => option.value,
    onChange,
    ...rest
}: CreatableSelectProps<T>) => {
    const resolvedValue = (() => {
        if (Array.isArray(value) && value.some((v) => !isObject(v))) {
            return value
                .map((v) => options.find((option) => getOptionValue(option) === v))
                .filter((option) => !isNil(option));
        }
        if (!isObject(value)) {
            return options.find((option) => value === getOptionValue(option));
        }
        return value;
    })();

    const creatableComponent = (
        <Creatable
            value={resolvedValue === undefined ? null : resolvedValue}
            className={classNames('CreatableSelect', inputClassName)}
            classNamePrefix="react-select"
            styles={getSelectStyles({ invalid, selectStyle, menuStyle })}
            menuPlacement={menuPlacement}
            options={options}
            createOptionPosition={createOptionPosition}
            isMulti={isMulti}
            formatCreateLabel={formatCreateLabel}
            isClearable={isClearable}
            getOptionLabel={getOptionLabel}
            onChange={(valueOptions, actionMeta) => {
                if (!onChange) return;
                const values = (() => {
                    if (!valueOptions) return isMulti ? [] : undefined;
                    if (!returnOnlyValues) return valueOptions;
                    if (Array.isArray(valueOptions)) return valueOptions.map(getOptionValue);
                    return getOptionValue(valueOptions || {});
                })();
                if (actionMeta.action === 'create-option') {
                    onNewOption(values);
                }
                onChange(values, actionMeta);
            }}
            {...rest}
        />
    );

    if (firstAddon) {
        return (
            <InputGroup
                className={classNames('flex-nowrap', className, {
                    'form-control is-invalid m-0 p-0': invalid,
                })}
            >
                <InputGroupText className="rounded-start">
                    <TextWithEllipsis>{firstAddon}</TextWithEllipsis>
                </InputGroupText>
                {secondAddon && <InputGroupText className="rounded-0">{secondAddon}</InputGroupText>}
                {creatableComponent}
            </InputGroup>
        );
    }

    return <>{creatableComponent}</>;
};

export default CreatableSelect;
