import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import { ACTIVE_OR_ARCHIVED, ActiveOrArchived } from 'platform/common/constants/status.constant';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { filterBySearchQuery } from 'platform/common/utils/search.util';
import { ROLE_LIST_PATH } from 'platform/userManagement/constants/path.constant';
import { SUPERUSER_ROLE_ID } from 'platform/userManagement/mappers/user.mapper';
import { fetchRoles } from 'platform/userManagement/services/userManagement.service';
import { TableCell } from '../../../common/common.types';
import CanNotEditWarning from '../../../common/components/CanNotEditWarning/CanNotEditWarning';
import FormattedTable, { TableColumn } from '../../../common/components/FormattedTable/FormattedTable';
import InlineDropdown from '../../../common/components/InlineDropdown/InlineDropdown';
import ListToolbar from '../../../common/components/ListToolbar/ListToolbar';
import UpdatedOn from '../../../common/components/UpdatedOn/UpdatedOn';
import { DATA_TYPES } from '../../../common/dataTypes';
import { Role } from '../../types/role.type';
import RoleFormContainer from './RoleFormContainer';

type QueryParams = {
    status: ActiveOrArchived[];
    searchQuery: string;
};

const RoleList = () => {
    const {
        queryParams: { status, searchQuery },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>({
        status: ['ACTIVE'],
        searchQuery: '',
    });

    const dispatch = useDispatch();
    const [{ data: roles, loading }, refetch] = usePromise([], () => fetchRoles({ status }), [status]);
    const canEdit = useFeature('ROLE_ADMINPANEL_EDIT');

    const columnDefinitions: TableColumn<Role>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            type: DATA_TYPES.TEXT,
            maxWidth: 80,
            Cell: ({ value }) => <StatusBadge status={value} />,
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 80,
        },
        {
            Header: 'Name',
            accessor: 'title',
            minWidth: 200,
            type: DATA_TYPES.TEXT,
            Cell: (row: TableCell<Role>) => (
                <span>
                    {row.original.id === SUPERUSER_ROLE_ID ? <i className="fa fa-lock fa-lg px-2" /> : null}
                    {row.original.title}
                </span>
            ),
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 160,
            Cell: (row: TableCell<Role>) => (
                <UpdatedOn date={row.original.updatedOn} updatedBy={row.original.updatedBy} />
            ),
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: ({ original: role }: TableCell<Role>) => (
                <>
                    <InlineDropdown
                        items={[
                            {
                                label: canEdit ? 'Edit' : 'View',
                                action: () => dispatch(push(`${ROLE_LIST_PATH}/${role.id}`)),
                            },
                        ]}
                    />
                </>
            ),
        },
    ];

    return (
        <div>
            <BodyContainer helpKey="role_list">
                {!canEdit && <CanNotEditWarning />}
                <SelectWithAddon
                    name="Status"
                    className="mb-3"
                    value={status}
                    options={ACTIVE_OR_ARCHIVED}
                    isMulti
                    onChange={(value) => setQueryParams({ status: value })}
                />
                <FormattedTable
                    topToolbar={
                        <ListToolbar
                            value={searchQuery}
                            onCreate={canEdit ? () => dispatch(push(`${ROLE_LIST_PATH}/new`)) : undefined}
                            onSearch={(value) => setQueryParams({ searchQuery: value })}
                        />
                    }
                    columns={columnDefinitions}
                    data={filterBySearchQuery(
                        roles,
                        [...columnDefinitions.map((column) => column.accessor), 'updatedBy'],
                        searchQuery
                    )}
                    loading={loading}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <RoleFormContainer redirectTo={returnUrl} canEdit={canEdit} afterSubmit={refetch} />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <RoleFormContainer redirectTo={returnUrl} canEdit={canEdit} afterSubmit={refetch} />
                    }
                />
            </Routes>
        </div>
    );
};

export default RoleList;
