import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { CHART_PREVIEW_HEIGHT } from 'platform/analytics/analytics.constants';
import { ReportSettings } from 'platform/analytics/analytics.service';
import { SunburstState, Template } from 'platform/analytics/analytics.types';
import { exportChartData, exportedFilename, findColumn } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import ReportTracingTips from 'platform/analytics/reportComponents/ReportTracingTips/ReportTracingTips';
import { useReport } from 'platform/analytics/reportComponents/useReport';
import ChartEmptyView from 'platform/common/components/ChartEmptyView/ChartEmptyView';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import InformationModal from 'platform/common/components/InformationModal/InformationModal';
import { useModal } from 'platform/common/components/Modal/Modal';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { dateFilterSelectors } from 'platform/common/ducks/dateFilter.duck';
import { layoutSelectors } from 'platform/common/ducks/layout.duck';
import ReportChartTitle from '../ReportChartTitle';
import ReportSunburstChart from './ReportSunburstChart';

interface Props {
    reportName: string;
    template: Template;
    componentState: SunburstState;
}

const ReportSunburstChartContainer = ({ reportName, template, componentState }: Props) => {
    const { title, customFilters, customDateRange, dimensions, metrics } = componentState;
    const metric = metrics[0];
    const { showModal } = useModal();
    const { includeVatRate } = useSelector(analyticsSelectors.settings);
    const isMobile = useSelector(layoutSelectors.isMobile);
    const periods = useSelector(dateFilterSelectors.periods, shallowEqual);

    const settings = useMemo<ReportSettings>(
        () => ({
            dimensions: dimensions.filter(Boolean),
            metrics: [metric],
            customFilters,
            customDateRange,
            templateId: template.id,
            includeVatRate,
        }),
        [dimensions, metric, customFilters, template.id, includeVatRate, customDateRange]
    );

    const { loading, report, error, refetchReport } = useReport({
        settings,
        type: template.type,
    });

    const metricHeader = findColumn(report, metric);
    const dimensionHeaders = dimensions.map((dim) => findColumn(report, dim)?.name || dim);

    if (loading) {
        return <Placeholder minHeight="290px" />;
    }

    if (error) {
        return <ErrorMessage error={error} onRetry={refetchReport} />;
    }

    if (!report || !metricHeader || !dimensionHeaders.length) {
        return <ChartEmptyView />;
    }

    const titleText = title || `${metricHeader.name} by ${dimensionHeaders.join(', ')}`;

    return (
        <>
            <ReportChartTitle title={titleText} template={template} componentState={componentState}>
                <div className="ReportChart-buttonContainer ms-1">
                    <Button
                        className="ReportChart-button"
                        title="View in modal"
                        onClick={() => {
                            showModal((toggle) => (
                                <InformationModal
                                    isOpen
                                    toggle={toggle}
                                    title={titleText}
                                    style={{ maxWidth: '80%' }}
                                >
                                    <ReportSunburstChart
                                        report={report}
                                        dimensions={dimensions}
                                        metric={metric}
                                        maxHeight={CHART_PREVIEW_HEIGHT}
                                    />
                                </InformationModal>
                            ));
                        }}
                    >
                        <i className="fal fa-expand" />
                    </Button>
                    {!isMobile && (
                        <Button
                            className="ReportChart-button"
                            title="Download chart data"
                            onClick={exportChartData(
                                dimensions,
                                metric,
                                report,
                                exportedFilename(reportName, titleText, periods)
                            )}
                        >
                            <i className="fa fa-download" />
                        </Button>
                    )}
                </div>
            </ReportChartTitle>
            <ReportSunburstChart report={report} dimensions={dimensions} metric={metric} />
            <ReportTracingTips report={report} />
        </>
    );
};

export default ReportSunburstChartContainer;
