import { useState } from 'react';
import { Card } from 'reactstrap';
import classNames from 'classnames';
import { isDefined } from 'platform/common/common.types';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import UserIcons from 'platform/common/components/UserSelect/UserIcons';
import TaskTitle from 'platform/task/Common/TaskTitle';
import KanbanBlockedByAdder from 'platform/task/Kanban/KanbanBlockedByAdder';
import { Task, TaskLane } from 'platform/task/task.types';
import { User } from 'platform/userManagement/types/user.type';
import KanbanCardLabel from './KanbanCardLabel';
import KanbanDateTag from './KanbanDateTag';
import KanbanToggleButton from './KanbanToggleButton';

interface Props {
    task: Task;
    lanes: TaskLane[];
    userOptions: User[];
    onOpen: (taskId: number) => void;
    onChange: (blockedByTaskIds: number[]) => Promise<void>;
}

const KanbanBlockedByList = ({ task, lanes, userOptions, onOpen, onChange }: Props) => {
    const [adding, setAdding] = useState(false);

    const otherTasks = lanes
        .flatMap((lane) => lane.tasks.map((t) => ({ ...t, lane })))
        .filter((t) => t.id !== task.id);

    const taskOptions = otherTasks.filter((t) => t.status === 'ACTIVE' && !task.blockedBy?.includes(t.id));

    const handleAdd = async (newTaskIds: number[]) => {
        await onChange([...(task.blockedBy ?? []), ...newTaskIds]);
        setAdding(false);
    };

    const handleRemove = (taskId: number) => onChange((task.blockedBy ?? []).filter((id) => id !== taskId));

    return (
        <>
            <div className="d-flex">
                <KanbanCardLabel icon="fa fa-ban">Blocked by</KanbanCardLabel>
                <KanbanToggleButton onClick={() => setAdding(true)} />
            </div>
            <div className="position-relative">
                {adding && (
                    <KanbanBlockedByAdder
                        options={taskOptions}
                        onSubmit={handleAdd}
                        onCancel={() => setAdding(false)}
                    />
                )}
                <div className={task.blockedBy?.length ? 'mt-3' : ''}>
                    {(task.blockedBy ?? [])
                        .map((id) => otherTasks.find((t) => t.id === id))
                        .filter(isDefined)
                        .map((dependency) => (
                            <Card
                                key={dependency.id}
                                className={classNames('Kanban-sub-card mt-2 mb-0', {
                                    'Kanban-card--muted': dependency.status === 'ARCHIVED',
                                })}
                                onClick={() => onOpen(dependency.id)}
                            >
                                <div className="d-flex align-items-center gap-2">
                                    <TaskTitle
                                        className="flex-fill"
                                        task={dependency}
                                        lane={dependency.lane}
                                    />
                                    <KanbanDateTag className="px-1" task={dependency} />
                                    <UserIcons userIds={dependency.assigneeIds ?? []} options={userOptions} />
                                    <InlineDropdown
                                        items={[
                                            {
                                                icon: 'fa-minus',
                                                label: 'Remove dependency',
                                                action: () => handleRemove(dependency.id),
                                            },
                                        ]}
                                    />
                                </div>
                            </Card>
                        ))}
                </div>
            </div>
        </>
    );
};

export default KanbanBlockedByList;
