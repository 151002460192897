import { ChangeEvent } from 'react';
import { FormGroup } from 'reactstrap';
import classNames from 'classnames';
import {
    ChartState,
    ChartType,
    ComponentSettings,
    ReportFilterWithOperator,
    Template,
} from 'platform/analytics/analytics.types';
import {
    customFiltersToReportFilters,
    reportFiltersToCustomFilters,
} from 'platform/analytics/analytics.util';
import ReportBarChartSettings from 'platform/analytics/reportComponents/ReportChartContainer/ReportBarChart/ReportBarChartSettings';
import ReportDoughnutChartSettings from 'platform/analytics/reportComponents/ReportChartContainer/ReportDoughnutChart/ReportDoughnutChartSettings';
import ReportLineChartSettings from 'platform/analytics/reportComponents/ReportChartContainer/ReportLineChart/ReportLineChartSettings';
import ReportWordCloudChartSettings from 'platform/analytics/reportComponents/ReportChartContainer/ReportWordCloudChart/ReportWordCloudChartSettings';
import { CHARTS } from 'platform/analytics/reportComponents/ReportComponentActions/reportComponents.constant';
import ReportCustomDateRange from 'platform/analytics/reportComponents/ReportCustomDateRange';
import ReportCustomFilters from 'platform/analytics/reportComponents/ReportFilters/ReportCustomFilters';
import TemplateSelect from 'platform/analytics/reportComponents/TemplateSelect';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import ReportStackBarChartSettings from './ReportStackBarChart.tsx/ReportStackBarSettings';
import ReportStackLineChartSettings from './ReportStackLine/ReportStackLineChartSettings';
import ReportSunburstChartSettings from './ReportSunburstChart/ReportSunburstChartSettings';

const formatChartOption = (chart: ChartState) => (
    <div className="d-flex align-items-center">
        {typeof chart.icon === 'string' ? (
            <i className={classNames('text-primary', chart.icon)} />
        ) : (
            <chart.icon width={16} height={16} />
        )}
        <span className="ms-1">{chart.name}</span>
    </div>
);

const keepChartProps = (newChartType: ChartType, oldChartState: ChartState) => {
    const { chartType, name, templateId, placement, dimensions, metrics, id } = oldChartState;
    return {
        name,
        templateId,
        placement,
        // SUNBURST can have multiple dimensions
        // STACK_LINE can have one dimensions
        dimensions: chartType === 'SUNBURST' || newChartType === 'STACK_LINE' ? [dimensions[0]] : dimensions,
        // SUNBURST can have only one metric
        // STACK_LINE can have only one metric
        metrics: newChartType === 'SUNBURST' || newChartType === 'STACK_LINE' ? [metrics[0]] : metrics,
        id,
    };
};

const ReportChartSettings = ({
    componentState,
    template,
    compatibleFilters,
    dimensionOptions,
    metricOptions,
    filterOptionsMap,
    loading,
    filtersLoading,
    canEdit,
    onChange,
    onFiltersChange,
}: ComponentSettings<ChartState>) => {
    const handleChartChange = (chartType: ChartType) => {
        const defaultChartProps = CHARTS.find((c) => c.chartType === chartType);
        if (!defaultChartProps) {
            return;
        }

        onChange({
            ...defaultChartProps,
            ...keepChartProps(chartType, componentState),
        });
    };

    const handleFiltersChange = async (filters: ReportFilterWithOperator[]) => {
        const resolvedFilters = await onFiltersChange(filters);
        onChange({
            ...componentState,
            customFilters: reportFiltersToCustomFilters(resolvedFilters),
        });
    };

    const handleTemplateChange = (newTemplate: Template) =>
        onChange({
            ...componentState,
            templateId: newTemplate.id,
            metrics: componentState.metrics.filter((metric) =>
                newTemplate.metrics.some((m) => m.key === metric)
            ),
            dimensions: componentState.dimensions.filter((metric) =>
                newTemplate.dimensions.some((m) => m.key === metric)
            ),
        });

    return (
        <>
            <hr />
            <TemplateSelect
                templateId={componentState.templateId}
                canEdit={canEdit}
                onChange={handleTemplateChange}
            />
            <SelectWithAddon
                className="mb-3"
                selectClassname="flex-fill"
                value={componentState.chartType}
                name="Type"
                options={CHARTS}
                isClearable={false}
                getOptionValue={(option: ChartState) => option.chartType}
                getOptionLabel={(option: ChartState) => option.name}
                formatOptionLabel={formatChartOption}
                onChange={handleChartChange}
            />
            <InputWithAddOn
                className="mb-3"
                value={componentState.title || ''}
                placeholder="Optional title"
                type="text"
                leftAddOn={{ title: 'Name' }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChange({ ...componentState, title: e.target.value })
                }
            />
            {componentState.chartType === 'LINE' && (
                <ReportLineChartSettings
                    componentState={componentState}
                    metrics={metricOptions}
                    dimensions={dimensionOptions}
                    isLoading={loading}
                    onChange={onChange}
                />
            )}
            {componentState.chartType === 'STACK_LINE' && (
                <ReportStackLineChartSettings
                    componentState={componentState}
                    metrics={metricOptions}
                    dimensions={dimensionOptions}
                    isLoading={loading}
                    onChange={onChange}
                />
            )}
            {componentState.chartType === 'DOUGHNUT' && (
                <ReportDoughnutChartSettings
                    componentState={componentState}
                    metrics={metricOptions}
                    dimensions={dimensionOptions}
                    isLoading={loading}
                    onChange={onChange}
                />
            )}
            {componentState.chartType === 'BAR' && (
                <ReportBarChartSettings
                    componentState={componentState}
                    metrics={metricOptions}
                    dimensions={dimensionOptions}
                    isLoading={loading}
                    onChange={onChange}
                />
            )}
            {componentState.chartType === 'STACK_BAR' && (
                <ReportStackBarChartSettings
                    componentState={componentState}
                    metrics={metricOptions}
                    dimensions={dimensionOptions}
                    isLoading={loading}
                    onChange={onChange}
                />
            )}
            {componentState.chartType === 'WORD_CLOUD' && (
                <ReportWordCloudChartSettings
                    componentState={componentState}
                    metrics={metricOptions}
                    dimensions={dimensionOptions}
                    isLoading={loading}
                    onChange={onChange}
                />
            )}
            {componentState.chartType === 'SUNBURST' && (
                <ReportSunburstChartSettings
                    componentState={componentState}
                    metrics={metricOptions}
                    dimensions={dimensionOptions}
                    isLoading={loading}
                    onChange={onChange}
                />
            )}
            <FormGroup className="mt-3 mb-0">
                <ReportCustomDateRange
                    customDateRange={componentState.customDateRange}
                    onChange={(customDateRange) => onChange({ ...componentState, customDateRange })}
                />
            </FormGroup>
            <FormGroup className="mt-3">
                <ReportCustomFilters
                    template={template}
                    compatibleFilters={compatibleFilters}
                    filters={customFiltersToReportFilters(componentState.customFilters)}
                    filterOptionsMap={filterOptionsMap}
                    loading={filtersLoading}
                    onChange={handleFiltersChange}
                />
            </FormGroup>
            <hr />
        </>
    );
};

export default ReportChartSettings;
