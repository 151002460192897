import { Optional, SelectItem } from 'platform/common/common.types';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { makeOptions } from 'platform/common/utils/option.util';

export type IdToName = Record<number, string>;

export type TaskStatus = 'ACTIVE' | 'DONE' | 'ARCHIVED';

export const TASK_STATUS_LABELS: Record<TaskStatus, string> = {
    ACTIVE: 'Active',
    DONE: 'Done',
    ARCHIVED: 'Archived',
};

export const TASK_STATUS_OPTIONS = makeOptions(TASK_STATUS_LABELS);

export interface TaskData {
    name: string;
    status: TaskStatus;
    description?: string;
    startDate?: string;
    dueDate?: string;
    tentativeDates?: boolean;
    assigneeIds?: number[];
    notifiedUserIds?: number[];
    labelKeys?: string[];
    display?: TaskDisplayOptions;
    blockedBy?: number[];
}

export interface TaskCreationData {
    name: string;
    status: ActiveOrArchived;
    labelKeys?: string[];
    dueDate?: string;
    assigneeIds?: number[];
    description?: string;
}

export interface Task extends TaskData, AuditProps {
    id: number;
    attachments?: TaskAttachment[];
    subtasks?: Task[];
    lastMovedToLane?: string;
    syncing?: boolean;
    hidden?: boolean;
}

export interface TaskAttachment {
    id: number;
    name: string;
    size: number;
}

export interface TaskComment {
    id: number;
    text: string;
    createdOn: string;
    createdBy: string;
    userId: number;
}

interface TaskDisplayOptions {
    showDescriptionOnCard?: boolean;
}

export type TaskLaneType = 'WORK_FINISHED' | 'DONE';

export const TASK_LANE_TYPE_OPTIONS: SelectItem<TaskLaneType | undefined>[] = [
    { value: undefined, label: 'Active' },
    { value: 'WORK_FINISHED', icon: 'fa-flag', label: 'Work finished' },
    { value: 'DONE', icon: 'fa-circle-check', label: 'Done (change status)' },
];

export interface TaskLaneTimeout {
    days: number;
    notifyLaneOwners?: boolean;
    notifyTaskUsers?: boolean;
}

export interface TaskLaneData {
    name: string;
    type?: TaskLaneType;
    ownerIds?: number[];
    ownerMustBeAssignee?: boolean;
    timeout?: TaskLaneTimeout;
}

export interface TaskLane extends TaskLaneData, AuditProps {
    id: number;
    tasks: Task[];
    syncing?: boolean;
}

export type ParentLaneLocation = { laneId: number; position: number };
export type ParentTaskLocation = { parentTaskId: number; position: number };
export type TaskLocation = ParentLaneLocation | ParentTaskLocation;

export interface TaskBoard extends AuditProps {
    id: number;
    name: string;
    status: ActiveOrArchived;
    internal?: boolean;
    views?: TaskBoardView[];
}

export interface TaskBoardViewFilters {
    labels?: string[];
    lanes?: number[];
}

export interface TaskBoardView {
    name: string;
    filters: TaskBoardViewFilters;
}

export interface TaskBoardFormModel extends Optional<TaskBoard, 'id'> {
    labelNames: LabelNames;
    laneOptions: TaskLane[];
}

export type LabelNames = Record<string, string>;

export interface TaskBoardStateData {
    name: string;
    status: ActiveOrArchived;
    labelNames?: LabelNames;
}

export interface TaskBoardState extends TaskBoardStateData {
    id: number;
    advertiserId: number;
    version: number;
    lanes: TaskLane[];
    views: TaskBoardView[];
}

interface AuditProps {
    createdOn?: string;
    createdBy?: string;
    updatedOn?: string;
    updatedBy?: string;
}

type BoardEvent<Type, Details> = {
    type: Type;
    version: number;
    updatedOn: string;
    updatedBy: string;
    details: Details;
};

type LaneEvent<Type, Details> = BoardEvent<Type, Details> & {
    laneId: number;
};

type TaskEvent<Type, Details> = BoardEvent<Type, Details> & {
    laneId?: number;
    parentTaskId?: number;
    taskId: number;
};

export type LaneChange =
    | LaneEvent<'LANE_ADDED', { position: number; data: TaskLaneData }>
    | LaneEvent<'LANE_UPDATED', { data: TaskLaneData }>
    | LaneEvent<'LANE_MOVED', { position: number }>
    | LaneEvent<'LANE_DELETED', undefined>;

export type TaskChange =
    | TaskEvent<'TASK_ADDED', { containerName: string; position: number; data: TaskData }>
    | TaskEvent<'TASK_UPDATED', { data: Partial<TaskData> }>
    | TaskEvent<'TASK_REORDERED', { containerName: string; position: number }>
    | TaskEvent<'TASK_MOVED', { containerName: string; position: number }>
    | TaskEvent<'TASK_DELETED', undefined>
    | TaskAttachmentChange
    | TaskCommentChange;

export type TaskAttachmentChange =
    | TaskEvent<'ATTACHMENT_ADDED', { attachment: TaskAttachment }>
    | TaskEvent<'ATTACHMENT_DELETED', { attachment: TaskAttachment }>;

type TaskCommentChange =
    | TaskEvent<'COMMENT_ADDED', { id: number; text: string }>
    | TaskEvent<'COMMENT_REMOVED', { id: number; text: string }>;

export type TaskBoardChange =
    | BoardEvent<'BOARD_UPDATED', { data: TaskBoardStateData }>
    | LaneChange
    | TaskChange;

export type TaskBoardChangeInclude = 'ALL' | 'TASKS_AND_SUBTASKS' | 'TASKS_ONLY';

export type TaskLabelOption = { value: string; label: string };
