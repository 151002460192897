import { CSSProperties } from 'react';
import { ErrorDetails } from 'platform/common/error.types';
import CloudErrorIcon from '../../../assets/icons/CLOUD_ERROR.svg';
import ErrorIndicator from './ErrorIndicator';

interface Props {
    error: ErrorDetails | undefined;
    onRetry?: () => void;
    style?: CSSProperties;
    hideIndicator?: boolean;
}

const ErrorMessage = ({ error, onRetry, hideIndicator, style = { height: 200 } }: Props) => {
    if (!error) {
        return null;
    }

    return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={style}>
            <img src={CloudErrorIcon} width={70.75} alt="Cloud error" />
            <h6 className="mt-2">Could not load data</h6>
            {!hideIndicator && <ErrorIndicator error={error} position="static" onRetry={onRetry} />}
        </div>
    );
};

export default ErrorMessage;
