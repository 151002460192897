import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import TimedOfferForm from './TimedOfferForm';
import { createTimedOffer, fetchTimedOffer, updateTimedOffer } from './timedOffer.service';
import { TimedOffer, TimedOfferFormModel } from './timedOffer.types';

const toPayload = (model: TimedOfferFormModel, advertiserId: number, id: number): TimedOffer => ({
    id,
    advertiserId,
    name: model.name,
    status: model.status,
    dateFrom: model.dateRange.from,
    dateTo: model.dateRange.to,
});

const TimedOfferFormContainer = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);
    const advertiserId = useSelector(activeAdvertiserSelectors.id);

    const handleOpen = async (): Promise<TimedOfferFormModel> => {
        if (id) {
            const timedOffer = await fetchTimedOffer(id);

            return {
                name: timedOffer.name,
                status: timedOffer.status,
                dateRange: { from: timedOffer.dateFrom, to: timedOffer.dateTo },
            };
        }

        return { name: '', status: 'ACTIVE', dateRange: { from: '', to: '' } };
    };

    const handleSubmit = (model: TimedOfferFormModel) => {
        const payload = toPayload(model, advertiserId, id);

        if (id) {
            return updateTimedOffer(payload);
        }

        return createTimedOffer(payload);
    };

    return (
        <FormContainer
            helpKey="timed_offer_form"
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            redirectTo={redirectTo}
            canEdit={canEdit}
            isEdit={!!id}
            sidePanel
            onSubmitFinish={afterSubmit}
        >
            {(props) => <TimedOfferForm {...props} />}
        </FormContainer>
    );
};

export default TimedOfferFormContainer;
