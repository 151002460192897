import styles from '../constants/styles.constant.scss';

export const CHART_COLORS = [
    styles.darkBlue,
    styles.lightSlateGray,
    styles.green,
    styles.blue,
    styles.lightGreen,
    styles.grayDark,
    styles.grey,
    styles.teal,
    styles.cadetBlue,
    styles.lightBlue,
    styles.lightGrey,
    styles.lightTeal,
    styles.lighterBlue,
    styles.lighterGrey,
    styles.lighterTeal,
];

export const getChartColors = (chartLength: number) => {
    const colors: string[] = [];
    for (let i = 0; i < chartLength; i += 1) {
        const colourIndex = i % CHART_COLORS.length;
        colors.push(CHART_COLORS[colourIndex]);
    }

    // On pie carts we don't want two colors to be next to each other
    if (colors.length > 1 && colors[colors.length - 1] === colors[0]) {
        colors[colors.length - 1] = styles.grey;
    }
    return colors;
};
