import { useState } from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash-es';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import Avatar from 'platform/common/components/Avatar/Avatar';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import RawHtml from 'platform/common/components/RawHtml/RawHtml';
import { useLoading } from 'platform/common/hooks/useLoading';
import { usePromise } from 'platform/common/hooks/usePromise';
import { inTextLinkFormat } from 'platform/common/utils/url.util';
import { createTaskComment, deleteTaskComment, getTaskComments } from 'platform/task/task.service';
import { User } from 'platform/userManagement/types/user.type';
import KanbanCardLabel from './KanbanCardLabel';
import KanbanCommentForm from './KanbanCommentForm';
import KanbanTimestamp from './KanbanTimestamp';
import KanbanToggleButton from './KanbanToggleButton';

interface Props {
    boardId: number;
    taskId: number;
    users: User[];
}

const KanbanCommentList = ({ boardId, taskId, users }: Props) => {
    const profile = useSelector(authSelectors.ready.profile);
    const [adding, setAdding] = useState(false);
    const [loading, withLoading] = useLoading();
    const [{ data: comments }, refetch] = usePromise([], () => getTaskComments(boardId, taskId), [
        boardId,
        taskId,
    ]);

    const handleAdd = (text: string) =>
        withLoading(async () => {
            await createTaskComment(boardId, taskId, text);
            await refetch();
            setAdding(false);
        });

    const handleDelete = (commentId: number) =>
        withLoading(async () => {
            await deleteTaskComment(boardId, taskId, commentId);
            await refetch();
        });

    return (
        <>
            <div className="d-flex mb-3">
                <KanbanCardLabel icon="fa fa-comment">Comments</KanbanCardLabel>
                <KanbanToggleButton onClick={() => setAdding(true)} />
            </div>
            <div className="position-relative">
                {loading && <OverlayLoader />}
                {adding && <KanbanCommentForm onSubmit={handleAdd} onCancel={() => setAdding(false)} />}
                {orderBy(comments, [(c) => c.createdOn], ['desc']).map((comment) => (
                    <div key={comment.id} className="Kanban-comment-list-item mt-2 mb-3">
                        <Avatar
                            className="mt-1 me-2"
                            size="tiny"
                            name={comment.createdBy}
                            imageUrl={users.find((u) => u.id === comment.userId)?.imageUrl}
                        />
                        <div className="flex-grow-1">
                            <div className="mb-2">
                                <b className="me-1">{comment.createdBy}</b>
                                <KanbanTimestamp date={comment.createdOn} />
                            </div>
                            <RawHtml>{inTextLinkFormat(comment.text)}</RawHtml>
                        </div>
                        <div className="ms-1">
                            {comment.userId === profile.id && (
                                <InlineDropdown
                                    items={[
                                        {
                                            icon: 'fa-trash',
                                            label: 'Delete',
                                            action: () => handleDelete(comment.id),
                                            confirmation: 'Permanently delete this comment?',
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default KanbanCommentList;
