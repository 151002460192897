import { ApolloError } from '@apollo/client';
import { FilterInputProps } from 'platform/analytics/modes/Analytics';
import { PopoverContainerType } from 'platform/common/components/Popover/Popover';
import {
    DimensionFilterOption,
    FilterDefinition,
    FilterOperator,
    ReportFilterWithOperator,
} from '../../analytics.types';
import AnalyticsContainFilter from './AnalyticsContainFilter';
import AnalyticsInFilter from './AnalyticsInFilter';

const SUPPORTED_OPERATORS: FilterOperator[] = ['IS', 'IS_NOT', 'CONTAINS', 'CONTAINS_NOT'];

interface Props {
    filter: ReportFilterWithOperator;
    filterDefinition: FilterDefinition;
    options: DimensionFilterOption[];
    error?: ApolloError;
    filterInputProps?: FilterInputProps;
    disabled?: boolean;
    loading: boolean;
    refetch: () => void;
    onChange: (filter: ReportFilterWithOperator) => void;
    onRemove: () => void;
    container?: PopoverContainerType;
}

const AnalyticsOperatorFilter = ({
    filter,
    filterDefinition,
    options,
    error,
    filterInputProps,
    disabled,
    loading,
    refetch,
    onChange,
    onRemove,
    container,
}: Props) => {
    const multi = filterInputProps?.isMulti ?? filterDefinition.isMulti ?? true;

    switch (filter.operator) {
        case 'CONTAINS':
        case 'CONTAINS_NOT':
            return (
                <AnalyticsContainFilter
                    name={filterDefinition.name}
                    filter={filter}
                    operators={SUPPORTED_OPERATORS}
                    options={options}
                    error={error}
                    multi={multi}
                    loading={loading}
                    disabled={disabled}
                    refetch={refetch}
                    onChange={onChange}
                    onRemove={onRemove}
                    container={container}
                />
            );
        default:
            return (
                <AnalyticsInFilter
                    name={filterDefinition.name}
                    filter={filter}
                    operators={SUPPORTED_OPERATORS}
                    options={options}
                    error={error}
                    multi={multi}
                    loading={loading}
                    disabled={disabled}
                    refetch={refetch}
                    onChange={onChange}
                    onRemove={onRemove}
                    container={container}
                />
            );
    }
};

export default AnalyticsOperatorFilter;
