import FormFlightLabelSelect from 'platform/campaign/common/FormFlightLabelSelect';
import FormProductLabelSelect from 'platform/campaign/common/FormProductLabelSelect';
import FormProductSubLabelSelect from 'platform/campaign/common/FormProductSubLabelSelect';
import { toApiModel } from 'platform/campaign/strategy/mappers/strategy.mapper';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import FormContainer, { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import { updateStrategy } from '../../services/strategy.service';
import { StrategyModel } from '../../strategy.types';

type Props = {
    strategy: StrategyModel;
    afterSubmit: () => void;
    onCancel: () => void;
};

const StrategyForm = ({ strategy, afterSubmit, onCancel }: Props) => (
    <FormContainer
        helpKey="strategy_form"
        sidePanel={false}
        onOpen={async () => strategy}
        onSubmit={(model) => updateStrategy(strategy.id, toApiModel(model))}
        redirectAfterSubmit={false}
        onSubmitFinish={afterSubmit}
        onCancel={onCancel}
        canEdit
        isEdit
    >
        {({ formikProps: { values, setFieldValue, submitForm } }: FormProps<StrategyModel>) => (
            <CardForm
                title="Strategy"
                subtitle={`ID: ${strategy.id}`}
                submitLabel="Save & continue"
                onSubmit={submitForm}
                onCancel={onCancel}
            >
                <ControlledCard title="General information">
                    <FormRow label="Advertiser">{strategy.advertiser.name}</FormRow>
                    <FormRow label="Campaign">{strategy.campaign.name}</FormRow>
                    <FormRow label="Name">
                        <FormInput name="name" validate={required} disabled={strategy.type === 'RTB'} />
                    </FormRow>
                    <FormRow label="Product">
                        <FormProductLabelSelect
                            name="productLabel"
                            advertiserId={strategy.advertiser.id}
                            onChange={() => setFieldValue('productSubLabel', null)}
                        />
                    </FormRow>
                    {!!values.productLabel?.value && (
                        <FormRow label="Sub-product">
                            <FormProductSubLabelSelect
                                name="productSubLabel"
                                advertiserId={strategy.advertiser.id}
                                productLabel={values.productLabel}
                            />
                        </FormRow>
                    )}
                    <FormRow label="Flight">
                        <FormFlightLabelSelect name="flightLabel" advertiserId={strategy.advertiser.id} />
                    </FormRow>
                </ControlledCard>
            </CardForm>
        )}
    </FormContainer>
);

export default StrategyForm;
