import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash-es';
import Avatar from 'platform/common/components/Avatar/Avatar';
import IconButton from 'platform/common/components/IconButton/IconButton';
import Popover from 'platform/common/components/Popover/Popover';
import Select from 'platform/common/components/Select/Select';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import UserIcons from 'platform/common/components/UserSelect/UserIcons';
import useToggle from 'platform/common/hooks/useToggle';
import { User } from 'platform/userManagement/types/user.type';
import './KanbanCardUserEdit.scss';

interface Props {
    className?: string;
    showOwnerMissing?: boolean;
    userIds: number[];
    userOptions: User[];
    onChange?: (val: number[]) => void;
}

const KanbanCardUserEdit = ({ className, showOwnerMissing, userIds, userOptions, onChange }: Props) => {
    const [search, setSearch] = useState('');
    const [selectedIds, setSelectedIds] = useState<number[]>(userIds);
    const [toggle, onToggle] = useToggle(false);

    const optionsList = userOptions
        .filter(({ id }) => !selectedIds?.includes(id))
        .filter(({ name }) => name.toLowerCase()?.includes(search.toLowerCase()));

    useEffect(() => {
        if (!isEqual(userIds, selectedIds)) {
            setSelectedIds(userIds);
        }
    }, [userIds]);

    const handleToggle = (open: boolean) => {
        if (!open && !isEqual(userIds, selectedIds) && onChange) {
            onChange(selectedIds);
        }
        onToggle(open);
        setSearch('');
    };

    return (
        <Popover
            isOpen={toggle}
            popoverClassName="KanbanCardUserEdit-Popover"
            onToggle={handleToggle}
            className={className}
            placement="bottom-end"
            preventOverflow
            renderPopover={() => (
                <div className="popover-container">
                    <div className="KanbanCardUserEdit-Select">
                        <Select
                            options={userOptions.map((op) => ({ value: op.id, label: op.name }))}
                            value={selectedIds}
                            onChange={(vals) => setSelectedIds(vals)}
                            isMulti
                            components={{
                                IndicatorSeparator: null,
                                DropdownIndicator: null,
                            }}
                            autoFocus
                            menuIsOpen={false}
                            onInputChange={(val, action) =>
                                action.action === 'input-change' && setSearch(val)
                            }
                        />
                    </div>
                    <div className="KanbanCardUserEdit-Option-List">
                        {optionsList.map(({ id, name, imageUrl }) => (
                            <div
                                key={id}
                                className="KanbanCardUserEdit-Option-Item"
                                role="button"
                                tabIndex={-1}
                                onClick={() => {
                                    setSelectedIds([...selectedIds, id]);
                                    setSearch('');
                                }}
                            >
                                <Avatar size="tiny" name={name} imageUrl={imageUrl} />
                                <div>{name}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        >
            <IconButton className="KanbanCardUserEdit-IconList">
                {showOwnerMissing && (
                    <Tooltip
                        className="d-flex align-items-center"
                        renderTooltip={() => 'Column owner is not assigned to this task'}
                    >
                        <i className="fa fa-user-crown Kanban-red me-3" />
                    </Tooltip>
                )}
                <div
                    className={classNames('UserIcon KanbanCardUserEdit-AddUserIcon', {
                        'Kanban--show-on-hover': userIds.length > 0,
                    })}
                >
                    <i className="fa fa-user-plus text-muted" />
                </div>
                <UserIcons userIds={userIds.slice(0, 5)} options={userOptions} />
            </IconButton>
        </Popover>
    );
};

export default KanbanCardUserEdit;
