import { ChangeEvent } from 'react';
import { Button } from 'reactstrap';
import { InteractionComponentState, InteractionSelect } from 'platform/analytics/analytics.types';
import IconButton from 'platform/common/components/IconButton/IconButton';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';

interface Props {
    componentState: InteractionSelect;
    onChange: (state: InteractionComponentState) => void;
}

const ReportInteractionSelectFields = ({ componentState, onChange }: Props) => {
    const handleOptionChange = (index: number, option: { label: string; value?: number }) => {
        if (componentState.interactionType !== 'SELECT') {
            return;
        }

        onChange({
            ...componentState,
            selectOptions: componentState.selectOptions.map((o, i) => (i === index ? option : o)),
        });
    };

    return (
        <>
            <InputWithAddOn
                className="mb-3"
                value={componentState.key}
                type="text"
                leftAddOn={{ title: 'Macro' }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChange({ ...componentState, key: e.target.value })
                }
            />
            <InputWithAddOn
                value={componentState.defaultValue}
                type="number"
                leftAddOn={{ title: 'Default value' }}
                className="mb-3"
                inputClassName="border-right-0"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    onChange({
                        ...componentState,
                        defaultValue: e.target.value ? Number(e.target.value) : undefined,
                    });
                }}
            />
            <Button
                color="primary"
                className="mb-3"
                onClick={() =>
                    onChange({
                        ...componentState,
                        selectOptions: [...componentState.selectOptions, { label: '', value: 1 }],
                    })
                }
            >
                Add option
            </Button>
            {componentState.selectOptions.map((option, index) => (
                <div key={index} className="d-flex align-items-center mb-3">
                    <InputWithAddOn
                        className="me-3"
                        value={option.label}
                        type="text"
                        leftAddOn={{ title: 'Label' }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleOptionChange(index, { ...option, label: e.target.value })
                        }
                    />
                    <InputWithAddOn
                        value={option.value}
                        type="number"
                        inputClassName="border-right-0"
                        leftAddOn={{ title: 'Value' }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleOptionChange(index, {
                                ...option,
                                value: e.target.value ? Number(e.target.value) : undefined,
                            })
                        }
                    />
                    <IconButton
                        className="ms-2"
                        onClick={() =>
                            onChange({
                                ...componentState,
                                selectOptions: componentState.selectOptions.filter((o, i) => i !== index),
                            })
                        }
                    >
                        <i className="far fa-circle-xmark text-danger" style={{ fontSize: 16 }} />
                    </IconButton>
                </div>
            ))}
        </>
    );
};

export default ReportInteractionSelectFields;
