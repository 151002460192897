import { Badge, Button, Col, Form, FormGroup, Label } from 'reactstrap';
import { Formik } from 'formik';
import { FormModel } from 'platform/analytics/reportComponents/ReportTableContainer/InlineActions/InlineActionFormContainer';
import { CAMPAIGN_STATE_OPTIONS } from 'platform/campaign/campaign/constants/state.constant';
import { availableStrategyStateOptions } from 'platform/campaign/strategy/constants/state.constant';
import { UNIT_STATE_OPTIONS } from 'platform/campaign/unit/constants/state.constant';
import { CURRENCY_SYMBOL } from 'platform/common/constants/common.constant';
import { BudgetOptimization } from 'platform/common/constants/optimization.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { number, positiveNumber } from 'platform/common/utils/validators.util';
import FormButtonArray from 'platform/formik/FormButtonArray/FormButtonArray';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import { Context } from './InlineActions.types';

enum BidChangeType {
    SET = 'SET',
    DECREASE = 'DECREASE',
    INCREASE = 'INCREASE',
}

const BID_CHANGE_TYPE_OPTIONS = [
    {
        value: BidChangeType.SET,
        label: 'Set bid',
    },
    {
        value: BidChangeType.DECREASE,
        label: 'Dec. %',
    },
    {
        value: BidChangeType.INCREASE,
        label: 'Inc. %',
    },
];

interface Props {
    initialValues: FormModel;
    minBid?: number;
    maxBid?: number;
    inlineActions: string[];
    units: Context['units'];
    advertiser?: Context['advertiser'];
    onCancel: () => void;
    onSubmit: (formData: FormModel) => Promise<void>;
}

const InlineActionsForm = ({
    initialValues,
    onCancel,
    onSubmit,
    minBid,
    maxBid,
    inlineActions,
    units = [],
    advertiser,
}: Props) => (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, dirty, values }) => (
            <Form onSubmit={handleSubmit}>
                {inlineActions.includes('change_campaign_state') && (
                    <FormGroup row className="align-items-center">
                        <Label xs={4} className="text-end pe-0 py-0">
                            Campaign state
                        </Label>
                        <Col xs={6}>
                            <FormSelect name="campaignState" options={CAMPAIGN_STATE_OPTIONS} />
                        </Col>
                    </FormGroup>
                )}

                {inlineActions.includes('change_campaign_budget') && (
                    <>
                        {initialValues.externalSystem === 'FACEBOOK' && (
                            <>
                                <FormGroup row className="align-items-center">
                                    <Label xs={4} className="text-end pe-0 py-0">
                                        <div>Budget optimization</div>
                                    </Label>
                                    <Col xs={6}>
                                        {initialValues.campaignAttributes?.budgetOptimization ? (
                                            <Badge color="primary">ON</Badge>
                                        ) : (
                                            <Badge color="secondary">OFF</Badge>
                                        )}
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="align-items-center">
                                    <Label xs={4} className="text-end pe-0 py-0">
                                        <div>
                                            {initialValues.campaignAttributes?.budgetOptimization ===
                                            BudgetOptimization.LIFETIME
                                                ? 'Campaign lifetime budget'
                                                : 'Campaign spending limit'}
                                        </div>
                                    </Label>
                                    <Col xs={6}>
                                        <FormNumberInput
                                            name="campaignBudget"
                                            label={CURRENCY_SYMBOL}
                                            validate={number}
                                        />
                                    </Col>
                                </FormGroup>
                            </>
                        )}
                        {initialValues.campaignAttributes?.budgetOptimization ===
                            BudgetOptimization.DAILY && (
                            <FormGroup row className="align-items-center">
                                <Label xs={4} className="text-end pe-0 py-0">
                                    <div>Campaign daily budget</div>
                                </Label>
                                <Col xs={6}>
                                    <FormNumberInput
                                        name="campaignDailyBudget"
                                        label={CURRENCY_SYMBOL}
                                        validate={number}
                                    />
                                </Col>
                            </FormGroup>
                        )}
                    </>
                )}

                {inlineActions.includes('change_strategy_state') && initialValues.strategyState && (
                    <FormGroup row className="align-items-center">
                        <Label xs={4} className="text-end pe-0 py-0">
                            Strategy state
                        </Label>
                        <Col xs={6}>
                            <FormSelect
                                name="strategyState"
                                options={availableStrategyStateOptions(initialValues.strategyState)}
                            />
                        </Col>
                    </FormGroup>
                )}

                {inlineActions.includes('change_unit_bid') && (
                    <>
                        <FormGroup row className="align-items-center">
                            <Label xs={4} className="text-end pe-0 py-0">
                                <div>Unit Bid</div>
                                <div className="text-muted font-xs">
                                    {units.length === 1
                                        ? 'Affects 1 unit'
                                        : `Affects ${units.length} units${
                                              minBid === maxBid
                                                  ? ''
                                                  : ` (${DATA_TYPES.EUR.format(
                                                        minBid
                                                    )} - ${DATA_TYPES.EUR.format(maxBid)})`
                                          }`}
                                </div>
                            </Label>
                            <Col xs={6}>
                                <FormButtonArray
                                    name="unitBid.typeOfChange"
                                    buttons={BID_CHANGE_TYPE_OPTIONS}
                                />
                            </Col>
                        </FormGroup>
                        {values.unitBid.typeOfChange === BidChangeType.SET ? (
                            <FormGroup row className="align-items-center">
                                <Label xs={4} className="text-end pe-0 py-0">
                                    New unit bid
                                </Label>
                                <Col xs={6}>
                                    <FormNumberInput
                                        name="unitBid.bidCpm"
                                        step="0.01"
                                        validate={number}
                                        label={CURRENCY_SYMBOL}
                                    />
                                </Col>
                            </FormGroup>
                        ) : (
                            <>
                                <FormGroup row className="align-items-center">
                                    <Label xs={4} className="text-end pe-0 py-0">
                                        {values.unitBid.typeOfChange === BidChangeType.DECREASE &&
                                            'Decrease bids by'}
                                        {values.unitBid.typeOfChange === BidChangeType.INCREASE &&
                                            'Increase bids by'}
                                    </Label>
                                    <Col xs={6}>
                                        <FormNumberInput name="unitBid.changeByPercent" label="%" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="align-items-center">
                                    <Label xs={4} className="text-end pe-0 py-0">
                                        {values.unitBid.typeOfChange === BidChangeType.DECREASE &&
                                            'Lower bid limit'}
                                        {values.unitBid.typeOfChange === BidChangeType.INCREASE &&
                                            'Upper bid limit'}
                                    </Label>
                                    <Col xs={6}>
                                        <FormNumberInput
                                            name="unitBid.limit"
                                            step="0.01"
                                            validate={number}
                                            label={CURRENCY_SYMBOL}
                                        />
                                    </Col>
                                </FormGroup>
                            </>
                        )}
                    </>
                )}

                {inlineActions.includes('change_unit_budget') && (
                    <FormGroup row className="align-items-center">
                        <Label xs={4} className="text-end pe-0 py-0">
                            <div>Budget</div>
                            <div className="text-muted font-xs">
                                {units.length === 1 ? 'Affects 1 unit' : `Affects ${units.length} units`}
                            </div>
                        </Label>
                        <Col xs={6}>
                            <FormNumberInput
                                name="unitBudget"
                                label={CURRENCY_SYMBOL}
                                validate={number}
                                step={0.01}
                            />
                        </Col>
                    </FormGroup>
                )}

                {inlineActions.includes('change_unit_daily_budget') && (
                    <FormGroup row className="align-items-center">
                        <Label xs={4} className="text-end pe-0 py-0">
                            <div>Daily Budget</div>
                            <div className="text-muted font-xs">
                                {units.length === 1 ? 'Affects 1 unit' : `Affects ${units.length} units`}
                            </div>
                        </Label>
                        <Col xs={6}>
                            <FormNumberInput
                                name="unitDailyBudget"
                                label={CURRENCY_SYMBOL}
                                validate={number}
                                step={0.01}
                            />
                        </Col>
                    </FormGroup>
                )}

                {inlineActions.includes('change_unit_state') && (
                    <FormGroup row className="align-items-center">
                        <Label xs={4} className="text-end pe-0 py-0">
                            <div>Unit state</div>
                            <div className="text-muted font-xs">
                                {units.length === 1 ? 'Affects 1 unit' : `Affects ${units.length} units`}
                            </div>
                        </Label>
                        <Col xs={6}>
                            <FormSelect
                                name="unitState"
                                options={UNIT_STATE_OPTIONS}
                                disabled={!units.length || units.some((unit) => unit.stateLocked)}
                            />
                        </Col>
                    </FormGroup>
                )}

                {inlineActions.includes('change_google_keyword_bid_factor') && (
                    <FormGroup row className="align-items-center">
                        <Label xs={4} className="text-end pe-0 py-0">
                            <div>Keyword Bid Multiplier</div>
                            {advertiser && (
                                <div className="text-muted font-xs">
                                    changing multiplier for {advertiser.name} advertiser
                                </div>
                            )}
                        </Label>
                        <Col xs={6}>
                            <FormNumberInput
                                name="bidFactor"
                                label={CURRENCY_SYMBOL}
                                validate={positiveNumber}
                                step={0.01}
                            />
                        </Col>
                    </FormGroup>
                )}

                <div className="mt-3 mb-1 d-flex justify-content-end">
                    <Button color="secondary" className="me-2" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button color="primary" type="submit" disabled={!dirty}>
                        Apply
                    </Button>
                </div>
            </Form>
        )}
    </Formik>
);

export default InlineActionsForm;
