import { ReactNode } from 'react';
import { Alert, Button } from 'reactstrap';
import { bulkUpdateCampaigns } from 'platform/campaign/campaign/services/campaign.service';
import { bulkUpdateStrategies } from 'platform/campaign/strategy/services/strategy.service';
import { SelectItem } from 'platform/common/common.types';
import Spinner from 'platform/common/components/Spinner/Spinner';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { useLoading } from 'platform/common/hooks/useLoading';
import { isValueChanged } from './EditableReportTableCell/EditableReportTableCell';
import { LabelOptionsProvider } from './LabelOptionsContext';

export interface ReportTableFormModel {
    rowChanges: {
        campaign_id: number;
        campaign_product_sub_label?: SelectItem<string>;
        campaign_product_label?: SelectItem<string>;
        campaign_flight_label?: SelectItem<string>;
        country_labels?: SelectItem<string>[];
        strategy_id: number;
        strategy_product_label?: SelectItem<string>;
        strategy_product_sub_label?: SelectItem<string>;
        strategy_flight_label?: SelectItem<string>;
    }[];
}

interface Props {
    defaultValues: any[];
    afterSubmit: () => void;
    children: ReactNode;
}

const ReportTableFormContainer = ({ defaultValues, afterSubmit, children }: Props) => {
    const [loading, withLoading] = useLoading();
    const handleOpen = async (): Promise<ReportTableFormModel> => ({
        rowChanges: [],
    });

    const handleSubmit = async ({ rowChanges }: ReportTableFormModel) => {
        const requests: Promise<any>[] = [];
        const campaignChanges = rowChanges.filter((change) => !!change?.campaign_id);
        const strategyChanges = rowChanges.filter((change) => !!change?.strategy_id);

        if (campaignChanges.length) {
            requests.push(
                bulkUpdateCampaigns({
                    campaigns: campaignChanges.map((change) => ({
                        id: change.campaign_id,
                        flightLabel: change.campaign_flight_label?.value,
                        productLabel: change.campaign_product_label?.value,
                        productSubLabel: change.campaign_product_sub_label?.value,
                        countryLabels: change.country_labels?.map((option) => option.value),
                    })),
                })
            );
        }

        if (strategyChanges.length) {
            requests.push(
                bulkUpdateStrategies({
                    strategies: strategyChanges.map((change) => ({
                        id: change.strategy_id,
                        productLabel: change.strategy_product_label?.value,
                        flightLabel: change.strategy_flight_label?.value,
                        productSubLabel: change.strategy_product_sub_label?.value,
                    })),
                })
            );
        }

        return withLoading(() => Promise.all(requests));
    };

    return (
        <FormContainer
            helpKey="report_table_form"
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
        >
            {({ formikProps: { values, submitForm, resetForm } }) => {
                const changedRows = values.rowChanges.reduce((acc, formChange, index) => {
                    if (!formChange) return acc;

                    const isRowChanged = Object.entries(formChange)
                        .filter(([key]) => key !== 'campaign_id' && key !== 'strategy_id')
                        .some(([key, formValue]) => isValueChanged(formValue, defaultValues[index][key]));

                    return isRowChanged ? acc + 1 : acc;
                }, 0);

                return (
                    <>
                        {!!changedRows && (
                            <Alert color="warning" className="rounded-0 d-flex align-items-center">
                                {changedRows} rows changed. Would you like to save?
                                <Button
                                    disabled={loading}
                                    onClick={submitForm}
                                    className="ms-2 d-flex align-items-center"
                                >
                                    Save
                                    {loading && <Spinner className="ms-1" size="sm" />}
                                </Button>
                                <Button
                                    disabled={loading}
                                    onClick={() => resetForm()}
                                    className="ms-2 d-flex align-items-center"
                                >
                                    Cancel
                                </Button>
                            </Alert>
                        )}
                        <LabelOptionsProvider>{children}</LabelOptionsProvider>
                    </>
                );
            }}
        </FormContainer>
    );
};

export default ReportTableFormContainer;
