import { useCallback, useMemo, useState } from 'react';
import { Button, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalFooter } from 'reactstrap';
import classNames from 'classnames';
import { noop } from 'lodash-es';
import { generateOlapReport } from 'platform/analytics/analytics.service';
import { ReportFilter, OlapReport } from 'platform/analytics/analytics.types';
import { getFilterNumberValue } from 'platform/analytics/analytics.util';
import { fetchFlightLabels } from 'platform/campaign/advertiserManagement/FlightLabels/flightLabel.service';
import { fetchProducts } from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.service';
import CampaignStateIndicator from 'platform/campaign/campaign/components/CampaignStateIndicator';
import { CampaignState } from 'platform/campaign/campaign/constants/state.constant';
import { bulkUpdateCampaigns } from 'platform/campaign/campaign/services/campaign.service';
import { Period, TableCell } from 'platform/common/common.types';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import LabelWithSubtext from 'platform/common/components/LabelWithSubtext/LabelWithSubtext';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import CreatableSelect from 'platform/common/components/Select/CreatableSelect';
import { OptionType } from 'platform/common/components/Select/select.types';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { usePromise } from 'platform/common/hooks/usePromise';
import { namesToOptions } from 'platform/common/utils/option.util';

interface Props {
    toggle: () => void;
    afterUpdate: () => void;
    filters: ReportFilter[];
    date: Period;
}

interface DataRow {
    advertiser_id: number;
    advertiser_name: string;
    campaign_id: number;
    campaign_name: string;
    campaign_state: CampaignState;
    campaign_product_label: string;
    campaign_flight_label: string;
    campaign_runtime_from: string;
    campaign_runtime_to: string;
}

const isCampaignSelected = (campaignId: number, selectedCampaigns: number[]) =>
    selectedCampaigns.includes(campaignId);

const BulkCampaignLabelModal = ({ toggle, afterUpdate, filters, date }: Props) => {
    const [selectedCampaigns, selectCampaigns] = useState<number[]>([]);
    const [productLabel, updateProductLabel] = useState<OptionType | null>(null);
    const [flightLabel, updateFlightLabel] = useState<OptionType | null>(null);

    const [{ loading, data, error }] = usePromise<OlapReport<DataRow> | undefined>(
        undefined,
        () =>
            generateOlapReport({
                templateId: 'campaign_list',
                dimensions: [
                    'advertiser_id',
                    'advertiser_name',
                    'campaign_id',
                    'campaign_name',
                    'campaign_state',
                    'campaign_product_label',
                    'campaign_flight_label',
                    'campaign_runtime_from',
                    'campaign_runtime_to',
                ],
                metrics: [],
                from: date.from,
                to: date.to,
                dimensionFilters: filters,
            }),
        [filters, date]
    );

    const onRowClick = (campaignId: number) => {
        selectCampaigns(
            selectedCampaigns.includes(campaignId)
                ? selectedCampaigns.filter((id) => id !== campaignId)
                : [...selectedCampaigns, campaignId]
        );
    };

    const onAllCampaignsClick = useCallback(() => {
        selectCampaigns(selectedCampaigns.length ? [] : data?.rows.map((row) => row.campaign_id) || []);
    }, [data, selectedCampaigns.length]);

    const ready = Boolean(!loading && selectedCampaigns.length);
    const advertiserId = getFilterNumberValue(filters, 'advertiser_id')!;

    const onApply = async () => {
        await bulkUpdateCampaigns({
            campaigns: selectedCampaigns.map((id) => ({
                id,
                productLabel: productLabel?.value,
                flightLabel: flightLabel?.value,
            })),
        });
        afterUpdate();
        toggle();
    };

    const [
        {
            data: [productOptions, flightLabelOptions],
            loading: labelLoading,
        },
    ] = usePromise(
        [],
        async () => {
            if (!advertiserId) {
                return Promise.resolve([]);
            }
            const labels = await Promise.all([fetchProducts(advertiserId), fetchFlightLabels(advertiserId)]);
            return labels.map(namesToOptions);
        },
        [advertiserId]
    );

    const columns = useMemo(
        () => [
            {
                Header: () => (
                    <Input
                        type="checkbox"
                        className="position-static m-0"
                        checked={!!selectedCampaigns.length}
                        onChange={onAllCampaignsClick}
                    />
                ),
                width: 70,
                headerClassName: 'cell-align-center',
                className: 'cell-align-center',
                Cell: (props: TableCell<DataRow>) => (
                    <Input
                        type="checkbox"
                        className="position-static m-0"
                        checked={isCampaignSelected(props.original.campaign_id, selectedCampaigns)}
                        readOnly
                    />
                ),
                sortable: false,
            },
            {
                Header: 'Advertiser name',
                autoWidth: true,
                Cell: (props: TableCell<DataRow>) => (
                    <LabelWithSubtext
                        label={props.original.advertiser_name}
                        subtext={DATA_TYPES.ID.format(props.original.advertiser_id)}
                    />
                ),
            },
            {
                Header: 'Campaign name',
                accessor: (row: DataRow) => row.campaign_name,
                Cell: (props: TableCell<DataRow>) => (
                    <>
                        <CampaignStateIndicator className="me-2" state={props.original.campaign_state} />
                        <LabelWithSubtext
                            label={props.original.campaign_name}
                            subtext={DATA_TYPES.ID.format(props.original.campaign_id)}
                        />
                    </>
                ),
                id: 'name',
                autoWidth: true,
            },
            {
                Header: 'Runtime from',
                accessor: (row: DataRow) => row.campaign_runtime_from,
                type: DATA_TYPES.TEXT,
                autoWidth: true,
                id: 'runtimeFrom',
            },
            {
                Header: 'Runtime to',
                accessor: (row: DataRow) => row.campaign_runtime_to,
                type: DATA_TYPES.TEXT,
                autoWidth: true,
                id: 'runtimeTo',
            },
            {
                Header: 'Product',
                accessor: (row: DataRow) => row.campaign_product_label,
                type: DATA_TYPES.TEXT,
                autoWidth: true,
                id: 'productLabel',
            },
            {
                Header: 'Flight',
                accessor: (row: DataRow) => row.campaign_flight_label,
                type: DATA_TYPES.TEXT,
                autoWidth: true,
                id: 'flightLabel',
            },
        ],
        [onAllCampaignsClick, selectedCampaigns]
    );

    return (
        <Modal isOpen toggle={toggle} style={{ maxWidth: '95vw' }}>
            <ModalHeader onClose={toggle}>
                <div className="d-flex flex-row align-items-center px-3 pt-3">
                    <span className="ModalHeader-title">Select campaigns and new labels</span>
                    <div className="d-flex ms-3">
                        <InputGroup className="flex-nowrap">
                            <InputGroupText>Product</InputGroupText>
                            <CreatableSelect
                                className="pe-3"
                                options={productOptions || []}
                                returnOnlyValues={false}
                                isLoading={labelLoading}
                                value={productLabel}
                                placeholder="Select or enter"
                                formatCreateLabel={(value: string) => (
                                    <span>
                                        <b>Create label</b>
                                        {` "${value}"`}
                                    </span>
                                )}
                                onChange={updateProductLabel}
                                onNewOption={updateProductLabel}
                            />
                        </InputGroup>
                        <InputGroup className="flex-nowrap">
                            <InputGroupText>Flight</InputGroupText>
                            <CreatableSelect
                                className="pe-3"
                                options={flightLabelOptions || []}
                                returnOnlyValues={false}
                                isLoading={labelLoading}
                                value={flightLabel}
                                onChange={updateFlightLabel}
                                onNewOption={updateFlightLabel}
                                placeholder="Select or enter"
                                formatCreateLabel={(value: string) => (
                                    <span>
                                        <b>Create label</b>
                                        {` "${value}"`}
                                    </span>
                                )}
                            />
                        </InputGroup>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody style={{ maxHeight: '80vh', overflow: 'scroll' }}>
                <FormattedTable
                    NoDataComponent={error ? () => <ErrorMessage error={error} /> : undefined}
                    data={data?.rows || []}
                    loading={loading || labelLoading}
                    stickyHeader={false}
                    getTrProps={(_: any, rowInfo?: TableCell<DataRow>) => ({
                        className: classNames('cursor-pointer', {
                            selected:
                                rowInfo &&
                                isCampaignSelected(rowInfo.original.campaign_id, selectedCampaigns),
                        }),
                        onClick: rowInfo ? () => onRowClick(rowInfo.original.campaign_id) : noop,
                    })}
                    columns={columns}
                />
            </ModalBody>
            <ModalFooter>
                <div>{ready && `Update will affect ${selectedCampaigns.length} campaign(s)`}</div>
                <div>
                    <Button className="ms-4 me-2" color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                    <AnimatedButton disabled={!ready} onClick={onApply}>
                        Update
                    </AnimatedButton>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default BulkCampaignLabelModal;
