import { useState } from 'react';
import { Alert } from 'reactstrap';
import { fetchDeeplyOlapColumns } from 'platform/adminPanel/components/AdvancedSettings/advanced.setting.service';
import ColumnUsagePopover from 'platform/adminPanel/components/ColumnDefinitions/ColumnUsagePopover';
import {
    COLUMN_TYPE_LABELS,
    ColumnDefinition,
    ColumnGroup,
    ColumnType,
} from 'platform/analytics/analytics.types';
import { CompatibleColumns, QUERY } from 'platform/analytics/hooks/useCompatibleColumns';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import client from 'platform/common/utils/createApolloClient.util';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import ColumnDefinitionLabelOverrides from './ColumnDefinitionLabelOverrides';

interface Props {
    columnType: ColumnType;
    columnGroups: ColumnGroup[];
}

const ColumnDefinitionForm = ({
    columnType,
    columnGroups,
    labels,
    formikProps: { submitForm, values: column },
    onCancel,
}: Props & FormProps<ColumnDefinition>) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const onSubmit = async () => {
        if (column.includeInDeeply) {
            const olapColumns = await fetchDeeplyOlapColumns();

            const compatibleColumns = await client
                .query<CompatibleColumns>({
                    query: QUERY,
                    variables: {
                        templateId: 'all_columns',
                        fields: olapColumns,
                        filter: { dimensionFilters: [] },
                    },
                    fetchPolicy: 'network-only',
                })
                .then((res) => res.data.reportCompatibleColumns || []);

            const isCompatible = olapColumns.includes(column.key) || compatibleColumns.includes(column.key);
            if (!isCompatible) {
                setErrorMessage('This is not compatible with other columns used in Deeply');
                return Promise.reject();
            }
        }
        return submitForm();
    };

    return (
        <CardForm
            title={`${labels.prefix} ${COLUMN_TYPE_LABELS[columnType]}`}
            submitLabel={labels.submit}
            onSubmit={onSubmit}
            onCancel={onCancel}
        >
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            <ControlledCard title="General info">
                <FormRow label={`${COLUMN_TYPE_LABELS[columnType]} key`}>
                    <FormInput name="key" type="text" disabled />
                </FormRow>
                <FormRow>
                    <ColumnUsagePopover columnKey={column.key} columnType={columnType} />
                </FormRow>
                <FormRow label="Name">
                    <FormInput name="name" type="text" validate={required} />
                </FormRow>
                <FormRow label="Alternative name">
                    <FormInput name="altName" type="text" />
                </FormRow>
                <FormRow label="Description">
                    <FormInput name="description" type="textarea" rows={3} />
                </FormRow>
                <FormRow label="Section">
                    <FormSelect
                        name="group"
                        options={columnGroups}
                        valueKey="key"
                        labelKey="name"
                        isClearable={false}
                        validate={required}
                    />
                </FormRow>
                <FormRow label="Data source">
                    <FormInput name="source" type="text" />
                </FormRow>
                <FormRow label="Use in Deeply">
                    <FormSwitch name="includeInDeeply" />
                </FormRow>
            </ControlledCard>
            <ColumnDefinitionLabelOverrides columnKey={column.key} columnType={columnType} />
        </CardForm>
    );
};

export default ColumnDefinitionForm;
