import { useLocation, useParams } from 'react-router';
import { Location } from 'history';
import { stringify } from 'qs';
import { ANALYTICS } from 'platform/analytics/analytics.navigation';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { parseQuery } from 'platform/common/utils/url.util';
import CustomReportForm, {
    CustomReportFormModel,
} from 'platform/customReports/CustomReportForm/CustomReportForm';
import { fetchSeats } from 'platform/userManagement/services/userManagement.service';
import { createCustomReport, fetchCustomReport, updateCustomReport } from './customReport.service';
import { CustomReportWithSettings } from './customReport.types';

const newLinkReport = ({ search }: Location): CustomReportWithSettings => {
    const { type, section, seatId } = parseQuery(search);

    return {
        userIds: [],
        assigneeIds: [],
        name: '',
        type,
        section,
        display: type === 'EXTERNAL' ? { openIn: 'NEW_TAB' } : undefined,
        status: 'ACTIVE',
        systemReportKey: undefined,
        url: '',
        seatIds: seatId ? [seatId] : [],
        usedAsTemplate: false,
        analyticsSettings: undefined,
    };
};

const adjustInternalLinkUrl = (report: CustomReportWithSettings): CustomReportWithSettings => {
    if (report.url?.startsWith(`${ANALYTICS.path}?`) && !parseQuery(report.url).internalLinkId) {
        return { ...report, url: `${report.url}&${stringify({ internalLinkId: report.id })}` };
    }
    return report;
};

const CustomReportFormContainer = ({ redirectTo, canEdit, afterSubmit }: FormContainerProps) => {
    const location = useLocation();
    const params = useParams<{ id: string }>();
    const handleOpen = async (): Promise<CustomReportFormModel> => {
        const [availableSeats, report] = await Promise.all([
            fetchSeats({}),
            params.id ? fetchCustomReport(Number(params.id)) : newLinkReport(location),
        ]);
        return { availableSeats, report };
    };

    const handleSubmit = async ({ report }: CustomReportFormModel) => {
        if (report.type === 'INTERNAL_LINK') {
            const reportWithId = report.id ? report : { ...report, id: await createCustomReport(report) };
            return updateCustomReport(adjustInternalLinkUrl(reportWithId));
        }
        return report.id ? updateCustomReport(report) : createCustomReport(report);
    };

    return (
        <FormContainer
            helpKey="custom_report_form"
            sidePanel
            canEdit={canEdit}
            isEdit={!!params.id}
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
            redirectTo={redirectTo}
        >
            {(props) => <CustomReportForm {...props} />}
        </FormContainer>
    );
};

export default CustomReportFormContainer;
