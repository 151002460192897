import classNames from 'classnames';
import { JourneyEventTypeCM360 } from 'platform/dashboard/dashboard.types';
import { getJourneyIconClass } from '../../CustomerJourney/JourneyRow';

interface Props {
    eventType: JourneyEventTypeCM360;
}

const JourneyEventTypeIcon = ({ eventType }: Props) => (
    <i
        className={classNames(
            'd-flex align-items-center justify-content-center font-xl',
            getJourneyIconClass(eventType)
        )}
        style={{
            borderRadius: '50%',
            width: 45,
            height: 45,
            backgroundColor: TYPE_BG_COLOR_MAP[eventType],
        }}
    />
);

const TYPE_BG_COLOR_MAP: Record<JourneyEventTypeCM360, string> = {
    click: '#F9EDBB',
    view: '#F9EDBB',
    action: '#DCF5E7',
    pageview: '#e7f3fb',
};

export default JourneyEventTypeIcon;
