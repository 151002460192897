import { CustomReport } from 'platform/customReports/customReport.types';

const SET_CUSTOM_REPORTS = 'SET_CUSTOM_REPORTS';

export interface CustomReportState {
    adminSeatCentralReports: CustomReport[];
    accessibleReports: CustomReport[];
}

type SetCustomReports = {
    type: typeof SET_CUSTOM_REPORTS;
    payload: CustomReportState;
};

type AnalyticsCustomReportsActions = SetCustomReports;

const defaultState: CustomReportState = {
    adminSeatCentralReports: [],
    accessibleReports: [],
};

const analyticsCustomReportsReducer = (
    state = defaultState,
    action: AnalyticsCustomReportsActions
): CustomReportState => {
    switch (action.type) {
        case SET_CUSTOM_REPORTS: {
            return action.payload;
        }
        default:
            return state;
    }
};

export const analyticsCustomReportsActions = {
    setCustomReports: (payload: CustomReportState): SetCustomReports => ({
        type: SET_CUSTOM_REPORTS,
        payload,
    }),
};

export default analyticsCustomReportsReducer;
