export const IOScriptExportHeader = {
    vendorName: 'Vendor',
    unitId: 'Unit ID',
    campaignName: 'Campaign name',
    campaignId: 'Campaign ID',
    strategyName: 'Strategy name',
    unitName: 'Unit name',
    creativeName: 'Creative name',
    creativeSize: 'Creative size',
    javascript: 'Javascript',
    iframe: 'iFrame',
    clickTracker: 'Click tracker',
    viewTracker: 'View tracker',
    vast2: 'VAST 2.0',
    vast3: 'VAST 3.0',
    vast4: 'VAST 4.0',
};

export const sortedHeaderArray = Object.freeze([
    'vendorName',
    'campaignName',
    'strategyName',
    'unitName',
    'creativeName',
    'creativeSize',
    'javascript',
    'iframe',
    'clickTracker',
    'viewTracker',
    'vast2',
    'vast3',
    'vast4',
]);
