import { SelectOption } from 'platform/common/common.types';
import AUDIENCE_NETWORK_ICON from '../../assets/icons/external-systems/AUDIENCE_NETWORK.svg';
import BING_ICON from '../../assets/icons/external-systems/BING.svg';
import CM360_ICON from '../../assets/icons/external-systems/CM360.svg';
import CRITEO_ICON from '../../assets/icons/external-systems/CRITEO.svg';
import DV360_ICON from '../../assets/icons/external-systems/DV360.svg';
import FACEBOOK_ICON from '../../assets/icons/external-systems/FACEBOOK.svg';
import GOOGLE_ICON from '../../assets/icons/external-systems/GOOGLE.svg';
import GOOGLE_ANALYTICS_ICON from '../../assets/icons/external-systems/GOOGLE_ANALYTICS.svg';
import GOOGLE_SEARCH_CONSOLE_ICON from '../../assets/icons/external-systems/GOOGLE_SEARCH_CONSOLE.svg';
import RUBICON_ICON from '../../assets/icons/external-systems/RUBICON.svg';
import TABOOLA_ICON from '../../assets/icons/external-systems/TABOOLA.svg';
import TIKTOK_ICON from '../../assets/icons/external-systems/TIKTOK.svg';
import TWITTER_ICON from '../../assets/icons/external-systems/TWITTER.svg';
import YIELDLAB_ICON from '../../assets/icons/external-systems/YIELDLAB.svg';

export type ExternalSystem =
    | 'FACEBOOK'
    | 'GOOGLE'
    | 'BING'
    | 'GCM'
    | 'GOOGLE_ANALYTICS'
    | 'GOOGLE_SEARCH_CONSOLE'
    | 'DV360'
    | 'TIKTOK'
    | 'TWITTER';

export type IntegrationStatus = 'SUCCESS' | 'ERROR' | 'IN_PROGRESS' | 'NEVER' | 'EXPIRED' | 'CANCELED';

export const INTEGRATION_STATUS_INFO: {
    [key in IntegrationStatus]: { label: string; className: string };
} = Object.freeze({
    SUCCESS: { label: 'Completed', className: 'text-success' },
    ERROR: { label: 'Failed', className: 'text-danger' },
    IN_PROGRESS: { label: 'In progress', className: 'text-info' },
    NEVER: { label: 'Inactive', className: 'text-muted' },
    EXPIRED: { label: 'Expired', className: 'text-danger' },
    CANCELED: { label: 'Canceled', className: 'text-warning' },
});

export const INTEGRATIONS_INFO: { [key: string]: { label: string; icon?: any } } = Object.freeze({
    GCM: { label: 'CM360', icon: CM360_ICON },
    YIELDLAB: { label: 'Yieldlab', icon: YIELDLAB_ICON },
    AUDIENCE_NETWORK: { label: 'Audience network', icon: AUDIENCE_NETWORK_ICON },
    RUBICON: { label: 'Rubicon', icon: RUBICON_ICON },
    CRITEO: { label: 'Criteo', icon: CRITEO_ICON },
    FACEBOOK: { label: 'Facebook', icon: FACEBOOK_ICON },
    GOOGLE: { label: 'Google Ads', icon: GOOGLE_ICON },
    GOOGLE_ANALYTICS: { label: 'Google Analytics', icon: GOOGLE_ANALYTICS_ICON },
    GOOGLE_SEARCH_CONSOLE: { label: 'Google Search Console', icon: GOOGLE_SEARCH_CONSOLE_ICON },
    AMAZON: { label: 'Amazon' },
    BING: { label: 'Bing', icon: BING_ICON },
    TABOOLA: { label: 'Taboola', icon: TABOOLA_ICON },
    MEDIAMATH: { label: 'Mediamath' },
    DOUBLECLICK: { label: 'Doubleclick' },
    DYNAMIC_ADS: { label: 'Dynamic Ads' },
    THE_TRADE_DESK: { label: 'The Trade Desk' },
    OUTBRAIN: { label: 'Outbrain' },
    DV360: { label: 'DV360', icon: DV360_ICON },
    TIKTOK: { label: 'TikTok', icon: TIKTOK_ICON },
    TWITTER: { label: 'Twitter', icon: TWITTER_ICON },
});

export const getIntegrationLabel = (system: ExternalSystem): string =>
    INTEGRATIONS_INFO[system]?.label || system;

export const getIntegrationIcon = (system: ExternalSystem): string | undefined =>
    INTEGRATIONS_INFO[system]?.icon;

export const getExternalSystemByLabel = (label: string): ExternalSystem | undefined =>
    Object.keys(INTEGRATIONS_INFO).find((key) => INTEGRATIONS_INFO[key].label === label) as ExternalSystem;

export const getCreativeIntegrationLabel = (system: ExternalSystem): string => {
    const systemLabel = getIntegrationLabel(system);
    return `${systemLabel} ${system === 'GCM' ? 'creative' : 'ad'}`;
};

export const ADVERTISER_INTEGRATIONS: ExternalSystem[] = [
    'FACEBOOK',
    'GOOGLE',
    'BING',
    'GCM',
    'GOOGLE_ANALYTICS',
    'GOOGLE_SEARCH_CONSOLE',
    'DV360',
    'TIKTOK',
    'TWITTER',
];

export const SYNCABLE_INTEGRATIONS: ExternalSystem[] = ['GCM', 'FACEBOOK', 'GOOGLE', 'BING'];

export const IMPORTED_INTEGRATIONS: ExternalSystem[] = [
    'FACEBOOK',
    'GOOGLE',
    'BING',
    'DV360',
    'TIKTOK',
    'TWITTER',
];

export const OBJECTIVE_LABELS: { [key: string]: string } = Object.freeze({
    LINK_CLICKS: 'Traffic',
    POST_ENGAGEMENT: 'Engagement',
    CONVERSIONS: 'Conversions',
    PRODUCT_CATALOG_SALES: 'Catalog sales',
    REACH: 'Reach',
    VIDEO_VIEWS: 'Video views',
});

export const getIntegrationOptions = (integrations: ExternalSystem[]): SelectOption[] =>
    integrations.map((i) => ({
        value: i,
        label: getIntegrationLabel(i),
    }));

export type Adserver = 'GCM';
