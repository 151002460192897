import { Button } from 'reactstrap';
import { createPersonalPrompt, getPersonalPrompts, updatePersonalPrompt } from 'platform/chat/chat.service';
import { PersonalPrompt } from 'platform/chat/chat.types';
import { useModal } from 'platform/common/components/Modal/Modal';
import { usePromise } from 'platform/common/hooks/usePromise';
import ChatPersonalPrompt from './ChatPersonalPrompt';
import ChatPersonalPromptModal from './ChatPersonalPromptModal';

interface Props {
    agentId: number;
    onPromptClick: (prompt: PersonalPrompt) => void;
}

const ChatPersonalPrompts = ({ agentId, onPromptClick }: Props) => {
    const { showModal } = useModal();
    const [{ data: personalPrompts, loading: personalPromptsLoading }, fetchData] = usePromise(
        [],
        () => getPersonalPrompts(),
        []
    );

    if (personalPromptsLoading) return null;

    const agentPersonalPrompts = personalPrompts.filter((p) => p.agentId === agentId);

    const handleSubmit = (formValues: PersonalPrompt, id?: number) => {
        const submitRequest = () =>
            id ? updatePersonalPrompt(id, formValues) : createPersonalPrompt(formValues);

        return submitRequest().then(fetchData);
    };

    return (
        <>
            <strong className="d-block mt-3 mb-1">Personal prompts</strong>
            {agentPersonalPrompts.map((prompt) => (
                <li key={prompt.id} className="mb-2">
                    <ChatPersonalPrompt
                        onSubmit={(formValues) => handleSubmit(formValues, prompt.id)}
                        onPromptClick={onPromptClick}
                        fetchData={fetchData}
                        prompt={prompt}
                    />
                </li>
            ))}

            <div className="d-flex justify-content-end">
                <Button
                    onClick={() =>
                        showModal((toggle) => (
                            <ChatPersonalPromptModal
                                onSubmit={(formValues) => handleSubmit(formValues)}
                                onOpen={async () => ({ name: '', prompt: '', agentId } as PersonalPrompt)}
                                toggle={toggle}
                                isCreate
                            />
                        ))
                    }
                >
                    Add
                </Button>
            </div>
        </>
    );
};

export default ChatPersonalPrompts;
